import { bool, node, string } from 'prop-types'
import Tooltip from './tooltip/Tooltip'

const ConditionalTooltipWrapper = ({ children, condition, tooltipText }) =>
  condition ? <Tooltip content={tooltipText}>{children}</Tooltip> : children

ConditionalTooltipWrapper.propTypes = {
  children: node.isRequired,
  condition: bool.isRequired,
  tooltipText: string.isRequired,
}

export default ConditionalTooltipWrapper
