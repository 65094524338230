import { createContext, useReducer, useContext, useMemo } from 'react'
import { node } from 'prop-types'

const SelectionContext = createContext()

const selectionInitialState = {
  selectedPolicies: [],
  allSelected: false,
}

const selectionReducer = (state, action) => {
  switch (action.type) {
    case 'SELECT_ITEM':
      return {
        ...state,
        selectedPolicies: state.selectedPolicies.some(
          policy => policy.id === action.payload.id
        )
          ? state.selectedPolicies
          : [...new Set([...state.selectedPolicies, action.payload])],
      }

    case 'DESELECT_ITEM':
      return {
        ...state,
        selectedPolicies: state.selectedPolicies.filter(
          policy => policy.id !== action.payload.id
        ),
      }

    case 'SELECT_GROUP':
      return {
        ...state,
        selectedPolicies: [
          ...new Set([...state.selectedPolicies, ...action.payload]),
        ],
        allSelected: false,
      }

    case 'DESELECT_GROUP':
      return {
        ...state,
        selectedPolicies: state.selectedPolicies.filter(
          policy => !action.payload.some(p => p.id === policy.id)
        ),
        allSelected: state.selectedPolicies.length !== action.payload.length,
      }

    case 'SELECT_ALL': {
      const filteredIds = action.payload.map(item => item.id) // Extract IDs from filtered data
      const selectedIds = state.selectedPolicies.map(policy => policy.id) // Extract IDs from selected policies

      // Determine if all filtered IDs are already selected
      const isAllSelected = filteredIds.every(id => selectedIds.includes(id))

      return {
        ...state,
        selectedPolicies: isAllSelected
          ? state.selectedPolicies.filter(
              policy => !filteredIds.includes(policy.id)
            ) // Remove filtered IDs
          : [
              ...state.selectedPolicies,
              ...action.payload.filter(
                policy => !selectedIds.includes(policy.id)
              ),
            ], // Add missing filtered IDs
        allSelected: !isAllSelected, // Toggle allSelected
      }
    }

    case 'RESET':
      return selectionInitialState
    default:
      return state
  }
}

export const SelectionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(selectionReducer, selectionInitialState)

  const contextValue = useMemo(() => {
    const getSelectedItemsCount = () => state.selectedPolicies.length

    const getGroupSelectedItemsCount = groupPolicies =>
      groupPolicies.filter(policy =>
        state.selectedPolicies.some(
          selectedPolicy => selectedPolicy.id === policy.id
        )
      ).length

    const selectAll = items => {
      dispatch({
        type: 'SELECT_ALL',
        payload: items,
      })
    }

    const selectItem = (policy, select) => {
      dispatch({
        type: select ? 'SELECT_ITEM' : 'DESELECT_ITEM',
        payload: policy,
      })
    }

    const selectGroup = (groupPolicies, select) => {
      dispatch({
        type: select ? 'SELECT_GROUP' : 'DESELECT_GROUP',
        payload: groupPolicies,
      })
    }

    const resetSelectionState = () => {
      dispatch({
        type: 'RESET',
      })
    }

    return {
      state,
      dispatch,
      getSelectedItemsCount,
      getGroupSelectedItemsCount,
      selectAll,
      selectGroup,
      selectItem,
      resetSelectionState,
    }
  }, [state, dispatch])

  return (
    <SelectionContext.Provider value={contextValue}>
      {children}
    </SelectionContext.Provider>
  )
}

export const useSelection = () => {
  const context = useContext(SelectionContext)
  if (!context) {
    throw new Error('useSelection must be used within a SelectionProvider')
  }
  return context
}

SelectionProvider.propTypes = {
  children: node.isRequired,
}
