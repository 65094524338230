import Axios from 'axios'
import { useQuery } from '@tanstack/react-query'

const usePolicyDiff = comparisonParams =>
  useQuery({
    queryKey: ['policyDiff', comparisonParams],
    queryFn: async () => {
      const response = await Axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/tenant-alignment/policies`,
        { params: comparisonParams }
      )
      return response.data.data
    },
    enabled: !!comparisonParams,
  })

export default usePolicyDiff
