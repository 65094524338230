import { object, arrayOf, bool } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleMinus,
  faCirclePlus,
  faSquareCheck,
} from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'
import { useExpansion } from '../context/ExpansionContext'
import { useSelection } from '../context/SelectionContext'

/**
 * ToolbarButtons component
 * @param {Array<{name: string, subGroups: Array<{name: string, categories: Array<{name: string, items: Array<{name: string, type: string}>}>}>}>} groupedPolicies - groupedPolicies
 * @param allPolicies
 * @param filtersEnabled
 * @returns {JSX.Element}
 */
const ToolbarButtons = ({ groupedPolicies, allPolicies, filtersEnabled }) => {
  const { state: selectionState, selectAll } = useSelection()
  const { selectedPolicies } = selectionState
  const { isAllExpanded, toggleExpandAll } = useExpansion()

  // Reduce function to extract all names
  const allGroups = groupedPolicies.reduce((acc, group) => {
    // Add the group name as a top-level item
    acc.push(group.name)

    // Iterate over subGroups and add their names, prefixed with the group name
    group.subGroups.forEach(subGroup => {
      acc.push(`${group.name}-${subGroup.name}`) // Combine group name and subGroup name for uniqueness

      // Iterate over categories within the subGroups and add their names
      subGroup.categories.forEach(category => {
        acc.push(`${group.name}-${subGroup.name}-${category.name}`) // Combine all levels for uniqueness
      })
    })

    return acc
  }, [])

  const handleExpandAll = () => {
    toggleExpandAll({ parentGroup: '', items: allGroups })
  }

  const handleSelectAll = () => {
    selectAll(allPolicies)
  }

  const isExpanded = isAllExpanded('', allGroups)

  const filteredIds = allPolicies.map(policy => policy.id) // Extract IDs from visible policies
  const selectedIds = selectedPolicies.map(policy => policy.id) // Extract IDs from selected policies

  // Check if all visible policies are already selected
  const allFilteredSelected = filteredIds.every(id => selectedIds.includes(id))

  return (
    <div className='flex items-center h-full'>
      {allPolicies.length !== 0 && (
        <>
          <button
            type='button'
            onClick={handleSelectAll}
            className='pl-2 text text-xs rounded flex items-center'
          >
            <FontAwesomeIcon
              className='text-black pr-1'
              icon={allFilteredSelected ? faSquareCheck : faSquare}
            />
            {allFilteredSelected ? `Deselect ` : `Select `}
            {filtersEnabled ? 'visible' : 'all'}
          </button>

          <button
            type='button'
            onClick={handleExpandAll}
            className='pl-4 text-xs rounded flex items-center'
          >
            <FontAwesomeIcon
              className='text-black pr-1'
              icon={isExpanded ? faCircleMinus : faCirclePlus}
            />
            {isExpanded ? 'Collapse All' : 'Expand All'}
          </button>
        </>
      )}
    </div>
  )
}

ToolbarButtons.defaultProps = {
  filtersEnabled: false,
}

ToolbarButtons.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  groupedPolicies: arrayOf(object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allPolicies: arrayOf(object).isRequired,
  filtersEnabled: bool,
}

export default ToolbarButtons
