/* eslint-disable react/forbid-prop-types */
import { arrayOf, bool, func, object, oneOf, string } from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import { alignmentStatusFilterOptions } from '../../config/TenantAlignmentConfig'
import Checkbox from '../Checkbox'
import { selectValueShape } from '../../utils/propTypes/tenantAlignmentProps'

const PolicyAlignmentSectionSkeleton = () => (
  <div className='flex flex-col gap-2'>
    <h3 className='mb-4 mt-4'>
      <Skeleton width='40%' />
    </h3>
    <Skeleton height='80px' count={15} />
  </div>
)

const PolicyAlignmentStatusSection = ({
  policyAlignmentStatusFilter,
  policiesLoading,
  policies,
  title,
  alignmentStatus,
  buildTenantPolicyItem,
  dataTestId,
  toggleSelectedPolicyIds,
  allPoliciesSelected,
}) => {
  if (
    policyAlignmentStatusFilter.length === 0 ||
    policyAlignmentStatusFilter.some(
      ({ value }) => alignmentStatus.includes(value) || value === 'deviated'
    )
  ) {
    if (policiesLoading) {
      return <PolicyAlignmentSectionSkeleton />
    }

    return (
      <div data-testid={dataTestId} className='flex flex-col gap-2'>
        <div className='flex flex-row items-center gap-2 pt-3 pb-2 sticky max-lg:top-[-32px] top-[-28px] max-sm:top-[-4px] z-[3] bg-white'>
          <Checkbox
            id={`select-all-${dataTestId}`}
            onChange={toggleSelectedPolicyIds}
            checked={allPoliciesSelected}
          />
          <h3 className='max-xl:text-lg'>{`${title} (${policies.length})`}</h3>
        </div>
        {policiesLoading && <PolicyAlignmentSectionSkeleton />}
        {!policiesLoading && policies.length === 0 && (
          <div className='flex items-center justify-center h-[100%] p-4'>
            No results found
          </div>
        )}
        {!policiesLoading &&
          policies.map(policy => buildTenantPolicyItem(policy))}
      </div>
    )
  }
  return null
}

PolicyAlignmentStatusSection.propTypes = {
  policyAlignmentStatusFilter: selectValueShape.isRequired,
  policiesLoading: bool.isRequired,
  policies: arrayOf(object).isRequired,
  title: string.isRequired,
  alignmentStatus: arrayOf(
    oneOf(alignmentStatusFilterOptions.map(({ value }) => value))
  ).isRequired,
  buildTenantPolicyItem: func.isRequired,
  dataTestId: string.isRequired,
  toggleSelectedPolicyIds: func.isRequired,
  allPoliciesSelected: bool.isRequired,
}

export default PolicyAlignmentStatusSection
