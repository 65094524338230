import TagsInput from 'react-tagsinput'
import './EmailInput.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { arrayOf, func, number, string } from 'prop-types'
import { useState } from 'react'

const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

const EmailInput = ({ placeholder, onTagChange, values, maxTags }) => {
  const [showValidationErr, setShowValidationErr] = useState(false)
  const [input, setInput] = useState('')
  const splitPastedEmails = unsplitString =>
    unsplitString.split(';').map(email => email.trim())

  return (
    <div>
      <div className='max-w-xl flex flex-row'>
        <TagsInput
          value={values}
          maxTags={maxTags}
          addOnBlur
          onChangeInput={setInput}
          inputValue={input}
          onChange={newData => {
            onTagChange(newData)
            setShowValidationErr(false)
          }}
          inputProps={{
            placeholder: values.length === maxTags ? '' : placeholder,
            disabled: values.length === maxTags,
          }}
          onlyUnique
          pasteSplit={splitPastedEmails}
          validationRegex={emailValidationRegex}
          onValidationReject={() => setShowValidationErr(true)}
          onBlur={() => setShowValidationErr(false)}
        />
        <button
          aria-label='clear input'
          type='button'
          className='btn'
          onClick={() => {
            setInput('')
            onTagChange([])
            setShowValidationErr(false)
          }}
        >
          <FontAwesomeIcon icon={faTrash} size='lg' className='navy-text' />
        </button>
      </div>
      {showValidationErr && (
        <p className='text-red-600'>Must be a valid email address</p>
      )}
    </div>
  )
}

EmailInput.defaultProps = {
  maxTags: 1,
}

EmailInput.propTypes = {
  values: arrayOf(string).isRequired,
  placeholder: string.isRequired,
  onTagChange: func.isRequired,
  maxTags: number,
}

export default EmailInput
