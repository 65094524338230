import { string } from 'prop-types'

const DriftedValue = ({
  baselineLabel,
  baselineValue,
  customerLabel,
  customerValue,
  path,
}) => (
  <div className='flex flex-col gap-0.5 pt-2'>
    <span>
      Property:
      <code
        key={`previously-accepted-${path}`}
        className='bg-[#f1f1f1] rounded-sm p-0.5 ml-1 w-fit font-semibold'
      >
        {path}
      </code>
    </span>
    <div className='pl-4 flex gap-0.5 flex-wrap'>
      <span className='pr-1 whitespace-nowrap font-semibold'>
        {baselineLabel}
      </span>
      <span className='whitespace-normal break-all'>{baselineValue}</span>
    </div>
    <div className='pl-4 flex gap-0.5 flex-wrap'>
      <span className='pr-1 whitespace-nowrap font-semibold'>
        {customerLabel}
      </span>
      <span className='whitespace-normal break-all'>{customerValue}</span>
    </div>
  </div>
)

DriftedValue.propTypes = {
  baselineLabel: string.isRequired,
  baselineValue: string.isRequired,
  customerLabel: string.isRequired,
  customerValue: string.isRequired,
  path: string.isRequired,
}

export default DriftedValue
