import { Controller, useForm } from 'react-hook-form'
import { bool, func, string } from 'prop-types'
import useCreateOrUpdateClientVariable from './api/useCreateOrUpdateClientVariable'
import Alert from '../Alert/Alert'
import FormControlButtons from '../Form/components/FormControlButtons/FormControlButtons'
import FormTextInput from '../Form/components/FormTextInput/FormTextInput'
import FormTextArea from '../Form/components/FormTextArea/FormTextArea'
import Toggle from '../Toggle/Toggle'
import RadioInput from './components/RadioInput'

const ClientVariablesForm = ({
  closePanel,
  variableId,
  initialName,
  initialDescription,
  initialHidden,
  initialType,
  onSuccessfulAction,
}) => {
  const defaultValues = {
    name: initialName || '',
    description: initialDescription || '',
    hidden: initialHidden || false,
    type: initialType || 'text',
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    watch,
    control,
  } = useForm({
    mode: 'onBlur',
    values: defaultValues,
  })

  const hidden = watch('hidden')

  const createOrUpdateClientVariable = useCreateOrUpdateClientVariable(() => {
    reset()
    onSuccessfulAction()
    closePanel()
  }, !!initialName)

  const onSubmit = data => {
    // only pass clientVariableId if it's an update operation
    const newVariable = {
      ...data,
      ...(variableId && { id: variableId }),
    }
    createOrUpdateClientVariable.mutate(newVariable)
  }

  const resetForm = () => {
    reset()
    createOrUpdateClientVariable.reset()
  }

  const clearForm = () => {
    reset({ values: defaultValues })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-label='client-variable-form'>
      <div className='flex flex-col gap-4 content-between pb-6'>
        <FormTextInput
          label='Variable Name'
          register={() =>
            register('name', {
              required: 'Variable name is required',
              maxLength: { value: 50, message: 'Max length 50 characters' },
              minLength: { value: 3, message: 'Min length 3 characters' },
            })
          }
          errors={errors.name}
        />

        <FormTextArea
          register={() =>
            register('description', {
              required: false,
              maxLength: { value: 200, message: 'Max length 200 characters' },
            })
          }
          errors={errors.description}
          label='Description'
        />

        <Controller
          name='hidden'
          control={control}
          render={({ field: { onChange } }) => (
            <Toggle
              checked={hidden}
              onChange={onChange}
              label={
                <span className='ms-2 font-medium text-base text-gray-900 dark:text-gray-300'>
                  Hide value
                  <span className='pl-1 text-gray-500 text-sm'>
                    (e.g. ********)
                  </span>
                </span>
              }
            />
          )}
        />

        <Controller
          name='type'
          control={control}
          render={({ field }) => (
            <div className='flex flex-col items-start'>
              <label htmlFor='type'>Type</label>
              <div className='flex flex-row space-x-4'>
                <RadioInput value='text' label='Text' field={field} />
                <RadioInput value='email' label='Email' field={field} />
                <RadioInput value='domain' label='Domain' field={field} />
                <RadioInput value='guid' label='GUID' field={field} />
              </div>
            </div>
          )}
        />

        {createOrUpdateClientVariable.error && (
          <Alert
            type='error'
            title={createOrUpdateClientVariable.error.response.data.message}
            margin='mt-3'
          >
            An error occurred while trying to {variableId ? 'update' : 'create'}{' '}
            the client variable.
            <ul>
              {createOrUpdateClientVariable.error.response.data.errors?.map(
                error => (
                  <li key={error}>{error}</li>
                )
              )}
            </ul>
          </Alert>
        )}
      </div>

      <FormControlButtons
        onCancel={() => {
          reset()
          closePanel()
          clearForm()
        }}
        onReset={() => resetForm()}
        submitText={initialName ? 'Update' : 'Create'}
        resetDisabled={!isDirty || createOrUpdateClientVariable.isPending}
        confirmDisabled={
          !isDirty ||
          Object.values(errors).length !== 0 ||
          createOrUpdateClientVariable.isPending
        }
      />
    </form>
  )
}

ClientVariablesForm.defaultProps = {
  variableId: '',
  initialName: '',
  initialDescription: '',
  initialHidden: false,
  initialType: 'text',
}

ClientVariablesForm.propTypes = {
  variableId: string,
  initialName: string,
  initialDescription: string,
  initialHidden: bool,
  initialType: string,
  closePanel: func.isRequired,
  onSuccessfulAction: func.isRequired,
}

export default ClientVariablesForm
