import Axios from 'axios'
import { useCallback, useEffect, useMemo, useState } from 'react'

const policyListCacheKey = 'tenantPolicyList'

const policyComparisonCacheKey = 'policyCompareResults'

const loadCachedPolicyList = () => {
  const cached = sessionStorage.getItem(policyListCacheKey)
  if (cached) {
    return JSON.parse(cached)
  }
  return {}
}

const loadCachedPolicyComparison = () => {
  const cached = sessionStorage.getItem(policyComparisonCacheKey)
  if (cached) {
    return JSON.parse(cached)
  }
  return {}
}

export const clearPolicyComparisonCache = () => {
  sessionStorage.removeItem(policyListCacheKey)
  sessionStorage.removeItem(policyComparisonCacheKey)
}

export const usePolicyCompare = (
  clientTenantId,
  policyGuid,
  subjectTenantIds,
  useLiveData
) => {
  const [policyListLoading, setPolicyListLoading] = useState(false)

  const [comparisonLoading, setComparisonLoading] = useState(false)

  const [error, setError] = useState()

  const [tenantPolicies, setTenantPolicies] = useState(loadCachedPolicyList())

  const [tenantComparisons, setTenantComparisons] = useState(
    loadCachedPolicyComparison()
  )

  const [isPolicyRenaming, setIsPolicyRenaming] = useState(false)

  const [policyRenameError, setPolicyRenameError] = useState()

  const clientPolicyId = useMemo(
    () => `${clientTenantId}-${policyGuid}`,
    [clientTenantId, policyGuid]
  )

  const loadTenantPolicies = useCallback(
    async ctId => {
      setPolicyListLoading(true)
      setError(undefined)

      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/list-policies`,
          { ctId }
        )

        const policies = []

        const data = Object.entries(response.data)

        data.forEach(([key, pt]) => {
          pt.forEach(p =>
            policies.push({
              policyGuid: p.id,
              policyTypeId: p.policyTypeId,
              policyTypeName: key,
              displayName: p.displayName ?? p.name,
              priority: p.priority,
              clientTenantId,
            })
          )
        })

        if (response.status < 200 || response.status >= 300) {
          setError('Failed to fetch baseline policies')
        } else {
          setTenantPolicies(tps => ({
            ...tps,
            [clientTenantId]: policies,
          }))
        }
      } catch (fetchBaselinePoliciesError) {
        setError('Failed to fetch baseline policies')
      } finally {
        setPolicyListLoading(false)
      }
    },
    [clientTenantId]
  )

  const loadPolicyCompareResults = useCallback(
    async (
      baselineClientTenantId,
      baselinePolicyGuid,
      baselinePolicyTypeId,
      subjectClientTenantIds
    ) => {
      setComparisonLoading(true)
      setError(undefined)

      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/compareTenantPoliciesByContent`,
          {
            baselinePolicyGuid,
            baselinePolicyTypeId,
            baselineClientTenantId,
            subjectClientTenantIds,
            useBaselineLiveData: useLiveData === true,
            getStringifiedDiff: true,
          }
        )

        if (response.status < 200 || response.status >= 300) {
          setError('Failed to fetch tenant policy comparisons')
        } else {
          setTenantComparisons(tcs => ({
            ...tcs,
            [clientPolicyId]: response.data,
          }))
        }
      } catch (failedToFetchTenantPolicyComparisonsErr) {
        setError('Failed to fetch tenant policy comparisons')
      } finally {
        setComparisonLoading(false)
      }
    },
    [clientPolicyId, useLiveData]
  )

  const renamePolicy = useCallback(
    async (
      baselineClientTenantId,
      subjectClientTenantId,
      targetPolicy,
      baselinePolicy,
      onSuccess = null
    ) => {
      setIsPolicyRenaming(true)
      setPolicyRenameError(undefined)

      const policyObject = {
        [targetPolicy.policyNameKeyName]:
          baselinePolicy?.displayName ?? baselinePolicy?.name,
      }
      if (targetPolicy.policyODataType) {
        policyObject['@odata.type'] = targetPolicy.policyODataType
      }

      try {
        const patchResponse = await Axios.patch(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/patch-policy`,
          {
            clientTenantId: subjectClientTenantId,
            policyId: targetPolicy.policyId,
            policyGuid: targetPolicy.policyGuid,
            policyTypeId: baselinePolicy.policyTypeId,
            policyName: targetPolicy.policyName,
            policyObject: JSON.stringify(policyObject),
          }
        )

        if (patchResponse.status < 200 || patchResponse.status >= 300) {
          setPolicyRenameError('Failed to patch tenant policy')
          return
        }

        const compareResponse = await Axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/compareTenantPoliciesByContent`,
          {
            baselinePolicyGuid: baselinePolicy.policyGuid,
            baselinePolicyTypeId: baselinePolicy.policyTypeId,
            baselineClientTenantId,
            subjectClientTenantIds: [subjectClientTenantId],
            useBaselineLiveData: useLiveData === true,
            getStringifiedDiff: true,
          }
        )

        if (compareResponse.status < 200 || compareResponse.status >= 300) {
          setError('Failed to fetch tenant policy comparisons')
        } else {
          setTenantComparisons(tcs => ({
            ...tcs,
            [clientPolicyId]: compareResponse.data,
          }))
          onSuccess?.()
        }
      } catch (err) {
        setError('Failed to rename policy')
      } finally {
        setIsPolicyRenaming(false)
      }
    },
    [clientPolicyId, useLiveData]
  )

  useEffect(() => {
    if (Object.keys(tenantPolicies).includes(clientTenantId.toString())) return
    loadTenantPolicies(clientTenantId)
  }, [clientTenantId, loadTenantPolicies, tenantPolicies])

  useEffect(() => {
    if (!clientTenantId || !subjectTenantIds || !policyGuid) return
    if (Object.keys(tenantComparisons).includes(clientPolicyId)) return
    const policyTypeId = tenantPolicies[clientTenantId]?.find(
      p => p.policyGuid === policyGuid
    )?.policyTypeId
    if (!policyTypeId) return
    loadPolicyCompareResults(
      clientTenantId,
      policyGuid,
      policyTypeId,
      subjectTenantIds
    )
  }, [
    clientPolicyId,
    clientTenantId,
    loadPolicyCompareResults,
    policyGuid,
    subjectTenantIds,
    tenantComparisons,
    tenantPolicies,
  ])

  useEffect(() => {
    sessionStorage.setItem(policyListCacheKey, JSON.stringify(tenantPolicies))
  }, [tenantPolicies])

  useEffect(() => {
    setTenantComparisons({})
  }, [useLiveData])

  const clearPolicyComparisonResults = () => {
    clearPolicyComparisonCache()
    setTenantComparisons([])
  }

  return {
    policyListLoading,
    comparisonLoading,
    error,
    loadTenantPolicies: () =>
      clientTenantId ? loadTenantPolicies(clientTenantId) : null,
    policies: tenantPolicies[clientTenantId] ?? [],
    tenantComparisons:
      tenantComparisons[`${clientTenantId}-${policyGuid}`] ?? [],
    clearPolicyComparisonResults,
    renamePolicy,
    isPolicyRenaming,
    policyRenameError,
  }
}
