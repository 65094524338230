import { oneOf } from 'prop-types'
import UKFlag from '../assets/UK-flag.png'
import AUFlag from '../assets/AU-flag.png'
import EUFlag from '../assets/EU-flag.png'
import USFlag from '../assets/US-flag.png'
import InforcerLogo from '../assets/inforcer-logo-login-select.png'

const RegionOption = ({ region }) => {
  switch (region) {
    case 'UK':
      return (
        <div className='flex items-center'>
          <img src={UKFlag} alt='UK Flag' className='mr-2 w-[20px] h-[20px]' />
          <span>United Kingdom</span>
        </div>
      )
    case 'ANZ':
      return (
        <div className='flex items-center'>
          <img src={AUFlag} alt='AU Flag' className='mr-2 w-[20px] h-[20px]' />
          <span>Australia & New Zealand</span>
        </div>
      )
    case 'EU':
      return (
        <div className='flex items-center'>
          <img src={EUFlag} alt='EU Flag' className='mr-2 w-[20px] h-[20px]' />
          <span>European Union</span>
        </div>
      )
    case 'US':
      return (
        <div className='flex items-center'>
          <img src={USFlag} alt='US Flag' className='mr-2 w-[20px] h-[20px]' />
          <span>United States</span>
        </div>
      )
    case 'Old Portal':
      return (
        <div className='flex items-center'>
          <img
            src={InforcerLogo}
            alt='US Flag'
            className='mr-2 w-[20px] h-[20px]'
          />
          <span>Inforcer Portal (Pre-Migration)</span>
        </div>
      )
    default:
      return null
  }
}

export default RegionOption

RegionOption.propTypes = {
  region: oneOf(['UK', 'ANZ', 'EU', 'US', 'Old Portal']).isRequired,
}
