import { node, number, oneOfType, shape, string } from 'prop-types'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import { useTenant } from '../../../contexts/TenantContext'

import useGetListPolicies from './api/queries/useGetListPolicies'
import useFilteredData from './hooks/useFilteredData'

import ProductGroup from './ProductGroup/ProductGroup'
import PolicyPickerSkeleton from '../../LoadingSkeletons/PolicyPickerSkeleton'

import { getIconByName } from './utils/utils'
import Alert from '../../Alert/Alert'
import PolicyPickerHeader from './components/PolicyPickerHeader'

const PolicyPicker = ({ selectedBackup, bulkActions }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedPolicyTags, setSelectedPolicyTags] = useState([])

  // Tenant data provider
  const tenant = useTenant()

  const {
    data: groupedPolicies = [],
    isFetching,
    dataUpdatedAt,
    isError,
    error: errors,
    isLoading,
  } = useGetListPolicies(
    tenant[0].clientTenantId,
    selectedBackup,
    tenant[0].isSharedBaseline
  )

  const filteredData = useFilteredData(
    groupedPolicies,
    isLoading,
    searchTerm,
    selectedOptions,
    selectedPolicyTags
  )

  const customOrder = [
    'Entra',
    'Intune',
    'Defender',
    'SharePoint',
    'M365 Admin Center',
    'Other',
  ]

  // Sort based on the custom order
  const orderedProducts = filteredData.sort(
    (a, b) => customOrder.indexOf(a.name) - customOrder.indexOf(b.name)
  )

  if (isLoading)
    return (
      <div className='p-5 bg-white min-w-[800px]'>
        <div className='mt-5'>
          <PolicyPickerSkeleton loadingTitle='Loading policies from Microsoft 365...' />
        </div>
      </div>
    )

  if (isError)
    return (
      <div className='p-5 bg-white min-w-[800px]'>
        <Alert type='error' title={errors.response.data.message} margin='mb-4'>
          {errors.response.data.errors?.map(error => (
            <ul>
              <li>{error}</li>
            </ul>
          ))}
        </Alert>
      </div>
    )

  return (
    <div className='p-5 bg-white min-w-[800px]'>
      <span className='text-xs'>
        {isFetching ? (
          <>
            Fetching latest data
            <FontAwesomeIcon icon={faSpinner} className='ml-2 animate-spin' />
          </>
        ) : (
          `Last fetched: ${moment(dataUpdatedAt).fromNow()}`
        )}
      </span>

      <div className='mt-5'>
        <PolicyPickerHeader
          isLoading={isLoading}
          groupedPolicies={groupedPolicies}
          filteredPolicies={filteredData}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          selectedPolicyTags={selectedPolicyTags}
          setSelectedPolicyTags={setSelectedPolicyTags}
        >
          {bulkActions}
        </PolicyPickerHeader>

        {orderedProducts.map(group => (
          <ProductGroup
            key={group.name}
            title={group.name}
            subGroups={group.subGroups}
            icon={getIconByName(group.name)}
          />
        ))}

        {filteredData.length === 0 && (
          <div className='mt-5 text-center text-gray-500'>
            No policies found
          </div>
        )}
      </div>
    </div>
  )
}

const manualBackupShape = shape({
  Comment: string.isRequired,
  backupRunId: number.isRequired,
})

const automaticBackupShape = shape({
  backupRunId: number.isRequired,
  backupRunTime: string.isRequired,
})

PolicyPicker.defaultProps = {
  selectedBackup: null,
  bulkActions: null,
}

PolicyPicker.propTypes = {
  selectedBackup: oneOfType([manualBackupShape, automaticBackupShape]),
  bulkActions: node,
}

export default PolicyPicker
