import { func, string } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

const ClientTenantVariablesSearchBar = ({
  searchQuery,
  handleSearchChange,
  handleClearSearch,
}) => (
  <div className='flex flex-grow'>
    <div className='relative flex flex-grow'>
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        className='absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-700 z-10'
      />
      <input
        className='flex-grow focus:outline-blue-400 w-full rounded-md light-grey-bg p-2 pl-10 text-gray-700'
        type='text'
        placeholder='Search'
        aria-label='Search'
        value={searchQuery}
        onChange={handleSearchChange}
      />
      {searchQuery && (
        <button
          type='button'
          className='absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-700 font-bold'
          onClick={handleClearSearch}
        >
          &#10005;
        </button>
      )}
    </div>
  </div>
)

ClientTenantVariablesSearchBar.defaultProps = {
  searchQuery: '',
}

ClientTenantVariablesSearchBar.propTypes = {
  searchQuery: string,
  handleSearchChange: func.isRequired,
  handleClearSearch: func.isRequired,
}

export default ClientTenantVariablesSearchBar
