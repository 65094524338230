import PropTypes from 'prop-types'

const PauseClientPopup = ({
  handleClosePopUp,
  closePopUpRefreshPolicies,
  selectedClient,
  responseData,
  submitData,
  loading,
}) => (
  <div className='popup-bg'>
    <div className='popup-container delete-tenant-popup text-center'>
      <h3 className='mb-4'>
        {selectedClient?.isEnabled ? 'Enable selected' : 'Disable selected'}
      </h3>
      {loading && (
        <>
          <div id='loading-spinner'>
            <div className='loading' />
          </div>
          <p>Updating client...</p>
        </>
      )}

      {!responseData && !loading && (
        <>
          <p className='ml-4'>
            Are you sure you'd like to{' '}
            {selectedClient?.isEnabled ? 'enable' : 'disable'}{' '}
            <strong>{selectedClient.displayName}</strong>?
          </p>
          <br />

          <div className='footer mt-[50px] mb-2'>
            <button
              type='button'
              className='btn navy-btn ml-4 mr-2 text-white'
              onClick={handleClosePopUp}
            >
              Back
            </button>
            <button
              type='button'
              className='btn bg-red-800 text-white'
              onClick={submitData}
            >
              {selectedClient?.isEnabled ? 'Enable ' : 'Disable '}client
            </button>
          </div>
        </>
      )}

      {responseData && (
        <>
          <p>
            <strong>{responseData.message}</strong>
          </p>
          <div className='footer mt-[50px] mb-2'>
            <button
              type='button'
              className='btn navy-btn ml-4 mr-2 text-white'
              onClick={closePopUpRefreshPolicies} // This should refresh the clients list, the other one shouldnt
            >
              Close
            </button>
          </div>
        </>
      )}
    </div>
  </div>
)

PauseClientPopup.propTypes = {
  handleClosePopUp: PropTypes.func.isRequired,
  closePopUpRefreshPolicies: PropTypes.func.isRequired,
  selectedClient: PropTypes.object.isRequired,
  submitData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default PauseClientPopup
