import { func } from 'prop-types'

const SearchBar = ({ onSearchChange, searchValue, onClear }) => {
  const clearSearch = () => {
    onSearchChange('')

    if (onClear) onClear()
  }

  return (
    <div className='flex items-center'>
      <input
        className='flex w-[350px] focus:outline-blue-400 rounded-md light-grey-bg p-2 text-gray-700 mb-5 mt-5'
        type='text'
        placeholder='Search'
        value={searchValue}
        onChange={e => onSearchChange(e.target.value)}
      />
      <button
        type='button'
        className='btn navy-btn clear-btn'
        onClick={clearSearch}
      >
        Clear
      </button>
    </div>
  )
}

SearchBar.propTypes = {
  onSearchChange: func.isRequired,
}

export default SearchBar
