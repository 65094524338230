import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ssoUserQueryKey } from './useGetSSOUsersQuery'

const useToggleUserEnabled = (onSuccess, onError) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['toggle-user-enabled'],
    mutationFn: async ({ users, enable }) =>
      await axios.patch(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/users/enabled`,
        {
          users,
          enable,
        }
      ),
    onSuccess: data => {
      queryClient.invalidateQueries(ssoUserQueryKey)
      onSuccess(data)
    },
    onError,
  })
}

export default useToggleUserEnabled
