import { Link, useLocation } from 'react-router-dom'
import BaselineGroupForm from './BaselineGroupForm'
import Alert from '../../Alert/Alert'
import HorizontalDivider from '../../HorizontalDivider'

const EditBaselineGroupPage = () => {
  const { state: navigationState } = useLocation()
  const tenants = navigationState?.tenants
  const selectedBaselineGroup = navigationState?.group
  const isEditMode = selectedBaselineGroup !== undefined

  if (!tenants) {
    return (
      <Alert type='error' title='No Tenants Provided'>
        <p>
          Make sure you don't navigate to this page directly and you're
          accessing this page by using the Edit or Create Group buttons on the
          <Link to='/tenant-management?tab=baselines'>
            Manage Baselines page
          </Link>
        </p>
      </Alert>
    )
  }

  return (
    <div className='ui-panel flex flex-col gap-4'>
      <div>
        <h1 className='pt-6 text-4xl'>
          {isEditMode ? 'Edit' : 'Create'} Baseline Group
        </h1>
        <h2 className='opacity-75 text-xl'>
          {isEditMode && selectedBaselineGroup.name}
        </h2>
      </div>
      <HorizontalDivider />
      <Alert type='info'>
        <strong>Note:</strong> You can align baseline and customer tenants to
        shared baselines but only customers can be aligned to a baseline
      </Alert>
      <BaselineGroupForm
        isEditMode={isEditMode}
        baselineToEdit={selectedBaselineGroup}
        tenants={tenants}
      />
    </div>
  )
}

export default EditBaselineGroupPage
