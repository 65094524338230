import { PropTypes } from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SharedBaselineGroupSkeleton = ({ loadingTitle }) => (
  <>
    <div className='font-medium mb-3'>
      <h4>{loadingTitle}</h4>
    </div>
    <div className='mb-3'>
      <Skeleton count={4} />
    </div>
  </>
)

SharedBaselineGroupSkeleton.propTypes = {
  loadingTitle: PropTypes.string.isRequired,
}

export default SharedBaselineGroupSkeleton
