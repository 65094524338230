import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const useGetClients = () =>
  useQuery({
    queryKey: ['clients-list'],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/get-clients`
      )
      return response.data
    },
  })

export default useGetClients
