const tenantTypeFilter = (row, id, filterValue) => {
  const { isBaseline, isSharedBaseline, inforcerManaged, isGoldenTenant } =
    row.original || {}

  // If no filter is applied, return true to display all rows
  if (
    !filterValue ||
    (Array.isArray(filterValue) && filterValue.length === 0)
  ) {
    return true
  }

  return filterValue.some(filter => {
    switch (filter) {
      case 'Baseline':
        return (
          (isBaseline === true && !isSharedBaseline) ||
          (isGoldenTenant === true && !isSharedBaseline)
        )
      case 'Shared Baseline':
        return isSharedBaseline === true && inforcerManaged !== true
      case 'Inforcer Managed':
        return (
          inforcerManaged === true && isSharedBaseline === true && isBaseline
        )
      case 'Customer':
        return (
          (isBaseline === false &&
            isSharedBaseline === false &&
            inforcerManaged === false) ||
          isGoldenTenant === false
        )
      default:
        return false
    }
  })
}

export default tenantTypeFilter
