import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const useDeleteClientVariable = (onSuccess, onError) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['remove-client-variable'],
    mutationFn: async id =>
      await axios.delete(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/variables/${id}`
      ),
    onSuccess: () => {
      queryClient.invalidateQueries('clientVariables')
      onSuccess()
    },
    onError,
  })
}

export default useDeleteClientVariable
