import { useMemo, useRef, useState } from 'react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { toast } from 'react-toastify'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { tableColumnDefinitions, tableColumnOrder } from './SSOUserTable'
import SSOAddUserToolbar from './SSOAddUserToolbar'
import { useGetSSOUsersQuery } from './useGetSSOUsersQuery'
import Alert from '../Alert/Alert'
import useAddUser from './useAddUser'
import SSOUsersTableActionMenu from './SSOUsersTableActionMenu'

const SSOUsers = () => {
  const columns = tableColumnDefinitions()

  const anchorElement = useRef()
  const [actionMenuOpen, setActionMenuOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { data, isLoading } = useGetSSOUsersQuery()
  const usersData = useMemo(() => data ?? [], [data])

  const addUser = useAddUser(
    () => {
      toast.success('User added successfully')
    },
    () => {
      setErrorMessage('Failed to add user')
    }
  )

  const tableInstance = useMaterialReactTable({
    columns,
    data: usersData,
    state: {
      isLoading,
    },
    enableRowSelection: true,
    renderTopToolbarCustomActions: ({ table }) => {
      const numSelectedRows = table.getSelectedRowModel().rows.length
      return (
        <div className='flex flex-row items-center mb-4'>
          <SSOAddUserToolbar addUserMutation={addUser} />
          {numSelectedRows > 0 && (
            <div className='flex flex-row gap-4 items-center'>
              <button
                type='button'
                className='hover:bg-gray-300 bg-gray-100 w-5 h-5 p-5 rounded-full cursor-pointer inline-flex items-center justify-center'
                id='user-action-button'
                aria-expanded={actionMenuOpen}
                aria-haspopup='true'
                onClick={() => setActionMenuOpen(!actionMenuOpen)}
              >
                <FontAwesomeIcon
                  ref={anchorElement}
                  icon={faEllipsisVertical}
                />
              </button>
              <span className='text-[rgb(var(--cyan))]'>{`${numSelectedRows} ${numSelectedRows === 1 ? 'row' : 'rows'} selected`}</span>
            </div>
          )}
          <SSOUsersTableActionMenu
            menuOpen={actionMenuOpen}
            setActionMenuOpen={setActionMenuOpen}
            anchorEl={anchorElement.current}
            selectedRows={table.getSelectedRowModel().rows}
            setErrorMessage={setErrorMessage}
          />
        </div>
      )
    },
    enableStickyHeader: true,
    enableColumnOrdering: false,
    enableColumnPinning: false,
    enableFacetedValues: true,
    positionToolbarAlertBanner: 'none',
    initialState: {
      density: 'compact',
      columnOrder: tableColumnOrder,
      showGlobalFilter: true,
      sorting: [{ id: 'displayName', asc: true }],
      pagination: { pageSize: 50, pageIndex: 0 },
    },
    muiTableContainerProps: {
      sx: { maxHeight: '100%' },
    },
    muiToolbarAlertBannerProps: {
      sx: {
        marginBottom: '16px',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        fontFamily: 'inherit',
        color: 'rgba(23, 27, 58, 0.85)',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontFamily: 'inherit',
        color: 'rgba(23, 27, 58, 0.85)',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      // This enables us to click anywhere on the row to select it
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: 'pointer' },
    }),
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
      },
    },
    muiTableFooterProps: {
      sx: {
        boxShadow: 'none',
      },
    },
    muiTopToolbarProps: {
      sx: {
        paddingTop: '8px',
        '& .MuiBox-root': {
          padding: 0,
        },
      },
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Settings',
        size: 100,
      },
    },
  })

  return (
    <>
      <h4 className='mb-4'>SSO User Management</h4>
      <Alert title='Manage Users' type='info' margin='mt-4 mb-4'>
        <p>You can use this section to change access rights for users.</p>
        <ul className='ul-sso'>
          <li>Client Admins can manage SSO configuration and users.</li>
          <li>
            If SSO is not enabled for a user then authentication via SSO will
            not be permitted, even if the user is permitted access via your
            Entra configuration.
          </li>
          <li>
            If a user is not enabled, the user will not be able to authenticate
            to Inforcer at all, even if SSO is enabled.
          </li>
        </ul>
      </Alert>
      {errorMessage && (
        <Alert title='Error' type='error' margin='mb-6'>
          <p>{errorMessage}</p>
        </Alert>
      )}
      <MaterialReactTable table={tableInstance} />
    </>
  )
}

export default SSOUsers
