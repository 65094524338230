import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const useClientTenantVariables = (tenantId, isEnabled = true) =>
  useQuery({
    queryKey: [`clientTenantVariables-${tenantId}`],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/tenants/${tenantId}/variables`
      )
      return response.data
    },
    enabled: isEnabled,
  })

export default useClientTenantVariables
