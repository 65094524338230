import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const DatePicker = ({ dates, selectedBackup, setSelectedBackup }) => {
  const dateClick = (key, value) => {
    if (selectedBackup && selectedBackup.key === key) {
      setSelectedBackup(null)
    } else {
      const updatedDate = { key, ...value } // Spread to include additional properties
      setSelectedBackup(updatedDate)
    }
  }

  const sortedArray = Object.entries(dates).sort(
    ([keyA], [keyB]) => keyB.backupRunTime - keyA.backupRunTime
  )

  return (
    <div>
      <div className='table-container'>
        <table>
          <tbody>
            <tr className='table-head'>
              <td>Backup Run Time</td>
              <td aria-label='Is Selected' />
            </tr>
            {sortedArray.map(([key, value]) => (
              <tr
                key={key}
                onClick={() => dateClick(parseInt(key), value)}
                className={`date-row cursor-pointer ${
                  selectedBackup && selectedBackup.key === parseInt(key)
                    ? 'selected'
                    : ''
                }`}
              >
                <td className='date-value'>{value.backupRunTime}</td>
                <td className='check-cell'>
                  {selectedBackup && selectedBackup.key === parseInt(key) ? (
                    <FontAwesomeIcon icon={faCheckCircle} className='ml-2' />
                  ) : null}
                </td>
              </tr>
            ))}
            {sortedArray.length === 0 && (
              <tr>
                <td colSpan='3'>No dates found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default DatePicker
