import { useMutation, useQueryClient } from '@tanstack/react-query'
import Axios from 'axios'

const useRefreshTenant = (onSuccess, onError) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['tenant-refresh'],
    mutationFn: async clientTenantId =>
      await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/runBackupForClientTenant`,
        { clientTenantId }
      ),
    onSuccess: () => {
      onSuccess()
      queryClient.invalidateQueries('tenant-summary')
    },
    onError,
  })
}

export default useRefreshTenant
