import { useState } from 'react'
import { arrayOf, string } from 'prop-types'
import { matchingVariableShape } from '../../../ClientVariables/shapes/shapes'
import Modal from '../../../Modal/Modal'
import ListTable from '../../../ListTable/components/ListTable'
import Alert from '../../../Alert/Alert'
import matchingVariablesTableConfig from './MatchingVariablesTableConfig'

const MatchingVariablesViewer = ({ displayName, variables }) => {
  const [isVariablesOpen, setIsVariablesOpen] = useState(false)
  if (!variables || variables.length === 0) {
    return null
  }

  const tableColumns = matchingVariablesTableConfig()

  return (
    <>
      <button
        type='button'
        onClick={e => {
          e.stopPropagation()
          setIsVariablesOpen(true)
        }}
        className='p-2 bg-white border rounded pb-2 mr-2 font-bold'
        name='View Variables'
      >
        {'{ }'}
      </button>
      <Modal
        isOpen={isVariablesOpen}
        setModalOpen={setIsVariablesOpen}
        closeOnBlur
      >
        <h3 className='text-center mb-4'>Matching Variables</h3>
        <Alert type='info'>
          The following variables were identified in the policy '{displayName}'
        </Alert>
        <div className='text-center my-6'>
          <ListTable uniqueKey='id' columns={tableColumns} data={variables} />
        </div>
      </Modal>
    </>
  )
}

MatchingVariablesViewer.defaultProps = {
  variables: [],
}

MatchingVariablesViewer.propTypes = {
  displayName: string.isRequired,
  variables: arrayOf(matchingVariableShape),
}

export default MatchingVariablesViewer
