import { bool, func, node, string } from 'prop-types'

/**
 *
 * @param {{
 *  checked: boolean,
 *  onChange: (boolean) => void,
 *  label: React.ReactNode,
 *  disabled: boolean
 * }} props
 */
const Checkbox = ({ id, checked, onChange, label, disabled }) => (
  <div className='inline-flex items-center'>
    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
    <label
      className={`relative flex items-center p-3 rounded-full ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
      htmlFor={id}
      onClick={e => {
        if (disabled) {
          e.preventDefault()
          e.stopPropagation()
        }
      }}
    >
      <input
        type='checkbox'
        className="checkbox-input before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:bg-gray-100 hover:checked:bg-gray-600 hover:checked:border-gray-600"
        id={id}
        checked={checked}
        onChange={e => {
          if (!disabled) {
            onChange(e.target.checked)
          }
        }}
        disabled={disabled}
      />
      <span className='absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-3.5 w-3.5'
          viewBox='0 0 20 20'
          fill='currentColor'
          stroke='currentColor'
          strokeWidth='1'
        >
          <path
            fillRule='evenodd'
            d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
            clipRule='evenodd'
          />
        </svg>
      </span>
    </label>
    {label && (
      <label
        className={`mt-px text-gray-700 ${
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        } select-none`}
        htmlFor={id}
      >
        {label}
      </label>
    )}
  </div>
)

Checkbox.defaultProps = {
  label: null,
  disabled: false,
}

Checkbox.propTypes = {
  id: string.isRequired,
  checked: bool.isRequired,
  onChange: func.isRequired,
  label: node,
  disabled: bool,
}

export default Checkbox
