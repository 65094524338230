import { func, string } from 'prop-types'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import Skeleton from 'react-loading-skeleton'
import Alert from '../Alert/Alert'

import Modal from '../Modal/Modal'
import ModalActionButtons from '../Modal/ModalActionButtons'

import useDeletePolicyTag from './api/useDeletePolicyTag'

const PolicyTagsActions = ({ id, tag, description, colour, onEdit }) => {
  const [isOpen, setIsOpen] = useState(false)

  const deleteTenantTag = useDeletePolicyTag(() => {
    setIsOpen(false)
    toast.success(`${tag} deleted successfully`)
  })

  return (
    <>
      <button
        aria-label='edit tag'
        onClick={() => onEdit({ id, tag, description, colour })}
        type='button'
      >
        <FontAwesomeIcon icon={faEdit} className='mx-1 hover:cursor-pointer' />
      </button>

      <button
        type='button'
        onClick={() => setIsOpen(true)}
        aria-label='delete tag'
      >
        <FontAwesomeIcon icon={faTrash} className='mx-1 hover:cursor-pointer' />
      </button>

      <Modal isOpen={isOpen} setModalOpen={() => setIsOpen(true)}>
        <>
          {deleteTenantTag.isPending ? (
            <Skeleton height={30} width='100%' />
          ) : (
            <h2 className='text-xl font-bold mb-4'>
              Are you sure you want to delete "{tag}" tag?
            </h2>
          )}

          {deleteTenantTag.isError && (
            <Alert
              type='error'
              title={deleteTenantTag?.error?.response?.data.message}
            >
              <ul>
                {deleteTenantTag.error?.response?.data?.errors.map(error => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            </Alert>
          )}

          <ModalActionButtons
            onCancel={() => {
              setIsOpen(false)
            }}
            onConfirm={() => {
              deleteTenantTag.mutate([id])
            }}
            confirmEnabled={!deleteTenantTag.isPending}
            cancelEnabled={!deleteTenantTag.isPending}
            cancelActionText='Cancel'
            confirmActionText='Confirm'
          />
        </>
      </Modal>
    </>
  )
}

PolicyTagsActions.defaultProps = {
  description: '',
}

PolicyTagsActions.propTypes = {
  id: string.isRequired,
  tag: string.isRequired,
  onEdit: func.isRequired,
  description: string,
  colour: string.isRequired,
}

export default PolicyTagsActions
