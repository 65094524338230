import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const useUpdateSubscribers = onSuccess => {
  const queryClient = useQueryClient()

  return useMutation({
    // subscriptions is an object with publishedBaselineTenantId as the key and the selected members as the value
    mutationFn: async ({ baselineGroupId, subscriptions }) => {
      const reqBody = {
        subscriptions: Object.keys(subscriptions).map(
          publishedBaselineTenantId => ({
            publishedBaseline: publishedBaselineTenantId,
            subscribers: subscriptions[publishedBaselineTenantId].map(
              subscriber => subscriber.memberClientId
            ),
          })
        ),
      }

      const response = await axios.put(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/internal/sharedBaselineGroups/${baselineGroupId}/subscriptions`,
        reqBody
      )

      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries('baselineGroup')
      onSuccess()
    },
  })
}

export default useUpdateSubscribers
