const TargetTenants = ({ targetTenants }) => {
  const sortedTenants = [...targetTenants].sort(
    (a, b) => b.isGoldenTenant - a.isGoldenTenant
  )

  return (
    <div>
      <div className='table-container'>
        <h4 className='pb-2'>
          <b>Tenants enrolled: {sortedTenants.length}</b>
        </h4>

        <table>
          <tbody>
            <tr className='table-head'>
              <td className='tenant-table-th tags-th'>Tags</td>
              <td className='tenant-table-th tenant-name-th'>Tenant Name</td>
              <td
                className='tenant-table-th baseline-th'
                aria-label='Check Cells'
              />
            </tr>
            {sortedTenants
              .filter(tenant => tenant && tenant.clientTenantId)
              ?.map(tenant => (
                <tr
                  key={tenant.msTenantId}
                  className='tenant-row cursor-pointer'
                >
                  <td className='baseline-cell'>
                    {tenant.isGoldenTenant && (
                      <span className='baseline-tag'>Baseline</span>
                    )}
                    {tenant.partnerCenter && (
                      <span className='partner-center-tag'>Partner Center</span>
                    )}
                  </td>
                  <td className='tenant-name'>
                    <span className='tenant-name-span'>
                      {tenant.tenantFriendlyName}&nbsp;
                    </span>
                  </td>
                  <td className='check-cell' aria-label='Check Cell' />
                </tr>
              ))}

            {sortedTenants?.length === 0 && (
              <tr>
                <td colSpan='4'>No tenants found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TargetTenants
