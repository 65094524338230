import { string } from 'prop-types'

const HorizontalDivider = ({ className }) => (
  <hr className={`border-[1px] w-full ${className}`} />
)

HorizontalDivider.defaultProps = {
  className: '',
}

HorizontalDivider.propTypes = {
  className: string,
}

export default HorizontalDivider
