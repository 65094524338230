import { string } from 'prop-types'
import PolicyCategory from '../PolicyCategory/PolicyCategory' // Add missing import statement
import ExpandButton from '../components/ExpandButton'
import Accordion from '../components/Accordion'
import { useExpansion } from '../context/ExpansionContext'
import { useSelection } from '../context/SelectionContext'
import Policy from '../Policy/Policy'
import { primaryGroupShape } from '../utils/propTypes'

/**
 * This component refers to the group underneath of the primary group {@link PolicyGroup}
 * @param {Object} parentGroup - product name
 * @param {Object} subGroup - subGroup
 */
const PrimaryGroup = ({ parentGroup, subGroup }) => {
  const { isExpanded, toggleExpand, toggleExpandAll, isAllExpanded } =
    useExpansion()
  const {
    state: { selectedPolicies },
    selectGroup,
  } = useSelection()

  const { name, categories } = subGroup

  const policiesInSubGroup = categories.flatMap(category =>
    category.items.filter(item => !item.readOnly)
  )

  // Reduce function to extract all names
  const allGroups = [subGroup].reduce((acc, group) => {
    // Add the group name
    acc.push(`${parentGroup}-${group.name}`)
    // Iterate over categories within the subGroups and add their names
    group.categories.forEach(category => {
      acc.push(`${parentGroup}-${group.name}-${category.name}`)
    })

    return acc
  }, [])

  const handleExpandAll = () => {
    toggleExpandAll({ parentGroup: null, items: allGroups })
  }

  const selectAll = policiesInSubGroup?.length
    ? policiesInSubGroup.every(item =>
        selectedPolicies.some(policy => item.id === policy.id)
      )
    : false

  const handleSelectAll = () => {
    selectGroup(policiesInSubGroup, !selectAll)
  }

  const handleToggleExpand = e => {
    e.stopPropagation()
    toggleExpand({ parentGroup, name })
  }

  const isGroupExpanded = isExpanded(parentGroup, name)

  return (
    <div className={`mb-5 bg-white rounded ${isGroupExpanded ? 'border' : ''}`}>
      <Accordion
        toggleExpand={handleToggleExpand}
        handleSelectAll={handleSelectAll}
        selectAll={selectAll}
        tooltipText={`Select all in ${name}`}
        backgroundColor='bg-white'
        disabled={policiesInSubGroup?.length === 0}
      >
        <span className='font-semibold'>{name}</span>
        <div className='ml-auto flex items-center'>
          <button
            type='button'
            onClick={e => {
              e.stopPropagation()
              handleExpandAll()
            }}
            className='p-2 bg-gray-800 text-white text-sm rounded mr-2'
          >
            {isAllExpanded(null, allGroups) ? 'Collapse' : 'Expand'}
          </button>
          <ExpandButton isExpanded={isGroupExpanded} />
        </div>
      </Accordion>

      {isGroupExpanded && (
        <div className='m-2'>
          {categories.map(category =>
            category.name === null ? (
              category.items.map(item => <Policy key={item.name} item={item} />)
            ) : (
              <PolicyCategory
                key={category.name}
                parentGroup={`${parentGroup}-${name}`}
                category={category}
              />
            )
          )}
        </div>
      )}
    </div>
  )
}

export default PrimaryGroup

PrimaryGroup.propTypes = {
  parentGroup: string.isRequired,
  subGroup: primaryGroupShape.isRequired,
}
