import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { node } from 'prop-types'

export const PendingChangesStateContext = createContext({})
export const usePendingChangesStateContext = () =>
  useContext(PendingChangesStateContext)

export const pendingChangesStates = {
  none: 'none',
  actionInitiated: 'actionInitiated',
  awaitingPendingChanges: 'awaitingPendingChanges',
}

const pendingChangesTimeoutSeconds = 60

const PendingChangesProvider = ({ children }) => {
  const [pendingChangesState, setPendingChangesState] = useState(
    pendingChangesStates.none
  )
  const [timeoutId, setTimeoutId] = useState(undefined)

  // Array of policy ID's/Guids that have pending changes so we can
  // show them as loading
  const [policyIdsWithPendingChanges, setPolicyIdsWithPendingChanges] =
    useState([])

  const isPolicyIdPendingChanges = useCallback(
    policyGuidToCheck =>
      policyIdsWithPendingChanges.includes(policyGuidToCheck),
    [policyIdsWithPendingChanges]
  )

  // set the state of pendingChanges and initiates or clears a timeout to ensure we don't
  // infinitely fetch for pending changes
  const handlePendingChangesState = useCallback(
    newState => {
      setPendingChangesState(newState)
      if (newState === pendingChangesStates.actionInitiated) {
        const timeout = setTimeout(() => {
          setPendingChangesState(pendingChangesStates.none)
          setPolicyIdsWithPendingChanges([])
        }, pendingChangesTimeoutSeconds * 1000)
        setTimeoutId(timeout)
      } else if (newState === pendingChangesStates.awaitingPendingChanges) {
        clearTimeout(timeoutId)
      }
    },
    [timeoutId, setPendingChangesState]
  )

  const contextValue = useMemo(
    () => ({
      pendingChangesState,
      setPendingChangesState: handlePendingChangesState,
      isPolicyIdPendingChanges,
      setPolicyIdsWithPendingChanges,
    }),
    [handlePendingChangesState, isPolicyIdPendingChanges, pendingChangesState]
  )

  return (
    <PendingChangesStateContext.Provider value={contextValue}>
      {children}
    </PendingChangesStateContext.Provider>
  )
}

PendingChangesProvider.propTypes = {
  children: node.isRequired,
}

export default PendingChangesProvider
