import { createContext, useContext, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import Axios from 'axios'
import { bool, node } from 'prop-types'

export const NotificationsContext = createContext({})
export const useNotificationsContext = () => useContext(NotificationsContext)
export const notificationsQueryKey = 'notifications'

const NotificationsProvider = ({ isActive, children }) => {
  const allNotifications = useQuery({
    queryKey: [notificationsQueryKey],
    gcTime: 0,
    queryFn: async () => {
      const response = await Axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/notifications`
      )
      return response.data
    },
    enabled: isActive,
    refetchInterval: 30000,
  })

  const notifications = useMemo(() => {
    if (allNotifications.isSuccess && !!allNotifications.data) {
      return allNotifications.data
        .filter(e => !e.action.includes('BackupPolicyAuto'))
        .sort((a, b) => new Date(b.queueTime) - new Date(a.queueTime))
    }
    return []
  }, [allNotifications.data, allNotifications.isSuccess])

  const notificationsAutomated = useMemo(() => {
    if (allNotifications.isSuccess && !!allNotifications.data) {
      return allNotifications.data
        .filter(e => e.action.includes('BackupPolicyAuto'))
        .sort((a, b) => new Date(b.queueTime) - new Date(a.queueTime))
    }
    return []
  }, [allNotifications.data, allNotifications.isSuccess])

  const activeNotificationCount = useMemo(() => {
    if (notifications.length > 0) {
      return notifications.filter(n => !n.endTime).length
    }
    return 0
  }, [notifications])

  const notificationsValue = useMemo(
    () => ({
      notifications,
      notificationsAutomated,
      activeNotificationCount,
      refetchNotifications: () => allNotifications.refetch(),
    }),
    [
      activeNotificationCount,
      allNotifications,
      notifications,
      notificationsAutomated,
    ]
  )

  return (
    <NotificationsContext.Provider value={notificationsValue}>
      {children}
    </NotificationsContext.Provider>
  )
}

NotificationsProvider.propTypes = {
  isActive: bool.isRequired,
  children: node.isRequired,
}

export default NotificationsProvider
