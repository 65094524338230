import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Checkbox from '../../components/Checkbox'

const AddUser = () => {
  const [clients, setClients] = useState([])
  const [loadingState, setLoadingState] = useState(false)
  const [formData, setFormData] = useState({
    userName: '',
    displayName: '',
    clientId: '',
    password: '',
    clientAdmin: false,
  })
  const [requestError, setRequestError] = useState('')
  const [fieldsError, setFieldsError] = useState('')
  const [responseData, setResponseData] = useState('')
  const [error, setError] = useState([])
  const [isEmail, setIsEmail] = useState(true)

  const navigate = useNavigate()

  const clientSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/get-clients`
      )
      const { data } = response
      setClients(data)
    } catch (error) {
      console.error('Error:', error.response.status)
      toast.error('Error Retrieving Clients')
    }
  }

  useEffect(() => {
    clientSearch()
  }, [])

  const validateEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleMailChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({ ...prevData, [name]: value }))

    if (name === 'userName') {
      setIsEmail(validateEmail(value))
    }
  }

  const handleClientChange = e => {
    const selectedClientId = e.target.value
    setFormData(prevData => ({
      ...prevData,
      clientId: selectedClientId,
    }))
  }

  const handleClientAdminChange = isClientAdmin => {
    setFormData(prevData => ({
      ...prevData,
      clientAdmin: isClientAdmin,
    }))
  }

  // Password conditions
  const validPass = () => {
    const newPass = formData.password
    const issues = []

    if (newPass.length < 8 || newPass.length > 25) {
      issues.push('New password should be between 8 -25 characters')
    }
    if (!/[A-Z]/.test(newPass)) {
      issues.push('New password should have at least 1 uppercase character')
    }
    if (!/\d/.test(newPass)) {
      issues.push('New password should have at least 1 number')
    }
    if (!/[^A-Za-z0-9]/.test(newPass)) {
      issues.push('New password should have at least 1 special character')
    }
    if (/[\s]/.test(newPass)) {
      issues.push('New password should not contain spaces')
    }

    if (issues.length) {
      setError(issues)
      return false
    }

    setError([])
    return true
  }

  const submitData = async () => {
    if (
      !formData.clientId ||
      !formData.userName ||
      !formData.displayName ||
      !formData.password
    ) {
      setFieldsError('All form fields are required')
      return
    }

    if (!isEmail) {
      setFieldsError('Please enter a valid email address for the user name')
      return
    }

    if (!validPass()) {
      return
    }

    try {
      setLoadingState(true)

      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/addUser`,
        {
          clientId: formData.clientId,
          username: formData.userName,
          displayName: formData.displayName,
          password: formData.password,
          clientAdmin: formData.clientAdmin,
        }
      )

      setResponseData(response.data.message.Data)
      setFieldsError('')
      setFormData({ userName: '', displayName: '', clientId: '', password: '' })
    } catch (error) {
      setRequestError('Error. Please check the details and try again.')
      setResponseData('')
      setFieldsError('')
    } finally {
      setLoadingState(false)
    }
  }

  return (
    <div className='ui-panel admin-2-col'>
      <div className='heading'>
        <h2>Add User</h2>
      </div>
      <button
        type='button'
        onClick={() => navigate(-1)}
        className='btn bg-white text-gray-900 flex items-center justify-center mb-2'
      >
        {' '}
        <FontAwesomeIcon icon={icon({ name: 'angle-left' })} />
        <span>&nbsp;&nbsp;Back</span>
      </button>

      <div className='action-body no-progress-bar flex'>
        <form className='w-[50%] max-w-lg'>
          {fieldsError && (
            <div className='mt-4 mb-[24px]' role='alert'>
              <span className='font-small'>{fieldsError}</span>
            </div>
          )}
          <div className='flex flex-col space-y-2 my-4'>
            <label className='font-semibold ' htmlFor='username'>
              User name:
            </label>
            <input
              type='text'
              name='userName'
              value={formData.userName || ''}
              onChange={handleMailChange}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
            />
          </div>

          <div className='flex flex-col space-y-2 my-4'>
            <label className='font-semibold ' htmlFor='displayname'>
              Display Name:
            </label>
            <input
              type='text'
              name='displayName'
              value={formData.displayName || ''}
              onChange={handleMailChange}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
            />
          </div>

          <div className='flex flex-col space-y-2 my-4'>
            <label className='font-semibold' htmlFor='selectclient'>
              Select Client:
            </label>
            <select
              value={formData.clientId}
              onChange={handleClientChange}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
            >
              <option value=''>Select a client</option>
              {clients.map(client => (
                <option key={uuidv4()} value={client.clientId}>
                  {client.displayName}
                </option>
              ))}
            </select>
          </div>

          <div className='flex flex-col space-y-2 my-4'>
            <label className='font-semibold ' htmlFor='password'>
              Password:
            </label>
            <input
              type='password'
              name='password'
              value={formData.password || ''}
              onChange={handleMailChange}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
            />
          </div>

          <div className='flex flex-col space-y-2 my-4'>
            <Checkbox
              checked={formData.clientAdmin || false}
              onChange={handleClientAdminChange}
              label={<b>Client Admin?</b>}
            />
          </div>

          <button
            type='button'
            onClick={submitData}
            className='btn cyan-btn mt-4'
          >
            Submit
          </button>
        </form>
        <div className='w-[50%] p-4 ml-4'>
          <h4 className='mb-[20px]'>Result</h4>

          {loadingState ? (
            <div id='loading-spinner'>
              <div className='loading' />
            </div>
          ) : error.length > 0 ? (
            error.map(issue => (
              <ul key={uuidv4()}>
                <li className='font-medium list-disc ml-4'>{issue}</li>
              </ul>
            ))
          ) : responseData ? (
            <div>
              <p>
                <strong>Client Id: </strong>
                <span>{responseData.clientId}</span>
              </p>
              <p>
                <strong>Display Name: </strong>
                <span>{responseData.displayName}</span>
              </p>
              <p>
                <strong>Passhash: </strong>
                <span>{responseData.passhash}</span>
              </p>
              <p>
                <strong>Username: </strong>
                <span>{responseData.username}</span>
              </p>
              <p>
                <strong>MFA Seed: </strong>
                <span>{responseData.mfaSeed}</span>
              </p>
              <p>
                <strong>User Id: </strong>
                <span>{responseData.userId}</span>
              </p>
            </div>
          ) : requestError ? (
            <p>{requestError}</p>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default AddUser
