import { number } from 'prop-types'
import {
  faCheckCircle,
  faCircleXmark,
  faWarning,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TenantAlignmentIcon = ({
  score = 0,
  alignedThreshold = 100,
  semiAlignedThreshold = 90,
}) => {
  let color = 'cyan'
  let icon = faCheckCircle
  let alt = 'Fully Aligned'

  if (score < alignedThreshold && score >= semiAlignedThreshold) {
    color = 'yellow'
    icon = faWarning
    alt = 'Semi-Aligned'
  }
  if (score < semiAlignedThreshold) {
    color = 'red'
    icon = faCircleXmark
    alt = 'Not Aligned'
  }

  return (
    <div className={`alignment-light ${color}`}>
      <FontAwesomeIcon icon={icon} title={alt} />
    </div>
  )
}

TenantAlignmentIcon.defaultProps = {
  score: 0,
  alignedThreshold: 100,
  semiAlignedThreshold: 90,
}

TenantAlignmentIcon.propTypes = {
  score: number,
  alignedThreshold: number,
  semiAlignedThreshold: number,
}

export default TenantAlignmentIcon
