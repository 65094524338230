/* eslint-disable react/forbid-prop-types */
import { useMemo, useState } from 'react'
import { arrayOf, bool, func, object } from 'prop-types'
import Modal from '../Modal/Modal'
import { steps } from './utils'
import DeployConfigurationContent from './DeployPolicyConfiguration'
import DeployPolicyReviewSummary from './DeployPolicyReviewSummary'
import { createNewAdditionalSettingsConfig } from '../AdditionalOptions/AdditionalOptionsConfig'
import DeployPolicyFinalSummary from '../PolicyDeployment/DeployPolicyFinalSummary'
import createDeployPolicyPayload from '../PolicyDeployment/createDeployPolicyPayload'
import ConfirmationPopupSkeleton from '../LoadingSkeletons/ConfirmationPopupSkeleton'
import Alert from '../Alert/Alert'
import {
  pendingChangesStates,
  usePendingChangesStateContext,
} from '../../contexts/PendingChangesStateContext'
import {
  policyShape,
  summaryTenantShape,
} from '../../utils/propTypes/tenantAlignmentProps'
import ProcessClientVariablesSelector from '../ClientVariables/ProcessClientVariablesSelector'
import ModalActionButtons from '../Modal/ModalActionButtons'
import useDeployPolicies from './hooks/useDeployPolicies'

const DeployPolicyDialog = ({
  isOpen,
  setModalOpen,
  onCancel,
  selectedPolicies,
  sourceTenant,
  destinationTenants,
  additionalSettings,
  setAdditionalSettings,
  // we pass it in like this because otherwise it has to try and initialise the
  // step state with the value of the feature flag onMount which creates a race condition
  enableClientVariables,
}) => {
  const [step, setStep] = useState(
    enableClientVariables ? steps.variables : steps.configuration
  )

  const [selectedVariables, setSelectedVariables] = useState([])

  const allMatchingVariables = useMemo(
    () =>
      Array.from(
        selectedPolicies
          .flatMap(policy => policy.matchingVariables || [])
          .reduce(
            (map, variable) => map.set(variable.variableId, variable),
            new Map()
          )
          .values()
      ),
    [selectedPolicies]
  )

  const { setPendingChangesState, setPolicyIdsWithPendingChanges } =
    usePendingChangesStateContext()

  const deployPolicies = useDeployPolicies(() => {
    setPolicyIdsWithPendingChanges(existingPolicies => [
      ...existingPolicies,
      ...selectedPolicies.map(
        policy => policy.subjectPolicyId || policy.policyId
      ),
    ])
    setPendingChangesState(pendingChangesStates.actionInitiated)
    setStep(steps.final)
  })

  if (selectedPolicies.length === 0)
    return (
      <Modal isOpen={isOpen} setModalOpen={setModalOpen}>
        No Policies Selected
        <button type='button' className='btn cyan-btn' onClick={onCancel}>
          Close
        </button>
      </Modal>
    )

  return (
    <Modal
      isOpen={isOpen}
      dialogPanelWidth='w-3/5'
      setModalOpen={setModalOpen}
      closeOnBlur={false}
    >
      {enableClientVariables && step === steps.variables && (
        <>
          <h3 className='mb-4'>Variables</h3>
          <ProcessClientVariablesSelector
            destinationTenants={destinationTenants}
            matchingVariables={allMatchingVariables}
            selectedVariables={selectedVariables}
            setSelectedVariables={setSelectedVariables}
          />
          <ModalActionButtons
            onCancel={() => {
              setAdditionalSettings(createNewAdditionalSettingsConfig())
              onCancel()
            }}
            onConfirm={() => setStep(steps.configuration)}
            confirmActionText='Next'
          />
        </>
      )}
      {step === steps.configuration && (
        <DeployConfigurationContent
          selectedPolicies={selectedPolicies}
          additionalSettings={additionalSettings}
          setAdditionalSettings={setAdditionalSettings}
          onCancel={() => {
            if (enableClientVariables) {
              setStep(steps.variables)
            } else {
              setAdditionalSettings(createNewAdditionalSettingsConfig())
              onCancel()
            }
          }}
          onConfirm={() => setStep(steps.review)}
          cancelButtonText={enableClientVariables ? 'Previous' : 'Cancel'}
        />
      )}
      {step === steps.review && !deployPolicies.isPending && (
        <DeployPolicyReviewSummary
          selectedPolicies={selectedPolicies.map(({ policyName, ...rest }) => ({
            displayName: policyName,
            ...rest,
          }))}
          additionalSettings={additionalSettings}
          sourceTenantName={sourceTenant.tenantFriendlyName}
          destinationTenants={destinationTenants}
          onSubmit={() => {
            deployPolicies.mutate(
              createDeployPolicyPayload(
                selectedPolicies,
                additionalSettings,
                sourceTenant,
                destinationTenants,
                selectedVariables
              )
            )
          }}
          onPrevious={() => setStep(steps.configuration)}
          selectedVariables={selectedVariables}
        />
      )}
      {deployPolicies.isError && (
        <Alert
          title='Unexpected Error Occurred'
          type='error'
          message={deployPolicies.error}
        />
      )}
      {deployPolicies.isPending && (
        <ConfirmationPopupSkeleton loadingTitle='Deployment in progress...' />
      )}
      {step === steps.final && (
        <DeployPolicyFinalSummary
          successfulPolicies={deployPolicies.data.data.confirmationData}
          erroredPolicies={deployPolicies.data.data.errorMessages}
          additionalPolicySettings={additionalSettings.filter(setting =>
            selectedPolicies.some(
              policy => policy.policyTypeId === setting.policyId
            )
          )}
          onClose={() => {
            // reset additional settings object
            setAdditionalSettings(createNewAdditionalSettingsConfig())
            setModalOpen(false)
          }}
          sourceTenantName={sourceTenant.tenantFriendlyName}
          destinationTenants={destinationTenants}
        />
      )}
    </Modal>
  )
}

DeployPolicyDialog.propTypes = {
  isOpen: bool.isRequired,
  setModalOpen: func.isRequired,
  onCancel: func.isRequired,
  selectedPolicies: arrayOf(policyShape).isRequired,
  additionalSettings: arrayOf(object).isRequired,
  setAdditionalSettings: func.isRequired,
  sourceTenant: summaryTenantShape.isRequired,
  destinationTenants: arrayOf(summaryTenantShape.isRequired).isRequired,
  enableClientVariables: bool.isRequired,
}

export default DeployPolicyDialog
