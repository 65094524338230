import Axios from 'axios'
import { toast } from 'react-toastify'

export const usePolicyViewer = () => {
  const viewPolicy = async (
    clientTenantId,
    tenantFriendlyName,
    policyGuid,
    policyTypeId,
    policyTypeName,
    policyDisplayName
  ) => {
    const params = {
      clientTenantId,
      policyTypeId,
      policyGuid,
    }

    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/get-policy-data`,
        { params }
      )

      const policyData = Array.isArray(response.data)
        ? response.data[0]
        : response.data

      window.policyData = JSON.stringify(policyData)
      window.policyTypeName = JSON.stringify(policyTypeName)
      window.policyDisplayName = JSON.stringify(policyDisplayName)
      window.tenantName = JSON.stringify(tenantFriendlyName)

      window.open(
        '/policy.html',
        `view-policy-${policyData.id}`,
        'width=600,height=900'
      )
    } catch (error) {
      toast.error('Failed to get policy data')
    }
  }

  return viewPolicy
}
