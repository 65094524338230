/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faPlus } from '@fortawesome/free-solid-svg-icons'
import { MRT_GlobalFilterTextField } from 'material-react-table'
import ListTable from '../ListTable/components/ListTable'
import useTenantTags from './api/useTenantTags'
import ContentPanel from '../ContentPanel/ContentPanel'
import TenantTagForm from './TenantTagForm'
import Alert from '../Alert/Alert'
import tableConfig from './tableConfig'

const TenantTagSettings = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [tagToEdit, setTagToEdit] = useState(undefined)
  const [tenantsLinkedToTag, setTenantsLinkedToTag] = useState(undefined)
  const [linkedTenantsSearch, setLinkedTenantsSearch] = useState('')

  const {
    data: tenantTags,
    isPending: isTenantTagsLoading,
    isError: isTenantTagsError,
    error: tenantTagsError,
  } = useTenantTags()

  const togglePanel = () => setIsOpen(!isOpen)

  const columns = useMemo(() => {
    const handleEditTag = tagData => {
      setTagToEdit(tagData)
      setIsOpen(true)
    }
    return tableConfig.tableColumnDefinitions({
      handleEditTag,
      setIsContentPanelOpen: () => setIsOpen(false),
      setTenantsLinkedToTag,
    })
  }, [])

  const errorMessage =
    tenantTagsError?.response?.data?.message || 'Error fetching tags'

  const filteredLinkedTenants = useMemo(
    () =>
      tenantsLinkedToTag?.filter(tenant =>
        tenant.toLowerCase().includes(linkedTenantsSearch.toLowerCase())
      ) ?? [],
    [tenantsLinkedToTag, linkedTenantsSearch]
  )

  return (
    <div>
      <ListTable
        uniqueKey='id'
        data={tenantTags ?? []}
        columns={columns}
        renderTopToolbar={({ table }) => (
          <div className='flex gap-3 items-center py-2'>
            <MRT_GlobalFilterTextField table={table} />
            <button
              type='button'
              className='btn cyan-btn'
              onClick={togglePanel}
            >
              <FontAwesomeIcon icon={faPlus} className='mr-1' />
              New
            </button>
          </div>
        )}
        loading={isTenantTagsLoading}
        enableRowSelection={false}
        enableMultiRowSelection={false}
        ariaLabelKey='tag'
      />
      {isTenantTagsError && (
        <Alert type='error' title={errorMessage}>
          <ul>
            {tenantTagsError?.response?.data?.errors.map(error => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </Alert>
      )}
      <ContentPanel
        isOpen={isOpen}
        togglePanel={togglePanel}
        panelHeading={tagToEdit ? 'Edit Existing Tag' : 'Create New Tag'}
      >
        <TenantTagForm
          closePanel={() => setIsOpen(false)}
          tagId={tagToEdit?.id}
          initialTagName={tagToEdit?.tag}
          initialDescription={tagToEdit?.description}
          initialSelectedColour={tagToEdit?.colour}
          onSuccessfulAction={() => setTagToEdit(undefined)}
        />
      </ContentPanel>

      <ContentPanel
        isOpen={!!tenantsLinkedToTag}
        panelHeading='Linked Tenants'
        togglePanel={() => {
          setTenantsLinkedToTag(undefined)
          setLinkedTenantsSearch('')
        }}
      >
        <div className='relative flex items-center'>
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className='absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-700 z-1'
          />
          <input
            className='flex-grow focus:outline-blue-400 w-full rounded-md light-grey-bg p-2 pl-10 text-gray-700'
            placeholder='Search Tenants...'
            type='search'
            aria-label='linked tenants search'
            value={linkedTenantsSearch}
            onChange={e => setLinkedTenantsSearch(e.target.value)}
          />
        </div>

        {filteredLinkedTenants && (
          <ul
            aria-label='linked tenants'
            className='flex flex-col gap-2 px-1 pt-2'
          >
            {filteredLinkedTenants.map(tenant => (
              <li key={tenant}>
                <span className='text-lg font-semibold'>{tenant}</span>
              </li>
            ))}
          </ul>
        )}
      </ContentPanel>
    </div>
  )
}

export default TenantTagSettings
