import { string } from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const TenantManagementSkeleton = ({ loadingTitle }) => (
  <>
    <h4 className='pt-6'>{loadingTitle}</h4>
    <div className='mb-3'>
      <Skeleton count={2} />
    </div>
    <div className='mb-3'>
      <Skeleton count={3} />
    </div>
    <div className='mb-3'>
      <Skeleton count={2} />
    </div>
    <div className='mb-3'>
      <Skeleton count={3} />
    </div>
    <div className='mb-3'>
      <Skeleton count={3} />
    </div>
    <div className='mb-3'>
      <Skeleton count={3} />
    </div>
    <div className='mb-3'>
      <Skeleton count={2} />
    </div>
    <div className='mb-3'>
      <Skeleton count={3} />
    </div>
    <div className='mb-3'>
      <Skeleton count={3} />
    </div>
    <div className='mb-3'>
      <Skeleton count={2} />
    </div>
    <div className='mb-3'>
      <Skeleton count={3} />
    </div>
  </>
)

TenantManagementSkeleton.defaultProps = {
  loadingTitle: '',
}

TenantManagementSkeleton.propTypes = {
  loadingTitle: string,
}

export default TenantManagementSkeleton
