import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { toast } from 'react-toastify'

const useCreateOrUpdateClientVariable = (onSuccess, isUpdate = false) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: variableData => {
      if (isUpdate) {
        return axios.put(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/variables/${variableData.id}`,
          variableData
        )
      }
      return axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/variables`,
        variableData
      )
    },
    onSuccess: variableData => {
      const variableName = variableData?.data?.data?.name

      toast.success(
        `${variableName ? `Variable ${variableName}` : 'Variable'} ${isUpdate ? 'updated' : 'created'} successfully!`
      )
      queryClient.invalidateQueries(['clientVariables'])
      onSuccess()
    },
  })
}

export default useCreateOrUpdateClientVariable
