import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form'
import { bool, func, string } from 'prop-types'
import { toast } from 'react-toastify'

import ClientsSelect from './ClientsSelect'
import BaselinesSelect from './BaselinesSelect'
import MembersSelect from './MembersSelect'

import useGetBaselineGroup from '../api/admin/useGetBaselineGroup'
import useCreateBaselineGroup from '../api/admin/useCreateBaselineGroup'

import SharedBaselinesSkeleton from './SharedBaselineGroupSkeleton'
import Alert from '../../Alert/Alert'
import FormControlButtons from '../../Form/components/FormControlButtons/FormControlButtons'

const SharedBaselineGroupForm = ({
  baselineGroupId,
  isEdit,
  isOpen,
  setIsOpen,
}) => {
  const defaultFormValues = {
    isEdit,
    id: '',
    name: '',
    description: '',
    clientId: '',
    publishedBaselines: [],
    members: [],
    availableToAllClients: false,
    inforcerManaged: false,
  }

  const { data: initialData, isLoading } = useGetBaselineGroup(baselineGroupId)

  // use default form values and then hydrate with initial data if it exists
  const initialFormValues = {
    ...defaultFormValues,
    ...(isEdit && {
      ...initialData?.data,
      publishedBaselines:
        initialData?.data.publishedBaselines.map(
          baseline => baseline.publishedBaselineTenantId
        ) || [],
      members:
        initialData?.data.members.map(member => member.memberClientId) || [],
    }),
  }

  // Because we load data async we need to update the default form values after data is loaded
  const methods = useForm({
    values: initialFormValues,
  })

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = methods

  const handleReset = () => {
    setIsOpen('')
    reset(undefined, { keepDefaultValues: true })
    setValue('isEdit', false)
  }

  const createBaselineGroup = useCreateBaselineGroup(() => {
    handleReset()
    toast.success(
      `Shared baseline group  ${isEdit ? `updated` : `created`} successfully!`
    )
  })

  const onSubmit = data => {
    createBaselineGroup.mutate({ isEdit, data })
  }

  const availableToAllClients = useWatch({
    control,
    name: 'availableToAllClients',
  })
  const inforcerManaged = useWatch({
    control,
    name: 'inforcerManaged',
  })

  if (isLoading) {
    return (
      <div
        className={`overflow-y-auto fixed z-10 top-0 right-0 h-full bg-white w-[640px] p-3 pt-[70px] transform transition-transform ${
          isOpen === baselineGroupId || isOpen === 'create'
            ? 'translate-x-0'
            : 'translate-x-full'
        }`}
      >
        <SharedBaselinesSkeleton loadingTitle='Loading shared baseline group...' />
      </div>
    )
  }

  return (
    <div>
      <div
        className={`overflow-y-auto fixed z-10 top-0 right-0 h-full bg-white w-[640px] p-3 pt-[70px] transform transition-transform ${
          isOpen === baselineGroupId || isOpen === 'create'
            ? 'translate-x-0'
            : 'translate-x-full'
        }`}
      >
        <h2 className='text-base font-semibold text-gray-900 pb-2'>
          {isEdit ? 'Edit' : 'New'} shared baseline group
        </h2>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className='space-y-6'>
            <div className='space-y-2'>
              <input
                id='name'
                placeholder={
                  errors.name ? errors.name.message : 'Baseline group name'
                }
                {...register('name', { required: 'Name is required' })}
                className={`block w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none sm:text-sm ${
                  errors.name
                    ? 'border-red-500'
                    : 'focus:ring-blue-500 focus:border-blue-500'
                }`}
              />
            </div>
            <div className='space-y-2'>
              <textarea
                id='description'
                placeholder={
                  errors.description
                    ? errors.description.message
                    : 'Baseline group description'
                }
                {...register('description')}
                className={`block w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none sm:text-sm ${
                  errors.description
                    ? 'border-red-500'
                    : 'focus:ring-blue-500 focus:border-blue-500'
                }`}
              />
            </div>

            <ClientsSelect />
            <BaselinesSelect />
            <MembersSelect />

            <div className='mb-2'>
              <Controller
                control={control}
                name='inforcerManaged'
                render={() => (
                  <input
                    type='checkbox'
                    id='inforcerManaged'
                    value={inforcerManaged}
                    disabled={availableToAllClients}
                    onChange={event => {
                      setValue('inforcerManaged', event.target.checked)
                    }}
                    checked={inforcerManaged}
                    className='mr-3'
                  />
                )}
              />
              <label htmlFor='inforcerManaged'>Inforcer managed baseline</label>
            </div>

            {!createBaselineGroup.isLoading && createBaselineGroup.isError && (
              <div className='mb-2'>
                <Alert
                  type='error'
                  title='Failed to create group'
                  margin='mb-4'
                >
                  <ul>
                    {createBaselineGroup.isError &&
                      createBaselineGroup.error.response.data?.errors.map(
                        errorMessage => (
                          <li key={errorMessage}>{errorMessage}</li>
                        )
                      )}
                  </ul>
                </Alert>
              </div>
            )}
            <FormControlButtons
              onCancel={() => {
                createBaselineGroup.reset()
                handleReset()
              }}
              onReset={() => {
                reset(undefined, { keepDefaultValues: true })
                createBaselineGroup.reset()
              }}
              confirmButtonText={isEdit ? 'Update' : 'Save'}
            />
          </form>
        </FormProvider>
      </div>
    </div>
  )
}

SharedBaselineGroupForm.defaultProps = {
  baselineGroupId: null,
  isEdit: false,
  isOpen: 'create',
}

SharedBaselineGroupForm.propTypes = {
  baselineGroupId: string,
  isEdit: bool,
  isOpen: string,
  setIsOpen: func.isRequired,
}

export default SharedBaselineGroupForm
