import { func, oneOf, shape, string } from 'prop-types'

const FormTextInput = ({ register, errors, label, type, placeholder }) => {
  const registerProps = register()

  return (
    <div className='flex flex-col'>
      <label htmlFor={label}>{label}</label>
      <input
        type={type}
        id={label}
        {...registerProps}
        placeholder={placeholder}
        className={`flex rounded border p-2 bg-white text-gray-700 focus:outline-blue-400 ${errors ? 'border-red-500 focus:outline-red-500' : 'border-gray-400'}`}
      />
      {errors && <p className='text-red-500'>{errors.message}</p>}
    </div>
  )
}

FormTextInput.defaultProps = {
  errors: undefined,
  label: '',
  type: 'text',
  placeholder: '',
}

FormTextInput.propTypes = {
  register: func.isRequired,
  errors: shape({
    message: string,
  }),
  label: string,
  type: oneOf(['text', 'number']),
  placeholder: string,
}

export default FormTextInput
