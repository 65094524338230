import { func, shape, string } from 'prop-types'

const RadioInput = ({ value, label, field }) => (
  <label className='cursor-pointer'>
    <input
      className='mr-2'
      type='radio'
      value={value}
      checked={field.value === value}
      onChange={() => field.onChange(value)}
    />
    {label}
  </label>
)

RadioInput.propTypes = {
  value: string.isRequired,
  label: string.isRequired,
  field: shape({
    value: string.isRequired,
    onChange: func.isRequired,
  }).isRequired,
}

export default RadioInput
