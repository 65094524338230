import { arrayOf, object } from 'prop-types'
import { Fragment } from 'react'

const ReviewSummaryContent = ({ additionalPolicySettings }) => (
  <div>
    <h4 className='text-lg pt-1'>
      <strong>Additional settings:</strong>
    </h4>
    <ul>
      {additionalPolicySettings.map(policy => (
        <Fragment key={`additional-options-${policy.policyHeading}`}>
          <hr className='border-[1px] mb-3 w-3/4' />
          <li>
            <strong className='text-md'>{policy.policyHeading}</strong>
            <ul
              aria-label={`deploy policy settings for ${policy.policyHeading}`}
            >
              {policy.additionalSettings.length === 0 ? (
                <p className='font-semibold opacity-75'>
                  There are no deployment options for this policy type
                </p>
              ) : (
                policy.additionalSettings.map(
                  ({
                    text,
                    flagName,
                    value,
                    renderDropdownIfTrue,
                    renderExchangeOptions,
                    hideToggle,
                    radioOptions,
                  }) => {
                    const policySettings =
                      renderDropdownIfTrue || renderExchangeOptions
                    const selectedSettingText =
                      policySettings &&
                      value &&
                      policySettings.find(option => option.value === true)?.text
                    const selectedRadioOption = radioOptions?.find(
                      option => option.value === value
                    )

                    return (
                      <li key={`${flagName}-${policy.policyId}`}>
                        {text}
                        {!hideToggle && (
                          <strong className='pl-2'>
                            {value ? 'Yes' : 'No'}
                          </strong>
                        )}
                        {hideToggle && (
                          <strong className='pl-2'>
                            {selectedRadioOption?.label ||
                              'Unknown value selected'}
                          </strong>
                        )}
                        {selectedSettingText && (
                          <strong> - {selectedSettingText}</strong>
                        )}
                      </li>
                    )
                  }
                )
              )}
            </ul>
          </li>
        </Fragment>
      ))}
    </ul>
  </div>
)

ReviewSummaryContent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  additionalPolicySettings: arrayOf(object).isRequired,
}

export default ReviewSummaryContent
