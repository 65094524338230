import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const NewClientNewOBCode = () => {
  const [loadingState, setLoadingState] = useState(false)
  const [formData, setFormData] = useState({
    displayName: '',
    allowedClients: '',
    reportEmail: '',
    onboardingToken: '',
  })
  const [requestError, setRequestError] = useState('')
  const [fieldsError, setFieldsError] = useState('')
  const [newData, setNewData] = useState([])
  const [isEmail, setIsEmail] = useState(true)

  const navigate = useNavigate()

  const validateEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleMailChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({ ...prevData, [name]: value }))

    if (name === 'reportEmail') {
      setIsEmail(validateEmail(value))
    }
  }

  const submitData = async () => {
    if (
      !formData.displayName ||
      !formData.reportEmail ||
      !formData.allowedClients ||
      !formData.onboardingToken
    ) {
      setFieldsError('All form fields are required')
      return
    }

    if (!isEmail) {
      setFieldsError('Please enter a valid email address for the Report Email')
      return
    }

    try {
      setLoadingState(true)

      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/newObTokenAndClient`,
        {
          displayName: formData.displayName,
          reportEmail: formData.reportEmail,
          allowedClients: formData.allowedClients,
          onboardingToken: formData.onboardingToken,
        }
      )

      let { data } = response
      if (!Array.isArray(data)) {
        data = [data]
      }

      setNewData(data)
      setFieldsError('')
      setFormData({
        displayName: '',
        allowedClients: '',
        reportEmail: '',
        onboardingToken: '',
      })
    } catch (error) {
      setRequestError('Error. Please check the details and try again.')
      setNewData([])
      setFieldsError('')
      console.error(error)
    } finally {
      setLoadingState(false)
    }
  }

  return (
    <div className='ui-panel admin-2-col'>
      <div className='heading'>
        <h2>Create New Client and Onboarding Code</h2>
      </div>

      <button
        type='button'
        onClick={() => navigate(-1)}
        className='btn bg-white text-gray-900 flex items-center justify-center mb-2'
      >
        {' '}
        <FontAwesomeIcon icon={icon({ name: 'angle-left' })} />
        <span>&nbsp;&nbsp;Back</span>
      </button>
      <div className='action-body no-progress-bar flex'>
        <form className='w-[50%] max-w-lg'>
          {fieldsError && (
            <div className='mt-4 mb-[24px]' role='alert'>
              <span className='font-small'>{fieldsError}</span>
            </div>
          )}
          <div className='flex flex-col space-y-2 my-4'>
            <label className='font-semibold ' htmlFor='displayname'>
              Display Name:
            </label>
            <input
              type='text'
              name='displayName'
              value={formData.displayName || ''}
              onChange={handleMailChange}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400 '
            />
          </div>
          <div className='flex flex-col space-y-2 my-4'>
            <label className='font-semibold ' htmlFor='reportemail'>
              Report Email:
            </label>
            <input
              type='text'
              name='reportEmail'
              value={formData.reportEmail || ''}
              onChange={handleMailChange}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400 '
            />
          </div>
          <div className='flex flex-col space-y-2 my-4'>
            <label className='font-semibold ' htmlFor='allowedclients'>
              Allowed Clients:
            </label>
            <input
              type='number'
              min='0'
              name='allowedClients'
              value={formData.allowedClients || ''}
              onChange={handleMailChange}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400 '
            />
          </div>
          <div className='flex flex-col space-y-2 my-4'>
            <label className='font-semibold ' htmlFor='onboardingtokenname'>
              Onboarding Token Name:
            </label>
            <input
              type='text'
              name='onboardingToken'
              value={formData.onboardingToken || ''}
              onChange={handleMailChange}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400 '
            />
          </div>
          <button
            type='button'
            onClick={submitData}
            className='btn cyan-btn mt-4'
          >
            Submit
          </button>
        </form>
        <div className='w-[50%] p-4 ml-4'>
          <h4 className='mb-[10px]'>Result:</h4>

          {loadingState ? (
            <div id='loading-spinner'>
              <div className='loading' />
            </div>
          ) : newData.length > 0 ? (
            newData.map(user => (
              <div key={uuidv4()}>
                <p>
                  <strong>Client Id: </strong>
                  <span>{user.clientId}</span>
                </p>
                <p>
                  <strong>Onboarding Code: </strong>
                  <span>{user.onboardingCode}</span>
                </p>
                <p>
                  <strong>Onboarding Code ID: </strong>
                  <span>{user.onboardingCodeId}</span>
                </p>
                <p>
                  <strong>Expiry Date: </strong>
                  <span>{user.expiryDate}</span>
                </p>
                <p>
                  <strong>Allowed Onboardings: </strong>
                  <span>{user.allowedOnboardings}</span>
                </p>
              </div>
            ))
          ) : requestError ? (
            <p>{requestError}</p>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default NewClientNewOBCode
