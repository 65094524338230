import { bool, func, node, string } from 'prop-types'

const Toggle = ({ onChange, checked, disabled, labelText, label }) => (
  <label className='inline-flex items-center cursor-pointer w-fit'>
    <input
      type='checkbox'
      value=''
      className='hidden peer'
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[rgb(var(--cyan))]" />
    {labelText && (
      <span className='ms-2 font-medium text-base text-gray-900 dark:text-gray-300'>
        {labelText}
      </span>
    )}
    {label}
  </label>
)

Toggle.defaultProps = {
  disabled: false,
  labelText: undefined,
  label: undefined,
}

Toggle.propTypes = {
  onChange: func.isRequired,
  checked: bool.isRequired,
  labelText: string,
  label: node,
  disabled: bool,
}

export default Toggle
