import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const useMatchVariables = (clientTenantIds, variableIds) =>
  useQuery({
    queryKey: ['matchVariables', clientTenantIds, variableIds],
    queryFn: async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/variables/matches`,
        {
          clientTenantIds,
          variableIds,
        }
      )
      return response.data.data
    },
    enabled: clientTenantIds.length > 0 && variableIds.length > 0,
  })

export default useMatchVariables
