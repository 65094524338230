import { arrayOf, bool, func, node, shape, string } from 'prop-types'
import { useFeatureFlag } from 'configcat-react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faX } from '@fortawesome/free-solid-svg-icons'

import SearchBar from './SearchBar'
import FilterDropdown from './FilterDropdown'
import PolicyTagsFilter from './PolicyTagsFilter'
import ToolbarButtons from './ToolbarButtons'
import { useSelection } from '../context/SelectionContext'
import useAllItems from '../hooks/useAllItems'
import useGroupedOptions from '../hooks/useGroupedOptions'
import { useExpansion } from '../context/ExpansionContext'
import { primaryGroupShape } from '../utils/propTypes'

const PolicyPickerHeader = ({
  isLoading,
  groupedPolicies,
  filteredPolicies,
  searchTerm,
  setSearchTerm,
  selectedOptions,
  setSelectedOptions,
  selectedPolicyTags,
  setSelectedPolicyTags,
  children,
}) => {
  const { getSelectedItemsCount, resetSelectionState } = useSelection()
  const { toggleExpandAll, resetExpansionState } = useExpansion()

  const { value: enablePolicyTags } = useFeatureFlag('enablePolicyTags', false)

  const handleSearchTermChange = term => {
    // Set the raw term for input without trimming (allow spaces while typing)
    setSearchTerm(term)

    // Trim and lowercase the term for matching purposes only
    const trimmedTerm = term.trim().toLowerCase()

    if (trimmedTerm !== '') {
      const matchingGroups = []

      groupedPolicies.forEach(group => {
        const groupMatches = group.name.toLowerCase().includes(trimmedTerm)

        const matchingSubGroups = group.subGroups.reduce((subAcc, subGroup) => {
          const subGroupMatches = subGroup.name
            .toLowerCase()
            .includes(trimmedTerm)

          const matchingCategories = subGroup.categories.reduce(
            (catAcc, category) => {
              const categoryMatches = category?.name
                ?.toLowerCase()
                .includes(trimmedTerm)

              const matchingItems = category.items.reduce((itemAcc, item) => {
                const itemMatches = item.displayName
                  .toLowerCase()
                  .includes(trimmedTerm)

                if (itemMatches) {
                  itemAcc.push(item.displayName)
                }

                return itemAcc
              }, [])

              if (categoryMatches || matchingItems.length > 0) {
                catAcc.push({
                  categoryName: category.name,
                  items: matchingItems,
                })
              }

              return catAcc
            },
            []
          )

          if (subGroupMatches || matchingCategories.length > 0) {
            subAcc.push({
              subGroupName: subGroup.name,
              categories: matchingCategories,
            })
          }

          return subAcc
        }, [])

        if (groupMatches || matchingSubGroups.length > 0) {
          matchingGroups.push({
            groupName: group.name,
            subGroups: matchingSubGroups,
          })
        }
      })

      if (matchingGroups.length > 0) {
        const itemsToExpand = matchingGroups.reduce((acc, group) => {
          acc.push(group.groupName)

          group.subGroups.forEach(subGroup => {
            acc.push(`${group.groupName}-${subGroup.subGroupName}`)

            subGroup.categories.forEach(category => {
              acc.push(
                `${group.groupName}-${subGroup.subGroupName}-${category.categoryName}`
              )

              category.items.forEach(item => {
                acc.push(
                  `${group.groupName}-${subGroup.subGroupName}-${category.categoryName}-${item}`
                )
              })
            })
          })

          return acc
        }, [])

        toggleExpandAll({
          parentGroup: null, // Use `null` or leave empty if not grouping at the top level
          items: itemsToExpand,
        })
      }
    } else {
      resetExpansionState()
    }
  }

  const allPolicies = useAllItems(filteredPolicies, isLoading)
  const groupedOptions = useGroupedOptions(groupedPolicies, isLoading)

  return (
    <div className='flex flex-col mb-4 space-y-1'>
      <div className='w-full'>
        <SearchBar
          searchTerm={searchTerm}
          setSearchTerm={handleSearchTermChange}
          suggestions={allPolicies}
        >
          <div className='flex flex-row space-x-1 mt-2'>
            <div className='basis-1/8'>
              <FilterDropdown
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                options={groupedOptions}
              />
            </div>
            {enablePolicyTags && (
              <div className='basis-1/8'>
                <PolicyTagsFilter
                  selectedPolicyTags={selectedPolicyTags}
                  setSelectedPolicyTags={setSelectedPolicyTags}
                  filteredPolicies={filteredPolicies}
                  hideLabel
                />
              </div>
            )}

            <div className='basis-1/3'>
              <ToolbarButtons
                groupedPolicies={groupedPolicies}
                allPolicies={allPolicies}
                filtersEnabled={
                  selectedOptions?.length > 0 || selectedPolicyTags?.length > 0
                }
              />
            </div>

            <div className='flex flex-1 justify-end'>
              <span className='flex items-center h-full text-xs font-medium'>
                {getSelectedItemsCount()} policies selected
                <button
                  type='button'
                  onClick={() => resetSelectionState()}
                  className='pl-4 text-xs rounded '
                  disabled={getSelectedItemsCount() === 0}
                >
                  {getSelectedItemsCount() > 0 && (
                    <FontAwesomeIcon
                      width={6}
                      height={6}
                      className='rounded-full mr-2'
                      icon={faX}
                    />
                  )}
                </button>
              </span>

              {children}
            </div>

            <div />
          </div>
        </SearchBar>
      </div>
    </div>
  )
}

PolicyPickerHeader.defaultProps = {
  children: null,
}

PolicyPickerHeader.propTypes = {
  isLoading: bool.isRequired,
  groupedPolicies: arrayOf(primaryGroupShape).isRequired,
  filteredPolicies: arrayOf(primaryGroupShape).isRequired,
  searchTerm: string.isRequired,
  setSearchTerm: func.isRequired,
  selectedOptions: arrayOf(
    shape({
      label: string,
      value: string,
    })
  ).isRequired,
  setSelectedOptions: func.isRequired,
  selectedPolicyTags: arrayOf(
    shape({
      label: string,
      value: string,
    })
  ).isRequired,
  setSelectedPolicyTags: func.isRequired,
  children: node,
}

export default PolicyPickerHeader
