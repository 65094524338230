import { bool, func, string } from 'prop-types'
import Modal from '../Modal/Modal'

/**
 * @param {{
 *  open: boolean,
 *  loading: boolean,
 *  error?: string,
 *  targetPolicyName?: string,
 *  baselinePolicyName?: string,
 *  onClose: () => void,
 *  onConfirmRename: () => void,
 * }} props
 */
const RenamePolicyPopup = ({
  open,
  loading,
  error,
  targetPolicyName,
  baselinePolicyName,
  onClose,
  onConfirmRename,
}) => (
  <Modal isOpen={open} setModalOpen={() => {}}>
    <h3 className='text-center mb-6'>Rename Policy</h3>
    <div className='text-center'>
      {loading ? (
        <>
          <div id='loading-spinner' data-testid='loadingSpinner'>
            <div className='loading' />
          </div>
          <p>Renaming policy, please wait...</p>
        </>
      ) : (
        <>
          You are about to rename <b>{targetPolicyName}</b> to{' '}
          <b>{baselinePolicyName}</b>. Are you sure you want to continue?
        </>
      )}
    </div>
    {error && <p className='text-red-500 text-center mt-[24px]'>{error}</p>}
    <div className='footer mt-10 flex justify-between'>
      <button
        type='button'
        disabled={loading}
        className='btn navy-btn mr-4'
        onClick={onClose}
      >
        Cancel
      </button>
      <button
        type='button'
        className='btn cyan-btn'
        disabled={loading}
        onClick={onConfirmRename}
      >
        Confirm
      </button>
    </div>
  </Modal>
)

RenamePolicyPopup.defaultProps = {
  error: undefined,
  targetPolicyName: undefined,
  baselinePolicyName: undefined,
}

RenamePolicyPopup.propTypes = {
  open: bool.isRequired,
  loading: bool.isRequired,
  error: string,
  targetPolicyName: string,
  baselinePolicyName: string,
  onClose: func.isRequired,
  onConfirmRename: func.isRequired,
}

export default RenamePolicyPopup
