/* eslint-disable camelcase */ /* eslint-disable react/jsx-pascal-case */
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MRT_GlobalFilterTextField } from 'material-react-table'
import { useTenantGroups } from '../../../hooks/UseTenantGroups'
import useGetTenants from '../../SharedBaselines/api/useGetTenants'
import ListTable from '../../ListTable/components/ListTable'
import BaselineGroupActionsCell from '../../TableCells/BaselineGroupActionsCell/BaselineGroupActionsCell'
import Alert from '../../Alert/Alert'
import Modal from '../../Modal/Modal'
import ModalActionButtons from '../../Modal/ModalActionButtons'

const BaselineManager = () => {
  const [groupToDelete, setGroupToDelete] = useState()

  const navigate = useNavigate()
  const { error, loading, groups, deleteGroup } = useTenantGroups()

  const {
    data: tenants,
    isLoading: isTenantsLoading,
    isError: isTenantsError,
  } = useGetTenants()

  const ActionsCell = useCallback(
    ({ row }) => (
      <BaselineGroupActionsCell
        onDelete={() => setGroupToDelete(row.original)}
        onEdit={() =>
          navigate('/baseline-management/edit', {
            state: { group: row.original, tenants: tenants?.data },
          })
        }
      />
    ),
    [navigate, tenants?.data]
  )

  const columns = useMemo(
    () => [
      {
        header: 'Baseline Group Name',
        accessor: 'name',
      },
      {
        header: 'Actions',
        accessor: 'actions',
        cell: ActionsCell,
        enableSorting: false,
        enableColumnFilter: false,
        size: 5,
        maxSize: 50,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
    ],
    [ActionsCell]
  )

  if (isTenantsError) {
    return (
      <Alert type='error' title='Failed To Load Tenants'>
        There was an error fetching the tenants list. Please try again and
        contact support if the issue persists.
      </Alert>
    )
  }

  return (
    <>
      {error ? <p className='text-red-500 mb-[24px]'>{error}</p> : null}
      <ListTable
        data={groups || []}
        loading={loading || isTenantsLoading}
        columns={columns}
        uniqueKey='clientTenantGroupId'
        columnOrder={['name', 'actions']}
        renderTopToolbar={({ table }) => (
          <div className='flex flex-row items-center gap-3 mb-3'>
            <MRT_GlobalFilterTextField table={table} />
            <button
              type='button'
              className='btn cyan-btn flex items-center'
              onClick={() =>
                navigate('/baseline-management/edit', {
                  state: { tenants: tenants.data },
                })
              }
            >
              <FontAwesomeIcon icon={faPlus} className='mr-1' />
              New Group
            </button>
          </div>
        )}
      />

      <Modal
        isOpen={!!groupToDelete}
        setModalOpen={() => setGroupToDelete(undefined)}
      >
        <h3 className='text-center mb-4'>Delete {groupToDelete?.name}</h3>
        <div className='text-center my-6'>
          <strong className='text-lg'>This action cannot be undone</strong>
        </div>
        <ModalActionButtons
          onCancel={() => setGroupToDelete(undefined)}
          onConfirm={() => {
            deleteGroup(groupToDelete.clientTenantGroupId)
            setGroupToDelete(undefined)
          }}
        />
      </Modal>
    </>
  )
}

export default BaselineManager
