/* eslint-disable react/no-unstable-nested-components */

import { ErrorBoundary } from 'react-error-boundary'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { v4 as uuid } from 'uuid'
import { node, object } from 'prop-types'
import ExceptionPage from '../ExceptionPage/ExceptionPage'

/**
 * Simple wrapper around the react-error-boundary library to
 * handle additional logic for logging exceptions
 * @param appInsights App Insights instance
 * @param children JSX children
 */
const InsightsErrorBoundary = ({ appInsights, children }) => {
  // Each time we mount the error boundary we should initialize a new correlationId
  const correlationId = uuid()
  const username = localStorage.getItem('username') || 'Unknown User'

  const logError = (error, info) => {
    const errObj = {
      exception: error,
      severityLevel: SeverityLevel.Error,
    }

    appInsights.trackException(errObj, {
      stackTrace: info,
      correlationId,
      username,
    })
  }

  const logAdditionalInfoForError = additionalText => {
    const event = {
      name: 'exceptionAdditionalInfo',
    }

    appInsights.trackEvent(event, {
      additionalInfo: additionalText,
      username,
      correlationId,
    })
  }

  return (
    <ErrorBoundary
      fallbackRender={({ resetErrorBoundary }) => (
        <ExceptionPage
          resetErrorBoundary={resetErrorBoundary}
          logAdditionalInfo={logAdditionalInfoForError}
        />
      )}
      onError={logError}
    >
      {children}
    </ErrorBoundary>
  )
}

InsightsErrorBoundary.propTypes = {
  children: node.isRequired,
  // Unfortunately I can't do instanceOf(ApplicationInsights) so this is our best option
  // eslint-disable-next-line react/forbid-prop-types
  appInsights: object.isRequired,
}

export default InsightsErrorBoundary
