import { arrayOf, func, shape, string } from 'prop-types'

/**
 * @param {{
 *  id?: string,
 *  options: { name: string, value: string }[],
 *  value?: string,
 *  onChange?: (string) => void,
 * }} props
 */
const TabSelector = ({ id, options, value, onChange }) => {
  const buildOption = option => {
    const selected = value === option.value
    return (
      <button
        type='button'
        key={option.value}
        className={`tab-selector-option${selected ? ' active' : ''} rounded-md`}
        onClick={() => onChange(option.value)}
      >
        {option.name}
      </button>
    )
  }

  return (
    <div id={id} className='tab-selector-wrapper rounded-md light-grey-bg'>
      {options.map(buildOption)}
    </div>
  )
}

TabSelector.defaultProps = {
  id: 'tab-selector',
  value: '',
}

TabSelector.propTypes = {
  id: string,
  options: arrayOf(shape({ name: string, value: string })).isRequired,
  value: string,
  onChange: func.isRequired,
}

export default TabSelector
