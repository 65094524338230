import { Link } from 'react-router-dom'

const ReportsPopup = () => (
  <div className='popup-bg'>
    <div className='popup-container'>
      <h3 className='text-center mb-4'>Reports Summary</h3>
      <p className='ml-4'>
        Your report run has been submitted and is now processing. You will
        receive an email confirming the status of your report run shortly.
      </p>
      <div className='footer mt-[50px] mb-2'>
        <button className='btn cyan-btn ml-4 text-white'>
          <Link to='/' className='text-white'>
            Home
          </Link>
        </button>
      </div>
    </div>
  </div>
)

export default ReportsPopup
