/* eslint-disable no-console */
import { LogLevel } from '@azure/msal-browser'

export const parallelOnboardingChunkMaxSize = 50

export const cspClientId = process.env.REACT_APP_ONBOARDING_CSP_CLIENT_ID

export const tenantClientId = process.env.REACT_APP_ONBOARDING_TENANT_CLIENT_ID

export const commonLoginAuthority = `https://login.microsoftonline.com/common`

export const loggerOptions = {
  loggerCallback: (level, message, containsPii) => {
    if (containsPii) {
      return
    }
    switch (level) {
      case LogLevel.Error:
        console.error(message)
        return
      case LogLevel.Info:
        console.info(message)
        return
      case LogLevel.Verbose:
        console.debug(message)
        return
      case LogLevel.Warning:
        console.warn(message)
        break
      default:
        break
    }
  },
}

// MSAL configuration
export const configuration = {
  auth: {
    clientId: cspClientId,
    authority: `https://login.microsoftonline.com/common`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions,
  },
}

// MSAL scopes
export const cspAuthScopes = [
  'https://api.partnercenter.microsoft.com/user_impersonation',
  'Application.ReadWrite.All',
  'User.Read',
  'RoleManagement.ReadWrite.Directory',
]

export const cspGraphAuthScopes = [
  'Application.ReadWrite.All',
  'RoleManagement.ReadWrite.Directory',
]

export const delegatedAdminAuthScopes = ['DelegatedAdminRelationship.Read.All']

export const tenantAuthScopes = [
  'Application.ReadWrite.All',
  'AppRoleAssignment.ReadWrite.All',
  'RoleManagement.ReadWrite.Directory',
]

// App Registration Permissions
export const tenantGraphPermissions =
  process.env.REACT_APP_ONBOARDING_GRAPH_PERMISSIONS.split(',')

export const tenantDefenderPermissions =
  process.env.REACT_APP_ONBOARDING_DEFENDER_PERMISSIONS.split(',')

export const exchangePermissions =
  process.env.REACT_APP_ONBOARDING_EXCHANGE_PERMISSIONS.split(',')
