export const alignmentComparisonSortOptions = [
  {
    label: 'Alignment Score',
    value: 'score',
  },
  {
    label: 'A-Z',
    value: 'a-z',
  },
]

export const alignmentScoreFilterOptions = [
  {
    label: 'No Filter',
    value: 'none',
  },
  {
    label: 'Fully Aligned',
    value: 'fully-aligned',
  },
  {
    label: 'Not Aligned',
    value: 'not-aligned',
  },
  {
    label: 'Semi Aligned',
    value: 'semi-aligned',
  },
]

export const alignmentRequiredFilter = {
  label: 'Alignment Required',
  value: 'alignment-required',
}

export const suggestedPoliciesFilter = {
  label: 'Suggested Policies',
  value: 'suggested-policies',
}

export const alignedFilter = {
  label: 'Aligned',
  value: 'aligned',
}

export const alignedWithVariablesFilter = {
  label: 'Aligned (with variables)',
  value: 'aligned-variables',
}

export const deviatedFilter = {
  label: 'Deviated',
  value: 'deviated',
}

export const acceptedDeviationFilter = {
  label: 'Accepted Deviation',
  value: 'accepted-deviation',
}

export const driftDetectedPoliciesFilter = {
  label: 'Drift Detected Policies',
  value: 'deviation-drifted',
}

export const alignmentStatusFilterOptions = [
  alignmentRequiredFilter,
  suggestedPoliciesFilter,
  alignedFilter,
  alignedWithVariablesFilter,
  deviatedFilter,
  acceptedDeviationFilter,
  driftDetectedPoliciesFilter,
]

export const alignmentStatusFilterOptionsFeatureFlagged = (
  enableDeviationDrift,
  enableClientVariables
) =>
  alignmentStatusFilterOptions.filter(option => {
    if (!enableDeviationDrift && option.value === 'deviation-drifted') {
      return false
    }
    return !(!enableClientVariables && option.value === 'aligned-variables')
  })

export const policyStates = {
  alignedWithVariables: 'Unchanged (variables substituted)',
}

export const alignmentPolicySortOptions = [
  {
    value: 'a-z',
    label: 'A-Z',
  },
  {
    value: 'policy-type',
    label: 'Policy Type',
  },
]
