import {
  arrayOf,
  bool,
  func,
  instanceOf,
  number,
  shape,
  string,
} from 'prop-types'
import { useState } from 'react'
import { Menu } from '@mui/material'
import { toast } from 'react-toastify'

import TenantTagsMenuActions from './components/TenantTagsMenuActions'
import Modal from '../Modal/Modal'
import Skeleton from '../LoadingSkeletons/ConfirmationPopupSkeleton'

import ListTags from './components/ListTags'

import useBulkTenantTags from './api/useBulkTenantTags'
import Alert from '../Alert/Alert'
import Tag from '../Tag/Tag'
import { hexToRgb } from '../../utils/colours'
import FormControlButtons from '../Form/components/FormControlButtons/FormControlButtons'

const TenantTagsManageModal = ({
  menuOpen,
  anchorEl,
  setActionMenuOpen,
  selectedRows,
  tags,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [initialTags, setInitialTags] = useState([]) // Store the initial state of existing tags
  const [existingTags, setExistingTags] = useState([]) // Current state of existing tags
  const [availableTags, setAvailableTags] = useState([]) // Current state of available tags

  const bulkTenantTags = useBulkTenantTags(() => {
    setModalOpen(false)
    toast.success('Tenant tags updated successfully')
  })

  const selectedTenants = selectedRows.map(row => row.original.clientTenantId)

  const cancelAction = () => {
    setModalOpen(false)
  }

  const linkTag = id => {
    if (!existingTags.some(existingTag => existingTag.id === id)) {
      setExistingTags(prevTags => [
        ...prevTags,
        tags.find(tag => tag.id === id),
      ])
      setAvailableTags(prevTags => prevTags.filter(t => t.id !== id))
    }
  }

  const unlinkTag = id => {
    setAvailableTags(prevTags => [...prevTags, tags.find(tag => tag.id === id)])
    setExistingTags(prevTags => prevTags.filter(tag => tag.id !== id))
  }

  // Calculate added and removed tags when saving
  const handleSave = () => {
    const addedTags = existingTags.filter(
      tag => !initialTags.some(initialTag => initialTag.id === tag.id)
    )
    const removedTags = initialTags.filter(
      tag => !existingTags.some(existingTag => existingTag.id === tag.id)
    )

    if (addedTags.length > 0) {
      bulkTenantTags.mutate({
        action: 'link',
        tenants: selectedTenants,
        tags: addedTags.map(tag => tag.id), // Send only the ids of added tags
      })
    }

    if (removedTags.length > 0) {
      bulkTenantTags.mutate({
        action: 'unlink',
        tenants: selectedTenants,
        tags: removedTags.map(tag => tag.id), // Send only the ids of removed tags
      })
    }
  }

  const onReset = () => {
    setExistingTags(initialTags)
    setAvailableTags(tags.filter(tag => !initialTags.includes(tag)))
  }

  const compareTags = () => {
    // Compare existingTags with initialTags
    const existingTagsChanged = !initialTags.every(initialTag =>
      existingTags.some(existingTag => existingTag.id === initialTag.id)
    )

    // Compare availableTags with initial availableTags (tags not in initialTags)
    const availableTagsChanged =
      availableTags.length !== tags.length - initialTags.length

    return existingTagsChanged || availableTagsChanged
  }

  return (
    <>
      <Menu
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={() => setActionMenuOpen(false)}
        MenuListProps={{
          'aria-labelledby': 'user-table-row-actions',
          disablePadding: true,
        }}
      >
        <TenantTagsMenuActions
          openModal={() => {
            const filteredTags = tags.filter(tag =>
              selectedRows.every(row => row.original.tags.includes(tag.id))
            )
            const filteredAvailableTags = tags.filter(
              tag =>
                !selectedRows.every(row => row.original.tags.includes(tag.id))
            )
            setActionMenuOpen(false)
            setInitialTags(filteredTags) // Set the initial tags state when modal opens
            setExistingTags(filteredTags) // Initialize the current existing tags
            setAvailableTags(filteredAvailableTags) // Initialize the available tags
            setModalOpen(true)
          }}
        />
      </Menu>

      <Modal closeOnBlur={false} isOpen={modalOpen} setModalOpen={setModalOpen}>
        {bulkTenantTags.isPending ? (
          <Skeleton width='100%' height='100%' />
        ) : (
          <>
            {/* Header */}
            <div className='flex justify-between items-center mb-4'>
              <h1 className='flex items-center text-2xl font-extrabold dark:text-white'>
                Manage tags for ({selectedRows.length})
                {selectedRows.length === 1 ? ' tenant' : ' tenants'}
              </h1>
              <button
                type='button'
                className='text-gray-400 hover:text-gray-600'
                onClick={cancelAction}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  className='h-6 w-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </div>

            <h4 className='mb-1 opacity-80'>Available tags</h4>

            <div
              className='flex flex-col items-start gap-2 border-2 mb-3 p-3 rounded'
              aria-label='available tags'
            >
              <div className='flex flex-wrap gap-2'>
                {availableTags.map(tag => (
                  <button
                    type='button'
                    key={tag.id}
                    onClick={() => linkTag(tag.id)}
                  >
                    <Tag
                      key={tag.id}
                      text={tag.tag}
                      description={tag.description}
                      colourRGB={hexToRgb(tag.colour)}
                    />
                  </button>
                ))}
                {availableTags.length === 0 && (
                  <p className='text-gray-500'>No available tags</p>
                )}
              </div>
            </div>

            <h4 className='mb-1 opacity-80'>Selected tags</h4>

            <ListTags tags={existingTags} unlinkTag={unlinkTag} />

            {bulkTenantTags.isError && (
              <Alert
                type='error'
                title={bulkTenantTags.error.response?.data.message}
                margin='mb-4 mt-2'
              >
                <ul>
                  {bulkTenantTags.error.response?.data.errors?.map(error => (
                    <li>{error}</li>
                  ))}
                </ul>
              </Alert>
            )}

            <FormControlButtons
              onConfirm={handleSave}
              onCancel={cancelAction}
              onReset={onReset}
              confirmButtonText='Save'
              resetDisabled={!compareTags()}
              confirmDisabled={!compareTags()}
            />
          </>
        )}
      </Modal>
    </>
  )
}

TenantTagsManageModal.defaultProps = {
  anchorEl: null,
  tags: [],
}

TenantTagsManageModal.propTypes = {
  menuOpen: bool.isRequired,
  anchorEl: instanceOf(Element),
  setActionMenuOpen: func.isRequired,
  selectedRows: arrayOf(
    shape({
      original: shape({
        clientTenantId: number.isRequired,
        tags: arrayOf(
          shape({
            id: string.isRequired,
          })
        ),
      }),
    })
  ).isRequired,
  tags: arrayOf(
    shape({
      colour: string.isRequired,
      id: string.isRequired,
      tag: string.isRequired,
      description: string,
      tenants: arrayOf(string).isRequired,
    }).isRequired
  ),
}

export default TenantTagsManageModal
