/**
 * @param {{
 *  open: boolean;
 *  onClose?: () => void;
 *  children: React.ReactNode;
 * }} props
 */
const PopupDialog = ({ children, open, onClose }) => {
  if (!open) return null

  const handleContainerClick = event => {
    event.stopPropagation()
  }

  return (
    <div className='popup-bg popup-container-fixed' onClick={onClose}>
      <div
        className='popup-container-fit-height tenant-alignment-width-popup'
        onClick={handleContainerClick}
      >
        {children}
      </div>
    </div>
  )
}

export default PopupDialog
