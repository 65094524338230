import Axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useFeatureFlag } from 'configcat-react'
import TabSelector from '../../components/TabSelector'
import PopupDialog from '../../components/PopupDialog'
import { useTenantGroups } from '../../hooks/UseTenantGroups'
import DeletePolicyPopup from '../../components/Policies/DeletePolicyPopup'
import { createNewAdditionalSettingsConfig } from '../../components/AdditionalOptions/AdditionalOptionsConfig'
import DeployPolicyDialog from '../../components/TenantAlignment/DeployPolicyDialog'
import AlignmentByTenant from '../../components/TenantAlignment/AlignmentByTenant'
import AlignmentByPolicy from '../../components/TenantAlignment/AlignmentByPolicy'
import { isTenantLevelSettingPolicyType } from '../../utils/Policy'
import {
  pendingChangesStates,
  usePendingChangesStateContext,
} from '../../contexts/PendingChangesStateContext'
import AcceptPolicyDeviationDialog from '../../components/TenantAlignment/AcceptPolicyDeviationDialog'
import Alert from '../../components/Alert/Alert'

const tabOptions = [
  {
    name: 'Tenant',
    value: 'tenant',
  },
  {
    name: 'Policy',
    value: 'policy',
  },
]

const TenantAlignment = () => {
  const [selectedTab, setSelectedTab] = useState(tabOptions[0].value)

  // Controls the current values for all additional settings
  const [additionalSettings, setAdditionalSettings] = useState(
    createNewAdditionalSettingsConfig()
  )

  const { setPendingChangesState, setPolicyIdsWithPendingChanges } =
    usePendingChangesStateContext()
  const queryClient = useQueryClient()

  const [dialogIsOpen, setDialogIsOpen] = useState(false)

  const [toDeploy, setToDeploy] = useState()

  const [toAccept, setToAccept] = useState()

  const [toReject, setToReject] = useState()

  const [toDelete, setToDelete] = useState()

  const [, setDeployPolicyDisabled] = useState(true)

  const [, setDeployOverwriteWithSameName] = useState(false)

  const [, setGroupAssignment] = useState({})

  const [, setSelectedOption] = useState('')

  /**
   * @type {[
   *  {
   *      [policyTypeId: number]: {
   *          deployPolicyDisabled: boolean,
   *          deployOverwriteWithSameName: boolean,
   *          deployAssignments: boolean,
   *          deployLocation: boolean,
   *          deployNonStandardApplications: boolean,
   *          deployAuthenticationStrengths: boolean,
   *      }
   *  },
   *  React.Dispatch<React.SetStateAction<{}>>
   * ]}
   */
  const [, setDeployBulkSettings] = useState({})

  const [error, setError] = useState()

  const [loading, setLoading] = useState(false)

  const { value: enableClientVariables } = useFeatureFlag(
    'enableClientVariables',
    false
  )

  const {
    groups,
    loading: groupsLoading,
    error: groupsError,
  } = useTenantGroups()

  const uniquePolicyTypesToDeploy = useMemo(() => {
    const output = []

    if (!Array.isArray(toDeploy?.policies)) return output

    toDeploy.policies.forEach(({ policyTypeId, policyTypeName }) => {
      if (isTenantLevelSettingPolicyType(policyTypeId)) return

      if (output.some(p => p.policyTypeId === policyTypeId)) return
      output.push({ policyTypeId, policyTypeName })
    })

    return output
  }, [toDeploy?.policies])

  useEffect(() => {
    const settings = {}

    uniquePolicyTypesToDeploy.forEach(({ policyTypeId }) => {
      settings[policyTypeId] = {
        deployPolicyDisabled: true,
        deployOverwriteWithSameName: false,
        deployAssignments: true,
      }
    })

    setDeployBulkSettings(settings)
  }, [uniquePolicyTypesToDeploy])

  const handleCloseDialogs = () => {
    setDeployPolicyDisabled(true)
    setDeployOverwriteWithSameName(false)
    setDeployBulkSettings({})
    setGroupAssignment({})
    setSelectedOption('')
    setError(undefined)
    setToDeploy(undefined)
    setToAccept(undefined)
    setToReject(undefined)
    setToDelete(undefined)
  }

  const handleDeleteAcceptedDeviation = async (
    baselineClientTenantId,
    subjectClientTenantId,
    baselinePolicyGuid,
    subjectPolicyGuid,
    onDone
  ) => {
    try {
      setLoading(true)
      setError(undefined)

      const params = {
        baselineClientTenantId,
        subjectClientTenantId,
      }

      if (baselinePolicyGuid) params.baselinePolicyGuid = baselinePolicyGuid
      if (subjectPolicyGuid) params.subjectPolicyGuid = subjectPolicyGuid

      const response = await Axios.delete(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/deleteDeviationAcceptance`,
        { params }
      )

      if (response.status < 200 || response.status >= 300) {
        setError('Failed to remove accepted deviation')
        console.error(response.data)
        return
      }

      onDone?.()

      handleCloseDialogs()
    } finally {
      setLoading(false)
    }
  }

  const buildTab = () => {
    if (groupsLoading)
      return (
        <div className='w-[100%] h-[400px] flex items-center justify-center'>
          <div className='text-center'>
            <p>Loading tenant baseline groups...</p>
          </div>
        </div>
      )

    if (groupsError) {
      return (
        <div className='shared-baselines-page md:container mx-auto'>
          <div className='flex items-center shadow-sm bg-white w-full rounded-xl mb-6 py-8 px-10'>
            <Alert type='error' title={groupsError} margin='mb-4'>
              An unexpected error occurred while fetching tenant baseline
              groups.
            </Alert>
          </div>
        </div>
      )
    }

    if (groups.length === 0 && !groupsLoading) {
      return (
        <div className='w-[100%] h-[400px] flex items-center justify-center'>
          <div className='text-center'>
            <p>No tenant baseline groups detected</p>
            <Link to='/tenant-management?tab=baselines'>
              <button className='btn cyan-btn mt-4' type='button'>
                <FontAwesomeIcon icon={faPlus} className='pr-1' />
                Create Groups
              </button>
            </Link>
          </div>
        </div>
      )
    }

    switch (selectedTab) {
      case 'tenant':
        return (
          <AlignmentByTenant
            onDeployPolicy={deployConfig => {
              setToDeploy(deployConfig)
              setDialogIsOpen(true)
            }}
            onAcceptDeviation={setToAccept}
            onRejectDeviation={setToReject}
            onDeletePolicy={setToDelete}
            tenantBaselines={groups ?? []}
          />
        )
      case 'policy':
        return (
          <AlignmentByPolicy
            onDeployPolicy={deployConfig => {
              setToDeploy(deployConfig)
              setDialogIsOpen(true)
            }}
            tenantBaselines={groups ?? []}
          />
        )
      default:
        return <h1>Unknown Tab Selected</h1>
    }
  }

  const buildDeployPolicyDialog = () => (
    <DeployPolicyDialog
      isOpen={dialogIsOpen}
      setModalOpen={setDialogIsOpen}
      onCancel={() => {
        setToDeploy(null)
        setDialogIsOpen(false)
      }}
      selectedPolicies={
        toDeploy?.policy ? [toDeploy.policy] : toDeploy?.policies
      }
      sourceTenant={toDeploy?.baseline}
      destinationTenants={
        toDeploy?.tenant ? [toDeploy.tenant] : toDeploy?.tenants
      }
      additionalSettings={additionalSettings}
      setAdditionalSettings={setAdditionalSettings}
      enableClientVariables={enableClientVariables}
    />
  )

  const buildRejectPolicyDialog = () => (
    <PopupDialog open={toReject} onClose={handleCloseDialogs}>
      <h3 className='text-center mb-6'>Unaccept Deviation</h3>
      <div className='text-center'>
        You are about to unaccept the previously accepted deviation of{' '}
        <b>{toReject?.policy.policyName}</b> for{' '}
        <b>{toReject?.tenant.tenantFriendlyName}</b>. Are you sure you want to
        continue?
      </div>
      {error ? (
        <p className='text-red-500 text-center mt-[24px]'>{error}</p>
      ) : null}
      <div className='footer mt-10 flex justify-between'>
        <button
          type='button'
          className='btn navy-btn mr-4'
          onClick={handleCloseDialogs}
        >
          Cancel
        </button>
        <button
          type='button'
          className='btn cyan-btn'
          disabled={loading}
          onClick={() =>
            handleDeleteAcceptedDeviation(
              toReject?.baseline.clientTenantId,
              toReject?.tenant.clientTenantId,
              toReject?.policy?.isMissingFromBaseline
                ? null
                : toReject?.policy.policyGuid,
              toReject?.policy?.isMissingFromBaseline
                ? toReject?.policy.policyGuid
                : toReject?.policy.policyDataDiff?.[0]?.policyGuid,
              toReject?.onDone
            )
          }
        >
          Confirm
        </button>
      </div>
    </PopupDialog>
  )

  return (
    <>
      <div className='ui-panel tenant-management'>
        <div className='heading'>
          <div className='flex justify-between flex-wrap'>
            <h2 id='tenant-alignment-title' className='mr-4'>
              Tenant Alignment
            </h2>
            <div className='flex items-center'>
              <label className='block mr-2 text-right'>
                <b>Search By</b>
              </label>
              <TabSelector
                value={selectedTab}
                onChange={setSelectedTab}
                options={tabOptions}
              />
            </div>
          </div>
        </div>
        <div className='action-body no-progress-bar'>
          <div className='alignment-container'>{buildTab()}</div>
        </div>
      </div>
      {dialogIsOpen && buildDeployPolicyDialog()}
      {toAccept && (
        <AcceptPolicyDeviationDialog
          deviationPayload={toAccept}
          isOpen={!!toAccept}
          onCloseDialog={handleCloseDialogs}
          onSuccess={toAccept?.onSuccess}
        />
      )}

      {buildRejectPolicyDialog()}
      {toDelete && (
        <DeletePolicyPopup
          clientTenantId={toDelete.tenant.clientTenantId}
          handleClosePopUp={handleCloseDialogs}
          policies={[toDelete.policy]}
          onSuccess={() => {
            queryClient.invalidateQueries('tenant-summary')
            setPolicyIdsWithPendingChanges(existingPolicyGuids => [
              ...existingPolicyGuids,
              toDelete.policy.subjectPolicyId,
            ])
            setPendingChangesState(pendingChangesStates.actionInitiated)
          }}
          explicitDeleteGuid={toDelete.policyGuid}
          saveOverride
        />
      )}
    </>
  )
}

export default TenantAlignment
