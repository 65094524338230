import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { useFeatureFlag } from 'configcat-react'
import Tooltip from '../../../tooltip/Tooltip'

import { useSelection } from '../context/SelectionContext'
import { useTenant } from '../../../../contexts/TenantContext'

import PolicyViewJSON from '../../../PolicyViewJSON/PolicyViewJSON'
import { policyShape } from '../utils/propTypes'
import { keyDownHandler } from '../../../../utils/accessibility'
import MatchingVariablesViewer from '../components/MatchingVariablesViewer'

import PolicyTags from '../../../PolicyTags/components/PolicyTags'
/**
 * PolicySubGroupItem component
 * @param {object} item - item
 * @returns {JSX.Element}
 */
const Policy = ({ item }) => {
  const {
    state: { selectedPolicies },
    selectItem,
  } = useSelection()

  const { value: enablePolicyTags } = useFeatureFlag('enablePolicyTags', false)

  const tenant = useTenant()
  const policySelected = selectedPolicies?.length
    ? selectedPolicies.some(policy => policy.id === item.id)
    : false

  const [isChecked, setIsChecked] = useState(policySelected)

  const { readOnly, displayName, description, matchingVariables, tags } = item

  const { value: enableClientVariables } = useFeatureFlag(
    'enableClientVariables',
    false
  )

  useEffect(() => {
    setIsChecked(policySelected)
  }, [policySelected])

  const handleCheckboxToggle = () => {
    if (!readOnly) {
      const newCheckedState = !isChecked
      selectItem(item, newCheckedState)
    }
  }

  return (
    <div
      role='button'
      className={`flex justify-between items-center p-2 w-full text-left bg-neutral-50 mt-2 mb-2 ${readOnly ? 'cursor-not-allowed' : ''}`}
      onClick={handleCheckboxToggle}
      onKeyDown={e => {
        keyDownHandler(e, handleCheckboxToggle)
      }}
      tabIndex={readOnly ? -1 : 0}
      aria-disabled={readOnly}
    >
      <div className='flex items-center flex-1'>
        {readOnly ? (
          <span className='mr-2'>
            <Tooltip content='Read only policy'>
              <FontAwesomeIcon icon={faLock} />
            </Tooltip>
          </span>
        ) : (
          <input
            type='checkbox'
            className='mr-2 pointer-events-none'
            checked={isChecked}
            readOnly
          />
        )}
        <div className='px-4 py-3'>
          <h2 className='text-sm font-semibold'>{displayName}</h2>
          <p className='text-xs text-gray-700'>
            {description === null ? 'No Description' : description}
          </p>

          {enablePolicyTags && (
            <PolicyTags linkedPolicyTagIds={tags} numVisibleTags={5} />
          )}
        </div>
      </div>

      {enableClientVariables && (
        <MatchingVariablesViewer
          variables={matchingVariables}
          displayName={displayName}
        />
      )}
      <PolicyViewJSON item={item} tenant={tenant[0]} />
    </div>
  )
}

export default Policy

Policy.propTypes = {
  item: policyShape.isRequired,
}
