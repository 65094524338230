import { string, shape, number } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons'
import usePolicyData from '../../api/usePolicyData'
import { policyShape } from '../Policies/PolicyPicker/utils/propTypes'

const PolicyViewJSON = ({ item, tenant }) => {
  const { id, policyTypeId, displayName, policyId, readOnly } = item

  const { isFetching, refetch } = usePolicyData({
    clientTenantId: tenant.clientTenantId,
    policyTypeId,
    policyId,
    id,
  })

  const handlePolicyWindow = e => {
    e.stopPropagation()

    refetch()
      .then(policyData => {
        window.policyData = JSON.stringify(policyData.data)
        window.policyTypeName = JSON.stringify('')
        window.policyDisplayName = JSON.stringify(displayName)
        window.tenantName = JSON.stringify(tenant.tenantFriendlyName)
      })
      .then(() => {
        window.open(
          '/policy.html',
          `policy-viewer-${id}`,
          'width=600,height=900'
        )
      })
  }

  return (
    <button
      type='button'
      className='p-2 bg-white border rounded pt-2 pb-2'
      onClick={handlePolicyWindow}
      disabled={
        id === '00000000-0000-0000-0000-000000000000' && readOnly === true
      }
      aria-label='View policy data'
    >
      {isFetching ? (
        <FontAwesomeIcon icon={faSpinner} className='animate-spin' />
      ) : (
        <FontAwesomeIcon icon={faSearch} />
      )}
    </button>
  )
}

export default PolicyViewJSON

PolicyViewJSON.propTypes = {
  item: policyShape.isRequired,
  tenant: shape({
    tenantFriendlyName: string.isRequired,
    clientTenantId: number.isRequired,
  }).isRequired,
}
