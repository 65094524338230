import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { toast } from 'react-toastify'

const useUpdateClientTenantVariables = (onSuccess, tenantId) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async variables =>
      await axios.put(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/tenants/${tenantId}/variables`,
        variables
      ),
    onSuccess: () => {
      toast.success('Tenant variables updated successfully')
      queryClient.invalidateQueries([`clientTenantVariables-${tenantId}`])
      onSuccess()
    },
    onError: () => {
      toast.error('An error occurred updating tenant variables')
    },
  })
}

export default useUpdateClientTenantVariables
