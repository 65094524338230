import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const useBulkTenantTags = (onSuccess, onError) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['bulk-tenant-tags'],
    mutationFn: async ({ action, tenants, tags }) => {
      if (action === 'unlink') {
        await axios.delete(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/tenantTags/links`,
          { data: { tenants, tags } }
        )
      } else {
        await axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/tenantTags/links`,
          { tenants, tags }
        )
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('tenantTags')
      onSuccess()
    },
    onError,
  })
}

export default useBulkTenantTags
