import { Children } from 'react'
import { string, node } from 'prop-types'

const HeadingPanel = ({ heading, subHeading, styles, children }) => (
  <div
    className={`flex items-center gap-6 max-[1280px]:flex-wrap shadow-sm bg-white w-full rounded-xl mb-6 py-8 px-10 ${styles}`}
  >
    <div className='shared-baselines-heading w-full rounded-xl overflow-hidden'>
      <h2>{heading}</h2>
      <p>{subHeading}</p>
    </div>
    {children}
  </div>
)

export default HeadingPanel

HeadingPanel.propTypes = {
  heading: string.isRequired,
  subHeading: string.isRequired,
  styles: string,
  children: node,
}

HeadingPanel.defaultProps = {
  styles: '',
  children: null,
}
