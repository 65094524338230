import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const BulkActionsMenu = () => (
  <button
    type='button'
    className='flex items-center  text-[0.85rem] text-xs font-bold border-none rounded-sm justify-between bg-gray-100 w-[140px] border border-gray-300 px-3 py-2 gap-2 cursor-pointer h-[38px] text-[#575757]'
    id='bulk-actions'
  >
    Bulk Actions
    <FontAwesomeIcon icon={faChevronDown} color='#cccccc' fontWeight='bold' />
  </button>
)

export default BulkActionsMenu
