import { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ListClientsNoClick from '../components/ListClientsNoClick'
import SearchBar from '../components/SearchBar'

const AllClients = () => {
  const [clients, setClients] = useState([])
  const [allClients, setAllClients] = useState([])
  const [clientSearch, setClientSearch] = useState('')
  const [loadingState, setLoadingState] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/get-clients`
        )
        const { data } = response
        setAllClients(data)
        setClients(data)
      } catch (error) {
        console.error('Error:', error.response.status)
        toast.error('Error fetching clients')
      } finally {
        setLoadingState(false)
      }
    }
    fetchClients()
  }, [])

  const filterClients = searchValue => {
    if (searchValue) {
      // If the search query is provided perform local filtering
      const filteredClients = allClients.filter(client =>
        client.displayName.toLowerCase().includes(searchValue.toLowerCase())
      )
      setClients(filteredClients)
    } else {
      // else fetch all tenants from the API
      setClients(allClients)
    }
  }

  return (
    <div className='ui-panel tenant-management list-all-clients'>
      <div className='heading mb-[20px]'>
        <h2>All Clients</h2>
      </div>
      <div className='action-body no-progress-bar'>
        <div className='select-tenant-heading'>
          <h4>Search Clients</h4>
        </div>
        <SearchBar
          onSearchChange={newSearchValue => {
            setClientSearch(newSearchValue)
            filterClients(newSearchValue)
          }}
          searchValue={clientSearch}
        />
        <ListClientsNoClick
          clients={clients}
          clientSearch={clientSearch}
          onClientSearchChange={newSearchValue => {
            setClientSearch(newSearchValue)
            filterClients(newSearchValue)
          }}
        />
      </div>

      <div className='mt-6 gap-3 flex'>
        <button onClick={() => navigate(-1)} className='btn navy-btn'>
          Back
        </button>
      </div>
    </div>
  )
}

export default AllClients
