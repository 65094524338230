import { bool, func, shape, string } from 'prop-types'

const AlignmentPolicy = ({ active, policy, onClick }) => (
  <button
    className={`w-full flex alignment-search-item rounded-sm light-grey-bg p-2 ${active ? 'active' : ''}`}
    onClick={onClick}
    type='button'
    tabIndex={0}
  >
    <div className='flex flex-col items-start w-full text-left'>
      <div className='text-md break-normal [overflow-wrap:anywhere]'>
        <b>{policy.displayName}</b>
      </div>
      <div className='text-sm opacity-75'>{policy.policyTypeName}</div>
    </div>
  </button>
)

AlignmentPolicy.propTypes = {
  active: bool.isRequired,
  policy: shape({
    displayName: string.isRequired,
    policyTypeName: string.isRequired,
  }).isRequired,
  onClick: func.isRequired,
}

export default AlignmentPolicy
