import { useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import GlobalContext from '../contexts/GlobalContext'

import logo from '../assets/inforcer-logo.png'

const ValidatePassReset = ({ validatePassReq, validatePassErr }) => {
  const { token } = useParams()
  const { loadingState } = useContext(GlobalContext)

  return (
    <div className='login-page forgot-password-page'>
      <div className='login-box text-center'>
        <img src={logo} className='inforcer-logo' alt='Inforcer Logo' />

        {loadingState ? (
          <div id='loading-spinner'>
            <div className='loading' />
          </div>
        ) : validatePassErr ? (
          <>
            <div
              className='text-center p-4 mb-[24px] text-sm text-red-800 rounded-lg bg-red-50'
              role='alert'
            >
              <span className='text-sm'>{validatePassErr}</span>
            </div>
            <Link to='/login' className='text-sm underline'>
              Back to login page
            </Link>
          </>
        ) : (
          <div>
            <button
              className='btn cyan-btn'
              type='submit'
              onClick={() => validatePassReq(token)}
            >
              Confirm Password Reset
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ValidatePassReset
