/* eslint-disable camelcase */ /* eslint-disable react/jsx-pascal-case */
import { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { MRT_GlobalFilterTextField } from 'material-react-table'
import ListTable from '../ListTable/components/ListTable'
import usePolicyTags from './api/usePolicyTags'
import ContentPanel from '../ContentPanel/ContentPanel'
import Alert from '../Alert/Alert'
import tableConfig from './config/tableConfig'
import PolicyTagForm from './PolicyTagForm'

const PolicyTagSettings = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [tagToEdit, setTagToEdit] = useState(undefined)

  const {
    data: policyTags,
    isPending: isPolicyTagsLoading,
    isError: isPolicyTagsError,
    error: policyTagsError,
  } = usePolicyTags()

  const togglePanel = () => setIsOpen(!isOpen)

  const columns = useMemo(() => {
    const handleEditTag = tagData => {
      setTagToEdit(tagData)
      setIsOpen(true)
    }
    return tableConfig.tableColumnDefinitions({
      handleEditTag,
      setIsContentPanelOpen: () => setIsOpen(false),
    })
  }, [])

  const errorMessage =
    policyTagsError?.response?.data?.message || 'Error fetching policy tags'

  return (
    <div>
      <ListTable
        uniqueKey='id'
        data={policyTags ?? []}
        columns={columns}
        renderTopToolbar={({ table }) => (
          <div className='flex gap-3 items-center py-2'>
            <MRT_GlobalFilterTextField table={table} />
            <button
              type='button'
              className='btn cyan-btn'
              onClick={togglePanel}
            >
              <FontAwesomeIcon icon={faPlus} className='mr-1' />
              New
            </button>
          </div>
        )}
        loading={isPolicyTagsLoading}
        enableRowSelection={false}
        enableMultiRowSelection={false}
      />
      {isPolicyTagsError && (
        <Alert type='error' title={errorMessage}>
          <ul>
            {policyTagsError?.response?.data?.errors?.map(error => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </Alert>
      )}
      <ContentPanel
        isOpen={isOpen}
        togglePanel={togglePanel}
        panelHeading={tagToEdit ? 'Edit Existing Tag' : 'Create New Tag'}
      >
        <PolicyTagForm
          closePanel={() => setIsOpen(false)}
          tagId={tagToEdit?.id}
          initialTagName={tagToEdit?.tag}
          initialDescription={tagToEdit?.description}
          initialSelectedColour={tagToEdit?.colour}
          onSuccessfulAction={() => setTagToEdit(undefined)}
        />
      </ContentPanel>
    </div>
  )
}

export default PolicyTagSettings
