import HiddenValueCell from '../TableCells/HiddenValueCell/HiddenValueCell'

const matchSummaryTableDefinition = () => [
  {
    header: 'Tenant',
    accessor: 'tenantName',
  },
  {
    header: 'Value',
    accessor: 'value',
    cell: ({ row }) => (
      <HiddenValueCell
        value={row.original.value}
        hidden={row.original.hidden}
      />
    ),
  },
]

export default matchSummaryTableDefinition
