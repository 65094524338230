import { useMemo } from 'react'
import { arrayOf, func } from 'prop-types'
import {
  destinationTenantShape,
  matchingVariableShape,
  selectedVariableShape,
} from './shapes/shapes'
import ListTable from '../ListTable/components/ListTable'
import Alert from '../Alert/Alert'
import useMatchVariables from './api/useMatchVariables'
import {
  matchingVariablesTableDefinition,
  nonMatchingVariablesTableDefinition,
} from './tableConfig'
import ProcessClientVariablesAlert from './ProcessClientVariablesAlert'

const ProcessClientVariablesSelector = ({
  destinationTenants,
  matchingVariables,
  selectedVariables,
  setSelectedVariables,
}) => {
  const {
    data: matchResponse,
    isPending,
    error,
  } = useMatchVariables(
    destinationTenants.map(({ clientTenantId }) => clientTenantId),
    matchingVariables.map(variable => variable.variableId)
  )

  const matchTableColumns = useMemo(
    () =>
      matchingVariablesTableDefinition({
        destinationTenants,
      }),
    [destinationTenants]
  )

  const nonMatchTableColumns = useMemo(
    () => nonMatchingVariablesTableDefinition(),
    []
  )

  const groupedMatches = useMemo(() => {
    // Process the matched variables and match the the source tenant variable values
    const matches =
      matchResponse?.matched?.map(match => ({
        id: match.id,
        name: match.name,
        sourceValue: matchingVariables.find(v => v.variableId === match.id)
          ?.variableValue,
        value: match.value,
        clientTenantId: match.clientTenantId,
        hidden: match.hidden,
        tenantFriendlyName: destinationTenants.find(
          dt => dt.clientTenantId === match.clientTenantId
        )?.tenantFriendlyName,
      })) || []

    // Return a unique list of variables with their matched values
    return Object.values(
      matches?.reduce((acc, match) => {
        const {
          id,
          name,
          sourceValue,
          value,
          clientTenantId,
          hidden,
          tenantFriendlyName,
        } = match

        if (!acc[id]) {
          acc[id] = { id, name, sourceValue, hidden, values: [] }
        }

        acc[id].values.push({
          value,
          clientTenantId,
          tenantFriendlyName,
          hidden,
        })

        return acc
      }, {})
    )
  }, [matchResponse, destinationTenants, matchingVariables])

  const nonMatches = useMemo(
    () =>
      matchResponse?.unmatched?.map(unmatch => ({
        id: unmatch.id,
        name: matchingVariables.find(v => v.variableId === unmatch.id)
          ?.variableName,
        sourceValue: matchingVariables.find(v => v.variableId === unmatch.id)
          ?.variableValue,
        value: 'No match found',
        hidden: unmatch.hidden,
        tenantFriendlyName: destinationTenants.find(
          dt => dt.clientTenantId === unmatch.clientTenantId
        )?.tenantFriendlyName,
      })) || [],
    [matchResponse, matchingVariables, destinationTenants]
  )

  if (matchingVariables.length === 0) {
    return (
      <Alert type='info'>
        <strong>No matching variables found.</strong>
        <p>
          There were no values matching any configured variables in the selected
          source policies.
        </p>
      </Alert>
    )
  }

  const hasMatches = groupedMatches.length > 0
  const hasNonMatches = nonMatches.length > 0

  return (
    <>
      {error && (
        <Alert type='error'>
          {error.message ??
            'An error occurred while fetching the matching variables'}
        </Alert>
      )}

      <h4 className='mt-3 mb-3'>Matched Variables</h4>

      <ProcessClientVariablesAlert
        pending={isPending}
        isSuccess={hasMatches}
        okMessage='The following variables were matched. Choose which variables to apply when deploying your policies.'
        warningMessage='No variables matched. If you expected variables to match, please review your selected policies and variable configuration.'
      />

      <ListTable
        uniqueKey='id'
        data={groupedMatches}
        columns={matchTableColumns}
        loading={isPending}
        selectedListItems={selectedVariables}
        setSelectedListItems={setSelectedVariables}
        enableRowSelection
        enableMultiRowSelection
        initialState={{ pagination: { pageSize: 5, pageIndex: 0 } }}
      />
      <h4 className='mt-3 mb-3'>Unmatched Variables</h4>

      <ProcessClientVariablesAlert
        pending={isPending}
        isSuccess={!hasNonMatches}
        okMessage='All variables matched successfully for all selected tenants.'
        warningMessage='The following variables were found in the source policies but no matches were found in your variable configuration for the selected tenant(s).'
      />

      {hasNonMatches && (
        <ListTable
          uniqueKey='id'
          data={nonMatches}
          columns={nonMatchTableColumns}
          loading={isPending}
          initialState={{ pagination: { pageSize: 5, pageIndex: 0 } }}
        />
      )}
    </>
  )
}

ProcessClientVariablesSelector.defaultProps = {}

ProcessClientVariablesSelector.propTypes = {
  destinationTenants: arrayOf(destinationTenantShape).isRequired,
  matchingVariables: arrayOf(matchingVariableShape).isRequired,
  selectedVariables: arrayOf(selectedVariableShape).isRequired,
  setSelectedVariables: func.isRequired,
}

export default ProcessClientVariablesSelector
