import { useEffect, useMemo, useState } from 'react'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { v4 as uuidv4 } from 'uuid'
import 'react-loading-skeleton/dist/skeleton.css'
import Axios from 'axios'
import { Checkbox, FormControlLabel } from '@mui/material'
import ListTable from '../components/ListTable/components/ListTable'
import SelectedItems from '../components/ListTable/components/SelectedItems'
import TenantTypeCell from '../components/TableCells/TenantTypeCell/TenantTypeCell'
import ReportsPopup from '../components/ReportsPopup'
import ReportingEmails from '../components/ReportingEmails'
import TabSelector from '../components/TabSelector'
import useGetListTenants from '../hooks/useGetListTenants'
import useGetReports from '../hooks/useGetReports'
import tenantTypeFilter from '../components/TableCells/TenantTypeCell/filters'
import TenantTagsCell from '../components/TableCells/TenantTagsCell/TenantTagsCell'
import useTenantTags from '../components/TenantTags/api/useTenantTags'
import uniqueTenantTags from '../utils/uniqueTenantTags'

const mostUsedReports = ['Tenant Audit Report', 'Tenant MFA Status Report']

const Reporting = () => {
  // Form Logic
  const formArray = [1, 2, 3, 4]
  const [formNo, setFormNo] = useState(formArray[0])
  const [selectedReports, setSelectedReports] = useState([])

  // Destination tenants
  const [selectedDestinationTenants, setSelectedDestinationTenants] = useState(
    []
  )
  // Parse data for posting
  const [formResponses, setFormResponses] = useState([])

  const [openPopup, setOpenPopup] = useState(false)

  const [toggleRunReportsForWholeEstate, setToggleRunReportsForWholeEstate] =
    useState(false)

  const [initialReportEmail, setInitialReportEmail] = useState('')

  const [emailFields, setEmailFields] = useState({
    mailTo: '',
    mailCC: '',
    mailBCC: '',
  })

  const { data: tenantTags, isLoading: isTenantTagsLoading } = useTenantTags()

  const tabOptions = [
    {
      name: 'Reporting',
      value: 'reporting',
    },
    {
      name: 'Auditing',
      value: 'auditing',
    },
  ]

  const [selectedTab, setSelectedTab] = useState(tabOptions[0].value)

  const handleToggleChange = () => {
    setToggleRunReportsForWholeEstate(!toggleRunReportsForWholeEstate)
  }

  const getReports = useGetReports()
  const getTenants = useGetListTenants()

  const getReportEmail = async () => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/get-report-email`
      )
      setInitialReportEmail(response.data.reportEmail)
    } catch (error) {
      console.error('Error:', error.response.status)
    }
  }

  // Set initial email when fetched
  useEffect(() => {
    if (initialReportEmail && !emailFields.mailTo) {
      setEmailFields(fields => ({ ...fields, mailTo: initialReportEmail }))
    }
  }, [initialReportEmail, emailFields.mailTo])

  const handleEmailFieldChange = e => {
    const { name, value } = e.target
    setEmailFields(fields => ({ ...fields, [name]: value }))
  }

  const isValidEmail = email => email.includes('@')

  const hasDuplicateEmails = fields => {
    const { mailTo, mailCC, mailBCC } = fields
    const emails = [mailTo, mailCC, mailBCC].filter(email => email)
    return new Set(emails).size !== emails.length
  }

  // Output responses for review on final step
  const renderDisplayValue = response => {
    if (response.step === 1) {
      return (
        <div>
          <p>
            <strong>Selected Reports </strong>
          </p>
          <ul className='confirm-items'>
            {response.response[0].map(report => (
              <li key={uuidv4()} className='sm-text'>
                {report.name}
              </li>
            ))}
          </ul>
        </div>
      )
    }
    if (response.step === 2) {
      return (
        <div>
          <p>
            <strong>Selected tenants </strong>
          </p>
          <ul className='confirm-items'>
            {response.response.map(tenants => (
              <>
                {tenants.map(i => (
                  <li key={uuidv4()}>{i.tenantFriendlyName}</li>
                ))}
              </>
            ))}
          </ul>
        </div>
      )
    }
    if (response.step === 3) {
      const emailData = response.response[0]
      return (
        <div>
          <p>
            <strong>Recipient Email Addresses</strong>
          </p>
          <ul className='confirm-items'>
            {emailData.mailTo && <li>{emailData.mailTo}</li>}
            {emailData.mailCC && <li>{emailData.mailCC}</li>}
            {emailData.mailBCC && <li>{emailData.mailBCC}</li>}
          </ul>
        </div>
      )
    }
    if (typeof response.response === 'string') {
      return <p>{response.response}</p>
    }
    return <p>{response.response.join(', ')}</p>
  }

  // Timeline navigation
  const next = () => {
    if (formNo === 1) {
      if (selectedReports.length === 0) {
        toast.error('Please select a report')
        return
      }

      const stepOneRes = { step: formNo, response: [selectedReports] }

      // Find the existing response for step 1 in the formResponses array
      const existingResponseIndex = formResponses.findIndex(
        response => response.step === formNo
      )

      if (existingResponseIndex !== -1) {
        // If an existing response exists, replace it with the latest response
        const updatedResponses = [...formResponses]
        updatedResponses[existingResponseIndex] = stepOneRes
        setFormResponses(updatedResponses)
      } else {
        // Otherwise, add the response to the array
        setFormResponses(prevFormResponses => [
          ...prevFormResponses,
          stepOneRes,
        ])
      }

      setFormNo(formNo + 1)
    } else if (formNo === 2) {
      if (selectedDestinationTenants.length === 0) {
        toast.error('Please select a destination tenant')
        return
      }

      const stepTwoRes = {
        step: formNo,
        response: [selectedDestinationTenants],
      }

      // Find the existing response for step 1 in the formResponses array
      const existingResponseIndex = formResponses.findIndex(
        response => response.step === formNo
      )

      if (existingResponseIndex !== -1) {
        // If an existing response exists, replace it with the latest response
        const updatedResponses = [...formResponses]
        updatedResponses[existingResponseIndex] = stepTwoRes
        setFormResponses(updatedResponses)
      } else {
        // Otherwise, add the response to the array
        setFormResponses(prevFormResponses => [
          ...prevFormResponses,
          stepTwoRes,
        ])
      }

      // pre-populate email field in next step with existing email
      getReportEmail()

      setFormNo(formNo + 1)
    } else if (formNo === 3) {
      if (hasDuplicateEmails(emailFields)) {
        toast.error('Duplicate email addresses are not allowed')
        return
      }

      const isMailToValid =
        !emailFields.mailTo || isValidEmail(emailFields.mailTo)
      const isMailCCValid =
        !emailFields.mailCC || isValidEmail(emailFields.mailCC)
      const isMailBCCValid =
        !emailFields.mailBCC || isValidEmail(emailFields.mailBCC)

      if (!isMailToValid || !isMailCCValid || !isMailBCCValid) {
        toast.error('Please enter valid email address')
        return
      }

      const stepThreeRes = { step: formNo, response: [emailFields] }

      // Find the existing response for step 1 in the formResponses array
      const existingResponseIndex = formResponses.findIndex(
        response => response.step === formNo
      )

      if (existingResponseIndex !== -1) {
        // If an existing response exists, replace it with the latest response
        const updatedResponses = [...formResponses]
        updatedResponses[existingResponseIndex] = stepThreeRes
        setFormResponses(updatedResponses)
      } else {
        // Otherwise, add the response to the array
        setFormResponses(prevFormResponses => [
          ...prevFormResponses,
          stepThreeRes,
        ])
      }

      setFormNo(formNo + 1)
    }
  }

  const pre = () => {
    setFormNo(formNo - 1)
  }

  const finalSubmit = () => {
    setOpenPopup(true)

    // Get emails
    const destinationEmails = []

    if (emailFields.mailTo) {
      destinationEmails.push(emailFields.mailTo)
    }
    if (emailFields.mailCC) {
      destinationEmails.push(emailFields.mailCC)
    }
    if (emailFields.mailBCC) {
      destinationEmails.push(emailFields.mailBCC)
    }

    // Requests
    const runReportsWholeEstate = async reportBody => {
      try {
        await Axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/run-reports-whole-estate`,
          reportBody
        )
      } catch (error) {
        console.error(error.response.data)
      }
    }

    const runReports = async reportBody => {
      try {
        await Axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/run-reports`,
          reportBody
        )
      } catch (error) {
        console.error(error.response.data)
      }
    }

    // If/Else to run one or other of the above requests based on step 1 toggle
    if (toggleRunReportsForWholeEstate === true) {
      const reportTypeId = formResponses[0].response[0].map(item => +item.id)
      const infTenantId = formResponses[1].response[0].map(
        ({ clientTenantId }) => +clientTenantId
      )

      const reportBody = {
        infTenantId,
        reportTypeId,
        destinationEmails,
      }

      runReportsWholeEstate(reportBody)
    } else {
      const reportTypeId = formResponses[0].response[0].map(item => +item.id)

      const reportBody = formResponses[1].response
        .flat()
        .map(({ clientTenantId }) => ({
          infTenantId: clientTenantId,
          reportTypeId,
          destinationEmails,
        }))
      runReports(reportBody)
    }
  }

  const orderedReports = useMemo(
    () =>
      getReports.data
        ? [
            ...getReports.data.filter(report =>
              mostUsedReports.includes(report.name)
            ),
            ...getReports.data.filter(
              report => !mostUsedReports.includes(report.name)
            ),
          ]
        : [],
    [getReports.data]
  )

  const reportsConfig = {
    columns: [{ header: 'Report Name', accessor: 'name' }],
    data: orderedReports,
  }

  const uniqueTags = uniqueTenantTags({
    tenants: getTenants.data,
    tenantTags,
  })

  const tenantsConfig = {
    columns: [
      { header: 'Tenant Name', accessor: 'tenantFriendlyName' },
      {
        header: 'Type',
        accessor: 'type',
        filterVariant: 'multi-select',
        filterSelectOptions: ['Baseline', 'Customer'],
        filterFn: tenantTypeFilter,
        cell: ({ row }) => TenantTypeCell({ row }),
      },
      {
        header: 'Tags',
        accessor: 'tags',
        cell: ({ row }) =>
          TenantTagsCell({
            linkedTenantTagIds: row.original.tags,
            allTenantTags: tenantTags,
            numVisibleTags: 4,
          }),
        size: 700,
        minSize: 100,
        maxSize: 800,
        grow: true,
        filterVariant: 'multi-select',
        filterSelectOptions: uniqueTags.map(tag => ({
          value: tag?.id,
          label: tag?.tag,
        })),
      },
    ],
    positionGlobalFilter: 'left',
    enableFacetedValues: true,
    data: getTenants.data,
  }

  // Timeline step text
  const stepH1 = 'Select Reports'
  const stepH2 = 'Select Tenants'
  const stepH3 = 'Confirm recipient emails'
  const stepH4 = 'Confirm selection'

  const stepP1 = 'Select Report(s) to run'
  const stepP2 = 'Select Tenants'
  const stepP3 = 'Confirm recipient emails'
  const stepP4 = 'Confirm Reports and Tenants'

  const renderReports = () => (
    <>
      <div className='progress-bar'>
        {formArray.map((v, i) => (
          <div key={uuidv4()}>
            <div className='flex'>
              <div className='flex'>
                <div
                  className={`progress-number progress-number-${v} ${formNo - 1 === i || formNo - 1 === i + 1 || formNo - 1 === i + 2 || formNo === formArray.length ? 'cyan-bg text-white' : 'bg-slate-300'}`}
                >
                  {v}
                </div>
                <div className='progress-text'>
                  <p className='navy-text'>
                    <strong>
                      {(v === 1 && stepH1) ||
                        (v === 2 && stepH2) ||
                        (v === 3 && stepH3) ||
                        (v === 4 && stepH4)}
                    </strong>
                  </p>
                  <p className='text-gray-400'>
                    {(v === 1 && stepP1) ||
                      (v === 2 && stepP2) ||
                      (v === 3 && stepP3) ||
                      (v === 4 && stepP4)}
                  </p>
                </div>
              </div>
            </div>
            {i !== formArray.length - 1 && (
              <div className='progress-bar-link' />
            )}
          </div>
        ))}
      </div>
      <div className='action-body-form'>
        {formNo === 1 && (
          <>
            {getReports.isError && (
              <p className='text-red-500 font-semibold mb-3'>
                {getReports.error}
              </p>
            )}
            <ListTable
              selectedListItems={selectedReports}
              setSelectedListItems={setSelectedReports}
              data={reportsConfig.data}
              columns={reportsConfig.columns}
              uniqueKey='id'
              enableMultiRowSelection
              enableRowSelection
              loading={getReports.isPending}
            />

            <div className='gap-3 flex'>
              <button
                type='button'
                onClick={next}
                disabled={selectedReports?.length === 0}
                className='btn cyan-btn'
              >
                Next
              </button>
            </div>
          </>
        )}
        {formNo === 2 && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={toggleRunReportsForWholeEstate}
                  onChange={handleToggleChange}
                  name='toggleReports'
                  color='primary'
                />
              }
              label='Collate reports across all selected tenants'
            />
            <SelectedItems
              title={
                selectedDestinationTenants.length > 0
                  ? 'Selected tenants:'
                  : 'No tenants selected'
              }
              selectedListItems={selectedDestinationTenants.map(
                tenant => tenant.tenantFriendlyName
              )}
            />
            {getTenants.isError && (
              <p className='text-red-500 font-semibold mb-3'>
                {getTenants.error}
              </p>
            )}
            <ListTable
              selectedListItems={selectedDestinationTenants}
              setSelectedListItems={setSelectedDestinationTenants}
              uniqueKey='clientTenantId'
              data={tenantsConfig.data}
              columns={tenantsConfig.columns}
              enableMultiRowSelection
              enableRowSelection
              loading={getTenants.isLoading || isTenantTagsLoading}
            />

            <div className='gap-3 flex'>
              <button type='button' onClick={pre} className='btn navy-btn'>
                Previous
              </button>
              <button
                type='button'
                disabled={selectedDestinationTenants?.length === 0}
                onClick={next}
                className='btn cyan-btn'
              >
                Next
              </button>
            </div>
          </>
        )}
        {formNo === 3 && (
          <>
            <ReportingEmails
              handleEmailFieldChange={handleEmailFieldChange}
              emailFields={emailFields}
            />

            <div className='mt-6 gap-3 flex'>
              <button type='button' onClick={pre} className='btn navy-btn'>
                Previous
              </button>
              <button type='button' onClick={next} className='btn cyan-btn'>
                Next
              </button>
            </div>
          </>
        )}
        {formNo === 4 && (
          <div className='final-confirmation'>
            <h3>Review and confirm your changes</h3>

            <div className='mt-4'>
              <ul>
                {formResponses.map(response => (
                  <li key={uuidv4()}>{renderDisplayValue(response)}</li>
                ))}
              </ul>
            </div>
            <div className='mt-6 gap-3 flex'>
              <button type='button' onClick={pre} className='btn navy-btn'>
                Previous
              </button>
              <button
                type='button'
                onClick={finalSubmit}
                className='btn cyan-btn'
              >
                Run Reports
              </button>
            </div>
            {openPopup === true && <ReportsPopup />}
          </div>
        )}
      </div>
    </>
  )

  const renderAuditReports = () => (
    <div className='no-progress-bar flex iframe-container'>
      <iframe
        src={process.env.REACT_APP_PROSPECTING_TOOL_URL}
        title='Inforcer 365 Security Audit'
        style={{ width: '100%', height: '100%', border: '0px' }}
      />
    </div>
  )

  const buildTab = () => {
    switch (selectedTab) {
      case 'reporting':
        return renderReports()
      case 'auditing':
        return renderAuditReports()
      default:
        return renderReports()
    }
  }

  return (
    <div className='ui-panel reporting'>
      <ToastContainer />
      <div className='heading'>
        <div className='flex justify-between flex-wrap'>
          <h2 className='mr-4'>Reporting</h2>
          <div className='flex items-center'>
            <TabSelector
              value={selectedTab}
              onChange={setSelectedTab}
              options={tabOptions}
            />
          </div>
        </div>
        <p>
          {selectedTab === 'reporting'
            ? 'Run available reports and receive a confirmation email'
            : 'Preview: Run a security audit on a Microsoft 365 tenant'}
        </p>
      </div>
      <div className='action-body'>{buildTab()}</div>
    </div>
  )
}

export default Reporting
