import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import { Link } from 'react-router-dom'
import ConfirmationPopupSkeleton from './LoadingSkeletons/ConfirmationPopupSkeleton'

const BackupConfirmationPopup = ({
  finalResponse,
  formResponses,
  backEndErrors,
}) => {
  const tenantName = formResponses[0].response[0].tenantFriendlyName
  const backUpName = formResponses[2].response

  const totalPolicies =
    (finalResponse ? finalResponse.length : 0) +
    (backEndErrors ? backEndErrors.length : 0)

  const errorSegment =
    backEndErrors && backEndErrors.length > 0
      ? `, Errors: ${backEndErrors.join(', ')}`
      : ''

  const copyToClipboard = `Selected Tenant: ${tenantName}, Backup name: ${backUpName}, Policies successfully queued for back up ${finalResponse ? finalResponse.length : 0}/${totalPolicies}: \n${finalResponse ? finalResponse.map(item => item).join('\n') : ''}${errorSegment}`

  return (
    <div className='popup-bg'>
      <div className='popup-container'>
        <h3 className='text-center mb-4'>Back Up Summary</h3>

        {finalResponse === null ? (
          <ConfirmationPopupSkeleton loadingTitle='Back Up in progress...' />
        ) : finalResponse && finalResponse.length > 0 ? (
          <div>
            <p className='my-2'>
              <strong>Selected Tenant - </strong>
              {tenantName}
            </p>
            <p className='mt-2 mb-2'>
              <strong>Back Up name - </strong>
              {backUpName}
            </p>

            <p className='mb-2'>
              <strong>Policies successfully queued for back up - </strong>
              {finalResponse.length}/{totalPolicies}
            </p>
            <table>
              <tbody>
                {finalResponse.map((item, index) => (
                  <tr key={index}>
                    <td>{item}</td>
                    <td aria-label='Success'>
                      <FontAwesomeIcon icon={faCheckCircle} className='ml-1' />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <h4 className='report-error'>
            An error has ocurred, please refresh the page and try again, or
            contact us at <span className='cyan-text'>info@inforcer.com.</span>.
          </h4>
        )}

        {backEndErrors && (
          <ul>
            <li>
              <strong>Errors - </strong>
              {backEndErrors.length}
            </li>
            {backEndErrors.map((err, index) => (
              <li key={index}>{err}</li>
            ))}
          </ul>
        )}

        {(finalResponse !== null && finalResponse.length > 0) ||
        backEndErrors ? (
          <div className='footer mt-4 mb-2'>
            <button
              className='btn navy-btn'
              onClick={() => {
                navigator.clipboard.writeText(copyToClipboard)
              }}
            >
              Copy to clipboard
            </button>
            <button className='btn cyan-btn ml-4 text-white'>
              <Link to='/' className='text-white'>
                Home
              </Link>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default BackupConfirmationPopup
