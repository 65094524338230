import TenantTagsActions from './TenantTagsActions'
import Tag from '../Tag/Tag'
import { hexToRgb } from '../../utils/colours'
import ClickableCell from '../TableCells/ClickableCell/ClickableCell'

const tableColumnDefinitions = ({
  handleEditTag,
  setIsContentPanelOpen,
  setTenantsLinkedToTag,
}) => [
  {
    header: 'Tag',
    accessor: 'tag',
    cell: ({ row }) => (
      <Tag
        text={row.original.tag}
        description={row.original.description}
        colourRGB={hexToRgb(row.original.colour)}
      />
    ),
  },
  {
    header: 'Description',
    accessor: 'description',
  },
  {
    header: 'Tenant count',
    cell: ({ row }) => (
      <ClickableCell
        disabled={row.original.tenants.length === 0}
        onClick={() => setTenantsLinkedToTag(row.original.tenants)}
      >
        {row.original.tenants.length}
      </ClickableCell>
    ),
  },
  {
    header: 'Actions',
    accessor: 'actions',
    enableColumnFilter: false,
    cell: ({ row }) => (
      <TenantTagsActions
        id={row.original.id}
        tag={row.original.tag}
        description={row.original.description}
        colour={row.original.colour}
        onEdit={handleEditTag}
        onSuccess={setIsContentPanelOpen}
      />
    ),
  },
]

export default { tableColumnDefinitions }
