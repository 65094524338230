import { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import {
  faArrowsRotate,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { alignmentPolicySortOptions } from '../../config/TenantAlignmentConfig'
import { usePolicyCompare } from '../../hooks/UsePolicyCompare'
import Checkbox from '../Checkbox'
import { usePolicyViewer } from '../../hooks/UsePolicyViewer'
import colourStyles from '../../config/ReactSelectColours'
import CollapsibleCheckboxCard from '../CollapsibleCard/CollapsibleCheckboxCard'
import TenantAlignmentIcon from './TenantAlignmentIcon'
import CollapsibleCard from '../CollapsibleCard/CollapsibleCard'
import RenamePolicyPopup from './RenamePolicyPopup'
import { exchangePolicyIds } from '../../utils/Policy'
import AlignmentPolicy from './AlignmentPolicy'
import useGetTenants from '../SharedBaselines/api/useGetTenants'
import Alert from '../Alert/Alert'

/**
 * @param {{
 *  onDeployPolicy: (obj: any) => void,
 *  tenantBaselines: any[],
 * }} props
 */
const AlignmentByPolicy = ({ onDeployPolicy, tenantBaselines }) => {
  const [useLiveData, setUseLiveData] = useState(false)

  const [policySearch, setPolicySearch] = useState('')

  const [tenantBaselineId, setTenantBaselineId] = useState(
    tenantBaselines[0]?.clientTenantGroupId
  )

  const [selectedPolicyId, setSelectedPolicyId] = useState()
  const [selectedPolicyTypeId, setSelectedPolicyTypeId] = useState()

  const [selectedTenantIds, setSelectedTenantIds] = useState([])

  const [selectedTenantsWithoutPolicyIds, setSelectedTenantWithoutPolicyIds] =
    useState([])

  const [selectedNameMatchedPolicyIds, setSelectedNameMatchedPolicyIds] =
    useState([])

  const [selectedPolicySort, setSelectedPolicySort] = useState(
    alignmentPolicySortOptions[0]
  )

  const [selectedPolicyTypeFilter, setSelectedPolicyTypeFilter] = useState([])

  const [selectedDisplayFilter, setSelectedDisplayFilter] = useState([])

  const [comparisonSearch, setComparisonSearch] = useState('')

  const [tenantSearch, setTenantSearch] = useState('')

  const [collapsedOverride, setCollapsedOverride] = useState(true)

  const [policyRenameData, setPolicyRenameData] = useState()

  const {
    isError: tenantsIsError,
    error: getTenantsError,
    isLoading: tenantsLoading,
    data: { data: tenants = [], errors: tenantsErrors = [] } = {},
  } = useGetTenants()

  const viewPolicy = usePolicyViewer()

  const displayFilterOptions = [
    { label: 'Name Matched', value: 'named' },
    { label: 'Similar', value: 'similar' },
    { label: 'Tenants Without Policy', value: 'without-named' },
  ]

  const selectedBaseline = useMemo(
    () =>
      tenantBaselines.find(
        baseline => baseline.clientTenantGroupId === tenantBaselineId
      ),
    [tenantBaselineId, tenantBaselines]
  )

  const tenantDropdownOptions = useMemo(() => {
    if (!selectedBaseline) return []

    const output = []

    if (!selectedBaseline.clientTenantIds.length === 0) return output

    selectedBaseline.clientTenantIds.forEach(id => {
      const tenant = tenants.find(t => t.clientTenantId === id)
      if (tenant)
        output.push({
          value: tenant.clientTenantId,
          label: tenant.tenantFriendlyName,
        })
    })

    return output
  }, [selectedBaseline, tenants])

  const baselineTenant = useMemo(
    () =>
      tenants.find(
        bl => bl.clientTenantId === selectedBaseline.baselineClientTenantId
      ),
    [selectedBaseline.baselineClientTenantId, tenants]
  )

  const {
    policies,
    tenantComparisons,
    error: policyLoadError,
    policyListLoading,
    comparisonLoading,
    loadTenantPolicies,
    clearPolicyComparisonResults,
    renamePolicy,
    isPolicyRenaming,
    policyRenameError,
  } = usePolicyCompare(
    selectedBaseline?.baselineClientTenantId,
    selectedPolicyId,
    selectedBaseline?.clientTenantIds,
    useLiveData
  )

  const selectedPolicy = useMemo(
    () => policies.find(policy => policy.policyGuid === selectedPolicyId),
    [policies, selectedPolicyId]
  )

  const policySearchResults = useMemo(() => {
    const output = policies
      .filter(policy =>
        policy.displayName.toLowerCase().includes(policySearch.toLowerCase())
      )
      .filter(
        policy =>
          selectedPolicyTypeFilter.length === 0 ||
          selectedPolicyTypeFilter.some(
            ({ value }) => value === policy.policyTypeName
          )
      )

    output.sort((a, b) => {
      switch (selectedPolicySort.value) {
        case 'a-z':
          return a.displayName.localeCompare(b.displayName)
        case 'policy-type':
          return a.policyTypeId - b.policyTypeId
        default:
          return 0
      }
    })

    return output
  }, [policies, policySearch, selectedPolicySort, selectedPolicyTypeFilter])

  const availablePolicyTypes = useMemo(() => {
    const output = []
    for (const policy of policies) {
      if (!policy.policyTypeName || output.includes(policy.policyTypeName))
        continue
      output.push(policy.policyTypeName)
    }
    output.sort()
    return output
  }, [policies])

  const comparisonRealPolicyName = useMemo(
    () => tenantComparisons?.[0]?.diff?.policyName,
    [tenantComparisons]
  )

  const comparisonResults = useMemo(() => {
    if (!selectedPolicyId) return []

    const output = {}

    let allDataDiffs = []
    let allAssignmentDiffs = []
    let allStringifiedDiffs = []
    for (const comparison of tenantComparisons ?? []) {
      if (!comparison?.diff) continue

      if (
        selectedTenantIds.length === 0 ||
        selectedTenantIds.some(
          ({ value }) => value === comparison.subjectClientTenantId
        )
      ) {
        allDataDiffs = [
          ...allDataDiffs,
          ...comparison.diff.policyDataDiff.map(d => ({
            ...d,
            subjectClientTenantId: comparison.subjectClientTenantId,
            policyNameKeyName: comparison.diff.policyNameKeyName,
          })),
        ]
        allAssignmentDiffs = [
          ...allAssignmentDiffs,
          ...comparison.diff.policyAssignmentsDiff.map(d => ({
            ...d,
            subjectClientTenantId: comparison.subjectClientTenantId,
            policyNameKeyName: comparison.diff.policyNameKeyName,
          })),
        ]
        allStringifiedDiffs = [
          ...allStringifiedDiffs,
          ...comparison.diff.stringifiedDiffs.map(d => ({
            ...d,
            subjectClientTenantId: comparison.subjectClientTenantId,
            policyNameKeyName: comparison.diff.policyNameKeyName,
          })),
        ]
      }
    }

    for (const {
      diff,
      policyGuid,
      policyId,
      diffCount,
      policyName,
      policyNameKeyName,
      policyODataType,
      matchPercentage,
      subjectClientTenantId,
    } of allDataDiffs) {
      output[policyGuid] = {
        policyGuid,
        policyId,
        diffCount,
        matchPercentage,
        policyName,
        policyNameKeyName,
        policyODataType,
        policyDataDiff: diff,
        policyAssignmentsDiff: [],
        subjectClientTenantId,
      }
    }

    for (const {
      diff,
      policyGuid,
      policyId,
      diffCount,
      policyName,
      policyNameKeyName,
      policyODataType,
      matchPercentage,
      subjectClientTenantId,
    } of allAssignmentDiffs) {
      if (!output[policyGuid]) {
        output[policyGuid] = {
          policyGuid,
          policyId,
          diffCount,
          matchPercentage,
          policyName,
          policyNameKeyName,
          policyODataType,
          policyDataDiff: [],
          policyAssignmentsDiff: diff,
          subjectClientTenantId,
        }
      } else {
        output[policyGuid].diffCount += diffCount
        output[policyGuid].policyAssignmentsDiff = diff
      }
    }

    for (const { policyGuid, stringifiedDiff } of allStringifiedDiffs) {
      if (!output[policyGuid]) {
        continue
      } else {
        output[policyGuid].stringifiedDiff = stringifiedDiff
      }
    }

    return Object.values(output)
  }, [selectedPolicyId, selectedTenantIds, tenantComparisons])

  const nameMatchedResults = useMemo(
    () =>
      comparisonResults.filter(
        p =>
          p.policyName.toLowerCase() === comparisonRealPolicyName?.toLowerCase()
      ),
    [comparisonRealPolicyName, comparisonResults]
  )

  const similarResults = useMemo(() => {
    const output = comparisonResults
      .filter(
        p =>
          p.policyName.toLowerCase() !== comparisonRealPolicyName?.toLowerCase()
      )
      .filter(p =>
        p.policyName.toLowerCase().includes(comparisonSearch.toLowerCase())
      )
    output.sort((a, b) => b.matchPercentage - a.matchPercentage)
    return output
  }, [comparisonRealPolicyName, comparisonResults, comparisonSearch])

  const tenantsWithoutPolicy = useMemo(
    () =>
      comparisonLoading
        ? []
        : tenants
            .filter(t =>
              selectedBaseline.clientTenantIds.includes(t.clientTenantId)
            )
            .filter(
              t =>
                !nameMatchedResults.some(
                  result => result?.subjectClientTenantId === t.clientTenantId
                )
            )
            .filter(t =>
              t.tenantFriendlyName
                .toLowerCase()
                .includes(tenantSearch.toLowerCase())
            ),
    [
      nameMatchedResults,
      comparisonLoading,
      selectedBaseline.clientTenantIds,
      tenantSearch,
      tenants,
    ]
  )

  const allTenantsWithoutPolicySelected = useMemo(() => {
    const selectedPolicyIsExchange =
      exchangePolicyIds.includes(selectedPolicyTypeId)

    const selectableTenants = tenantsWithoutPolicy.filter(
      tenant => !(selectedPolicyIsExchange && tenant.exchange === false)
    )

    return (
      selectedTenantsWithoutPolicyIds.length === selectableTenants.length &&
      selectableTenants.length > 0
    )
  }, [
    selectedTenantsWithoutPolicyIds.length,
    tenantsWithoutPolicy,
    selectedPolicyTypeId,
    exchangePolicyIds,
  ])

  const allNameMatchedPoliciesSelected = useMemo(
    () =>
      selectedNameMatchedPolicyIds.length === nameMatchedResults?.length &&
      nameMatchedResults?.length > 0,
    [nameMatchedResults?.length, selectedNameMatchedPolicyIds.length]
  )

  const nonAlignedNameMatchedPolicyIds = useMemo(
    () =>
      selectedNameMatchedPolicyIds.filter(id =>
        nameMatchedResults.some(
          p => p.policyId === id && p.matchPercentage < 100
        )
      ),
    [nameMatchedResults, selectedNameMatchedPolicyIds]
  )

  useEffect(() => {
    if (tenantBaselineId) return
    setTenantBaselineId(tenantBaselines[0]?.clientTenantGroupId)
  }, [tenantBaselines, tenantBaselineId])

  useEffect(() => {
    if (selectedTenantIds || !selectedBaseline) return
    if (selectedBaseline.clientTenantIds.length === 0) return
    setSelectedTenantIds(selectedBaseline.clientTenantIds[0])
  }, [selectedBaseline, selectedTenantIds])

  useEffect(() => {
    setCollapsedOverride(true)
  }, [selectedPolicy])

  const toggleSelectedTenantWithoutPolicy = (tenantId, selected) => {
    if (!selected) {
      setSelectedTenantWithoutPolicyIds(ids =>
        ids.filter(id => tenantId !== id)
      )
    } else {
      setSelectedTenantWithoutPolicyIds(ids => [...ids, tenantId])
    }
  }

  const toggleSelectedNameMatchedPolicy = (policyId, selected) => {
    if (!selected) {
      setSelectedNameMatchedPolicyIds(ids => ids.filter(id => policyId !== id))
    } else {
      setSelectedNameMatchedPolicyIds(ids => [...ids, policyId])
    }
  }

  const toggleAllTenantsWithoutPolicySelected = () => {
    if (allTenantsWithoutPolicySelected) {
      setSelectedTenantWithoutPolicyIds([])
    } else {
      const selectedPolicyIsExchange =
        exchangePolicyIds.includes(selectedPolicyTypeId)

      const filteredTenants = tenantsWithoutPolicy.filter(
        tenant => !(selectedPolicyIsExchange && tenant.exchange === false)
      )

      setSelectedTenantWithoutPolicyIds(
        filteredTenants.map(tenant => tenant.clientTenantId)
      )
    }
  }

  const toggleAllNameMatchedPoliciesSelected = () => {
    if (allNameMatchedPoliciesSelected) {
      setSelectedNameMatchedPolicyIds([])
    } else {
      setSelectedNameMatchedPolicyIds(nameMatchedResults.map(e => e.policyId))
    }
  }

  const toggleCollapsedOverride = () => {
    setCollapsedOverride(!collapsedOverride)
  }

  const buildPolicyDescription = (policy, tenant = null) => {
    const {
      stringifiedDiff,
      policyDataDiff,
      policyAssignmentsDiff,
      deployProcessing,
    } = policy

    const noDiffs =
      [...(policyDataDiff ?? []), ...(policyAssignmentsDiff ?? [])].length === 0

    if (noDiffs || !stringifiedDiff) {
      return <div className='text-md'>No deviations detected</div>
    }

    return (
      <div className='text-md'>
        {stringifiedDiff ? (
          <div className='mb-2'>
            <b>Data deviation from baseline:</b>
            <br />
            <p style={{ whiteSpace: 'pre-wrap', lineBreak: 'anywhere' }}>
              {stringifiedDiff}
            </p>
          </div>
        ) : null}
        {tenant ? (
          <button
            type='button'
            className='btn cyan-btn text-xs ml-1'
            disabled={deployProcessing}
            onClick={() => {
              const { policyGuid, policyTypeId, displayName, priority } =
                selectedPolicy

              onDeployPolicy({
                baseline: baselineTenant,
                tenant,
                policy: {
                  policyGuid,
                  policyTypeId,
                  policyName: displayName,
                  priority,
                },
                onDone: () => {
                  setSelectedTenantWithoutPolicyIds([])
                },
              })
            }}
          >
            Align to Baseline
          </button>
        ) : null}
      </div>
    )
  }

  const buildPolicyContent = () => {
    if (!selectedPolicy) {
      return (
        <div className='w-[100%] h-[400px] flex items-center justify-center'>
          <p>Please select a Policy</p>
        </div>
      )
    }

    const { policyGuid, policyTypeId, policyTypeName } = selectedPolicy

    const selectedTenantNoPolicy = tenantsWithoutPolicy?.find(tenant =>
      selectedTenantsWithoutPolicyIds.includes(tenant.clientTenantId)
    )

    const disableSelectAllDeployment =
      nameMatchedResults?.length === 0 ||
      (selectedTenantNoPolicy?.exchange === false &&
        exchangePolicyIds.includes(selectedPolicyTypeId))

    const disableNonExchange =
      tenantsWithoutPolicy?.some(tenant => !tenant.exchange) &&
      exchangePolicyIds.includes(selectedPolicyTypeId)

    const alignTenantsDisabled = () => {
      if (selectedTenantsWithoutPolicyIds.length === 0) {
        return true
      }

      if (
        selectedTenantNoPolicy &&
        !selectedTenantNoPolicy.exchange &&
        exchangePolicyIds.includes(selectedPolicyTypeId)
      ) {
        return true
      }

      return false
    }

    const disableTenantCheckbox = tenant =>
      tenant.exchange === false &&
      exchangePolicyIds.includes(selectedPolicyTypeId)

    return (
      <>
        <h3 className='mb-4'>{selectedPolicy.displayName}</h3>
        <div className='flex items-center mb-6'>
          <label className='w-[240px] mr-2'>
            <b>Baseline tenant</b>
          </label>
          <span className='flex-grow'>{baselineTenant.tenantFriendlyName}</span>
        </div>
        <div className='flex items-center mb-4'>
          <label id='tenantFilter' className='w-[240px] min-w-[240px] mr-2'>
            <b>Tenant filter</b>
          </label>
          <div
            aria-labelledby='tenantFilter'
            className='w-[100%] focus:outline-blue-400 rounded-md text-gray-700 text-left'
          >
            <Select
              value={selectedTenantIds}
              options={tenantDropdownOptions}
              onChange={setSelectedTenantIds}
              styles={colourStyles}
              isMulti
              closeMenuOnSelect={false}
              placeholder='No filter'
            />
          </div>
        </div>
        <div className='flex items-center mb-4'>
          <label id='displayFilter' className='w-[240px] min-w-[240px] mr-2'>
            <b>Display filter</b>
          </label>

          <div
            aria-labelledby='displayFilter'
            className='w-[100%] focus:outline-blue-400 rounded-md text-gray-700 text-left'
          >
            <Select
              value={selectedDisplayFilter}
              options={displayFilterOptions}
              onChange={setSelectedDisplayFilter}
              styles={colourStyles}
              isMulti
              closeMenuOnSelect={false}
              placeholder='No filter'
            />
          </div>
        </div>

        <div className='text-right'>
          <button
            className='btn cyan-btn w-[110px]'
            onClick={toggleCollapsedOverride}
          >
            {collapsedOverride ? 'Expand' : 'Collapse'} All
          </button>
        </div>

        {selectedDisplayFilter.length === 0 ||
        selectedDisplayFilter.some(({ value }) => value === 'named') ? (
          <>
            <h3 className='mb-4 mt-12'>
              Name Matched Policies ({nameMatchedResults.length})
            </h3>
            <div className='flex mt-4 mb-2'>
              <div className='min-w-[76px] mr-4'>
                <button
                  type='button'
                  className='btn cyan-btn text-xs'
                  onClick={toggleAllNameMatchedPoliciesSelected}
                  disabled={nameMatchedResults.length === 0}
                >
                  {allNameMatchedPoliciesSelected
                    ? 'Unselect All'
                    : 'Select All'}
                </button>
              </div>
              <div className='text-right flex-grow'>
                <button
                  type='button'
                  className='btn text-xs cyan-btn ml-2 mb-2'
                  disabled={
                    nonAlignedNameMatchedPolicyIds.length === 0 ||
                    disableNonExchange
                  }
                  onClick={() => {
                    const { policyGuid, policyTypeId, displayName, priority } =
                      selectedPolicy

                    const selTenants = nonAlignedNameMatchedPolicyIds.map(
                      policyId => {
                        const policy = nameMatchedResults.find(
                          p => p.policyId === policyId
                        )
                        const tenant = tenants.find(
                          t => t.clientTenantId === policy.subjectClientTenantId
                        )
                        return tenant
                      }
                    )

                    onDeployPolicy({
                      baseline: baselineTenant,
                      tenants: selTenants,
                      policy: {
                        policyGuid,
                        policyTypeId,
                        policyName: displayName,
                        priority,
                      },
                      onDone: () => {
                        setSelectedTenantWithoutPolicyIds([])
                      },
                    })
                  }}
                >
                  Align {nonAlignedNameMatchedPolicyIds.length} Tenants to
                  Baseline
                </button>
              </div>
            </div>
            <div className='alignment-results-list'>
              {nameMatchedResults.length === 0 ? (
                comparisonLoading ? (
                  <div className='flex items-center justify-center h-[200px] p-4'>
                    <div id='loading-spinner' className='mt-4'>
                      <div className='loading' />
                    </div>
                  </div>
                ) : (
                  <div className='flex items-center justify-center h-[100%] p-4'>
                    No results found
                  </div>
                )
              ) : (
                nameMatchedResults.map(policy => {
                  const tenant = tenants.find(
                    t => t.clientTenantId === policy.subjectClientTenantId
                  )
                  return (
                    <CollapsibleCheckboxCard
                      key={policy.policyId}
                      checked={selectedNameMatchedPolicyIds.includes(
                        policy.policyId
                      )}
                      onChange={val =>
                        toggleSelectedNameMatchedPolicy(policy.policyId, val)
                      }
                      forceOpen={collapsedOverride}
                      cardTitle={
                        <div className='w-[100%] flex justify-between items-center'>
                          <div>
                            <b>{tenant.tenantFriendlyName}</b>
                            <br />
                            <span className='text-md'>{policy.policyName}</span>
                          </div>
                          <div className='flex items-center ml-4'>
                            {policy.matchPercentage ? (
                              <span className='mr-2'>
                                {policy.matchPercentage.toFixed(2)}%
                              </span>
                            ) : null}
                            <TenantAlignmentIcon
                              score={policy.matchPercentage}
                              alignedThreshold={
                                selectedBaseline.alignedThreshold
                              }
                              semiAlignedThreshold={
                                selectedBaseline.semiAlignedThreshold
                              }
                            />
                          </div>
                        </div>
                      }
                      cardContent={buildPolicyDescription(policy)}
                      cardActions={
                        policy.matchPercentage < 100 ? (
                          <div className='text-right'>
                            <button
                              className='btn cyan-btn text-xs ml-1'
                              onClick={() => {
                                const {
                                  policyGuid,
                                  policyTypeId,
                                  displayName,
                                } = selectedPolicy
                                onDeployPolicy({
                                  baseline: baselineTenant,
                                  tenant,
                                  policy: {
                                    policyGuid,
                                    policyTypeId,
                                    policyName: displayName,
                                  },
                                })
                              }}
                            >
                              Align to Baseline
                            </button>
                          </div>
                        ) : null
                      }
                    />
                  )
                })
              )}
            </div>
          </>
        ) : null}

        {selectedDisplayFilter.length === 0 ||
        selectedDisplayFilter.some(({ value }) => value === 'similar') ? (
          <>
            <h3 className='mb-4 mt-12'>
              Similar Policies ({similarResults.length})
            </h3>
            <div className='max-w-[1320px]'>
              <div className='flex items-center mb-4'>
                <input
                  className='flex flex-grow focus:outline-blue-400 rounded-md light-grey-bg p-2 text-gray-700'
                  type='text'
                  placeholder='Search'
                  value={comparisonSearch}
                  onChange={e => setComparisonSearch(e.target.value)}
                />
                <button
                  className='btn navy-btn clear-btn'
                  onClick={() => setComparisonSearch('')}
                >
                  Clear
                </button>
              </div>
              <div className='alignment-results-list'>
                {similarResults.length === 0 ? (
                  comparisonLoading ? (
                    <div className='flex items-center justify-center h-[200px] p-4'>
                      <div id='loading-spinner' className='mt-4'>
                        <div className='loading' />
                      </div>
                    </div>
                  ) : (
                    <div className='flex items-center justify-center h-[100%] p-4'>
                      No results found
                    </div>
                  )
                ) : (
                  similarResults.map(policy => {
                    const tenant = tenants.find(
                      t => t.clientTenantId === policy.subjectClientTenantId
                    )
                    return (
                      <CollapsibleCard
                        key={policy.policyId}
                        forceOpen={collapsedOverride}
                        cardTitle={
                          <div className='w-[100%] flex justify-between items-center'>
                            <div>
                              <b>{tenant.tenantFriendlyName}</b>
                              <br />
                              <span className='text-md'>
                                {policy.policyName}
                              </span>
                            </div>
                            <div className='flex items-center ml-4'>
                              {policy.matchPercentage ? (
                                <span className='mr-2'>
                                  {policy.matchPercentage.toFixed(2)}%
                                </span>
                              ) : null}
                              <TenantAlignmentIcon
                                score={policy.matchPercentage}
                                alignedThreshold={
                                  selectedBaseline.alignedThreshold
                                }
                                semiAlignedThreshold={
                                  selectedBaseline.semiAlignedThreshold
                                }
                              />
                            </div>
                          </div>
                        }
                        cardContent={buildPolicyDescription(policy)}
                        cardActions={
                          policy.policyNameKeyName ? (
                            <button
                              type='button'
                              className='btn cyan-btn text-xs ml-1'
                              onClick={() => {
                                setPolicyRenameData({
                                  baselineClientTenantId:
                                    baselineTenant.clientTenantId,
                                  subjectClientTenantId: tenant.clientTenantId,
                                  targetPolicy: policy,
                                  baselinePolicy: selectedPolicy,
                                })
                              }}
                            >
                              Rename to Baseline
                            </button>
                          ) : null
                        }
                      />
                    )
                  })
                )}
              </div>
            </div>
          </>
        ) : null}
        {selectedDisplayFilter.length === 0 ||
        selectedDisplayFilter.some(({ value }) => value === 'without-named') ? (
          <>
            <h3 className='mb-4 mt-12'>
              Tenants Without Policy ({tenantsWithoutPolicy.length})
            </h3>
            <div className='flex items-center mb-4'>
              <input
                className='flex flex-grow focus:outline-blue-400 rounded-md light-grey-bg p-2 text-gray-700'
                type='text'
                placeholder='Search'
                value={tenantSearch}
                onChange={e => setTenantSearch(e.target.value)}
              />
              <button
                className='btn navy-btn clear-btn'
                onClick={() => setTenantSearch('')}
              >
                Clear
              </button>
            </div>
            <div className='flex mt-4 mb-2'>
              <div className='min-w-[76px] mr-4'>
                <button
                  type='button'
                  className='btn cyan-btn text-xs'
                  onClick={toggleAllTenantsWithoutPolicySelected}
                  disabled={tenantsWithoutPolicy.length === 0}
                >
                  {allTenantsWithoutPolicySelected
                    ? 'Unselect All'
                    : 'Select All'}
                </button>
              </div>
              <div className='text-right flex-grow'>
                <button
                  type='button'
                  className='btn text-xs cyan-btn ml-2 mb-2'
                  disabled={alignTenantsDisabled()}
                  onClick={() => {
                    const { policyGuid, policyTypeId, displayName } =
                      selectedPolicy

                    const selTenants = tenants.filter(t =>
                      selectedTenantsWithoutPolicyIds.some(
                        e => t.clientTenantId === e
                      )
                    )

                    onDeployPolicy({
                      baseline: baselineTenant,
                      tenants: selTenants,
                      policy: {
                        policyGuid,
                        policyTypeId,
                        policyName: displayName,
                      },
                      onDone: () => {
                        setSelectedTenantWithoutPolicyIds([])
                      },
                    })
                  }}
                >
                  Align {selectedTenantsWithoutPolicyIds.length} Tenants to
                  Baseline
                </button>
              </div>
            </div>
            <div className='alignment-results-list'>
              {tenantsWithoutPolicy.length === 0 ? (
                comparisonLoading ? (
                  <div className='flex items-center justify-center h-[200px] p-4'>
                    <div id='loading-spinner' className='mt-4'>
                      <div className='loading' />
                    </div>
                  </div>
                ) : (
                  <div className='flex items-center justify-center h-[100%] p-4'>
                    No results found
                  </div>
                )
              ) : (
                tenantsWithoutPolicy.map(tenant => (
                  <CollapsibleCheckboxCard
                    key={tenant.clientTenantId}
                    onChange={checked =>
                      toggleSelectedTenantWithoutPolicy(
                        tenant.clientTenantId,
                        checked
                      )
                    }
                    checked={selectedTenantsWithoutPolicyIds.includes(
                      tenant.clientTenantId
                    )}
                    forceOpen={collapsedOverride}
                    cardTitle={
                      <div className='w-[100%] flex justify-between items-center'>
                        <b>{tenant.tenantFriendlyName}</b>
                        <div className='flex items-center ml-4'>
                          <TenantAlignmentIcon
                            score={0}
                            alignedThreshold={selectedBaseline.alignedThreshold}
                            semiAlignedThreshold={
                              selectedBaseline.semiAlignedThreshold
                            }
                          />
                        </div>
                      </div>
                    }
                    cardActions={
                      <div className='text-right'>
                        <button
                          type='button'
                          className='btn text-xs navy-btn'
                          onClick={() =>
                            viewPolicy(
                              baselineTenant.clientTenantId,
                              baselineTenant.tenantFriendlyName,
                              policyGuid,
                              policyTypeId,
                              policyTypeName,
                              selectedPolicy.displayName
                            )
                          }
                        >
                          View Baseline Policy&nbsp;&nbsp;
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </button>
                        <button
                          disabled={disableTenantCheckbox(tenant)}
                          type='button'
                          className='btn cyan-btn text-xs ml-1'
                          onClick={() => {
                            const {
                              policyGuid,
                              policyTypeId,
                              displayName,
                              priority,
                            } = selectedPolicy
                            onDeployPolicy({
                              baseline: baselineTenant,
                              tenant,
                              policy: {
                                policyGuid,
                                policyTypeId,
                                policyName: displayName,
                                priority,
                              },
                              onDone: () => {
                                setSelectedTenantWithoutPolicyIds([])
                              },
                            })
                          }}
                        >
                          Deploy Policy
                        </button>
                      </div>
                    }
                    disabled={disableTenantCheckbox(tenant)}
                  />
                ))
              )}
            </div>
          </>
        ) : null}
      </>
    )
  }
  return (
    <>
      <div className='alignment-search-panel'>
        {tenantsIsError && (
          <Alert type='error' margin='mb-4' title='Error retrieving tenants'>
            <ul>
              {getTenantsError.response.data.errors?.map(error => (
                <li>{error}</li>
              ))}
            </ul>
          </Alert>
        )}
        {policyLoadError && (
          <Alert
            type='error'
            margin='mb-4'
            title='Error retrieving comparison results'
          >
            {policyLoadError}
          </Alert>
        )}
        <div className='flex items-center mb-4'>
          <label id='baselineGroupFilter' className='w-[200px] mr-2'>
            <b>Baseline Group</b>
          </label>
          <div
            aria-labelledby='baselineGroupFilter'
            className='w-[100%] focus:outline-blue-400 rounded-md text-gray-700 text-left'
          >
            <Select
              options={tenantBaselines.map(baseline => ({
                label: baseline.name,
                value: baseline.clientTenantGroupId,
              }))}
              value={{
                label: selectedBaseline?.name,
                value: selectedBaseline?.clientTenantGroupId,
              }}
              onChange={selectedOption =>
                setTenantBaselineId(selectedOption.value)
              }
            />
          </div>
        </div>
        <div className='flex items-center mb-4'>
          <label id='sortByFilter' className='w-[200px] mr-2'>
            <b>Sort by</b>
          </label>
          <div
            aria-labelledby='sortByFilter'
            className='w-[100%] focus:outline-blue-400 rounded-md text-gray-700 text-left'
          >
            <Select
              value={selectedPolicySort}
              options={alignmentPolicySortOptions}
              onChange={setSelectedPolicySort}
            />
          </div>
        </div>
        <div className='flex items-center'>
          <label id='policyTypeFilter' className='w-[200px] mr-2'>
            <b>Filter by policy type</b>
          </label>
          <div
            aria-labelledby='policyTypeFilter'
            className='w-[100%] focus:outline-blue-400 rounded-md text-gray-700 text-left'
          >
            <Select
              placeholder='No Filter'
              value={selectedPolicyTypeFilter}
              options={availablePolicyTypes.map(policyType => ({
                label: policyType,
                value: policyType,
              }))}
              onChange={setSelectedPolicyTypeFilter}
              styles={colourStyles}
              isMulti
              closeMenuOnSelect={false}
            />
          </div>
        </div>
        <div className='flex items-center mb-6'>
          <Checkbox
            checked={useLiveData}
            onChange={setUseLiveData}
            label={
              <div className='pt-6'>
                <b>Use live baseline data</b>
                <br />
                <span className='text-xs'>
                  May take significantly longer to process
                </span>
              </div>
            }
          />
        </div>
        <div className='flex items-center justify between mb-6'>
          <div className='relative max-md:w-full mr-4 flex items-center '>
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className='absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-700 z-1'
            />
            <input
              className='flex-grow focus:outline-blue-400 w-full rounded-md light-grey-bg p-2 pl-10 text-gray-700'
              type='text'
              placeholder='Search'
              aria-label='Search'
              value={policySearch}
              onChange={e => setPolicySearch(e.target.value)}
            />
            {policySearch && (
              <button
                type='button'
                className='absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-700 font-bold'
                onClick={() => setPolicySearch('')}
              >
                &#10005;
              </button>
            )}
          </div>
          <button
            className='btn cyan-btn flex items-center'
            disabled={!selectedBaseline}
            onClick={() => {
              clearPolicyComparisonResults()
              setSelectedPolicyId(undefined)
              loadTenantPolicies()
            }}
          >
            <FontAwesomeIcon icon={faArrowsRotate} className='mr-2' />
            Reload
          </button>
        </div>
        <div className='flex flex-col gap-1 pr-1 rounded-sm overflow-y-scroll max-h-[50vh]'>
          {policyListLoading || tenantsLoading ? (
            <div className='flex items-center justify-center h-[200px] p-4'>
              <div id='loading-spinner' className='mt-4'>
                <div className='loading' />
              </div>
            </div>
          ) : (
            policySearchResults.map(policy => (
              <AlignmentPolicy
                active={policy.policyGuid === selectedPolicyId}
                policy={policy}
                key={policy.policyGuid}
                onClick={() => {
                  setSelectedTenantWithoutPolicyIds([])
                  setSelectedPolicyId(policy.policyGuid)
                  setSelectedPolicyTypeId(policy.policyTypeId)
                }}
              />
            ))
          )}
        </div>
      </div>
      <div className='alignment-content-panel flex-grow'>
        {buildPolicyContent()}
      </div>
      <RenamePolicyPopup
        open={!!policyRenameData}
        loading={isPolicyRenaming}
        error={policyRenameError}
        baselinePolicyName={
          policyRenameData?.baselinePolicy?.displayName ??
          policyRenameData?.baselinePolicy?.name
        }
        targetPolicyName={policyRenameData?.targetPolicy?.policyName}
        onClose={() => setPolicyRenameData(null)}
        onConfirmRename={() =>
          renamePolicy(
            policyRenameData.baselineClientTenantId,
            policyRenameData.subjectClientTenantId,
            policyRenameData.targetPolicy,
            policyRenameData.baselinePolicy,
            () => setPolicyRenameData(null)
          )
        }
      />
    </>
  )
}

export default AlignmentByPolicy
