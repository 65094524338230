import { bool, func, node } from 'prop-types'
import CollapsibleCard from './CollapsibleCard'
import Checkbox from '../Checkbox'

/**
 * @param {{
 *  cardTitle?: React.ReactNode,
 *  cardContent?: React.ReactNode,
 *  cardActions?: React.ReactNode,
 *  checked: boolean,
 *  forceOpen?: boolean,
 *  onChange?: (boolean) => void,
 * }} props
 */
const CollapsibleCheckboxCard = ({
  cardTitle,
  cardContent,
  cardActions,
  checked,
  forceOpen,
  onChange,
  disabled,
}) => (
  <CollapsibleCard
    cardTitle={
      <div className='flex'>
        <div
          className='rounded-[50%] h-[44px] w-[44px] mr-3 bg-white'
          onClick={e => e.stopPropagation()}
        >
          <Checkbox
            checked={checked}
            onChange={val => onChange?.(val)}
            disabled={disabled}
          />
        </div>
        {cardTitle}
      </div>
    }
    cardContent={cardContent}
    cardActions={cardActions}
    forceOpen={forceOpen}
  />
)

CollapsibleCheckboxCard.defaultProps = {
  cardTitle: undefined,
  cardContent: undefined,
  cardActions: undefined,
  checked: false,
  forceOpen: false,
  onChange: undefined,
}

CollapsibleCheckboxCard.propTypes = {
  cardTitle: node,
  cardContent: node,
  cardActions: node,
  checked: bool,
  forceOpen: bool,
  onChange: func,
}

export default CollapsibleCheckboxCard
