import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const ssoUserQueryKey = 'sso-users'

const useGetSSOUsersQuery = () =>
  useQuery({
    queryKey: [ssoUserQueryKey],
    gcTime: 0,
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/sso/users`
      )
      return response.data
    },
  })

export { useGetSSOUsersQuery, ssoUserQueryKey }
