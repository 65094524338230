import { func } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

const TenantTagsMenuActions = ({ openModal }) => (
  <div className='flex flex-col space-y-4 p-3' role='none'>
    <button
      type='button'
      onClick={() => openModal()}
      className='flex flex-row items-center'
    >
      <FontAwesomeIcon
        icon={faPlusCircle}
        size='lg'
        className='opacity-75 mr-2'
      />
      Manage Tags
    </button>
  </div>
)

TenantTagsMenuActions.propTypes = {
  openModal: func.isRequired,
}

export default TenantTagsMenuActions
