import Axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

/**
 * Fetches the SSO Configuration for this user
 * @param onDataChange a function to call when the data changes. It's important to note that this is only
 * used like this because we need to sync the form state with the data from the server. This is not an
 * encouraged use case for react-query
 */
const useGetSSOConfig = onDataChange => {
  const ssoConfigQuery = useQuery({
    queryKey: ['sso-config'],
    gcTime: 0,
    queryFn: async () => {
      const response = await Axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/sso/configuration`
      )
      return response.data
    },
  })

  useEffect(() => {
    if (ssoConfigQuery.data) {
      onDataChange(ssoConfigQuery.data)
    }
  }, [onDataChange, ssoConfigQuery.data])

  return ssoConfigQuery
}
export default useGetSSOConfig
