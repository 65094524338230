const defaultError =
  'An error occurred attempting to authenticate. Please try again.'
const errCodeMap = {
  SSO_PROVIDER_FETCH_FAILED: 'Failed to fetch SSO provider. Please try again.',
  SSO_EMAIL_REQUIRED: 'A valid email address is required to sign in with SSO.',
  SSO_MFA_REQUIRED:
    'Multi-factor authentication is required to sign in with SSO. Please check your Microsoft configuration.',
  SSO_UNKNOWN_USER: defaultError,
  SSO_CONFIGURATION_ERR: defaultError,
  SSO_INVALID_RELAY_STATE: defaultError,
}

/**
 * Get the error message for a given SSO error code
 * @param {string} errorCode - The error code to get the message for
 * @returns {string} The error message for the given error code
 */
const getSSOError = errorCode => {
  if (errorCode === undefined || errorCode === null) {
    return null
  }
  return errCodeMap[errorCode] ?? defaultError
}

export default getSSOError
