import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const BackUpCommentPicker = ({
  dates,
  selectedComment,
  setSelectedComment,
}) => {
  const dateClick = (key, value) => {
    if (selectedComment && selectedComment.key === key) {
      setSelectedComment({ key: null, value: '' }) // Unselect the date
    } else {
      const updatedComment = { key, ...value } // Spread to capture all properties of value
      setSelectedComment(updatedComment) // Select the clicked date
    }
  }

  return (
    <div>
      <div className='table-container'>
        <table>
          <tbody>
            <tr className='table-head'>
              <td>Backup Name</td>
              <td aria-label='Is Selected' />
            </tr>
            {Object.entries(dates).map(([key, value]) => (
              <tr
                key={key}
                onClick={() => dateClick(parseInt(key), value)}
                className={`date-row cursor-pointer ${
                  selectedComment && selectedComment.key === parseInt(key)
                    ? 'selected'
                    : ''
                }`}
              >
                <td className='date-value'>
                  {value?.Comment || 'No backup name found'}
                </td>
                <td className='check-cell'>
                  {selectedComment && selectedComment.key === parseInt(key) ? (
                    <FontAwesomeIcon icon={faCheckCircle} className='ml-2' />
                  ) : null}
                </td>
              </tr>
            ))}
            {Object.keys(dates).length === 0 && (
              <tr>
                <td colSpan='3'>No backups found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BackUpCommentPicker
