import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

const useAcceptDeviation = (onSuccess, onError) =>
  useMutation({
    mutationFn: async data => {
      const response = await axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/deviationAcceptance`,
        { data }
      )

      return response.data.data
    },
    onSuccess: data => {
      onSuccess(data)
    },
    onError,
  })

export default useAcceptDeviation
