import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import TrackChangesEmailInputs from '../../components/TrackChangesEmailInputs/TrackChangesEmailInputs'
import TabSelector from '../../components/TabSelector'
import BaselineManager from '../../components/BaselineGroups/components/BaselineManager'
import Checkbox from '../../components/Checkbox'

import TenantManagementSkeleton from '../../components/LoadingSkeletons/TenantManagementSkeleton'

import useGetListTenants from '../../hooks/useGetListTenants'
import TenantTagsManagementTable from '../../components/TenantTags/TenantManagementTable'
import Alert from '../../components/Alert/Alert'

const tabOptions = [
  {
    name: 'Tenants',
    value: 'tenants',
  },
  {
    name: 'Baselines',
    value: 'baselines',
  },
]

const ManageTenants = () => {
  const [params, setSearchParams] = useSearchParams()

  const selectedTab = params.get('tab') || 'tenants'

  const {
    data: listTenants = [],
    isLoading: isListTenantsLoading,
    isError: isListTenantsError,
  } = useGetListTenants()

  const [partnerCenterOnly, setPartnerCenterOnly] = useState(false)

  const filteredTenants = useMemo(() => {
    if (!partnerCenterOnly) return listTenants
    if (!listTenants) return []
    return listTenants?.filter(e => e?.partnerCenter)
  }, [listTenants, partnerCenterOnly])

  const headerText =
    selectedTab === 'tenants' ? 'Manage Tenants' : 'Manage Baselines'

  if (isListTenantsLoading) {
    return (
      <div className='ui-panel tenant-management pt-6'>
        <TenantManagementSkeleton />
      </div>
    )
  }

  if (isListTenantsError) {
    return (
      <div className='ui-panel tenant-management'>
        <Alert title='Error' alert='error' margin='my-6'>
          <p>There was an error loading the tenants.</p>
        </Alert>
      </div>
    )
  }

  return (
    <div className='ui-panel tenant-management'>
      <div className='heading'>
        <div className='flex justify-between flex-wrap'>
          <h2 className='mr-4'>{headerText}</h2>
          <TabSelector
            value={selectedTab}
            onChange={newTab => setSearchParams({ tab: newTab })}
            options={tabOptions}
          />
        </div>
      </div>
      <div className='action-body no-progress-bar'>
        {selectedTab === 'tenants' && (
          <>
            <TrackChangesEmailInputs />
            <h4 className='pt-4'>
              <b>Tenants enrolled: {listTenants.length}</b>
            </h4>

            <div className='mt-4'>
              <Checkbox
                id='partnerCenterOnlyCheckbox'
                checked={partnerCenterOnly}
                label='Show Partner Center tenants only'
                onChange={setPartnerCenterOnly}
              />
            </div>
            <TenantTagsManagementTable tenants={filteredTenants} />
          </>
        )}

        {selectedTab === 'baselines' && <BaselineManager />}
      </div>
    </div>
  )
}

export default ManageTenants
