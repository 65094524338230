import { arrayOf, number, string } from 'prop-types'
import { useMemo } from 'react'

import Skeleton from 'react-loading-skeleton'
import TagsPopover from '../../TagsPopover/TagsPopover'
import Tag from '../../Tag/Tag'
import { hexToRgb } from '../../../utils/colours'
import usePolicyTags from '../api/usePolicyTags'
import ErrorHandlerAlert from '../../ErrorHandlerAlert/ErrorHandlerAlert'

const PolicyTags = ({ linkedPolicyTagIds, numVisibleTags }) => {
  const {
    data: policyTags,
    isLoading: isLoadingPolicyTags,
    isError: isPolicyTagsError,
    error: isPolicyTagsErrors,
  } = usePolicyTags()

  const tags = useMemo(
    () => policyTags?.filter(tag => linkedPolicyTagIds.includes(tag.id)) || [],
    [policyTags, linkedPolicyTagIds]
  )
  const visibleTags = useMemo(
    () => tags.slice(0, numVisibleTags),
    [tags, numVisibleTags]
  )
  const hiddenTags = useMemo(
    () => tags.slice(numVisibleTags),
    [tags, numVisibleTags]
  )

  if (isLoadingPolicyTags && !isPolicyTagsError) {
    return <Skeleton count={1} />
  }

  if (isPolicyTagsError) {
    return (
      <ErrorHandlerAlert
        error={isPolicyTagsErrors}
        title='Error fetching policy tags'
      />
    )
  }

  return (
    <div className='flex flex-wrap pt-2'>
      {visibleTags.map(tag => (
        <Tag
          key={tag.id}
          text={tag.tag}
          description={tag.description}
          colourRGB={hexToRgb(tag.colour)}
        />
      ))}
      <div className='pt-0.5'>
        <TagsPopover tags={hiddenTags} />
      </div>
    </div>
  )
}

PolicyTags.defaultProps = {
  numVisibleTags: 4,
  linkedPolicyTagIds: [],
}

PolicyTags.propTypes = {
  linkedPolicyTagIds: arrayOf(string),
  numVisibleTags: number,
}

export default PolicyTags
