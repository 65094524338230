import PolicyTagsActions from '../PolicyTagsActions'
import Tag from '../../Tag/Tag'
import { hexToRgb } from '../../../utils/colours'

const tableColumnDefinitions = ({ handleEditTag, setIsContentPanelOpen }) => [
  {
    header: 'Tag',
    accessor: 'tag',
    cell: ({ row }) => (
      <Tag
        text={row.original.tag}
        description={row.original.description}
        colourRGB={hexToRgb(row.original.colour)}
      />
    ),
  },
  {
    header: 'Description',
    accessor: 'description',
  },
  {
    header: 'Actions',
    accessor: 'actions',
    enableColumnFilter: false,
    cell: ({ row }) => (
      <PolicyTagsActions
        id={row.original.id}
        tag={row.original.tag}
        description={row.original.description}
        colour={row.original.colour}
        onEdit={handleEditTag}
        onSuccess={setIsContentPanelOpen}
      />
    ),
  },
]

export default { tableColumnDefinitions }
