import Select, { components } from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faSort } from '@fortawesome/free-solid-svg-icons'
import { arrayOf, func, node, string } from 'prop-types'
import { useFeatureFlag } from 'configcat-react'
import {
  alignmentPolicySortOptions,
  alignmentStatusFilterOptionsFeatureFlagged,
} from '../../config/TenantAlignmentConfig'
import colourStyles from '../../config/ReactSelectColours'
import { selectValueShape } from '../../utils/propTypes/tenantAlignmentProps'

const FilterControl = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.Control {...props}>
    <FontAwesomeIcon icon={faFilter} />
    {children}
  </components.Control>
)
FilterControl.propTypes = {
  children: node.isRequired,
}

const SortControl = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.Control {...props}>
    <FontAwesomeIcon icon={faSort} />
    {children}
  </components.Control>
)
SortControl.propTypes = {
  children: node.isRequired,
}

const PolicyFilters = ({
  policyTypes,
  selectedPolicySort,
  setSelectedPolicySort,
  selectedPolicyTypeFilter,
  setSelectedPolicyTypeFilter,
  policyAlignmentStatusFilter,
  setPolicyAlignmentStatusFilter,
}) => {
  const { value: enableClientVariables } = useFeatureFlag(
    'enableClientVariables',
    false
  )

  const { value: enableDeviationDrift } = useFeatureFlag(
    'deviationDrift',
    false
  )

  const alignmentStatusFilterOptions =
    alignmentStatusFilterOptionsFeatureFlagged(
      enableDeviationDrift,
      enableClientVariables
    )

  return (
    <div className='flex flex-col lg:flex-row flex-wrap gap-3 w-full py-4'>
      <Select
        isMulti={false}
        value={selectedPolicySort}
        options={alignmentPolicySortOptions}
        onChange={setSelectedPolicySort}
        className='w-full xl:w-auto max-lg:text-sm'
        placeholder='Sort by'
        closeMenuOnSelect
        components={{ Control: SortControl }}
        menuPortalTarget={document.body}
        styles={{
          ...colourStyles,
          control: css => ({ ...css, paddingLeft: '1rem' }),
        }}
      />

      <Select
        isMulti
        value={selectedPolicyTypeFilter}
        options={policyTypes.map(policyType => ({
          label: policyType,
          value: policyType,
        }))}
        className='w-full xl:flex-1 max-lg:text-sm'
        onChange={setSelectedPolicyTypeFilter}
        placeholder='Policy Types'
        components={{ Control: FilterControl }}
        menuPortalTarget={document.body}
        closeMenuOnSelect={false}
        styles={{
          ...colourStyles,
          control: css => ({ ...css, paddingLeft: '1rem' }),
        }}
      />

      <Select
        isMulti
        value={policyAlignmentStatusFilter}
        options={alignmentStatusFilterOptions}
        onChange={setPolicyAlignmentStatusFilter}
        className='w-full xl:flex-1 max-lg:text-sm'
        placeholder='Alignment Status'
        components={{ Control: FilterControl }}
        menuPortalTarget={document.body}
        closeMenuOnSelect={false}
        styles={{
          ...colourStyles,
          control: css => ({ ...css, paddingLeft: '1rem' }),
        }}
      />
    </div>
  )
}

PolicyFilters.defaultProps = {
  policyTypes: [],
}

PolicyFilters.propTypes = {
  policyTypes: arrayOf(string),
  selectedPolicySort: selectValueShape.isRequired,
  setSelectedPolicySort: func.isRequired,
  selectedPolicyTypeFilter: selectValueShape.isRequired,
  setSelectedPolicyTypeFilter: func.isRequired,
  policyAlignmentStatusFilter: selectValueShape.isRequired,
  setPolicyAlignmentStatusFilter: func.isRequired,
}

export default PolicyFilters
