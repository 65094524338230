import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import HeadingPanel from './HeadingPanel'
import Alert from '../../Alert/Alert'
import useGetBaselineGroup from '../api/admin/useGetBaselineGroup'
import SharedBaselinesGroupSkeleton from './SharedBaselinesGroupSkeleton'
import SubscribersTable from './SubscribersTable'
import ModalActionButtons from '../../Modal/ModalActionButtons'
import useUpdateSubscribers from '../api/useUpdateSubscribers'
import extractErrorFromResponse from '../../../utils/extractErrorFromResponse'

const ManageSubscribers = () => {
  const { baselineGroupId } = useParams()
  const [selectedMembers, setSelectedMembers] = useState(undefined)

  const navigate = useNavigate()

  const getBaselineGroup = useGetBaselineGroup(baselineGroupId)

  const updateSubscribers = useUpdateSubscribers(() =>
    toast.success(
      `Successfully updated subscribers for ${getBaselineGroup.data.data.name}`
    )
  )

  const columnConfig = [
    {
      header: 'Member Client Id',
      accessor: 'memberClientId',
      sx: { width: '25%', borderLeft: 'none', borderRight: 'none' },
    },
    {
      header: 'Member Name',
      accessor: 'memberClientDisplayName',
      sx: { width: '75%', borderLeft: 'none', borderRight: 'none' },
    },
  ]

  if (getBaselineGroup.isLoading) {
    return (
      <div className='shared-baselines-page md:container mx-auto'>
        <div className='flex items-center shadow-sm bg-white w-full rounded-xl mb-6 py-8 px-10'>
          <SharedBaselinesGroupSkeleton />
        </div>
      </div>
    )
  }

  if (getBaselineGroup.isError) {
    const { message, errors } = extractErrorFromResponse(getBaselineGroup.error)
    return (
      <div className='flex items-center shadow-sm bg-white w-full rounded-xl mb-6 py-8 px-10'>
        <Alert
          type='error'
          title={message || 'Unexpected Error Updating Subscribers'}
          margin='mb-4'
        >
          <ul>
            {errors.map(error => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </Alert>
      </div>
    )
  }

  const { availableToAllClients } = getBaselineGroup.data.data

  return (
    <div className='shared-baselines-page manage-subscribers md:container mx-auto'>
      <HeadingPanel
        heading={`Manage Subscribers for: ${getBaselineGroup.data.data.name}`}
        subHeading='Manage subscribers to published baselines'
      />
      {updateSubscribers.isError && (
        <div className='flex items-center shadow-sm bg-white w-full rounded-xl mb-6 py-8 px-10'>
          <Alert
            type='error'
            title={
              extractErrorFromResponse(updateSubscribers.error).message ||
              'Unexpected Error Updating Subscribers'
            }
            margin='mb-4'
          >
            {extractErrorFromResponse(updateSubscribers.error).errors.map(
              error => (
                <ul key={error}>
                  <li>{error}</li>
                </ul>
              )
            )}
          </Alert>
        </div>
      )}

      {availableToAllClients && (
        <div className='flex items-center shadow-sm bg-white w-full rounded-xl mb-6 py-8 px-10'>
          <div className='py-8'>
            <h2 className='text-2xl opacity-80'>
              This Shared Baseline Group is available to all clients
            </h2>
            <button
              type='button'
              className='btn cyan-btn mt-4'
              onClick={() => navigate('/admin-portal/shared-baselines-manage')}
            >
              Back
            </button>
          </div>
        </div>
      )}

      {!availableToAllClients &&
        getBaselineGroup.data.data.publishedBaselines.map(
          ({
            publishedBaselineTenantId,
            publishedBaselineTenantDisplayName,
          }) => (
            <div
              className='py-2 px-6 bg-white'
              key={`${publishedBaselineTenantDisplayName}-${publishedBaselineTenantId}`}
            >
              <div className='py-2'>
                <h2 className='text-2xl'>
                  {publishedBaselineTenantDisplayName}
                </h2>
                <SubscribersTable
                  onChange={setSelectedMembers}
                  uniqueKey='memberClientId'
                  columns={columnConfig}
                  data={getBaselineGroup.data.data.members}
                  loading={getBaselineGroup.isLoading}
                  initialSubscribers={getBaselineGroup.data.data.subscribers.filter(
                    subscriber =>
                      subscriber.publishedBaselineTenantId ===
                      publishedBaselineTenantId
                  )}
                  publishedBaselineTenantId={publishedBaselineTenantId}
                />
              </div>
            </div>
          )
        )}
      {!availableToAllClients && (
        <div className='bg-white sticky px-6 py-2 bottom-[-20px] z-20'>
          <ModalActionButtons
            cancelActionText='Back'
            onCancel={() => navigate('/admin-portal/shared-baselines-manage')}
            confirmActionText='Submit'
            confirmEnabled={!updateSubscribers.isPending}
            cancelEnabled={!updateSubscribers.isPending}
            onConfirm={() =>
              updateSubscribers.mutate({
                baselineGroupId,
                subscriptions: selectedMembers,
              })
            }
          />
        </div>
      )}
    </div>
  )
}

export default ManageSubscribers
