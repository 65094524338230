import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const usePolicyTags = () =>
  useQuery({
    queryKey: ['policyTags'],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/policyTags`
      )
      return response.data
    },
  })

export default usePolicyTags
