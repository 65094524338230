/* eslint-disable react/prop-types */
import {
  faCheckCircle,
  faCircleMinus,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const tableColumnOrder = ['displayName', 'clientAdmin', 'isSSO', 'enabled']

const tableColumnDefinitions = () => [
  {
    header: 'Display Name',
    accessorKey: 'displayName',
    size: '100',
    filterVariant: 'autocomplete',
    Header: () => (
      <div>
        <strong>Display Name</strong>
        <p className='font-normal text-sm opacity-75'>
          User&apos;s display name
        </p>
      </div>
    ),
    Cell: ({ renderedCellValue, row }) => (
      <div>
        <FontAwesomeIcon icon={faUserCircle} size='lg' />
        <strong className='ml-2'>{renderedCellValue}</strong>
        <p className='text-sm opacity-75'>{row.original.username}</p>
      </div>
    ),
  },
  {
    header: 'Client Admin',
    accessorKey: 'clientAdmin',
    filterVariant: 'checkbox',
    size: '100',
    Cell: ({ cell }) => (
      <div className='flex justify-center'>
        <FontAwesomeIcon
          icon={cell.getValue() ? faCheckCircle : faCircleMinus}
          color={cell.getValue() ? 'rgba(var(--navy), 0.9)' : 'grey'}
          size='xl'
        />
      </div>
    ),
  },
  {
    header: 'SSO Enabled?',
    accessorKey: 'isSSO',
    filterVariant: 'checkbox',
    size: '100',
    Cell: ({ cell }) => (
      <div className='flex justify-center'>
        <FontAwesomeIcon
          icon={cell.getValue() ? faCheckCircle : faCircleMinus}
          color={cell.getValue() ? 'rgba(var(--navy), 0.9)' : 'grey'}
          size='xl'
        />
      </div>
    ),
  },
  {
    header: 'Enabled?',
    accessorKey: 'enabled',
    filterVariant: 'checkbox',
    size: '100',
    Cell: ({ cell }) => (
      <div className='flex justify-center'>
        <FontAwesomeIcon
          icon={cell.getValue() ? faCheckCircle : faCircleMinus}
          color={cell.getValue() ? 'rgba(var(--navy), 0.9)' : 'grey'}
          size='xl'
        />
      </div>
    ),
  },
]

export { tableColumnDefinitions, tableColumnOrder }
