import { useState } from 'react'
import { bool, func, oneOf, shape, string } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faLock } from '@fortawesome/free-solid-svg-icons'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const VariableFormTextInput = ({
  register,
  errors,
  label,
  subLabel,
  type,
  typeDisplay,
  placeholder,
  builtIn,
  isLoading,
}) => {
  const registerProps = register()

  const [show, setShow] = useState(false)

  const handleKeyDown = e => {
    if (e.key === 'Enter' || e.key === ' ') {
      setShow(!show)
    }
  }

  return (
    <div className='flex flex-col'>
      <span className='mb-2'>
        <label htmlFor={label}>
          <code className='bg-[#f1f1f1] rounded-sm p-1 }'>{label}</code>
        </label>
        {builtIn && (
          <FontAwesomeIcon
            icon={faLock}
            className='text-gray-500 ml-1'
            title='built-in'
          />
        )}
        <span className='text-sm text-gray-500 pl-2'>
          ({type === 'password' ? `hidden ${typeDisplay}` : typeDisplay})
        </span>
      </span>
      <span className='text-sm text-gray-500'>{subLabel}</span>

      <div className='relative'>
        {isLoading ? (
          <Skeleton height={40} width='100%' />
        ) : (
          <>
            <input
              type={show ? 'text' : type}
              id={label}
              {...registerProps}
              placeholder={placeholder}
              disabled={builtIn}
              readOnly={builtIn}
              className={`flex w-full rounded border p-2 bg-white text-gray-700 focus:outline-blue-400 ${errors ? 'border-red-500 focus:outline-red-500' : 'border-gray-400'}`}
              autoComplete={`${type === 'password' ? 'new-password' : 'off'}`}
            />
            {type === 'password' && (
              <span
                className='absolute inset-y-0 right-0 flex items-center pr-2'
                onClick={() => setShow(!show)}
                onKeyDown={handleKeyDown}
                role='button'
                tabIndex={0}
                title='Reveal Hidden Value'
              >
                <FontAwesomeIcon
                  icon={show ? faEyeSlash : faEye}
                  className='text-gray-500 cursor-pointer'
                />
              </span>
            )}
          </>
        )}
      </div>

      {errors && <p className='text-red-500'>{errors.message}</p>}
    </div>
  )
}

VariableFormTextInput.defaultProps = {
  errors: undefined,
  label: '',
  type: 'text',
  typeDisplay: 'string',
  placeholder: '',
  subLabel: '',
  builtIn: false,
  isLoading: false,
}

VariableFormTextInput.propTypes = {
  register: func.isRequired,
  errors: shape({
    message: string,
  }),
  label: string,
  type: oneOf(['text', 'email', 'password']),
  typeDisplay: string,
  placeholder: string,
  subLabel: string,
  builtIn: bool,
  isLoading: bool,
}

export default VariableFormTextInput
