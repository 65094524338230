import Select from 'react-select'
import { arrayOf, func, shape, string } from 'prop-types'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { useMemo } from 'react'
import customStyles from '../utils/config/ReactSelect/CustomStyles'
import SortControl from '../../../CustomTags/SortControl'
/**
 * FilterDropdown component
 * @param {array} selectedOptions - The currently selected options
 * @param {function} setSelectedOptions - The function to set the selected options
 * @param {array} options - The available options
 * @returns {JSX.Element} The FilterDropdown component
 */
const FilterDropdown = ({ selectedOptions, setSelectedOptions, options }) => {
  const handleChange = newSelectedOptions => {
    setSelectedOptions(newSelectedOptions)
  }

  const customComponents = useMemo(
    () => ({
      // eslint-disable-next-line react/no-unstable-nested-components
      Control: props => <SortControl {...props} icon={faFilter} />,
    }),
    []
  )

  return (
    <Select
      isMulti
      placeholder='Group'
      value={selectedOptions}
      onChange={handleChange}
      options={options}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isClearable
      components={customComponents}
      styles={customStyles('Group', selectedOptions)}
    />
  )
}

const optionShape = shape({
  group: string.isRequired,
  value: string.isRequired,
  label: string.isRequired,
})

FilterDropdown.propTypes = {
  selectedOptions: arrayOf(optionShape).isRequired,
  setSelectedOptions: func.isRequired,
  options: arrayOf(
    shape({
      label: string,
      options: arrayOf(optionShape),
    })
  ).isRequired,
}

export default FilterDropdown
