import { object, string } from 'prop-types'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued'
import './PolicyDiffViewer.css'

const PolicyDiffViewer = ({
  policyLeft,
  policyRight,
  leftTitle,
  rightTitle,
  policyDiffTitle,
}) => (
  <>
    <div className='policy-diff-title mb-4'>
      <h3>{policyDiffTitle}</h3>
    </div>
    <div className='policy-diff-table'>
      <ReactDiffViewer
        oldValue={policyLeft}
        newValue={policyRight}
        leftTitle={leftTitle}
        rightTitle={rightTitle}
        showDiffOnly={false}
        compareMethod={DiffMethod.JSON}
      />
    </div>
  </>
)

export default PolicyDiffViewer

PolicyDiffViewer.propTypes = {
  policyLeft: object.isRequired,
  policyRight: object.isRequired,
  leftTitle: string.isRequired,
  rightTitle: string.isRequired,
  policyDiffTitle: string.isRequired,
}
