const customStyles = (header, selectedOptions) => ({
  placeholder: baseStyles => ({
    ...baseStyles,
    fontSize: '0.85rem',
    fontWeight: 'bold',
    color: '#575757',
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: '0.125rem',
    boxShadow: '0 0 1px 0px #f5f5f5',
    backgroundColor: state.hasValue ? '#e7f3fb' : '#f5f5f5', // Change background color when selected
    borderColor: state.isFocused ? '#1f2937' : '#d1d5db',
    border: 'none',
    width: '140px',
  }),

  option: (provided, state) => ({
    ...provided,
    fontSize: '0.75rem', // Reduced font size for options
    padding: '8px 12px', // Adjust padding to fit smaller text
    backgroundColor: state.isSelected ? '#e7f3fb' : '#fff', // Change background color when selected
    color: state.isSelected ? '#0056b3' : 'inherit',
  }),
  menu: provided => ({
    ...provided,
    fontSize: '14px', // Keep dropdown text normal-sized
    borderRadius: '4px',
    zIndex: 999, // Ensure dropdown appears above other elements
    width: '200px',
  }),
  menuList: provided => ({
    ...provided,
    maxHeight: '200px', // Keep dropdown size unchanged
  }),
  groupHeading: provided => ({
    ...provided,
    fontSize: '0.75rem', // Adjusted heading font size
  }),
  multiValue: () => ({
    display: 'none', // Hide individual selected items
  }),
  multiValueRemove: provided => ({
    ...provided,
    color: '#fff',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#0056b3',
      color: '#fff',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none', // Removes the vertical separator
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 4px',
    marginLeft: '2px',
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#434141',
    justifyContent: 'flex-start',
    ':before':
      selectedOptions.length > 0
        ? {
            content: `"${header} (${selectedOptions.length})"`, // Show only when items are selected
            position: 'absolute',
            left: '4px',
            color: selectedOptions.length > 0 ? '#178bdb' : '#706e6e',
            fontSize: '0.85rem',
            fontWeight: 'bold',
          }
        : {},
  }),
  dropdownIndicator: provided =>
    selectedOptions.length > 0
      ? { display: 'none' }
      : {
          ...provided,
          color: selectedOptions.length > 0 ? '#fff' : '#cccccc',
        },
  clearIndicator: provided => ({
    ...provided,
    color: '#178bdb', // Change the color of the Clear All button
    ':hover': {
      color: 'rgba(23,139,219,0.84)', // Change color on hover
    },
  }),
})

export default customStyles
