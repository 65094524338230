import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { toast } from 'react-toastify'

const useCreateOrUpdatePolicyTag = (onSuccess, isUpdate = false) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: tagData => {
      if (isUpdate) {
        return axios.put(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/policyTags/${tagData.id}`,
          tagData
        )
      }
      return axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/policyTags`,
        tagData
      )
    },
    onSuccess: () => {
      toast.success(
        `Policy tag ${isUpdate ? 'updated' : 'created'} successfully!`
      )
      queryClient.invalidateQueries(['policyTags'])
      onSuccess()
    },
  })
}

export default useCreateOrUpdatePolicyTag
