import { arrayOf, func, number, object, oneOf, shape, string } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import ModalActionButtons from '../Modal/ModalActionButtons'
import DeployPolicyDialogHeader from '../PolicyDeployment/DeployPolicyDialogHeader'
import { steps } from './utils'
import ReviewSummaryContent from '../PolicyDeployment/ReviewSummaryContent'
import { exchangePolicyIds } from '../../utils/Policy'
import { summaryTenantShape } from '../../utils/propTypes/tenantAlignmentProps'
import SelectedVariablesSummary from '../ClientVariables/SelectedVariablesSummary'
import { selectedVariableShape } from '../ClientVariables/shapes/shapes'

const DeployPolicyReviewSummary = ({
  selectedPolicies,
  additionalSettings,
  sourceTenantName,
  destinationTenants,
  onPrevious,
  onSubmit,
  contentAlignment,
  selectedVariables,
}) => {
  const allTenantsExchangeDisabled = destinationTenants.every(
    tenant => !tenant.exchange
  )

  const filteredAdditionalSettings = additionalSettings.filter(setting =>
    selectedPolicies.some(policy => {
      if (
        allTenantsExchangeDisabled &&
        exchangePolicyIds.includes(policy.policyTypeId)
      ) {
        return false
      }
      return policy.policyTypeId === setting.policyId
    })
  )

  return (
    <div className='flex flex-col gap-2'>
      <DeployPolicyDialogHeader
        step={steps.review}
        alignment={contentAlignment}
      />
      <div
        className={`flex flex-row gap-8 ${contentAlignment === 'center' ? 'self-center' : ''}`}
      >
        <div className='text-center'>
          <h4 className='text-lg'>
            <strong>Source tenant </strong>
          </h4>
          <p>{sourceTenantName}</p>
        </div>
        <FontAwesomeIcon
          icon={faArrowRightLong}
          size='2xl'
          className='self-center'
        />
        <div className='text-center pb-4'>
          <h4 className='text-lg'>
            <strong>Destination tenants </strong>
          </h4>
          {destinationTenants.map(tenant => (
            <p key={tenant.clientTenantId}>{tenant.tenantFriendlyName}</p>
          ))}
        </div>
      </div>
      <h4 className='text-lg'>
        <strong>Policies to deploy - {selectedPolicies.length} </strong>
      </h4>
      <ul className='confirm-policies'>
        {selectedPolicies.map(policy => (
          <li key={`policy-${policy.policyGuid}`} className='sm-text'>
            {policy.displayName}
          </li>
        ))}
      </ul>
      <SelectedVariablesSummary selectedVariables={selectedVariables} />
      <ReviewSummaryContent
        additionalPolicySettings={filteredAdditionalSettings}
      />
      <ModalActionButtons
        onCancel={onPrevious}
        onConfirm={onSubmit}
        cancelActionText='Previous'
        confirmActionText='Submit'
      />
    </div>
  )
}

DeployPolicyReviewSummary.defaultProps = {
  contentAlignment: 'center',
  selectedVariables: [],
}

DeployPolicyReviewSummary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  additionalSettings: arrayOf(object).isRequired,
  sourceTenantName: string.isRequired,
  destinationTenants: arrayOf(summaryTenantShape.isRequired).isRequired,
  selectedPolicies: arrayOf(
    shape({
      policyGuid: string.isRequired,
      displayName: string.isRequired,
      policyTypeId: number.isRequired,
    })
  ).isRequired,
  onPrevious: func.isRequired,
  onSubmit: func.isRequired,
  contentAlignment: oneOf(['center', 'left']),
  selectedVariables: arrayOf(selectedVariableShape),
}

export default DeployPolicyReviewSummary
