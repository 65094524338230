import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons'
import { func } from 'prop-types'

const BaselineGroupActionsCell = ({ onDelete, onEdit }) => (
  <div className='flex gap-2 items-center justify-center'>
    <button
      type='button'
      aria-label='edit-group'
      className='navy-btn hover:navy-btn text-white p-2 rounded-md flex justify-center items-center w-8 h-8'
      onClick={onEdit}
    >
      <FontAwesomeIcon icon={faPenToSquare} />
    </button>
    <button
      className='navy-btn text-white p-2 rounded-md flex justify-center items-center w-8 h-8'
      type='button'
      aria-label='delete-group'
      onClick={onDelete}
    >
      <FontAwesomeIcon icon={faTrash} />
    </button>
  </div>
)

BaselineGroupActionsCell.propTypes = {
  onDelete: func.isRequired,
  onEdit: func.isRequired,
}

export default BaselineGroupActionsCell
