/**
 * Extracts the error message and errors from an error response.
 * @param error error object from react-query
 * @returns {{message: (undefined|string), errors: (string[])}}
 */
const extractErrorFromResponse = error => {
  const message = error.response?.data?.message
  const errors = error.response?.data?.errors || [
    'An unexpected error occurred while processing the request. ' +
      'This may be a temporary error but if it continues please contact support.',
  ]
  return {
    message,
    errors,
  }
}

export default extractErrorFromResponse
