import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons'
import { func, node, string } from 'prop-types'

const SplitButtonMenuItem = ({
  onPrimaryAction,
  onSecondaryAction,
  icon,
  text,
}) => (
  <div
    className='flex flex-row items-center justify-between pl-2 border-b-2'
    role='menuitem'
    tabIndex='-1'
  >
    <div>
      {icon}
      {text}
    </div>
    <div className='flex flex-row pl-2'>
      <button
        type='button'
        className='bg-[rgb(var(--cyan))] w-[2.5rem] hover:opacity-75'
        onClick={onPrimaryAction}
      >
        <FontAwesomeIcon icon={faCheck} color='white' size='xl' />
      </button>
      <button
        type='button'
        className='w-[2.5rem] py-1 bg-gray-500 opacity-75 hover:opacity-100'
        onClick={onSecondaryAction}
      >
        <FontAwesomeIcon icon={faMinus} size='xl' color='white' />
      </button>
    </div>
  </div>
)

SplitButtonMenuItem.propTypes = {
  onPrimaryAction: func.isRequired,
  onSecondaryAction: func.isRequired,
  icon: node.isRequired,
  text: string.isRequired,
}

export default SplitButtonMenuItem
