import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const useDeleteTenant = (onSuccess, onError) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['delete-tenant'],
    mutationFn: async clientTenantId => {
      await axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/delete-tenant`,
        { clientTenantId }
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries('tenantManagement')
      onSuccess()
    },
    onError,
  })
}

export default useDeleteTenant
