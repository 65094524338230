import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { PropTypes, arrayOf, string, shape } from 'prop-types'
import ConfirmationPopupSkeleton from './LoadingSkeletons/ConfirmationPopupSkeleton'
import ReviewSummaryContent from './RestoreReviewSummary'

const RestorePopup = ({
  finalResponse,
  formResponses,
  backEndErrors,
  additionalSettings,
  selectedPolicies,
  selectedBackup,
}) => {
  const sourceTenant = formResponses[0].response[0].tenantFriendlyName
  const { backupRunTime, Comment } = selectedBackup
  const backupRun = backupRunTime || Comment

  const totalPolicies =
    (finalResponse ? finalResponse.length : 0) +
    (backEndErrors ? backEndErrors.length : 0)

  const errorSegment =
    backEndErrors && backEndErrors.length > 0
      ? `, \nErrors:\n${backEndErrors.map(err => err.displayName || err.name).join('\n')}`
      : ''

  const copyToClipboard = `Selected Tenant: ${sourceTenant}, Selected Backup: ${backupRun}, Policies Queued for Restore:${
    finalResponse ? finalResponse.join('\n') : ''
  }, ${errorSegment}`

  return (
    <div className='popup-bg'>
      <div className='popup-container'>
        <h3 className='text-center mb-4'>Restore Summary</h3>

        {finalResponse === null ? (
          <ConfirmationPopupSkeleton loadingTitle='Restore in progress...' />
        ) : finalResponse && finalResponse.length > 0 ? (
          <div>
            <p className='my-2'>
              <strong>Selected Tenant - </strong> {sourceTenant}
            </p>

            <p className='my-2'>
              <strong>Selected Back Up - </strong> {backupRun}
            </p>

            <p>
              <strong>Policies successfully Restored - </strong>
              {finalResponse.length}/{totalPolicies}
            </p>
            <table>
              <tbody>
                {finalResponse.map((item, index) => (
                  <tr key={index}>
                    <td>{item}</td>
                    <td aria-label='Success'>
                      <FontAwesomeIcon icon={faCheckCircle} className='ml-1' />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p className='mb-2'>
              <strong>Additional settings (if applicable):</strong>
            </p>
            <ReviewSummaryContent
              additionalPolicySettings={additionalSettings.filter(setting =>
                selectedPolicies.some(
                  policy => policy.policyTypeId === setting.policyId
                )
              )}
            />
          </div>
        ) : (
          <h4 className='report-error'>
            An error has ocurred, please refresh the page and try again, or
            contact us at <span className='cyan-text'>info@inforcer.com.</span>.
          </h4>
        )}

        {backEndErrors && backEndErrors.length > 0 && (
          <div>
            <p className='mb-2'>
              <strong>Restore Errors:</strong>
            </p>
            <ul>
              {backEndErrors.map((error, index) => (
                <li key={index}>{error.displayName || error.name}</li>
              ))}
            </ul>
          </div>
        )}

        {(finalResponse !== null && finalResponse.length > 0) ||
        backEndErrors ? (
          <div className='footer mt-4'>
            <button
              type='button'
              className='btn navy-btn'
              onClick={() => {
                navigator.clipboard.writeText(copyToClipboard)
              }}
            >
              Copy to clipboard
            </button>
            <button type='button' className='btn cyan-btn ml-4 text-white'>
              <Link to='/' className='text-white'>
                Home
              </Link>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  )
}

RestorePopup.defaultProps = {
  finalResponse: null,
  backEndErrors: [],
}

RestorePopup.propTypes = {
  finalResponse: arrayOf(PropTypes.string),
  formResponses: arrayOf(PropTypes.object).isRequired,
  backEndErrors: arrayOf(PropTypes.object),
  selectedBackup: shape({
    backupRunTime: string,
    comment: string,
  }).isRequired,
}

export default RestorePopup
