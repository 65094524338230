export const getStringBetween = (str, start, end) => {
  const result = str.match(new RegExp(`${start}(.*)${end}`, 's'))

  return result[1]
}

/**
 * Format a timestamp to the user's local time
 * @param timestamp - Unix timestamp
 * @param timeZone - Timezone to format the timestamp to (leave blank for user's local timezone)
 * @param datetimeOptions - Options to format the date and time
 * @returns {string} - Formatted date and time
 */
export const formatToLocalTime = (
  timestamp,
  timeZone = undefined,
  datetimeOptions = undefined
) => {
  if (!timestamp) return 'Error: Invalid Date Value'

  const date = new Date(timestamp)

  const userLocale = navigator.language || 'en-gb'

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    ...datetimeOptions,
    timeZone: timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
  }

  return new Intl.DateTimeFormat(userLocale, options).format(date)
}

export const formatToLocalTimeLong = (timestamp, timeZone) => {
  const options = {
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  }

  return formatToLocalTime(timestamp, timeZone, options)
}

export const formatToLocalTimeNoSeconds = (timestamp, timeZone) =>
  formatToLocalTime(timestamp, timeZone, { second: undefined })

const utils = {
  getStringBetween,
  formatToLocalTimeLong,
  formatToLocalTimeNoSeconds,
}

export default utils
