import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const usePolicyData = ({ clientTenantId, policyTypeId, policyId, id }) =>
  useQuery({
    queryKey: id,
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/get-policy-data`,
        {
          params: {
            clientTenantId,
            policyTypeId,
            policyGuid: id,
            policyId,
            target: policyId ? 'SQL' : '365',
          },
        }
      )
      return response.data
    },
    enabled: false,
    retry: false,
  })

export default usePolicyData
