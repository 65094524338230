import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const useGetListPolicies = (ctId, selectedBackup, isSharedBaseline = false) =>
  useQuery({
    queryKey: [
      'policyData',
      ctId,
      ...(selectedBackup
        ? [
            selectedBackup.Comment
              ? selectedBackup.Comment
              : selectedBackup.backupRunId,
          ]
        : []),
    ],
    queryFn: async () => {
      let response
      if (selectedBackup || isSharedBaseline) {
        response = await axios.get(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/backup-policies`,
          {
            params: {
              clientTenantId: ctId,
              backupRunId: selectedBackup?.backupRunId,
              comment: selectedBackup?.Comment,
              sharedBaseline: isSharedBaseline,
            },
          }
        )
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/policies`,
          {
            params: {
              clientTenantId: ctId,
            },
          }
        )
      }

      return response.data
    },
  })

export default useGetListPolicies
