import { bool, func, string } from 'prop-types'
import Alert from './Alert/Alert'

const OnboardingRetrieveCustomers = ({
  onClick,
  title,
  onSkip,
  loading,
  isRefresh,
}) => (
  <>
    <h3 className='mb-4'>{title}</h3>
    <Alert type='warning' title='Allowing Popups'>
      <p>
        The onboarding process will prompt you to authenticate with Microsoft
        and Partner Center, please ensure you allow popups from this site. This
        can be done by clicking the popup icon in the address bar and selecting
        'Always allow popups from this site'.
      </p>
      <p className='mt-4'>
        <b>
          You will need to sign into an account with administrator access to
          Microsoft Partner Center.
        </b>
      </p>
    </Alert>
    <p className='my-4'>
      Clicking the button below will retrieve your customers' tenancy
      information from Microsoft Partner Center.
    </p>
    {loading ? (
      <div className='flex mt-4'>
        <div id='loading-spinner'>
          <div className='loading' />
        </div>
        <p className='ml-2 mt-1'>
          Fetching customer details from Partner Center...
        </p>
      </div>
    ) : (
      <>
        <button
          type='button'
          className='btn cyan-btn mr-4'
          onClick={() => {
            onClick()
          }}
          disabled={loading}
        >
          {loading ? 'Please Wait' : 'Retrieve Customers'}
        </button>
        {!isRefresh && (
          <button
            type='button'
            className='btn tertiary-btn'
            onClick={onSkip}
            disabled={loading}
          >
            Add Customer Without Partner Center
          </button>
        )}
      </>
    )}
  </>
)

OnboardingRetrieveCustomers.defaultProps = {
  isRefresh: false,
}

OnboardingRetrieveCustomers.propTypes = {
  onClick: func.isRequired,
  title: string,
  onSkip: func.isRequired,
  loading: bool.isRequired,
  isRefresh: bool,
}

OnboardingRetrieveCustomers.defaultProps = {
  title: 'Step 1: Retrieve customer information',
}

export default OnboardingRetrieveCustomers
