import { bool, shape } from 'prop-types'

const TenantTypeCell = ({ row }) => {
  const { inforcerManaged, isSharedBaseline, isGoldenTenant, isBaseline } =
    row.original

  let styles = ''
  let text = ''

  switch (true) {
    case inforcerManaged:
      styles = 'bg-inforcer-cyan border-inforcer-cyan text-white'
      text = 'Inforcer Managed'
      break
    case isSharedBaseline:
      styles = 'bg-white border-inforcer-navy text-inforcer-navy'
      text = 'Shared Baseline'
      break

    case isGoldenTenant || isBaseline:
      styles = 'bg-inforcer-navy border-inforcer-navy text-white'
      text = 'Baseline'
      break
    default:
      styles = 'bg-white border-slate-400 text-slate-600'
      text = 'Customer'
  }

  return (
    <span
      className={`${styles} border text-xs rounded-md px-1.5 py-1 leading-none`}
    >
      {text}
    </span>
  )
}

TenantTypeCell.propTypes = {
  row: shape({
    original: shape({
      isGoldenTenant: bool,
      isBaseline: bool,
      isSharedBaseline: bool,
      inforcerManaged: bool,
    }),
  }).isRequired,
}

export default TenantTypeCell
