import { node, string } from 'prop-types'
import './Chip.css'

/**
 * @param {{
 *  icon?: React.ReactNode | undefined,
 *  color: 'black' | 'blue' | 'amber',
 *  children: React.ReactNode,
 * }} props
 */
const Chip = ({ icon, color, children }) => (
  <div className={`chip ${color}`}>
    <div className='chip-icon'>{icon}</div>
    <div className='chip-content'>{children}</div>
  </div>
)

Chip.defaultProps = {
  icon: undefined,
}

Chip.propTypes = {
  icon: node,
  color: string.isRequired,
  children: node.isRequired,
}

export default Chip
