import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTags } from '@fortawesome/free-solid-svg-icons'
import { Popover } from '@mui/material'
import { arrayOf, number, oneOfType, shape, string } from 'prop-types'

import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'

import { hexToRgb } from '../../utils/colours'
import Tag from '../Tag/Tag'

const TagsPopover = ({ tags }) => (
  <PopupState
    variant='popover'
    popupId='popup-popover'
    disableAutoFocus={false}
    parentPopupState={null}
  >
    {popupState => (
      <div>
        {tags.length > 0 && (
          <button
            onMouseEnter={popupState.open}
            {...bindTrigger(popupState)}
            type='button'
            aria-label={`Show ${tags.length} hidden tags`}
            className='flex items-center justify-center space-x-1 text-xs font-medium text-inforcer-navy hover:opacity-70 hover:duration-200 z-1600 ease-in-out outline-none'
          >
            <span className='text-sm'>+ {tags.length}</span>
            <FontAwesomeIcon className='pt-[0.5px]' icon={faTags} />
          </button>
        )}

        <Popover
          {...bindPopover(popupState)}
          onClose={event => {
            popupState.close()
            event.stopPropagation()
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className='p-3'>
            <h2 className='font-bold text-sm'>More Tags</h2>

            {tags.map(tag => (
              <div key={tag.id} className='py-1'>
                <Tag
                  text={tag.tag}
                  description={tag.description}
                  colourRGB={hexToRgb(tag.colour)}
                />
              </div>
            ))}
          </div>
        </Popover>
      </div>
    )}
  </PopupState>
)

TagsPopover.propTypes = {
  tags: arrayOf(
    shape({
      id: oneOfType([string, number]).isRequired,
      tag: string.isRequired,
      colour: string, // Make sure to include colour if using hexToRgb function
    })
  ).isRequired,
}

export default TagsPopover
