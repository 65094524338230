import { createTheme } from '@mui/material/styles'

export const DARK_MODE_OPT = {
  light: 'light',
  dark: 'dark',
}

export const themeLocalStorageKey = 'theme'

export const isInDarkMode =
  localStorage.getItem(themeLocalStorageKey) === DARK_MODE_OPT.dark ||
  (!('theme' in localStorage) &&
    window.matchMedia('(prefers-color-scheme: dark)').matches)

const setDarkModeClasses = () => {
  if (isInDarkMode) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
}

// We need this to be called when the app first loads up but
// because we're not in a component I can't use the feature flag
// hook so until I figure out a solution I'm leaving this here as a reminder
// setDarkModeClasses()

export const MuiTheme = createTheme({
  palette: {
    primary: {
      main: '#178BDB', // Define your primary color here
    },
    secondary: {
      main: '#171B3A', // Define your secondary color here
    },
  },
})

export default setDarkModeClasses
