import { bool, node } from 'prop-types'
import './CollapsibleCard.css'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

/**
 * @param {{
 *  cardTitle?: React.ReactNode,
 *  cardContent?: React.ReactNode,
 *  cardActions?: React.ReactNode,
 *  forceOpen: boolean,
 * }} props
 */
const CollapsibleCard = ({
  cardTitle,
  cardContent,
  cardActions,
  forceOpen,
}) => {
  const [collapsed, setCollapsed] = useState(forceOpen)

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    setCollapsed(forceOpen)
  }, [forceOpen])

  return (
    <div className='collapsible-card light-grey-bg rounded-md'>
      <div
        className='collapsible-card-title flex justify-between items-center cursor-pointer p-4'
        onClick={toggleCollapsed}
        onKeyDown={toggleCollapsed}
        role='button'
        tabIndex={0}
      >
        <div className='w-[100%]'>{cardTitle}</div>
        <FontAwesomeIcon
          className='ml-4'
          icon={collapsed ? faChevronDown : faChevronUp}
        />
      </div>
      {cardContent ? (
        <div
          className={`collapsible-card-content p-4${collapsed ? ' hidden' : ''}`}
        >
          {cardContent}
        </div>
      ) : null}
      {cardActions ? (
        <div
          className={`collapsible-card-actions flex flex-row justify-end p-4${collapsed ? ' hidden' : ''}`}
        >
          {cardActions}
        </div>
      ) : null}
    </div>
  )
}

CollapsibleCard.defaultProps = {
  cardTitle: undefined,
  cardContent: undefined,
  cardActions: undefined,
  forceOpen: false,
}

CollapsibleCard.propTypes = {
  cardTitle: node,
  cardContent: node,
  cardActions: node,
  forceOpen: bool,
}

export default CollapsibleCard
