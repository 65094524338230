import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const useTenantTags = () =>
  useQuery({
    queryKey: ['tenantTags'],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/tenantTags`
      )
      return response.data
    },
  })

export default useTenantTags
