import { bool, func, string } from 'prop-types'

const ModalActionButtons = ({
  onConfirm,
  onCancel,
  confirmActionText,
  cancelActionText,
  confirmEnabled,
  cancelEnabled,
}) => (
  <div className='mt-4 flex gap-2'>
    <button
      type='button'
      disabled={!cancelEnabled}
      className='btn navy-btn'
      aria-label={cancelActionText || 'cancel'}
      onClick={onCancel}
    >
      {cancelActionText}
    </button>
    <button
      type='button'
      className='btn cyan-btn'
      onClick={onConfirm}
      aria-label={confirmActionText || 'confirm'}
      disabled={!confirmEnabled}
    >
      {confirmActionText}
    </button>
  </div>
)

ModalActionButtons.defaultProps = {
  // eslint-disable-next-line no-console
  onConfirm: () => console.log('No Action Provided'),
  // eslint-disable-next-line no-console
  onCancel: () => console.log('No Action Provided'),
  confirmActionText: 'Confirm',
  cancelActionText: 'Cancel',
  confirmEnabled: true,
  cancelEnabled: true,
}

ModalActionButtons.propTypes = {
  onConfirm: func,
  onCancel: func,
  confirmActionText: string,
  cancelActionText: string,
  confirmEnabled: bool,
  cancelEnabled: bool,
}

export default ModalActionButtons
