import { bool, func, string } from 'prop-types'

const SSOCheckbox = ({
  checked,
  onChange,
  onBlur,
  label,
  id,
  disabled,
  textHint,
}) => (
  <div className='inline-flex items-center'>
    <label
      className='relative flex px-3 items-center cursor-pointer'
      htmlFor={id}
    >
      <input
        type='checkbox'
        className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900"
        id={id}
        checked={checked}
        onChange={() => onChange(!checked)}
        onBlur={onBlur}
        disabled={disabled}
      />
      <span className='absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-3.5 w-3.5'
          viewBox='0 0 20 20'
          fill='currentColor'
          stroke='currentColor'
          strokeWidth='1'
        >
          <path
            fillRule='evenodd'
            d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
            clipRule='evenodd'
          />
        </svg>
      </span>
    </label>

    <label
      className='mt-px text-gray-700 cursor-pointer select-none'
      htmlFor={id}
      disabled={disabled}
    >
      {label}
      {textHint && <span className='sso-help-text'>{textHint}</span>}
    </label>
  </div>
)

SSOCheckbox.defaultProps = {
  checked: false,
  disabled: false,
  textHint: undefined,
  onBlur: () => {},
}

SSOCheckbox.propTypes = {
  checked: bool,
  onChange: func.isRequired,
  onBlur: func,
  label: string.isRequired,
  id: string.isRequired,
  disabled: bool,
  textHint: string,
}

export default SSOCheckbox
