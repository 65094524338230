import { useMutation, useQueryClient } from '@tanstack/react-query'
import Axios from 'axios'

const useDeleteSSOConfig = (onSuccess, onError) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['delete-sso-config'],
    mutationFn: async () => {
      await Axios.delete(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/sso/configuration`
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries('sso-config')
      onSuccess()
    },
    onError,
  })
}

export default useDeleteSSOConfig
