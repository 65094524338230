import { arrayOf, bool, func } from 'prop-types'

import Select from 'react-select'

import Skeleton from 'react-loading-skeleton'
import { useCallback, useMemo } from 'react'
import { faTag } from '@fortawesome/free-solid-svg-icons'
import CustomTagsOption from '../../../CustomTags/CustomTagsOption'
import SortControl from '../../../CustomTags/SortControl'

import usePolicyTags from '../../../PolicyTags/api/usePolicyTags'
import Alert from '../../../Alert/Alert'

import customStyles from '../utils/config/ReactSelect/CustomStyles'
import { useExpansion } from '../context/ExpansionContext'
import { primaryGroupShape } from '../utils/propTypes'
import policyTagsShape from '../../../PolicyTags/config/propTypes'

const PolicyTagsFilter = ({
  selectedPolicyTags,
  setSelectedPolicyTags,
  filteredPolicies,
  hideLabel,
}) => {
  const { expandAllDynamically, resetExpansionState } = useExpansion()

  const { data: policyTags = [], isLoading, isError, error } = usePolicyTags()

  const handleChange = useCallback(
    selectedOptions => {
      if (selectedOptions && selectedOptions.length > 0) {
        setSelectedPolicyTags(selectedOptions)
        expandAllDynamically(filteredPolicies)
      } else {
        setSelectedPolicyTags([])
        resetExpansionState()
      }
    },
    [
      setSelectedPolicyTags,
      expandAllDynamically,
      filteredPolicies,
      resetExpansionState,
    ]
  )

  const customComponents = useMemo(
    () => ({
      // eslint-disable-next-line react/no-unstable-nested-components
      Control: props => <SortControl {...props} icon={faTag} />,
      Option: CustomTagsOption,
    }),
    []
  )

  if (isLoading) {
    return <Skeleton count={5} />
  }

  if (isError) {
    return (
      <Alert type='error' title='Error fetching policy tags'>
        <div className='flex flex-col gap-1'>
          {error.response.data.errors.map(errorMessage => (
            <div key={errorMessage}>{errorMessage}</div>
          ))}
        </div>
      </Alert>
    )
  }

  const options = policyTags?.map(tag => ({
    label: tag.tag,
    value: tag.id,
    description: tag.description,
    colour: tag.colour,
  }))

  return (
    <div className='flex items-center'>
      {!hideLabel && (
        <label htmlFor='selectedPolicyTags' className='w-[200px] mr-2'>
          <b>Filter by policy tags</b>
        </label>
      )}
      <div
        id='selectedPolicyTags'
        aria-labelledby='Filter by policy tags'
        className='w-[100%] focus:outline-blue-400 text-gray-700 text-left'
      >
        <Select
          value={selectedPolicyTags}
          options={options}
          isMulti
          onChange={handleChange}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={customComponents}
          styles={customStyles('Tags', selectedPolicyTags)}
          placeholder='Tags'
          isClearable
        />
      </div>
    </div>
  )
}

PolicyTagsFilter.defaultProps = {
  hideLabel: false,
  filteredPolicies: [],
}

PolicyTagsFilter.propTypes = {
  setSelectedPolicyTags: func.isRequired,
  selectedPolicyTags: arrayOf(policyTagsShape).isRequired,
  filteredPolicies: arrayOf(primaryGroupShape),
  hideLabel: bool,
}

export default PolicyTagsFilter
