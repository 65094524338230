import { arrayOf, number, shape, string } from 'prop-types'
import { useMemo } from 'react'

import TagsPopover from '../../TagsPopover/TagsPopover'
import Tag from '../../Tag/Tag'
import { hexToRgb } from '../../../utils/colours'

const TenantTagsCell = ({
  linkedTenantTagIds,
  allTenantTags,
  numVisibleTags,
}) => {
  const tags = useMemo(
    () =>
      allTenantTags?.filter(tag => linkedTenantTagIds.includes(tag.id)) ?? [],
    [allTenantTags, linkedTenantTagIds]
  )

  const visibleTags = useMemo(
    () => tags.slice(0, numVisibleTags),
    [tags, numVisibleTags]
  )
  const hiddenTags = useMemo(
    () => tags.slice(numVisibleTags),
    [tags, numVisibleTags]
  )

  return (
    <div className='flex flex-wrap gap-2'>
      {visibleTags.map(tag => (
        <Tag
          key={tag.id}
          text={tag.tag}
          description={tag.description}
          colourRGB={hexToRgb(tag.colour)}
        />
      ))}
      <TagsPopover tags={hiddenTags} />
    </div>
  )
}

TenantTagsCell.defaultProps = {
  numVisibleTags: 4,
}

TenantTagsCell.propTypes = {
  linkedTenantTagIds: arrayOf(string).isRequired,
  allTenantTags: arrayOf(
    shape({
      id: string.isRequired,
      tag: string.isRequired,
      colour: string.isRequired,
    })
  ).isRequired,
  numVisibleTags: number,
}

export default TenantTagsCell
