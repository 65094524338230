import { toast } from 'react-toastify'
import { arrayOf, bool, func, instanceOf, object } from 'prop-types'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUserCheck,
  faUserGear,
  faUserMinus,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { Menu } from '@mui/material'
import useToggleClientAdmin from './useToggleClientAdmin'
import useToggleUserEnabled from './useToggleUserEnabled'
import useToggleUserSSOEnabled from './useToggleUserSSOEnabled'
import useRemoveUser from './useRemoveUser'
import ModalActionButtons from '../Modal/ModalActionButtons'
import Modal from '../Modal/Modal'
import SplitButtonMenuItem from './SplitButtonMenuItem'

const SSOUsersTableActionMenu = ({
  menuOpen,
  anchorEl,
  setActionMenuOpen,
  selectedRows,
  setErrorMessage,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [modalConfirmAction, setModalConfirmAction] = useState(undefined)

  const cancelAction = () => {
    setModalContent(null)
    setModalOpen(false)
    setModalConfirmAction(() => {})
  }

  const setConfirmationAction = ({ title, description, action }) => {
    setModalOpen(true)
    setActionMenuOpen(false)

    setModalContent(
      <div>
        <h4 className='mb-4'>{title}</h4>
        <p>{description}</p>
      </div>
    )

    setModalConfirmAction(() => action)
  }

  const handleMultiUserResponse = response => {
    setModalOpen(false)
    setErrorMessage('')

    if (response.data.requestFailures.length > 0) {
      toast.warn(response.data.message)
      setErrorMessage(
        response.data.requestFailures.map(
          request => `${request.username}: ${request.errors.join(', ')}`
        )
      )
    } else {
      toast.success(response.data.message)
      setErrorMessage('')
    }
  }

  const toggleClientAdmin = useToggleClientAdmin(
    handleMultiUserResponse,
    () => {
      setErrorMessage('Failed to update user client admin status')
    }
  )

  const toggleUserEnabled = useToggleUserEnabled(
    handleMultiUserResponse,
    () => {
      setErrorMessage('Failed to update user enabled status')
    }
  )

  const toggleUserSSOEnabled = useToggleUserSSOEnabled(
    handleMultiUserResponse,
    () => {
      setErrorMessage('Failed to update user SSO status')
    }
  )

  const removeUser = useRemoveUser(handleMultiUserResponse, () => {
    setErrorMessage('Failed to remove user')
  })

  const selectedUsers = selectedRows.map(({ original }) => ({
    targetUserId: original.userId,
    username: original.username,
  }))

  return (
    <>
      <Menu
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={() => setActionMenuOpen(false)}
        MenuListProps={{
          'aria-labelledby': 'user-table-row-actions',
          disablePadding: true,
        }}
      >
        <div className='flex flex-col' role='none'>
          <SplitButtonMenuItem
            text='Toggle Client Admin Status'
            icon={
              <FontAwesomeIcon
                icon={faUserGear}
                size='lg'
                className='pr-2 opacity-75'
              />
            }
            onPrimaryAction={() =>
              setConfirmationAction({
                title: `Enable Client Admin`,
                description: `Are you sure you want to enable client admin for ${selectedRows.length} users?`,
                action: () =>
                  toggleClientAdmin.mutate({
                    users: selectedUsers,
                    enable: true,
                  }),
              })
            }
            onSecondaryAction={() =>
              setConfirmationAction({
                title: `Disable Client Admin`,
                description: `Are you sure you want to disable client admin for ${selectedRows.length} users?`,
                action: () =>
                  toggleClientAdmin.mutate({
                    users: selectedUsers,
                    enable: false,
                  }),
              })
            }
          />

          <SplitButtonMenuItem
            text='Toggle Enabled Status'
            icon={
              <FontAwesomeIcon
                icon={faUserCheck}
                size='lg'
                className='pr-2 opacity-75'
              />
            }
            onPrimaryAction={() =>
              setConfirmationAction({
                title: `Enable Users`,
                description: `Are you sure you want to enable ${selectedRows.length} users?`,
                action: () =>
                  toggleUserEnabled.mutate({
                    users: selectedUsers,
                    enable: true,
                  }),
              })
            }
            onSecondaryAction={() =>
              setConfirmationAction({
                title: `Disable Client Admin`,
                description: `Are you sure you want to disable ${selectedRows.length} users?`,
                action: () =>
                  toggleUserEnabled.mutate({
                    users: selectedUsers,
                    enable: false,
                  }),
              })
            }
          />

          <SplitButtonMenuItem
            text='Toggle SSO Status'
            icon={
              <FontAwesomeIcon
                icon={faUsers}
                size='lg'
                className='pr-2 opacity-75'
              />
            }
            onPrimaryAction={() =>
              setConfirmationAction({
                title: `Enable SSO User`,
                description: `Are you sure you want to enable SSO for ${selectedRows.length} users?`,
                action: () =>
                  toggleUserSSOEnabled.mutate({
                    users: selectedUsers,
                    enable: true,
                  }),
              })
            }
            onSecondaryAction={() =>
              setConfirmationAction({
                title: `Disable Client Admin`,
                description: `Are you sure you want to disable SSO for ${selectedRows.length} users?`,
                action: () =>
                  toggleUserSSOEnabled.mutate({
                    users: selectedUsers,
                    enable: false,
                  }),
              })
            }
          />
          <button
            type='button'
            className='flex flex-row items-center pl-2 my-2'
            onClick={() =>
              setConfirmationAction({
                title: `Remove Users`,
                description: `Are you sure you want to remove ${selectedRows.length} users?`,
                action: () =>
                  removeUser.mutate({
                    users: selectedUsers,
                  }),
              })
            }
          >
            <FontAwesomeIcon
              icon={faUserMinus}
              size='lg'
              className='pr-2 opacity-75'
            />
            Remove Users
          </button>
        </div>
      </Menu>
      <Modal isOpen={modalOpen} closeOnBlur={false} setModalOpen={setModalOpen}>
        {modalContent}
        <ModalActionButtons
          onCancel={cancelAction}
          onConfirm={modalConfirmAction}
        />
      </Modal>
    </>
  )
}

SSOUsersTableActionMenu.defaultProps = {
  anchorEl: null,
}

SSOUsersTableActionMenu.propTypes = {
  menuOpen: bool.isRequired,
  selectedRows: arrayOf(object.isRequired).isRequired,
  anchorEl: instanceOf(Element),
  setErrorMessage: func.isRequired,
  setActionMenuOpen: func.isRequired,
}

export default SSOUsersTableActionMenu
