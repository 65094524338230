import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const ResetUserPass = () => {
  const [clients, setClients] = useState([])
  const [users, setUsers] = useState([])
  const [loadingState, setLoadingState] = useState(false)

  const [selectedClientId, setSelectedClientId] = useState('')
  const [selectedUserId, setSelectedUserId] = useState('')

  const [requestError, setRequestError] = useState('')

  const [newPass, setNewPass] = useState('')
  const [confirmNewPass, setConfirmNewPass] = useState('')
  const [passSuccess, setPassSuccess] = useState('')
  const [passError, setPassError] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    clientSearch()
  }, [])

  const clientSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/get-clients`
      )
      const { data } = response
      setClients(data)
    } catch (error) {
      console.error('Error:', error.response.status)
      toast.error('Error Retrieving Clients')
    }
  }

  const handleClientChange = e => {
    setSelectedUserId('')
    const clientId = e.target.value
    setSelectedClientId(clientId)
    if (clientId) {
      userSearch(clientId)
    } else {
      setUsers([])
    }
  }

  const handleUserChange = e => {
    const userId = e.target.value
    setSelectedUserId(userId)
  }

  const userSearch = async clientId => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/getUserList`,
        { clientId }
      )
      let { data } = response
      if (!Array.isArray(data)) {
        data = [data]
      }
      setUsers(data)
    } catch (error) {
      console.error('Error:', error.response.status)
    }
  }

  // Password conditions
  const validPass = () => {
    const issues = []

    if (newPass !== confirmNewPass) {
      issues.push('New password fields do not match')
    }
    if (newPass.length < 8 || newPass.length > 25) {
      issues.push('New password should be between 8 -25 characters')
    }
    if (!/[A-Z]/.test(newPass)) {
      issues.push('New password should have at least 1 uppercase character')
    }
    if (!/\d/.test(newPass)) {
      issues.push('New password should have at least 1 number')
    }
    if (!/[^A-Za-z0-9]/.test(newPass)) {
      issues.push('New password should have at least 1 special character')
    }
    if (/[\s]/.test(newPass)) {
      issues.push('New password should not contain spaces')
    }

    if (issues.length) {
      setPassError(issues)
      setPassSuccess('')
      return false
    }

    setPassError([])
    return true
  }

  // Run conditions and pw change request
  const handleSubmit = e => {
    e.preventDefault()

    if (!selectedUserId) {
      setRequestError('Please select a user')
      return
    }

    setRequestError('')

    if (validPass()) {
      handlePassChange()
    }
  }

  const handlePassChange = async () => {
    try {
      setLoadingState(true)

      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/resetUserPass`,
        {
          changePwUserId: selectedUserId,
          newPassword: newPass,
        }
      )

      setNewPass('')
      setConfirmNewPass('')

      if (response.status === 200) {
        setPassSuccess('Password Set Successfully')
        setPassError([])
      } else {
        setPassError(['An unexpected error has occurred.'])
        setPassSuccess('')
      }
    } catch (error) {
      if (error.response.status === 500) {
        setPassError(['Could not change password. Server Error.'])
        setPassSuccess('')
        setNewPass('')
        setConfirmNewPass('')
      }
    } finally {
      setLoadingState(false)
    }
  }

  return (
    <div className='ui-panel admin-2-col'>
      <div className='heading'>
        <h2>Reset User Pass</h2>
      </div>

      <button
        onClick={() => navigate(-1)}
        className='btn bg-white text-gray-900 flex items-center justify-center mb-2'
      >
        {' '}
        <FontAwesomeIcon icon={icon({ name: 'angle-left' })} />
        <span>&nbsp;&nbsp;Back</span>
      </button>

      <div className='action-body no-progress-bar'>
        <form onSubmit={handleSubmit} className='w-[50%] max-w-lg'>
          {requestError && (
            <div className='mt-4 mb-[24px]' role='alert'>
              <span className='font-small'>{requestError}</span>
            </div>
          )}

          {passError.length > 0 && (
            <div className='mt-4 mb-[24px]' role='alert'>
              <ul>
                {passError.map((issue, index) => (
                  <li key={index} className='font-small list-disc ml-4'>
                    {issue}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className='flex flex-col space-y-2 my-4'>
            <label htmlFor='selectClient' className='font-semibold'>
              Select Client:
            </label>
            <select
              id='selectClient'
              value={selectedClientId}
              name='selectClient'
              onChange={handleClientChange}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
            >
              <option value=''>Select a client</option>

              {clients.map(client => (
                <option key={uuidv4()} value={client.clientId}>
                  {client.displayName}
                </option>
              ))}
            </select>
          </div>

          <div className='flex flex-col space-y-2 my-4'>
            <label htmlFor='selectUser' className='font-semibold'>
              Select User:
            </label>
            <select
              id='selectUser'
              name='selectUser'
              value={selectedUserId}
              onChange={handleUserChange}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
            >
              <option value=''>Select a user</option>
              {users.map(user => (
                <option key={uuidv4()} value={user.userid}>
                  {user.displayName}
                </option>
              ))}
            </select>
          </div>

          <div className='flex flex-col space-y-2 my-4'>
            <label htmlFor='newPassword' className='font-semibold'>
              Enter new password
            </label>
            <input
              id='newPassword'
              type='password'
              name='newPassword'
              value={newPass}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
              onChange={e => {
                setNewPass(e.target.value)
              }}
            />
          </div>

          <div className='flex flex-col space-y-2 my-4'>
            <label className='font-semibold' htmlFor='confirmPassword'>
              Confirm new password
            </label>
            <input
              id='confirmPassword'
              type='password'
              name='confirmPassword'
              value={confirmNewPass}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
              onChange={e => {
                setConfirmNewPass(e.target.value)
              }}
            />
          </div>

          <button type='submit' className='btn cyan-btn mt-4'>
            Submit
          </button>
        </form>
        <div className='w-[50%] p-4 ml-4'>
          {passSuccess && (
            <div className='mt-4 mb-[24px]' role='alert'>
              <span className='font-small'>{passSuccess}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ResetUserPass
