import { arrayOf, number, shape, string } from 'prop-types'

const destinationTenantShape = shape({
  clientTenantId: number.isRequired,
  tenantFriendlyName: string.isRequired,
})

const matchingVariableShape = shape({
  policyId: string.isRequired,
  variableId: string.isRequired,
  variableName: string.isRequired,
  variableValue: string.isRequired,
})

const selectedVariableValueShape = shape({
  clientTenantId: number.isRequired,
  tenantFriendlyName: string.isRequired,
  value: string.isRequired,
})

const selectedVariableShape = shape({
  id: string.isRequired,
  name: string.isRequired,
  sourceValue: string.isRequired,
  values: arrayOf(selectedVariableValueShape),
})

const variableValueShape = shape({
  clientTenantId: number,
  value: string,
})

export {
  destinationTenantShape,
  matchingVariableShape,
  selectedVariableShape,
  variableValueShape,
}
