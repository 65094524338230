import {
  faCheckCircle,
  faCircleXmark,
  faClock,
  faClose,
  faWarning,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'

const NotificationPanel = ({ open = true, onClose, notifications = [] }) => {
  const handleClick = e => {
    e.stopPropagation()
  }

  const buildNotificationIcon = notification => {
    if (!notification.startTime) {
      return (
        <FontAwesomeIcon
          icon={faClock}
          className='mr-2 notification-status-icon grey'
        />
      )
    }
    if (!notification.endTime) {
      return (
        <div id='loading-spinner' className='mr-2'>
          <div className='loading' />
        </div>
      )
    }
    if (notification.actionIssues) {
      return (
        <FontAwesomeIcon
          icon={faWarning}
          className='mr-2 notification-status-icon yellow'
        />
      )
    }
    if (!notification.isSuccess) {
      return (
        <FontAwesomeIcon
          icon={faCircleXmark}
          className='mr-2 notification-status-icon red'
        />
      )
    }
    return (
      <FontAwesomeIcon
        icon={faCheckCircle}
        className='mr-2 notification-status-icon cyan'
      />
    )
  }

  const buildNotificationItem = notification => {
    const {
      friendlyActionName,
      displayName,
      policyName,
      endMessage,
      startMessage,
      queueMessage,
      endTime,
      startTime,
      queueTime,
      actionIssues,
    } = notification

    const name = displayName ?? policyName
    const message = endMessage ?? startMessage ?? queueMessage
    const updateTime = startTime ?? queueTime

    let timeMessage = 'Awaiting Update'
    if (endTime) {
      const [date, time] = endTime.split('T')
      timeMessage = `Completed ${date} at ${time.substring(0, 5)}`
    } else if (updateTime) {
      const [date, time] = updateTime.split('T')
      timeMessage = `Updated ${date} at ${time.substring(0, 5)}`
    }

    return (
      <Fragment key={notification.id}>
        <div className='pt-4 pb-4 flex align-start'>
          <div className='mt-0'>{buildNotificationIcon(notification)}</div>
          <div className='ml-1'>
            <h3 className='text-lg'>{friendlyActionName}</h3>
            {name ? <p className='text-md'>{name}</p> : null}
            <p className='mb-2 text-sm'>{message}</p>
            {actionIssues ? (
              <>
                <p className='text-xs text-bold'>Issue(s) detected:</p>
                <p className='text-xs mb-2'>{actionIssues}</p>
              </>
            ) : null}
            <p className='text-xs'>{timeMessage}</p>
          </div>
        </div>
        <hr />
      </Fragment>
    )
  }

  return (
    <div
      className={`notification-list notification-panel fixed right-0 bg-white shadow-lg h-full ${open ? ' open' : ''}`}
      onClick={handleClick}
    >
      <div className='flex flex-row items-center justify-between pl-8 pr-8 pt-6 pb-6'>
        <h2 className='text-3xl'>Notifications</h2>
        <FontAwesomeIcon
          className='notification-list-close-btn cursor-pointer text-xl'
          icon={faClose}
          onClick={onClose}
        />
      </div>
      <hr />
      <div className='overflow-y-auto grow pl-8 pr-8 pt-2 pb-4'>
        {notifications.length > 0 ? (
          notifications.map(buildNotificationItem)
        ) : (
          <p className='mt-6'>You currently have no notifications.</p>
        )}
      </div>
    </div>
  )
}

export default NotificationPanel
