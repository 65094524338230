import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const useDeletePolicyTag = (onSuccess, onError) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['remove-policy-tag'],
    mutationFn: async tags => {
      await axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/policyTags/delete`,
        {
          tags,
        }
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries('policyTags')
      onSuccess()
    },
    onError,
  })
}

export default useDeletePolicyTag
