import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ssoUserQueryKey } from './useGetSSOUsersQuery'

const useRemoveUser = (onSuccess, onError) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['remove-user-sso'],
    mutationFn: async ({ users }) =>
      await axios.delete(`${process.env.REACT_APP_MIDDLEWARE_URL}/sso/users`, {
        data: {
          users,
        },
      }),
    onSuccess: data => {
      queryClient.invalidateQueries(ssoUserQueryKey)
      onSuccess(data)
    },
    onError,
  })
}

export default useRemoveUser
