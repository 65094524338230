import { arrayOf, bool, number, shape, string } from 'prop-types'
import { matchingVariableShape } from '../../../ClientVariables/shapes/shapes'

export const policyShape = shape({
  id: string.isRequired,
  policyTypeId: number.isRequired,
  displayName: string.isRequired,
  readOnly: bool,
  platform: string,
  description: string,
  tags: arrayOf(string),
  policyId: number,
  matchingVariableShape: arrayOf(matchingVariableShape),
})

export const policyCategoryShape = shape({
  name: string.isRequired,
  items: arrayOf(policyShape.isRequired),
})

export const primaryGroupShape = shape({
  name: string.isRequired,
  categories: arrayOf(policyCategoryShape.isRequired),
})

export default {
  policyShape,
  policyCategoryShape,
  primaryGroupShape,
}
