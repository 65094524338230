import Axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { clearPolicyComparisonCache } from './UsePolicyCompare'

export const useTenantGroups = () => {
  const [initialised, setInitialised] = useState()

  const [loading, setLoading] = useState(false)

  const [error, setError] = useState()

  /**
   * @type {[{name: string, clientTenantGroupId: any, clientTenantIds: string[], baselineClientTenantId: string}[] ,React.Dispatch<React.SetStateAction<never[]>>]}
   */
  const [groups, setGroups] = useState([])

  const fetchGroups = useCallback(async () => {
    try {
      setLoading(true)
      setError(undefined)

      const response = await Axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/tenantGroups`
      )
      if (response.status < 200 || response.status >= 300) {
        setError('Failed to fetch groups')
      }

      const groups = {}

      for (const group of response.data) {
        const existing = groups[group.clientTenantGroupId]
        if (!existing) {
          groups[group.clientTenantGroupId] = {
            name: group.name,
            clientTenantGroupId: group.clientTenantGroupId,
            clientTenantIds: group.clientTenantId ? [group.clientTenantId] : [],
            alignedThreshold: group.alignedThreshold,
            semiAlignedThreshold: group.semiAlignedThreshold,
            baselineClientTenantId: group.baselineClientTenantId,
          }
        } else {
          groups[group.clientTenantGroupId].clientTenantIds.push(
            group.clientTenantId
          )
        }
      }

      setGroups(Object.values(groups))
    } catch (fetchGroupsError) {
      setError('Failed to fetch groups')
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (initialised) return
    setInitialised(true)
    fetchGroups()
  }, [fetchGroups, initialised])

  const saveGroup = async (
    name,
    alignedThreshold,
    semiAlignedThreshold,
    baselineClientTenantId,
    clientTenantIds,
    id = null,
    onSaved = null
  ) => {
    try {
      setLoading(true)
      setError(undefined)

      const groupResponse = await Axios.put(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/tenantGroup`,
        {
          id,
          name,
          alignedThreshold: parseFloat(alignedThreshold),
          semiAlignedThreshold: parseFloat(semiAlignedThreshold),
          baselineClientTenantId,
        }
      )

      if (groupResponse.status < 200 || groupResponse.status >= 300) {
        setError('Failed to save group')
        return
      }

      const clientTenantGroupId = id ?? groupResponse.data.id

      const membersResponse = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/tenantGroupMembers`,
        {
          clientTenantGroupId,
          clientTenantIds,
        }
      )

      if (membersResponse.status < 200 || membersResponse.status >= 300) {
        setError('Failed to save group members')
        return
      }

      const group = {
        name,
        alignedThreshold: parseFloat(alignedThreshold),
        semiAlignedThreshold: parseFloat(semiAlignedThreshold),
        clientTenantGroupId,
        clientTenantIds,
        baselineClientTenantId,
      }

      if (groups.some(e => e.clientTenantGroupId === clientTenantGroupId)) {
        setGroups(
          groups.map(e =>
            e.clientTenantGroupId === clientTenantGroupId ? group : e
          )
        )
        clearPolicyComparisonCache()
      } else {
        setGroups([...groups, group])
      }

      onSaved?.()
    } catch (saveError) {
      setError('Failed to save group(s)')
    } finally {
      setLoading(false)
    }
  }

  const deleteGroup = async groupId => {
    try {
      setLoading(true)
      setError(undefined)

      const response = await Axios.delete(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/tenantGroup?id=${groupId}`
      )

      if (response.status < 200 || response.status >= 300) {
        setError('Failed to delete group')
        return
      }

      setGroups(groups.filter(e => e.clientTenantGroupId !== groupId))
      clearPolicyComparisonCache()
    } catch (deleteError) {
      setError('Failed to delete group')
    } finally {
      setLoading(false)
    }
  }

  return {
    loading,
    error,
    groups,
    fetchGroups,
    saveGroup,
    deleteGroup,
  }
}
