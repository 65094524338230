import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { bool, func, number, shape, string } from 'prop-types'
import { hexToRgb } from '../../utils/colours'
import Tag from '../Tag/Tag'

const CustomTagsOption = props => {
  const { data, innerRef, innerProps, isSelected } = props

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className='flex items-center justify-between py-1 px-2 cursor-pointer'
    >
      <Tag
        text={data.label}
        description={data.description}
        colourRGB={hexToRgb(data.colour)}
      />
      {isSelected && (
        <span className='ml-2 font-bold'>
          <FontAwesomeIcon icon={faCheck} />
        </span>
      )}
    </div>
  )
}

CustomTagsOption.defaultProps = {
  innerRef: null,
}

CustomTagsOption.propTypes = {
  data: shape({
    label: string.isRequired,
    description: string,
    colour: string.isRequired,
  }).isRequired,
  innerRef: func,
  innerProps: shape({
    onClick: func.isRequired,
    onMouseMove: func.isRequired,
    onMouseOver: func.isRequired,
    tabIndex: number.isRequired,
  }).isRequired,
  isSelected: bool.isRequired, // Added isSelected to handle selected state
}

export default CustomTagsOption
