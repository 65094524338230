import {
  faBook,
  faCalendarDays,
  faCloudArrowUp,
  faCodeCompare,
  faFileLines,
  faHouse,
  faMagnifyingGlass,
  faServer,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import StyledNavLink from './StyledNavLink'

const NavLinks = () => (
  <ul>
    <li>
      <StyledNavLink to='/' fontAwesomeIcon={faHouse} label='Home' />
    </li>

    <li>
      <StyledNavLink to='/backup' fontAwesomeIcon={faServer} label='Back Up' />
    </li>

    <li>
      <StyledNavLink
        to='/deploy'
        fontAwesomeIcon={faCloudArrowUp}
        label='Deploy'
      />
    </li>

    <li>
      <StyledNavLink
        to='/restore'
        fontAwesomeIcon={faCalendarDays}
        label='Restore'
      />
    </li>

    <li>
      <StyledNavLink
        to='/reporting'
        fontAwesomeIcon={faFileLines}
        label='Reporting'
      />
    </li>

    <li>
      <StyledNavLink
        to='/tenant-management'
        fontAwesomeIcon={faUsers}
        label='Tenants'
      />
    </li>

    <li>
      <StyledNavLink
        to='/tenant-alignment'
        fontAwesomeIcon={faCodeCompare}
        label='Tenant Alignment'
      />
    </li>

    <li>
      <StyledNavLink
        to='/policy-explorer'
        fontAwesomeIcon={faMagnifyingGlass}
        label='Tenant Explorer'
      />
    </li>

    <li>
      <StyledNavLink
        to='/shared-baselines'
        fontAwesomeIcon={faBook}
        label='Policy Library'
      />
    </li>
  </ul>
)

export default NavLinks
