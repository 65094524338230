import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const PolicyDiffSkeleton = () => (
  <>
    <div className=' w-1/2 px-2 pb-4'>
      <h3>
        <Skeleton count={1} />
      </h3>
    </div>
    <div className='flex w-full max-md:flex-col'>
      <div className='w-1/2 max-md:w-full px-2'>
        <Skeleton count={8} />
      </div>
      <div className='w-1/2 max-md:w-full px-2'>
        <Skeleton count={8} />
      </div>
    </div>
  </>
)

export default PolicyDiffSkeleton
