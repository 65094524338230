import { useMemo, useState } from 'react'
import { arrayOf, bool, string } from 'prop-types'
import { destinationTenantShape, variableValueShape } from './shapes/shapes'
import Modal from '../Modal/Modal'
import ListTable from '../ListTable/components/ListTable'
import matchSummaryTableDefinition from './summaryTableConfig'
import HiddenValueCell from '../TableCells/HiddenValueCell/HiddenValueCell'

const ProcessedClientVariableMatch = ({
  values,
  destinationTenants,
  variableName,
  hidden,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const count = values.length
  const total = destinationTenants.length

  const matches = destinationTenants.map(tenant => ({
    tenantName: tenant.tenantFriendlyName,
    value:
      values.find(v => v.clientTenantId === tenant.clientTenantId)?.value ??
      null,
    hidden,
  }))

  const matchColumns = useMemo(() => matchSummaryTableDefinition(), [])

  return count === 1 && count === total ? (
    <HiddenValueCell value={matches[0]?.value} hidden={hidden} />
  ) : (
    <>
      <button
        className='underline hover:cursor-pointer hover:opacity-80'
        onClick={() => setModalIsOpen(true)}
        type='button'
        aria-label='View Matches'
      >
        {count} of {total} tenants configured
      </button>
      <Modal
        isOpen={modalIsOpen}
        setModalOpen={() => setModalIsOpen(false)}
        closeOnBlur
      >
        <h3>
          Matches for{' '}
          <code className='bg-[#f1f1f1] rounded-sm p-1'>{variableName}</code>
        </h3>
        <ListTable uniqueKey='id' data={matches} columns={matchColumns} />
      </Modal>
    </>
  )
}

ProcessedClientVariableMatch.defaultProps = {
  hidden: false,
}

ProcessedClientVariableMatch.propTypes = {
  values: arrayOf(variableValueShape).isRequired,
  destinationTenants: arrayOf(destinationTenantShape).isRequired,
  variableName: string.isRequired,
  hidden: bool,
}

export default ProcessedClientVariableMatch
