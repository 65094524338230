import { arrayOf, func, number, shape, string } from 'prop-types'

const RadioGroup = ({ policyTypeId, options, selected, onChange }) => (
  <div className='flex flex-col pl-4 py-2 border-l-2'>
    {options.map(option => (
      <div
        className='flex flex-row items-center w-fit cursor-pointer'
        key={`${policyTypeId}-${option.value}`}
      >
        <input
          type='radio'
          className='appearance-none rounded-full bg-gray-100 border-2 cursor-pointer border-gray-300 checked:bg-gray-100 checked:border-4 checked:border-[rgb(var(--cyan))] w-4 h-4'
          onChange={event => onChange(event.target.value)}
          name={`${policyTypeId}-radio`}
          value={option.value}
          checked={selected === option.value}
          id={`${policyTypeId}-radio-${option.value}`}
        />
        <label
          htmlFor={`${policyTypeId}-radio-${option.value}`}
          className='ms-2 font-semibold text-gray-900 dark:text-gray-300 cursor-pointer'
        >
          {option.label}
        </label>
      </div>
    ))}
  </div>
)

RadioGroup.propTypes = {
  policyTypeId: number.isRequired,
  selected: string.isRequired,
  onChange: func.isRequired,
  options: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
    })
  ).isRequired,
}

export default RadioGroup
