import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { node } from 'prop-types'

export const TenantRefreshStateContext = createContext({})
export const useTenantRefreshStateContext = () =>
  useContext(TenantRefreshStateContext)

export const tenantRefreshStates = {
  none: 'none',
  actionInitiated: 'actionInitiated',
  awaitingUpdates: 'awaitingUpdatesFromServer',
}

const TenantRefreshStateProvider = ({ children }) => {
  const [tenantsBeingRefreshed, setTenantsBeingRefreshed] = useState([])

  const setTenantRefreshState = useCallback(
    (clientTenantId, newTenantRefreshState) => {
      setTenantsBeingRefreshed(prevState => [
        ...prevState.filter(tenant => tenant.clientTenantId !== clientTenantId),
        { state: newTenantRefreshState, clientTenantId },
      ])
    },
    []
  )

  const removeTenantBeingRefreshed = useCallback(tenantIdToCheck => {
    setTenantsBeingRefreshed(prevState =>
      prevState.filter(tenant => tenant.clientTenantId !== tenantIdToCheck)
    )
  }, [])

  const isTenantBeingRefreshed = useCallback(
    tenantIdToCheck =>
      tenantsBeingRefreshed.some(
        tenant => tenant.clientTenantId === tenantIdToCheck
      ),
    [tenantsBeingRefreshed]
  )

  const contextValue = useMemo(
    () => ({
      tenantsBeingRefreshed,
      setTenantRefreshState,
      removeTenantBeingRefreshed,
      isTenantBeingRefreshed,
    }),
    [
      tenantsBeingRefreshed,
      setTenantRefreshState,
      removeTenantBeingRefreshed,
      isTenantBeingRefreshed,
    ]
  )

  return (
    <TenantRefreshStateContext.Provider value={contextValue}>
      {children}
    </TenantRefreshStateContext.Provider>
  )
}

TenantRefreshStateProvider.propTypes = {
  children: node.isRequired,
}

export default TenantRefreshStateProvider
