import { bool, func, string } from 'prop-types'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import Skeleton from 'react-loading-skeleton'
import Alert from '../Alert/Alert'

import Modal from '../Modal/Modal'
import ModalActionButtons from '../Modal/ModalActionButtons'

import useDeleteClientVariable from './api/useDeleteClientVariable'

const ClientVariablesActions = ({
  id,
  name,
  description,
  hidden,
  type,
  builtIn,
  onEdit,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const deleteClientVariable = useDeleteClientVariable(() => {
    setIsOpen(false)
    toast.success(`${name} deleted successfully`)
  })

  return (
    <>
      <button
        aria-label='edit variable'
        onClick={() => onEdit({ id, name, description, hidden, type })}
        type='button'
        disabled={builtIn}
      >
        <FontAwesomeIcon
          icon={faEdit}
          className={`mx-1 ${builtIn ? 'opacity-50' : 'hover:cursor-pointer'}`}
        />
      </button>

      <button
        type='button'
        onClick={() => setIsOpen(true)}
        aria-label='delete variable'
        disabled={builtIn}
      >
        <FontAwesomeIcon
          icon={faTrash}
          className={`mx-1 ${builtIn ? 'opacity-50' : 'hover:cursor-pointer'}`}
        />
      </button>

      <Modal isOpen={isOpen} setModalOpen={() => setIsOpen(true)}>
        <>
          {deleteClientVariable.isPending ? (
            <Skeleton height={30} width='100%' />
          ) : (
            <>
              <h2 className='text-xl font-bold mb-4'>
                Delete variable "{name}"?
              </h2>
              <p>
                This cannot be undone and will remove any configured values for
                this variable from all tenants
              </p>
            </>
          )}

          {deleteClientVariable.isError && (
            <Alert
              type='error'
              title={deleteClientVariable?.error?.response?.data.message}
            >
              <ul>
                {deleteClientVariable.error?.response?.data?.errors.map(
                  error => (
                    <li key={error}>{error}</li>
                  )
                )}
              </ul>
            </Alert>
          )}

          <ModalActionButtons
            onCancel={() => setIsOpen(false)}
            onConfirm={() => deleteClientVariable.mutate(id)}
            confirmEnabled={!deleteClientVariable.isPending}
            cancelEnabled={!deleteClientVariable.isPending}
            cancelActionText='Cancel'
            confirmActionText='Confirm'
          />
        </>
      </Modal>
    </>
  )
}

ClientVariablesActions.defaultProps = {
  description: '',
}

ClientVariablesActions.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  onEdit: func.isRequired,
  description: string,
  hidden: bool.isRequired,
  type: string.isRequired,
  builtIn: bool.isRequired,
}

export default ClientVariablesActions
