import TagsInput from 'react-tagsinput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { arrayOf, bool, func, number, string } from 'prop-types'
import { useState } from 'react'

const domainValidationRegex =
  /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]?\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/

const SSODomainsInput = ({
  placeholder,
  onDomainChange,
  values,
  maxDomains,
  disabled,
}) => {
  const [showValidationErr, setShowValidationErr] = useState(false)
  const [input, setInput] = useState('')
  const splitPastedDomains = unsplitString =>
    unsplitString.split(' ').map(domain => domain.trim())

  return (
    <div>
      <div className='flex flex-row'>
        <div className='sso-input'>
          <TagsInput
            value={values}
            maxTags={maxDomains}
            disabled={disabled}
            addOnBlur
            onChangeInput={value => {
              setShowValidationErr(false)
              setInput(value.toLowerCase())
            }}
            inputValue={input}
            onChange={newData => {
              onDomainChange(newData)
            }}
            inputProps={{
              placeholder: values.length === maxDomains ? '' : placeholder,
              disabled: disabled || values.length === maxDomains,
            }}
            onlyUnique
            pasteSplit={splitPastedDomains}
            validationRegex={domainValidationRegex}
            onValidationReject={() => setShowValidationErr(true)}
          />
        </div>
        <button
          aria-label='clear input'
          type='button'
          className='btn'
          onClick={() => {
            setInput('')
            onDomainChange([])
            setShowValidationErr(false)
          }}
        >
          <FontAwesomeIcon icon={faTrash} size='lg' className='navy-text' />
        </button>
      </div>
      {showValidationErr && (
        <p className='text-red-600'>Must be a valid domain</p>
      )}
    </div>
  )
}

SSODomainsInput.defaultProps = {
  maxDomains: 1,
}

SSODomainsInput.propTypes = {
  values: arrayOf(string).isRequired,
  placeholder: string.isRequired,
  onDomainChange: func.isRequired,
  maxDomains: number,
  disabled: bool.isRequired,
}

export default SSODomainsInput
