import { useNavigate } from 'react-router-dom'
import cloud from '../../assets/cloud-scratching-head.png'

const ExceptionPage = () => {
  const navigate = useNavigate()

  const handleRedirectHome = () => {
    navigate('/')
    setTimeout(() => {
      window.location.reload()
    }, 100)
  }

  const handleRedirectRaiseTicket = () => {
    navigate('/?openIntercom=true')
    setTimeout(() => {
      window.location.reload()
    }, 100)
  }

  return (
    <main className='h-screen'>
      <div className='gap-3 flex flex-col items-center justify-center py-3 h-full'>
        <div className='w-2/3 text-center flex flex-col items-center gap-6'>
          <h2 className='text-4xl'>
            Well, this is awkward. I'll call the Inforcer to find out what
            happened!
          </h2>
          <img src={cloud} alt='cloud with rain' width='450' />
          <h3 className='text-gray-500 text-2xl'>
            Any extra information that could help us such as what you were
            trying to do when the error occurred would be invaluable
          </h3>
        </div>

        <div className='flex mt-4'>
          <button onClick={handleRedirectHome} className='btn cyan-btn mr-4'>
            Home
          </button>
          <button onClick={handleRedirectRaiseTicket} className='btn navy-btn'>
            Raise a support ticket
          </button>
        </div>
      </div>
    </main>
  )
}

export default ExceptionPage
