import { bool, func, string } from 'prop-types'
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './SSOFileInput.css'

const SSOFileInput = ({
  disabled,
  fileContent,
  setFileContent,
  onFileSubmit,
}) => {
  const handleFileUpload = e => {
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.onload = event => {
      setFileContent(event.target.result)
      onFileSubmit(event.target.result)
    }

    reader.readAsText(file)
  }

  return (
    <>
      <label htmlFor='file-upload' className='sso-file-upload'>
        <FontAwesomeIcon icon={faCloudUpload} className='mr-2' /> Upload a
        certificate file
        <input
          type='file'
          name='file-upload'
          onChange={handleFileUpload}
          disabled={disabled}
          className='ml-4 cursor-pointer sso-input'
        />
      </label>
      <textarea
        id='publicKey'
        name='publicKey'
        rows={10}
        cols={100}
        className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 focus:outline-blue-400 sso-input'
        value={fileContent}
        disabled={disabled}
        readOnly
      />
    </>
  )
}

SSOFileInput.defaultProps = {
  disabled: false,
  onFileSubmit: () => {},
}

SSOFileInput.propTypes = {
  disabled: bool,
  fileContent: string.isRequired,
  setFileContent: func.isRequired,
  onFileSubmit: func,
}

export default SSOFileInput
