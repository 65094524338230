import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SharedBaselinesGroupSkeleton = () => (
  <div className='shared-baselines-page md:container mx-auto'>
    <div className='flex items-center max-[1280px]:flex-wrap bg-white w-full rounded-xl mb-6'>
      <div className='searchbar w-full rounded-xl overflow-hidden'>
        <Skeleton count={3} />
      </div>
    </div>

    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-5'>
      <Skeleton count={3} />
      <Skeleton count={3} />
      <Skeleton count={3} />
    </div>

    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-5'>
      <Skeleton count={3} />
      <Skeleton count={3} />
    </div>

    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-5'>
      <Skeleton count={3} />
    </div>
  </div>
)

export default SharedBaselinesGroupSkeleton
