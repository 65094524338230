import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { CSVLink } from 'react-csv'
import { arrayOf, bool, func, object } from 'prop-types'
import Checkbox from '../Checkbox'

const CustomTableToolbar = ({
  rowData,
  exportCsvDisabled,
  hideBaselines,
  setHideBaselines,
}) => {
  const filteredRowData = rowData.map(({ policyDiff, ...rest }) => rest)

  return (
    <div className='flex flex-row items-center gap-2'>
      <CSVLink
        className={`btn text-white ${exportCsvDisabled ? 'pointer-events-none bg-gray-500 opacity-50' : 'cyan-btn'}`}
        data={filteredRowData}
        filename='inforcer_dashboard.csv'
      >
        <FontAwesomeIcon className='mr-2' icon={faDownload} />
        Export to CSV
      </CSVLink>
      <Checkbox
        id='hide-baselines'
        label='Hide Baselines'
        onChange={setHideBaselines}
        checked={hideBaselines}
      />
    </div>
  )
}

CustomTableToolbar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rowData: arrayOf(object).isRequired,
  exportCsvDisabled: bool.isRequired,
  hideBaselines: bool.isRequired,
  setHideBaselines: func.isRequired,
}

export default CustomTableToolbar
