import { Link } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import cloud from '../../assets/shrugging-cloud-404.png'

const titleText =
  "We checked our Inforcement registry and couldn't find the page you were looking for"

const Page404 = () => {
  const { show } = useIntercom()

  const handleRaiseTicket = () => {
    show('showSpace', 'home')
  }

  return (
    <div className='gap-3 flex flex-col items-center justify-center h-full p-20'>
      <div className='w-2/3 text-center flex flex-col items-center gap-10'>
        <h2 className='text-4xl'>{titleText}</h2>
        <img src={cloud} alt='cloud with rain' />
        <h3 className='text-gray-500 text-2xl mt-6'>
          If this problem persists please contact our fantastic customer support
          team
        </h3>
      </div>
      <div className='flex mt-4'>
        <Link to='/' className='btn cyan-btn mr-4'>
          Home
        </Link>
        <Link onClick={handleRaiseTicket} className='btn navy-btn'>
          Raise a support ticket
        </Link>
      </div>
    </div>
  )
}

export default Page404
