import { arrayOf, bool, number, shape, string } from 'prop-types'

const FormStepper = ({ formConfig, currentStepNumber }) => (
  <>
    {formConfig.map(
      ({ stepHeading, stepBody, stepKey, enabled }, index) =>
        enabled && (
          <div key={stepKey}>
            <div className='flex'>
              <div className='flex'>
                <div
                  className={`progress-number ${
                    index + 1 > currentStepNumber
                      ? 'bg-slate-300'
                      : 'cyan-bg text-white'
                  }`}
                >
                  {index + 1}
                </div>
                <div className='progress-text'>
                  <p className='navy-text'>
                    <strong>{stepHeading}</strong>
                  </p>
                  <p className='text-gray-400'>{stepBody}</p>
                </div>
              </div>
            </div>
            {index !== formConfig.length - 1 && (
              <div className='progress-bar-link' />
            )}
          </div>
        )
    )}
  </>
)

FormStepper.propTypes = {
  formConfig: arrayOf(
    shape({
      stepHeading: string,
      stepBody: string,
      stepKey: string,
      enabled: bool,
    })
  ).isRequired,
  currentStepNumber: number.isRequired,
}

export default FormStepper
