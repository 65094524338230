import intuneIcon from '../assets/icons/intune.png'
import entraIcon from '../assets/icons/entra.png'
import otherIcon from '../assets/icons/other.png'
import defenderIcon from '../assets/icons/defender.png'
import sharepointIcon from '../assets/icons/sharepoint.png'
import m365AdminCenterIcon from '../assets/icons/m365admin.png'

const icons = [
  {
    name: 'Intune',
    url: intuneIcon,
  },
  {
    name: 'Entra',
    url: entraIcon,
  },
  {
    name: 'Defender',
    url: defenderIcon,
  },
  {
    name: 'SharePoint',
    url: sharepointIcon,
  },
  {
    name: 'M365 Admin Center',
    url: m365AdminCenterIcon,
  },
  {
    name: 'Other',
    url: otherIcon,
  },
  // Add the rest of the products here
]

export default icons
