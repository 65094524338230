import {
  arrayOf,
  bool,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types'

export const summaryTenantShape = shape({
  clientTenantId: number.isRequired,
  alignmentTimestamp: string,
  pendingChanges: shape({
    failed: number.isRequired,
    inProgress: number.isRequired,
    hasPendingChanges: bool.isRequired,
    succeeded: number.isRequired,
  }),
  lastBackupTimestamp: string,
  tenantFriendlyName: string.isRequired,
  tenantDnsName: string.isRequired,
  refreshProgress: shape({
    failed: number.isRequired,
    inProgress: number.isRequired,
    succeeded: number.isRequired,
    isRefreshing: bool.isRequired,
  }).isRequired,
  tags: arrayOf(string).isRequired,
  alignmentScore: number,
})

export const listTenantShape = shape({
  partnerCenter: bool.isRequired,
  exchange: bool.isRequired,
  createdDate: string.isRequired,
  clientReference: string.isRequired,
  tenantDnsName: string.isRequired,
  backupEnabled: number.isRequired,
  tenantFriendlyName: string.isRequired,
  clientTenantId: number.isRequired,
  msTenantId: string.isRequired,
  applicationSecretExpiry: string.isRequired,
  applicationId: string.isRequired,
  isGoldenTenant: bool.isRequired,
  clientId: number.isRequired,
  tags: arrayOf(string).isRequired,
})

export const tenantShape = shape({
  clientTenantId: number.isRequired,
  tenantFriendlyName: string.isRequired,
  inforcerManaged: bool.isRequired,
  isBaseline: bool.isRequired,
  isSharedBaseline: bool.isRequired,
  tags: arrayOf(string).isRequired,
})

export const singleFilterShape = shape({
  label: string.isRequired,
  value: string.isRequired,
})

export const selectValueShape = oneOfType([
  arrayOf(singleFilterShape),
  singleFilterShape,
])

export const driftedDiffShape = arrayOf(
  shape({
    driftType: string.isRequired,
    isDrifted: bool.isRequired,
    driftedProperties: arrayOf(
      shape({
        deviationSubjectValue: oneOfType([string, arrayOf(string)]),
        baselineDrifted: oneOfType([bool, arrayOf(string)]),
        currentSubjectValue: oneOfType([string, arrayOf(string)]),
        deviationBaselineValue: oneOfType([string, arrayOf(string)]),
        currentBaselineValue: oneOfType([string, arrayOf(string)]),
        subjectDrifted: oneOfType([bool, arrayOf(string)]),
      })
    ).isRequired,
    path: string.isRequired,
  })
)

export const diffShape = arrayOf(
  shape({
    path: string.isRequired,
    subjectValue: oneOfType([string, bool, number, arrayOf(string)]),
    compressInStringify: bool.isRequired,
    dataType: string.isRequired,
    state: string.isRequired,
    diffType: number.isRequired,
    baselineValue: oneOfType([string, bool, number, arrayOf(string)])
      .isRequired,
  })
).isRequired

export const policyDataDiffShape = arrayOf(
  shape({
    policyGuid: string.isRequired,
    policyId: number.isRequired,
    diff: diffShape,
    driftedDiff: driftedDiffShape,
  })
)

export const tenantTagsShape = shape({
  value: string,
  label: string,
})

export const policyShape = shape({
  policyId: number,
  subjectPolicyId: number,
  policyNameKeyName: string,
  deviationAcceptedByUsername: string,
  // eslint-disable-next-line react/forbid-prop-types
  policyAssignmentsDiff: arrayOf(object),
  subjectTenantId: number.isRequired,
  policyGuid: string.isRequired,
  isDeviation: bool.isRequired,
  comparatorPolicyGuids: arrayOf(string),
  policyCategoryId: number.isRequired,
  deviationAcceptedTimestamp: string,
  readOnly: bool,
  isPolicyDeviationAcceptable: bool.isRequired,
  deviationAcceptedReason: string,
  isMissingFromBaseline: bool.isRequired,
  matchingBaselinePoliciesCount: number.isRequired,
  state: string.isRequired,
  policyTypeName: string.isRequired,
  policyODataType: string,
  policyDataDiff: policyDataDiffShape,
  isDeviationAccepted: bool.isRequired,
  isPolicyDeployable: bool.isRequired,
  priority: number,
  // eslint-disable-next-line react/forbid-prop-types
  similarPolicies: object,
  stringifiedDiff: string,
  isMissingFromSubject: bool.isRequired,
  policyTypeId: number.isRequired,
  baselineTenantId: number.isRequired,
  deviationAcceptedByUserId: number,
  policyName: string.isRequired,
  matchingSubjectPoliciesCount: number.isRequired,
  tags: arrayOf(string),
})

export const tagShape = shape({
  colour: string.isRequired,
  id: string.isRequired,
  tag: string.isRequired,
})

export default {
  summaryTenantShape,
  listTenantShape,
  tenantShape,
  policyShape,
  tagShape,
}
