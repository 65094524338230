/**
 * Copies the deployment summary to clipboard
 *
 * @param additionalPolicySettings - additionalSettingsConfig object
 * @param successfulPolicies - array of successful policies
 * @param erroredPolicies - array of errored policies
 * @param sourceTenantName - source tenant name
 * @param destinationTenantNames - array of destination tenant names
 */
const deployPolicySummaryClipboardText = (
  additionalPolicySettings,
  successfulPolicies,
  erroredPolicies,
  sourceTenantName,
  destinationTenantNames
) => {
  const additionalSettingsText = additionalPolicySettings
    .map(({ policyHeading, additionalSettings }) => {
      // iterate over additional settings
      const additionalSettingsString = additionalSettings
        .map(({ text, value, renderDropdownIfTrue }) => {
          const nestedOptionString = renderDropdownIfTrue
            ? `- ${renderDropdownIfTrue.find(option => option.value === true).text}`
            : ''
          return `${text} ${value ? 'Yes' : 'No'} ${nestedOptionString}`
        })
        .join('\n\n')
      return `${policyHeading}:\n${additionalSettingsString}`
    })
    .join('\n\n---------------------------\n\n')

  const errorSegment =
    erroredPolicies.length > 0
      ? `, \nPolicy Deployment Errors:\n\n${erroredPolicies.map(err => err.displayName).join('\n')}`
      : ''

  return `Source Tenant: ${sourceTenantName}, Destination Tenants: ${destinationTenantNames.join('\n')}\n\nPolicies Queued for Deployment:\n${successfulPolicies.join(
    '\n'
  )}\n\nAdditional settings:\n\n${additionalSettingsText}${errorSegment}\n`
}

export default deployPolicySummaryClipboardText
