import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const useCreateBaselineGroup = (onSuccess, onError) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ isEdit, data }) => {
      let response

      if (isEdit) {
        response = await axios.put(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/internal/sharedBaselineGroups/${data.id}`,
          {
            data,
          }
        )
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/internal/sharedBaselineGroups`,
          {
            data,
          }
        )
      }
      return response.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries('sharedBaselineGroupsAdmin')
      onSuccess()
    },
    onError,
  })
}

export default useCreateBaselineGroup
