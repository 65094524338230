import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Axios from 'axios'
import { useNavigate } from 'react-router-dom'
import PauseClientsList from '../components/PauseClientsList'
import PauseClientPopup from '../components/PauseClientPopup'
import SearchBar from '../components/SearchBar'

const PauseClient = () => {
  const [clients, setClients] = useState([])
  const [clientQuery, setClientQuery] = useState('')
  const [selectedClient, setSelectedClient] = useState(null)
  const [allClients, setAllClients] = useState([])
  const [requestError, setRequestError] = useState('')
  const [responseData, setResponseData] = useState('')
  const [openPopup, setOpenPopup] = useState(false)
  const [loading, setLoading] = useState(false)
  const [clientSearch, setClientSearch] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    fetchClients()
  }, [])

  const fetchClients = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/get-clients`
      )
      const { data } = response
      setAllClients(data)
      setClients(data)
    } catch (error) {
      console.error('Error:', error.response.status)
      toast.error('Error Retrieving Clients')
    } finally {
      setLoading(false)
    }
  }

  const filterClients = searchValue => {
    if (searchValue) {
      // If the search query is provided perform local filtering
      const filteredClients = allClients.filter(client =>
        client.displayName.toLowerCase().includes(searchValue.toLowerCase())
      )
      setClients(filteredClients)
    } else {
      // else fetch all tenants from the API
      setClients(allClients)
    }
  }

  const submitData = async () => {
    try {
      setLoading(true)

      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/toggleClient`,
        {
          clientId: selectedClient.clientId,
          isEnabled: Number(selectedClient.isEnabled),
        }
      )

      setResponseData(response.data)
    } catch (error) {
      setRequestError('Error. Please check the details and try again.')
      setResponseData('')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenPopUp = () => {
    setOpenPopup(true)
  }

  const handleClosePopUp = () => {
    setOpenPopup(false)
  }

  const closePopUpRefreshPolicies = () => {
    setSelectedClient(null)
    setResponseData('')
    setClientSearch('')
    filterClients('')
    fetchClients()
    setOpenPopup(false)
  }

  return (
    <div className='ui-panel tenant-management list-all-clients'>
      <div className='heading mb-[20px]'>
        <h2>Pause Clients</h2>
      </div>

      <div className='action-body no-progress-bar'>
        <div className='select-tenant-heading'>
          <h4>Search Clients</h4>
        </div>
        <SearchBar
          searchValue={clientSearch}
          onSearchChange={newSearchValue => {
            setClientSearch(newSearchValue)
            filterClients(newSearchValue)
          }}
          onClear={() => {
            setClientSearch('')
            filterClients('')
            setSelectedClient(null)
          }}
        />
        <PauseClientsList
          clients={clients}
          clientQuery={clientQuery}
          selectedClient={selectedClient}
          setSelectedClient={setSelectedClient}
          setClientQuery={setClientQuery}
        />
      </div>

      <div className='mt-6 gap-3 flex'>
        <button
          type='button'
          onClick={() => navigate(-1)}
          className='btn navy-btn'
        >
          Back
        </button>
        <button
          type='button'
          className='btn cyan-btn'
          disabled={selectedClient === null}
          onClick={() => handleOpenPopUp()}
        >
          {selectedClient?.isEnabled ? 'Enable selected' : 'Disable selected'}
        </button>

        {openPopup === true && (
          <PauseClientPopup
            handleOpenPopUp={handleOpenPopUp}
            handleClosePopUp={handleClosePopUp}
            closePopUpRefreshPolicies={closePopUpRefreshPolicies}
            submitData={submitData}
            responseData={responseData}
            selectedClient={selectedClient}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </div>
    </div>
  )
}

export default PauseClient
