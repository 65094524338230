import {
  faExclamationTriangle,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { bool, string } from 'prop-types'
import { useState } from 'react'

const maskString = text =>
  text.length <= 5
    ? '*'.repeat(text.length)
    : text[0] + '*'.repeat(text.length - 2) + text[text.length - 1]

const HiddenValueCell = ({ value, hidden }) => {
  const [isValueHidden, setIsValueHidden] = useState(hidden)

  if (!value) {
    return (
      <span className='text-gray-400'>
        <FontAwesomeIcon icon={faExclamationTriangle} className='mr-2' />
        None - default value will be used
      </span>
    )
  }

  if (isValueHidden) {
    return (
      <>
        <span className='text-gray-400'>{maskString(value)}</span>
        {hidden && (
          <button
            type='button'
            className='ml-2 underline'
            aria-label='Show value'
            onClick={() => setIsValueHidden(false)}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        )}
      </>
    )
  }

  return (
    <span>
      {value}
      {hidden && !isValueHidden && (
        <button
          type='button'
          className='ml-2 underline'
          aria-label='Hide value'
          onClick={() => setIsValueHidden(true)}
        >
          <FontAwesomeIcon icon={faEyeSlash} />
        </button>
      )}
    </span>
  )
}

HiddenValueCell.defaultProps = {
  value: null,
  hidden: false,
}

HiddenValueCell.propTypes = {
  value: string,
  hidden: bool,
}

export default HiddenValueCell
