import { PropTypes } from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const PolicyPickerSkeleton = ({ loadingTitle }) => (
  <>
    <h4 className='mb-3'>{loadingTitle}</h4>
    {[...Array(7)].map((_, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`loader-${idx}`} className='mb-3'>
        <Skeleton height={64} />
      </div>
    ))}
  </>
)

PolicyPickerSkeleton.propTypes = {
  loadingTitle: PropTypes.string.isRequired,
}

export default PolicyPickerSkeleton
