import PropTypes from 'prop-types'
import { getStringBetween } from './utils'
import HorizontalDivider from '../HorizontalDivider'

const NoPolicyDiffContent = (
  <div>
    <h2 className='text-xl pb-4'>No Policy Difference Found</h2>
    <p>
      We were unable to find any policy differences between the last two
      backups, this could be for a number of reasons. If you have recently run a
      backup and are expecting changes, please contact our team for further
      assistance.
    </p>
  </div>
)

const PolicyDiffContent = ({ policyDiff }) => {
  if (!policyDiff) return NoPolicyDiffContent
  const titleSplit = policyDiff.split('\r\n\r\n')
  const title = titleSplit[0]
  const changedPoliciesContent = getStringBetween(
    policyDiff,
    'Changed Policies:[\r\n]+',
    '[\r\n]+Added Policies'
  )
  const addedPoliciesContent = getStringBetween(
    policyDiff,
    'Added Policies:[\r\n]+',
    '[\r\n]+Removed Policies:'
  )
  const removedPoliciesContent = getStringBetween(
    policyDiff,
    'Removed Policies:[\r\n]+',
    ''
  )

  return (
    <div className='flex flex-col gap-3 whitespace-pre-wrap'>
      <div>
        <h3 className='text-md'>{title}</h3>
        <HorizontalDivider />
      </div>
      <div>
        <h4 className='text-xl pb-1'>Changed Policies</h4>
        {changedPoliciesContent.replace(/(\r\n|\n|\r)/gm, '') ? (
          <p>{changedPoliciesContent}</p>
        ) : (
          <p className='opacity-80'>No Policy Changes</p>
        )}
      </div>
      <div>
        <h4 className='text-xl pb-1'>Added Policies</h4>
        {addedPoliciesContent.replace(/(\r\n|\n|\r)/gm, '') ? (
          <p>{addedPoliciesContent}</p>
        ) : (
          <p className='opacity-80'>No Policies Added</p>
        )}
      </div>
      <div>
        <h4 className='text-xl pb-1'>Removed Policies</h4>
        {removedPoliciesContent.replace(/(\r\n|\n|\r)/gm, '') ? (
          <p>{removedPoliciesContent}</p>
        ) : (
          <p className='opacity-80'>No Policies Removed</p>
        )}
      </div>
    </div>
  )
}

PolicyDiffContent.propTypes = {
  policyDiff: PropTypes.string.isRequired,
}

export default PolicyDiffContent
