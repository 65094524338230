const customStyles = {
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color, // Use color from option data
    ':hover': {
      backgroundColor: '#178bdb',
      color: 'white',
    },
  }),
  menuPortal: base => ({ ...base, zIndex: 4 }),
}

export default customStyles
