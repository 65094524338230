import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const useClientVariables = (isEnabled = true) =>
  useQuery({
    queryKey: ['clientVariables'],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/variables`
      )
      return response.data
    },
    enabled: isEnabled,
  })

export default useClientVariables
