import { Link } from 'react-router-dom'
import logo from '../assets/inforcer-logo.png'

const ForcePasswordRedirect = () => (
  <div className='login-page force-password-redirect'>
    <div className='login-box text-center'>
      <img src={logo} className='inforcer-logo' alt='Inforcer Logo' />
      <h3 className='mb-4'>Page not found</h3>
      <p className='mb-[40px]'>
        Please follow the link below to update your password
      </p>
      <Link to='/update-password' className='btn cyan-btn'>
        Update password
      </Link>
    </div>
  </div>
)

export default ForcePasswordRedirect
