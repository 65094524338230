import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faCircleMinus,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons'
import { bool } from 'prop-types'

const ConnectionStatusIcon = ({ connectionStatus }) => {
  if (connectionStatus === null)
    return (
      <FontAwesomeIcon
        title='Connection Status unknown'
        icon={faCircleMinus}
        size='xl'
        color='grey'
      />
    )
  if (!connectionStatus)
    return (
      <FontAwesomeIcon
        title='Connection Status not connected'
        icon={faCircleXmark}
        size='xl'
        color='red'
      />
    )
  return (
    <FontAwesomeIcon
      title='Connection Status connected'
      icon={faCheckCircle}
      size='xl'
    />
  )
}

ConnectionStatusIcon.defaultProps = {
  connectionStatus: null,
}

ConnectionStatusIcon.propTypes = {
  connectionStatus: bool,
}

export default ConnectionStatusIcon
