import { useState } from 'react'
import { bool, func } from 'prop-types'
import { useSearchParams } from 'react-router-dom'
import logo from '../../assets/inforcer-logo.png'

import SSOButton from './SSOButton'
import getSSOError from './SSOErrorCodes'

/**
 * Render the SSO login panel
 * @param {bool} show - Whether to show the SSO login panel
 * @param {function} normalLogin - Callback to switch to the normal login panel
 */
const SSOLogin = ({ show, normalLogin }) => {
  const [searchParams] = useSearchParams()
  const errCode = searchParams.get('errCode')

  const initialError = getSSOError(errCode)

  const [email, setEmail] = useState('')
  const [error, setError] = useState(initialError)

  const handleChange = event => {
    const { value } = event.target
    setEmail(value)
    setError(null)
  }

  const validateEmail = input => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(input)
  }

  const startSSOLogin = e => {
    e.preventDefault()
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.')
    }

    localStorage.setItem('username', email)

    window.location.replace(
      `${process.env.REACT_APP_MIDDLEWARE_URL}/login/sso?email=${email}`
    )
  }

  return (
    <form onSubmit={startSSOLogin} hidden={!show}>
      <img src={logo} alt='Inforcer' className='inforcer-logo' />
      <div className='login-box-inner'>
        {error && <p className='text-red-500 mb-[24px]'>{error}</p>}
        <p>Sign in with your Microsoft account</p>
        <input
          type='text'
          placeholder='Email'
          aria-label='SSO Email'
          className={`flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 mb-[20px] focus:outline-blue-400 ${error ? 'border-red-500' : ''}`}
          value={email}
          name='email'
          onChange={handleChange}
          autoComplete='email'
        />
        <SSOButton
          text='Sign in with Microsoft'
          onClick={startSSOLogin}
          lightMode
        />
        <button
          className='btn bg-white text-gray-900 flex items-center justify-center mb-2'
          style={{ border: '1px solid #ddd' }}
          type='button'
          onClick={normalLogin}
        >
          Back
        </button>
      </div>
    </form>
  )
}

SSOLogin.propTypes = {
  show: bool.isRequired,
  normalLogin: func.isRequired,
}

export default SSOLogin
