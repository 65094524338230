import Axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import {
  pendingChangesStates,
  usePendingChangesStateContext,
} from '../../../contexts/PendingChangesStateContext'
import {
  tenantRefreshStates,
  useTenantRefreshStateContext,
} from '../../../contexts/TenantRefreshStateContext'

const areAnyTenantsRefreshing = tenants =>
  tenants.some(tenant => tenant.refreshProgress.isRefreshing)
const areAnyTenantsPendingChanges = alignedTenants =>
  alignedTenants.some(tenant => tenant.pendingChanges.hasPendingChanges)

/**
 * This function is used to determine if we need to continue polling for pending changes on tenants
 * @param state tanstack query data
 * @param pendingChangesState current state of pending changes
 * @param setPendingChangesState function to set the state of pending changes
 * @param refetchTenantComparison function to refetch the tenant comparison
 * @param setPolicyIdsWithPendingChanges function to set the policy guids with pending changes
 * @returns {boolean} whether we need to continue polling
 */
const refetchUntilPendingChangesResolved = (
  { state },
  pendingChangesState,
  setPendingChangesState,
  refetchTenantComparison,
  setPolicyIdsWithPendingChanges
) => {
  if (state.status !== 'success') return false

  // if we have intiated the action and detected pending changes we need to wait for them to be resolved
  if (
    pendingChangesState === pendingChangesStates.actionInitiated &&
    areAnyTenantsPendingChanges(state.data.tenants)
  ) {
    setPendingChangesState(pendingChangesStates.awaitingPendingChanges)
  }

  // if we are waiting for pending changes and they have been resolved we can refetch the tenant comparison
  if (
    pendingChangesState === pendingChangesStates.awaitingPendingChanges &&
    !areAnyTenantsPendingChanges(state.data.tenants)
  ) {
    setPendingChangesState(pendingChangesStates.none)
    setPolicyIdsWithPendingChanges([])
    refetchTenantComparison()
  }

  return (
    pendingChangesState === pendingChangesStates.actionInitiated ||
    pendingChangesState === pendingChangesStates.awaitingPendingChanges
  )
}

/**
 * This function is used to determine if we need to continue polling for tenant refreshing
 * @param state useQuery state & data
 * @param tenantRefreshState current state of tenant refreshing
 * @param setTenantRefreshState function to set the refresh state
 * @param refetchTenantComparison function to refetch the tenant comparison
 * @returns {boolean} whether we need to continue polling
 */
const refetchUntilTenantFinishedRefreshing = (
  { state },
  isTenantBeingRefreshed
) => {
  if (state.status !== 'success') return false

  const allTenants = [state.data.baselineTenant, ...state.data.tenants]

  const refreshInitiatedForAnyTenant = allTenants.some(tenant =>
    isTenantBeingRefreshed(tenant.clientTenantId)
  )

  return refreshInitiatedForAnyTenant || areAnyTenantsRefreshing(allTenants)
}

const refetchIntervalSeconds = 3

/**
 * This function determines if we need to continue polling for the tenant summary
 * @param query tanstack query data
 * @param pendingChangesState current state of pending changes
 * @param setPendingChangesState function to set the state of pending changes
 * @param refetchTenantComparison function to refetch the tenant comparison
 * @param setPolicyIdsWithPendingChanges function to set the policy guids with pending changes
 * @param isTenantBeingRefreshed
 * @returns {number|boolean} the interval to refetch the tenant summary or false if we don't need to refetch
 */
const getRefetchInterval = (
  query,
  pendingChangesState,
  setPendingChangesState,
  refetchTenantComparison,
  setPolicyIdsWithPendingChanges,
  isTenantBeingRefreshed
) => {
  const refreshInProgress = refetchUntilTenantFinishedRefreshing(
    query,
    isTenantBeingRefreshed
  )
  const pendingChangesInProgress = refetchUntilPendingChangesResolved(
    query,
    pendingChangesState,
    setPendingChangesState,
    refetchTenantComparison,
    setPolicyIdsWithPendingChanges
  )

  return pendingChangesInProgress || refreshInProgress
    ? refetchIntervalSeconds * 1000
    : false
}

/**
 * This function is used to get the tenant summary
 * @param baselineGroupId
 * @param setBaselineTenant
 * @param refetchTenantComparison
 * @param setNumPendingChanges
 * @returns {UseQueryResult<any, DefaultError>}
 */
const useGetTenantSummary = (
  baselineGroupId,
  setBaselineTenant,
  refetchTenantComparison,
  setNumPendingChanges
) => {
  const {
    pendingChangesState,
    setPendingChangesState,
    setPolicyIdsWithPendingChanges,
  } = usePendingChangesStateContext()
  const {
    removeTenantBeingRefreshed,
    tenantsBeingRefreshed,
    setTenantRefreshState,
    isTenantBeingRefreshed,
  } = useTenantRefreshStateContext()

  const getTenantSummaryQuery = useQuery({
    queryKey: ['tenant-summary', baselineGroupId],
    refetchOnWindowFocus: false,
    gcTime: 0,
    queryFn: async () => {
      const response = await Axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/tenant-alignment/summary`,
        { params: { baselineGroupId } }
      )
      return response.data
    },
    refetchInterval: query =>
      getRefetchInterval(
        query,
        pendingChangesState,
        setPendingChangesState,
        refetchTenantComparison,
        setPolicyIdsWithPendingChanges,
        isTenantBeingRefreshed
      ),
  })

  useEffect(() => {
    if (!getTenantSummaryQuery.data) return

    setBaselineTenant(getTenantSummaryQuery.data.baselineTenant)
    setNumPendingChanges(getTenantSummaryQuery.data.tenants)

    const allTenants = [
      ...getTenantSummaryQuery.data.tenants,
      getTenantSummaryQuery.data.baselineTenant,
    ]

    if (
      tenantsBeingRefreshed.length === 0 &&
      allTenants.some(tenant => tenant.refreshProgress.isRefreshing)
    ) {
      allTenants.forEach(tenant => {
        if (tenant.refreshProgress.isRefreshing) {
          setTenantRefreshState(
            tenant.clientTenantId,
            tenantRefreshStates.awaitingUpdates
          )
        }
      })
    }

    tenantsBeingRefreshed.forEach(tenantRefreshState => {
      const refreshingTenantData = allTenants.find(
        tenantData =>
          tenantData.clientTenantId === tenantRefreshState.clientTenantId
      )
      // safety clause just in case the tenant is no longer in the tenant summary
      if (!refreshingTenantData) {
        removeTenantBeingRefreshed(tenantRefreshState.clientTenantId)
        return
      }
      // if the tenant is refreshing and we have initiated the action set to awaiting updates
      if (
        tenantRefreshState.state === tenantRefreshStates.actionInitiated &&
        refreshingTenantData.refreshProgress.isRefreshing
      ) {
        setTenantRefreshState(
          tenantRefreshState.clientTenantId,
          tenantRefreshStates.awaitingUpdates
        )
      }
      // if the tenant is awaiting updates and it is no longer refreshing remove it from the list
      if (
        tenantRefreshState.state === tenantRefreshStates.awaitingUpdates &&
        !refreshingTenantData.refreshProgress.isRefreshing
      ) {
        removeTenantBeingRefreshed(tenantRefreshState.clientTenantId)
        refetchTenantComparison()
      }
    })
  }, [
    setBaselineTenant,
    getTenantSummaryQuery.data,
    setNumPendingChanges,
    tenantsBeingRefreshed,
    removeTenantBeingRefreshed,
    setTenantRefreshState,
    refetchTenantComparison,
  ])

  return getTenantSummaryQuery
}
export default useGetTenantSummary
