import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { toast } from 'react-toastify'

const useCreateOrUpdateTenantTag = (onSuccess, isUpdate = false) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: tagData => {
      if (isUpdate) {
        return axios.put(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/tenantTags/${tagData.id}`,
          tagData
        )
      }
      return axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/tenantTags`,
        tagData
      )
    },
    onSuccess: () => {
      toast.success(`Tag ${isUpdate ? 'updated' : 'created'} successfully!`)
      queryClient.invalidateQueries(['tenantTags'])
      onSuccess()
    },
  })
}

export default useCreateOrUpdateTenantTag
