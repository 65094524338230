import { useMemo, useState } from 'react'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import PolicyPickerV2 from '../../components/Policies/PolicyPicker/PolicyPicker'
import TenantSkeleton from '../../components/LoadingSkeletons/TenantSkeleton'
import FormStepper from '../../components/FormStepper/FormStepper'
import ListTable from '../../components/ListTable/components/ListTable'
import useGetTenants from '../../components/SharedBaselines/api/useGetTenants'
import TenantTypeCell from '../../components/TableCells/TenantTypeCell/TenantTypeCell'
import tenantTypeFilter from '../../components/TableCells/TenantTypeCell/filters'
import TenantTagsCell from '../../components/TableCells/TenantTagsCell/TenantTagsCell'
import useTenantTags from '../../components/TenantTags/api/useTenantTags'
import uniqueTenantTags from '../../utils/uniqueTenantTags'
import ErrorHandlerAlert from '../../components/ErrorHandlerAlert/ErrorHandlerAlert'
import { useSelection } from '../../components/Policies/PolicyPicker/context/SelectionContext'
import { TenantProvider } from '../../contexts/TenantContext'
import { useExpansion } from '../../components/Policies/PolicyPicker/context/ExpansionContext'
import BulkActionsMenu from '../../components/Policies/PolicyPicker/components/BulkActionsMenu'

const tenantExplorerFormConfig = [
  {
    stepHeading: 'Select Tenant',
    stepBody: 'Select Tenant',
    stepKey: 'stepKey1',
    enabled: true,
  },
  {
    stepHeading: 'Select Policy',
    stepBody: 'Select Policy',
    stepKey: 'stepKey2',
    enabled: true,
  },
]

const TenantExplorer = () => {
  const {
    state: { selectedPolicies },
    resetSelectionState,
  } = useSelection()

  const { resetExpansionState } = useExpansion()

  // Form Logic
  const [formNo, setFormNo] = useState(1)

  // tells us whether a tenant is a shared baseline tenant
  const {
    data: tenants,
    isLoading: isTenantsLoading,
    isError: isTenantsError,
  } = useGetTenants()

  const { data: tenantTags, isLoading: isTenantTagsLoading } = useTenantTags()

  const [selectedSourceTenant, setSelectedSourceTenant] = useState([])

  // Parse data for posting
  const [formResponses, setFormResponses] = useState([])

  // Timeline navigation
  const next = () => {
    if (formNo === 1) {
      if (!selectedSourceTenant) {
        return
      }

      const stepOneRes = { step: formNo, response: selectedSourceTenant }

      // Find the existing response for step 1 in the formResponses array
      const existingResponseIndex = formResponses.findIndex(
        response => response.step === formNo
      )

      if (existingResponseIndex !== -1) {
        // If selected a different tenant then reset the formResponses
        if (
          selectedSourceTenant[0].clientTenantId !==
          formResponses[existingResponseIndex].response[0].clientTenantId
        ) {
          setFormResponses([stepOneRes])
          resetSelectionState()
        }
      } else {
        // Clicking next will create a response in formResponses for step one [0]
        setFormResponses([stepOneRes])
      }

      setFormNo(formNo + 1)
    } else if (formNo === 2) {
      if (selectedPolicies.length === 0) {
        return
      }

      const stepTwoRes = { step: formNo, response: selectedPolicies }

      // Find the existing response for step 1 in the formResponses array
      const existingResponseIndex = formResponses.findIndex(
        response => response.step === formNo
      )

      if (existingResponseIndex !== -1) {
        // If an existing response exists, replace it with the latest response
        const updatedResponses = [...formResponses]
        updatedResponses[existingResponseIndex] = stepTwoRes
        setFormResponses(updatedResponses)
      } else {
        // Otherwise, add the response to the array
        setFormResponses(prevFormResponses => [
          ...prevFormResponses,
          stepTwoRes,
        ])
      }

      resetExpansionState()

      setFormNo(formNo + 1)
    } else {
      toast.error('Please complete all input fields')
    }
  }

  const pre = () => {
    if (formNo === 2) {
      resetExpansionState()
    }
    setFormNo(formNo - 1)
  }

  const uniqueTags = uniqueTenantTags({
    tenants: tenants?.data,
    tenantTags,
  })

  const tenantsColumns = useMemo(
    () => [
      {
        header: 'Tenant Name',
        accessor: 'tenantFriendlyName',
      },
      {
        header: 'Type',
        accessor: 'type',
        size: 200,
        filterVariant: 'multi-select',
        filterSelectOptions: [
          'Baseline',
          'Shared Baseline',
          'Inforcer Managed',
          'Customer',
        ],
        filterFn: tenantTypeFilter,
        cell: ({ row }) => TenantTypeCell({ row }),
      },

      {
        header: 'Tags',
        accessor: 'tags',
        cell: ({ row }) =>
          TenantTagsCell({
            linkedTenantTagIds: row.original.tags,
            allTenantTags: tenantTags,
            numVisibleTags: 4,
          }),
        size: 700,
        minSize: 100,
        maxSize: 800,
        grow: true,
        filterVariant: 'multi-select',
        filterSelectOptions: uniqueTags.map(tag => ({
          value: tag.id,
          label: tag.tag,
        })),
      },
    ],
    [tenantTags, uniqueTags]
  )

  if (isTenantsError) {
    return (
      <ErrorHandlerAlert
        error={isTenantsError}
        title='Error fetching tenants'
      />
    )
  }

  return (
    <div className='ui-panel deploy-journey '>
      <div className='action-body '>
        <div className='progress-bar'>
          <div className='heading'>
            <h2>Tenant Explorer</h2>
            <p>Explore Policies in your chosen Tenant</p>
          </div>

          <FormStepper
            currentStepNumber={formNo}
            formConfig={tenantExplorerFormConfig}
          />
        </div>
        <div className='mt-[50px] action-body-form px-1.5'>
          {isTenantsLoading && (
            <TenantSkeleton loadingTitle='Loading tenants...' />
          )}
          {formNo === 1 && !isTenantsLoading && (
            <>
              <ListTable
                selectedListItems={selectedSourceTenant}
                setSelectedListItems={setSelectedSourceTenant}
                uniqueKey='clientTenantId'
                columns={tenantsColumns}
                data={tenants?.data ?? []}
                enableMultiRowSelection={false}
                loading={isTenantTagsLoading}
                enableRowSelection
              />

              <div className='mt-6 gap-3 flex'>
                <button
                  type='button'
                  onClick={next}
                  disabled={selectedSourceTenant.length === 0}
                  className='btn cyan-btn'
                >
                  Next
                </button>
              </div>
            </>
          )}
          {formNo === 2 && (
            <>
              <TenantProvider tenant={selectedSourceTenant}>
                <PolicyPickerV2 bulkActions={<BulkActionsMenu />} />
              </TenantProvider>

              <div className='mt-6 ml-6 gap-3 flex'>
                <button type='button' onClick={pre} className='btn navy-btn'>
                  Previous
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default TenantExplorer
