import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { object, string } from 'prop-types'

const StyledNavLink = ({ label, fontAwesomeIcon, to }) => (
  <NavLink to={to}>
    <span>
      {fontAwesomeIcon && <FontAwesomeIcon icon={fontAwesomeIcon} />}
      {label}
    </span>
  </NavLink>
)
StyledNavLink.defaultProps = {
  label: null,
  fontAwesomeIcon: undefined,
}

StyledNavLink.propTypes = {
  label: string,
  // Preferable to use PropTypes.shape but that's just not worth atm
  // eslint-disable-next-line react/forbid-prop-types
  fontAwesomeIcon: object,
  to: string.isRequired,
}

export default StyledNavLink
