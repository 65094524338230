import { useMemo, useState } from 'react'
import { PublicClientApplication } from '@azure/msal-browser'
import { useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { useOnboarding } from '../hooks/UseOnboarding'
import OnboardingRetrieveCustomers from '../components/OnboardingRetrieveCustomers'
import OnboardingRevokePermission from '../components/OnboardingRevokePermission'
import OnboardingSelectCustomers from '../components/OnboardingSelectCustomers'
import OnboardingSetupTenant from '../components/OnboardingSetupTenant'
import OnboardingSetupAllTenants from '../components/OnboardingSetupAllTenants'
import { configuration } from '../config/OnboardingConfig'

import Alert from '../components/Alert/Alert'
import useTenantTags from '../components/TenantTags/api/useTenantTags'

export const pca = new PublicClientApplication(configuration)

const onboardingSteps = [
  {
    title: 'Retrieve Customers',
    description: 'Fetch your Partner Center customers.',
  },
  {
    title: 'Select Customers',
    description: 'Choose the customers you want to onboard.',
  },
  {
    title: 'Onboard Tenants',
    description: 'Set up your customer tenancies.',
  },
  {
    title: 'Remove CSP Permissions',
    description: "Revoke Inforcer's access to Partner Center.",
  },
]

const Onboarding = () => {
  const {
    loading,
    error,
    state,
    skipFindCustomers,
    getCustomers,
    deleteCspEpa,
    addCustomer,
    confirmCustomers,
    updateCustomerMetadata,
    skipCustomer,
    onboardCustomer,
    onboardAllCustomers,
    reset,
  } = useOnboarding()

  const navigate = useNavigate()

  const [selectedTenantTags, setSelectedTenantTags] = useState([])

  const {
    data: tenantTags,
    isLoading: isTenantTagsLoading,
    isTenantTagsError,
    error: tenantTagsErrors,
  } = useTenantTags()

  const openTenants = () => {
    navigate('/tenant-management')
  }

  const currentStep = useMemo(() => {
    if (!state.cspEpaAdded) {
      return 0
    }
    if (state.customerProgress.length === 0) {
      return 1
    }
    if (
      state.customerProgress.some(
        p => p.status !== 'complete' && p.status !== 'error'
      )
    ) {
      return 2
    }
    if (!state.cspEpaRemoved) {
      return 3
    }
    return 4
  }, [state.cspEpaAdded, state.cspEpaRemoved, state.customerProgress])

  const buildStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <OnboardingRetrieveCustomers
            onClick={getCustomers}
            onSkip={skipFindCustomers}
            loading={loading}
          />
        )
      case 1:
        return (
          <OnboardingSelectCustomers
            onConfirm={confirmCustomers}
            onAddCustomer={addCustomer}
            customers={state.customers}
          />
        )
      case 2:
        if (isTenantTagsLoading) {
          return <Skeleton count={5} />
        }
        if (isTenantTagsError) {
          return (
            <Alert type='error' title='Error fetching tenant tags'>
              <div className='flex flex-col gap-1'>
                {tenantTagsErrors.response.data.errors.map(errorMessage => (
                  <div key={errorMessage}>{errorMessage}</div>
                ))}
              </div>
            </Alert>
          )
        }
        return state.onboardInParallel ? (
          <OnboardingSetupAllTenants
            state={state}
            onOnboardAllCustomers={onboardAllCustomers}
            tenantTags={tenantTags}
            setSelectedTenantTags={setSelectedTenantTags}
            selectedTenantTags={selectedTenantTags}
          />
        ) : (
          <OnboardingSetupTenant
            state={state}
            loading={loading}
            onUpdateMetadata={updateCustomerMetadata}
            onOnboardCustomer={onboardCustomer}
            onSkip={skipCustomer}
            tenantTags={tenantTags}
            setSelectedTenantTags={setSelectedTenantTags}
            selectedTenantTags={selectedTenantTags}
          />
        )
      case 3:
        return (
          <OnboardingRevokePermission
            onClick={deleteCspEpa}
            loading={loading}
          />
        )
      case 4:
        return (
          <>
            <h3 className='mb-4'>Onboarding completed</h3>
            {state.customerProgress.some(p => p.status === 'complete') && (
              <>
                <p className='mb-6'>Your selected tenants are now set-up!</p>
                <div className='mb-4'>
                  <Alert
                    title='The following tenants have been onboarded'
                    type='success'
                  >
                    <ul>
                      {/* render li for customerProgress when status === 'complete */}
                      {state.customerProgress
                        .filter(p => p.status === 'complete')
                        .map(p => (
                          <li key={p.customerId}>{p.friendlyName}</li>
                        ))}
                    </ul>
                  </Alert>
                </div>
              </>
            )}

            {state.customerProgress.some(p => p.status === 'error') && (
              <div className='mb-4'>
                <Alert
                  title='The following tenants could not be onboarded'
                  type='warning'
                >
                  <ul>
                    {/* render li for customerProgress when status === 'error */}
                    {state.customerProgress
                      .filter(p => p.status === 'error')
                      .map(p => (
                        <li key={p.customerId} className='font-semibold'>
                          {p.friendlyName}
                          <ul>
                            {p.log
                              .filter(l => l.status === 'error')
                              .map(l => (
                                <li key={l.message} className='font-light'>
                                  {l.message}
                                </li>
                              ))}
                          </ul>
                        </li>
                      ))}
                  </ul>
                </Alert>
              </div>
            )}
            <button
              type='button'
              className='btn cyan-btn'
              onClick={openTenants}
            >
              View Tenants
            </button>
          </>
        )
      default:
        return null
    }
  }

  const buildProgressBarItem = (step, title, description) => (
    <div key={step}>
      <div className='flex'>
        <div className='flex'>
          <div
            className={`progress-number ${currentStep >= step ? 'cyan-bg text-white' : 'bg-slate-300'}`}
          >
            {step + 1}
          </div>
          <div className='progress-text'>
            <p className='navy-text'>
              <strong>{title}</strong>
            </p>
            <p className='text-gray-400'>{description}</p>
          </div>
        </div>
      </div>
      {step !== onboardingSteps.length - 1 && (
        <div className='progress-bar-link' />
      )}
    </div>
  )

  return (
    <div className='ui-panel deploy-journey'>
      <div className='heading'>
        <h2>Tenant Onboarding</h2>
      </div>
      <div className='action-body'>
        <div className='progress-bar'>
          {onboardingSteps.map((s, i) =>
            buildProgressBarItem(i, s.title, s.description)
          )}
        </div>
        <div className='action-body-form wider'>
          <div>
            {error && (
              <Alert title={error?.title} type={error?.type}>
                {error?.message}
              </Alert>
            )}
            {buildStep()}

            {currentStep >= 1 ? (
              <>
                <hr className='my-10' />
                <p>
                  If you are experiencing issues onboarding your customers, you
                  can reset the onboarding flow by clicking the button below.{' '}
                  <b>
                    This may leave unwanted temporary Enterprise Applications on
                    your Azure tenancies.
                  </b>
                </p>
                <button
                  type='button'
                  onClick={reset}
                  disabled={loading}
                  className='btn navy-btn my-6'
                >
                  Restart
                </button>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Onboarding
