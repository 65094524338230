import { useMemo } from 'react'

const useGroupedOptions = (groupedPolicies, isLoading) =>
  useMemo(() => {
    if (isLoading) return []

    const products = new Set()
    const primaryGroups = new Set()

    groupedPolicies.forEach(group => {
      products.add(group.name)
      group.subGroups.forEach(subGroup => {
        primaryGroups.add(subGroup.name)
      })
    })

    return [
      {
        label: 'Product',
        options: Array.from(products).map(product => ({
          value: product,
          label: product,
          group: 'Product',
        })),
      },
      {
        label: 'Primary Group',
        options: Array.from(primaryGroups).map(primaryGroup => ({
          value: primaryGroup,
          label: primaryGroup,
          group: 'Primary Group',
        })),
      },
    ]
  }, [groupedPolicies, isLoading])

export default useGroupedOptions
