import { func, shape, string } from 'prop-types'

const FormTextArea = ({ register, placeholder, label, errors }) => {
  const registerProps = register()

  return (
    <div className='flex flex-col'>
      <label htmlFor={label}>{label}</label>
      <textarea
        id={label}
        {...registerProps}
        placeholder={placeholder}
        className='flex rounded border p-2 border-gray-400 bg-white text-gray-700 focus:outline-blue-400'
      />
      {errors && <p className='text-red-500'>{errors.message}</p>}
    </div>
  )
}

FormTextArea.defaultProps = {
  errors: undefined,
  label: '',
  placeholder: '',
}

FormTextArea.propTypes = {
  register: func.isRequired,
  errors: shape({
    message: string,
  }),
  label: string,
  placeholder: string,
}

export default FormTextArea
