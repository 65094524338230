import { bool, func, string } from 'prop-types'
import microsoftLogo from '../../assets/microsoft.svg'
import './SSOButton.css'

/**
 * A button to sign in with Microsoft SSO
 * @param {string} text - The text to display on the button
 * @param {function} onClick - The function to call when the button is clicked
 * @param {bool} lightMode - Whether to display the button in light mode (default: false)
 */
const SSOButton = ({ text, onClick, lightMode, disabled }) => (
  <button
    className={`ms-button ${lightMode ? 'ms-button-light' : ''} btn cyan-btn flex items-center justify-center mb-2`}
    type='button'
    onClick={onClick}
    disabled={disabled}
  >
    <img src={microsoftLogo} alt='Microsoft Login' className='ms-login' />
    {text}
  </button>
)

SSOButton.propTypes = {
  onClick: func.isRequired,
  text: string.isRequired,
  lightMode: bool,
  disabled: bool,
}

SSOButton.defaultProps = {
  lightMode: false,
  disabled: false,
}

export default SSOButton
