import Skeleton from 'react-loading-skeleton'

const AlignmentTenantsListSkeleton = () => (
  <div className='flex flex-col gap-2'>
    <h3 className='text-lg'>
      <Skeleton width='50%' />
    </h3>
    <Skeleton height='80px' />
    <h3 className='text-lg'>
      <Skeleton width='50%' />
    </h3>
    <Skeleton height='80px' count={3} />
  </div>
)

export default AlignmentTenantsListSkeleton
