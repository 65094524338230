import { useEffect, useState } from 'react'
import { string } from 'prop-types'

import { useSelection } from '../context/SelectionContext'
import Policy from '../Policy/Policy'
import ExpandButton from '../components/ExpandButton'
import { useExpansion } from '../context/ExpansionContext'
import { filterOutReadOnly } from '../utils/utils'
import Tooltip from '../../../tooltip/Tooltip'
import { keyDownHandler } from '../../../../utils/accessibility'
import { policyCategoryShape } from '../utils/propTypes'

/**
 * PolicyCategory Component
 *
 * This component renders a list of policies under a specified  It displays the top-level category name and iterates through any subgroup items to show relevant policies within those subgroups.
 *
 * @param {Object} category - The category object containing the top-level category name e.g. configuration profiles and associated subgroup policies.
 * @param {string} parentGroup - The top-level product name e.g. Entra
 * @returns {JSX.Element} The rendered component displaying the policies for the specified
 */
const PolicyCategory = ({ category, parentGroup }) => {
  const { isExpanded, toggleExpand } = useExpansion()
  const {
    state: { selectedPolicies },
    selectGroup,
  } = useSelection()
  const [isGroupSelected, setIsGroupSelected] = useState(false)

  const { name, items } = category

  const policies = filterOutReadOnly(items)

  useEffect(() => {
    setIsGroupSelected(
      policies?.length
        ? policies.every(item =>
            selectedPolicies.some(policy => policy.id === item.id)
          )
        : false
    )
  }, [selectedPolicies, policies])

  const handleGroupSelect = () => {
    const newGroupSelectionState = !isGroupSelected
    setIsGroupSelected(newGroupSelectionState)

    selectGroup(policies, newGroupSelectionState)
  }

  return (
    <div
      className={`mb-5 bg-white rounded ${isExpanded(parentGroup, name) ? 'border' : ''}`}
    >
      <div
        onClick={() => toggleExpand({ parentGroup, name })}
        onKeyDown={e =>
          keyDownHandler(e, () => toggleExpand({ parentGroup, name }))
        }
        role='button'
        tabIndex={0}
        className='flex items-center p-4 bg-neutral-100 cursor-pointer w-full text-left rounded'
      >
        <Tooltip content={`Select all in ${name}`}>
          <input
            type='checkbox'
            className='mr-4'
            checked={isGroupSelected}
            onChange={handleGroupSelect}
            onClick={e => e.stopPropagation()}
            disabled={policies?.length === 0}
          />
        </Tooltip>
        <span className='font-semibold'>{name}</span>
        <div className='ml-auto flex items-center'>
          <ExpandButton isExpanded={isExpanded(parentGroup, name)} />
        </div>
      </div>
      {isExpanded(parentGroup, name) && (
        <div className='bg-white p-2'>
          <div className='flex justify-between p-2'>
            <span className='text-gray-600 text-sm'>Policy Name</span>
            <span className='text-gray-600 text-sm'>Policy JSON</span>
          </div>
          <div>
            {items.map(policy => (
              <Policy key={policy.id} item={policy} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

PolicyCategory.propTypes = {
  category: policyCategoryShape.isRequired,
  parentGroup: string.isRequired,
}

export default PolicyCategory
