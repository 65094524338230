import { useMemo } from 'react'
import { filterOutReadOnly } from '../utils/utils'

const useAllItems = (groupedPolicies, isLoading) =>
  useMemo(() => {
    if (isLoading) return []
    return groupedPolicies.flatMap(group =>
      group.subGroups.flatMap(subGroup =>
        subGroup.categories
          .flatMap(category => category.items)
          .filter(policy => !policy.readOnly)
      )
    )
  }, [groupedPolicies, isLoading])

export default useAllItems
