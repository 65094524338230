/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
import { useFeatureFlag } from 'configcat-react'
import { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { MRT_GlobalFilterTextField } from 'material-react-table'
import { manageVariablesTableColumnDefinitions } from './tableConfig'
import ListTable from '../ListTable/components/ListTable'
import useClientVariables from './api/useClientVariables'
import ContentPanel from '../ContentPanel/ContentPanel'
import ClientVariablesForm from './ClientVariablesForm'
import Alert from '../Alert/Alert'

const ClientVariablesSettings = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [variableToEdit, setVariableToEdit] = useState(undefined)
  const { value: enableClientVariables } = useFeatureFlag(
    'enableClientVariables',
    false
  )

  const {
    data: clientVariables,
    isPending: isClientVariablesLoading,
    isError: isClientVariablesError,
    error: clientVariablesError,
  } = useClientVariables(enableClientVariables)

  const togglePanel = () => setIsOpen(!isOpen)

  const columns = useMemo(() => {
    const handleEditVariable = variableData => {
      setVariableToEdit(variableData)
      setIsOpen(true)
    }
    return manageVariablesTableColumnDefinitions({
      handleEditVariable,
      setIsContentPanelOpen: () => setIsOpen(false),
    })
  }, [])

  const errorMessage =
    clientVariablesError?.response?.data?.message ||
    'Error fetching client variables'

  return (
    <div>
      <ListTable
        uniqueKey='id'
        data={clientVariables ?? []}
        columns={columns}
        renderTopToolbar={({ table }) => (
          <div className='flex gap-3 items-center py-2'>
            <MRT_GlobalFilterTextField table={table} />
            <button
              type='button'
              className='btn bg-inforcer-cyan text-white'
              onClick={togglePanel}
            >
              <FontAwesomeIcon icon={faPlus} className='mr-1' />
              New
            </button>
          </div>
        )}
        loading={isClientVariablesLoading}
        enableRowSelection={false}
        enableMultiRowSelection={false}
      />
      {isClientVariablesError && (
        <Alert type='error' title={errorMessage}>
          <ul>
            {clientVariablesError?.response?.data?.errors?.map(error => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </Alert>
      )}
      <ContentPanel
        isOpen={isOpen}
        togglePanel={togglePanel}
        panelHeading={variableToEdit ? 'Edit Variable' : 'Create New Variable'}
      >
        <ClientVariablesForm
          closePanel={() => setIsOpen(false)}
          variableId={variableToEdit?.id}
          initialName={variableToEdit?.name}
          initialDescription={variableToEdit?.description}
          initialHidden={variableToEdit?.hidden}
          initialType={variableToEdit?.type}
          onSuccessfulAction={() => setVariableToEdit(undefined)}
        />
      </ContentPanel>
    </div>
  )
}

export default ClientVariablesSettings
