import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const useGetTenantsByClients = clientId =>
  useQuery({
    queryKey: ['client-tenants-list', clientId],
    queryFn: async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/list-tenants-by-client`,
        {
          clientId: Number(clientId),
        }
      )
      return response.data
    },
  })

export default useGetTenantsByClients
