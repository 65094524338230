import { useMemo } from 'react'
import { arrayOf } from 'prop-types'
import { useFeatureFlag } from 'configcat-react'
import { selectedVariableShape } from './shapes/shapes'
import { matchingVariablesTableDefinition } from './tableConfig'
import ListTable from '../ListTable/components/ListTable'

const SelectedVariablesSummary = ({ selectedVariables }) => {
  const matchTableColumns = useMemo(() => {
    // extract the unique destination tenants from the selected variables
    const destinationTenants = [
      ...new Map(
        selectedVariables
          ?.flatMap(v =>
            v.values?.map(({ clientTenantId, tenantFriendlyName }) => ({
              clientTenantId,
              tenantFriendlyName,
            }))
          )
          .map(item => [item.clientTenantId, item])
      ).values(),
    ]

    return matchingVariablesTableDefinition({
      destinationTenants,
    })
  }, [selectedVariables])

  const { value: enableClientVariables } = useFeatureFlag(
    'enableClientVariables',
    false
  )

  if (!enableClientVariables) {
    return null
  }

  if (selectedVariables.length === 0) {
    return (
      <div>
        <h4 className='text-lg pt-4'>
          <strong>Selected variables:</strong>
        </h4>
        <p>No variables selected.</p>
      </div>
    )
  }
  return (
    <div>
      <h4 className='text-lg pt-4'>
        <strong>Selected variables:</strong>
      </h4>
      <ListTable
        uniqueKey='id'
        data={selectedVariables}
        columns={matchTableColumns}
        enableRowSelection={false}
        enableMultiRowSelection={false}
      />
    </div>
  )
}

SelectedVariablesSummary.defaultProps = {
  selectedVariables: [],
}

SelectedVariablesSummary.propTypes = {
  selectedVariables: arrayOf(selectedVariableShape),
}

export default SelectedVariablesSummary
