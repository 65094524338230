// ADDITIONAL OPTIONS

export const deployPolicyDisabled = {
  text: 'Force policies to deploy in disabled state?',
  flagName: 'deployPolicyDisabled',
  value: true,
}

export const overwriteExistingPolicy = {
  text: 'Overwrite policy with same name at destination tenant?',
  flagName: 'overwriteExistingPolicy',
  value: false,
}

export const deployLocation = {
  text: 'Deploy location assignments?',
  flagName: 'deployLocation',
  value: true,
}

export const deployNonStandardApplications = {
  text: 'Deploy non-standard cloud application assignments?',
  flagName: 'deployNonStandardApplications',
  value: true,
}

export const deployAuthenticationStrengths = {
  text: 'Deploy authentication assignments?',
  flagName: 'deployAuthenticationStrengths',
  value: true,
}

export const deployNotificationTemplatesAndActions = {
  text: 'Deploy actions and notification template assignments?',
  flagName: 'deployNotificationTemplatesAndActions',
  value: true,
}

export const matchGroupAssignCreate = {
  text: 'Copy group (create if needed)',
  flagName: 'matchGroupAssignCreate',
  value: true,
}

export const matchGroupAssignDiscard = {
  text: 'Copy group (if already exists)',
  flagName: 'matchGroupAssignDiscard',
  value: false,
}

/** Explicit changes to policy types: 3,4,5,6,10 */

export const groupAssignmentFilterActions = {
  text: 'Deploy group and filter assignments?',
  flagName: 'deployAssignments',
  value: true,
}

export const matchGroupFilterAssignCreate = {
  text: 'Match to groups and filters in destination, create groups and filters if not present and assign',
  flagName: 'matchGroupAssignCreate',
  value: true,
}
export const matchGroupFilterAssignDiscard = {
  text: 'Match to groups and filters in destination, discard assignments if not present',
  flagName: 'matchGroupAssignDiscard',
  value: false,
}

/** End  */

export const deployAllUsers = {
  text: 'Assign to All Users',
  flagName: 'deployAllUsers',
  value: false,
}

export const deployAllDevices = {
  text: 'Assign to All Devices',
  flagName: 'deployAllDevices',
  value: false,
}

export const deployAllUsersAndDevices = {
  text: 'Assign to All Users & All Devices',
  flagName: 'deployAllUsersAndDevices',
  value: false,
}

export const groupAssignmentActions = {
  text: 'Deploy group assignments?',
  flagName: 'deployAssignments',
  value: true,
}

export const deployAllDomains = {
  text: 'Deploy to all domains?',
  flagName: 'deployAllDomains',
  value: false,
}

export const deployPriorityHigh = {
  text: 'Deploy with high priority?',
  flagName: 'deployPriorityHigh',
  value: true,
}

export const mailFlowRuleModeRadioOptions = [
  {
    label: 'Enforce',
    value: 'enforce',
  },
  {
    label: 'Test with Policy Tips',
    value: 'testWithPolicyTips',
  },
  {
    label: 'Test without Policy Tips',
    value: 'testWithoutPolicyTips',
  },
]

export const customQuarantineRadioOptions = [
  {
    label: 'Default Custom Policy',
    value: 'CustomPolicy',
  },
  {
    label: 'Default Full Access Policy',
    value: 'DefaultFullAccessPolicy',
  },
  {
    label: 'Admin Only Access Policy',
    value: 'AdminOnlyAccessPolicy',
  },
  {
    label: 'Default Full Access With Notification Policy',
    value: 'DefaultFullAccessWithNotificationPolicy',
  },
]

export const mailFlowRuleMode = {
  text: 'Mail Flow Rule Mode',
  flagName: 'mailFlowRuleMode',
  hideToggle: true,
  radioOptions: mailFlowRuleModeRadioOptions,
  value: mailFlowRuleModeRadioOptions[0].value,
}

export const customQuarantinePolicies = {
  text: 'Deploy Custom Quarantine Policies?',
  flagName: 'quarantineTagBehaviour',
  hideToggle: true,
  radioOptions: customQuarantineRadioOptions,
  value: customQuarantineRadioOptions[0].value,
}

/** Currently this is the single source of truth for additional settings configuration
 *  in the Deploy & Talignment journeys. Backup needs to be refactored still.
 *  We use the renderDropdownIfTrue for dropdowns/selects and the render exchange
 *  options for Exchange policies.
 */
export const additionalSettingsConfig = [
  {
    policyId: 1,
    policyTypeId: 1,
    policyHeading: 'Conditional Access Policies',
    additionalSettings: [
      deployPolicyDisabled,
      overwriteExistingPolicy,
      {
        ...groupAssignmentActions,
        renderDropdownIfTrue: [
          matchGroupAssignCreate,
          matchGroupAssignDiscard,
          deployAllUsers,
        ],
      },
      deployLocation,
      deployNonStandardApplications,
      deployAuthenticationStrengths,
    ],
  },
  {
    policyId: 2,
    policyTypeId: 2,
    policyHeading: 'Conditional Access Named Location',
    additionalSettings: [overwriteExistingPolicy],
  },
  {
    policyId: 3,
    policyTypeId: 3,
    policyHeading: 'Device Compliance Policies',
    additionalSettings: [
      overwriteExistingPolicy,
      {
        ...groupAssignmentFilterActions,
        renderDropdownIfTrue: [
          matchGroupFilterAssignCreate,
          matchGroupFilterAssignDiscard,
          deployAllUsers,
          deployAllDevices,
          deployAllUsersAndDevices,
        ],
      },
      deployNotificationTemplatesAndActions,
    ],
  },
  {
    policyId: 4,
    policyTypeId: 4,
    policyHeading: 'Device Configurations',
    additionalSettings: [
      overwriteExistingPolicy,
      {
        ...groupAssignmentFilterActions,
        renderDropdownIfTrue: [
          matchGroupFilterAssignCreate,
          matchGroupFilterAssignDiscard,
          deployAllUsers,
          deployAllDevices,
          deployAllUsersAndDevices,
        ],
      },
    ],
  },
  {
    policyId: 5,
    policyTypeId: 5,
    policyHeading: 'Group Policy Configurations',
    additionalSettings: [
      overwriteExistingPolicy,
      {
        ...groupAssignmentFilterActions,
        renderDropdownIfTrue: [
          matchGroupFilterAssignCreate,
          matchGroupFilterAssignDiscard,
          deployAllUsers,
          deployAllDevices,
          deployAllUsersAndDevices,
        ],
      },
    ],
  },
  {
    policyId: 6,
    policyTypeId: 6,
    policyHeading: 'Managed App Policies',
    additionalSettings: [
      overwriteExistingPolicy,
      {
        ...groupAssignmentFilterActions,
        renderDropdownIfTrue: [
          matchGroupFilterAssignCreate,
          matchGroupFilterAssignDiscard,
        ],
      },
    ],
  },
  {
    policyId: 9,
    policyTypeId: 9,
    policyHeading: 'Windows Autopilot Deployment Profiles',
    additionalSettings: [overwriteExistingPolicy],
  },
  {
    policyId: 10,
    policyTypeId: 10,
    policyHeading: 'Configuration Policies',
    additionalSettings: [
      overwriteExistingPolicy,
      {
        ...groupAssignmentFilterActions,
        renderDropdownIfTrue: [
          matchGroupFilterAssignCreate,
          matchGroupFilterAssignDiscard,
          deployAllUsers,
          deployAllDevices,
          deployAllUsersAndDevices,
        ],
      },
    ],
  },
  {
    policyId: 11,
    policyTypeId: 11,
    policyHeading: 'Intents',
    additionalSettings: [
      overwriteExistingPolicy,
      {
        ...groupAssignmentActions,
        renderDropdownIfTrue: [
          matchGroupAssignCreate,
          matchGroupAssignDiscard,
          deployAllUsers,
          deployAllDevices,
          deployAllUsersAndDevices,
        ],
      },
    ],
  },
  {
    policyId: 12,
    policyTypeId: 12,
    policyHeading: 'Entra/AAD Settings',
    additionalSettings: [],
  },
  {
    policyId: 14,
    policyTypeId: 14,
    policyHeading: 'Sharepoint Settings',
    additionalSettings: [],
  },
  {
    policyId: 15,
    policyTypeId: 15,
    policyHeading: 'Exchange Org-Level Settings',
    additionalSettings: [],
  },
  {
    policyId: 16,
    policyTypeId: 16,
    policyHeading: 'Organisation Settings',
    additionalSettings: [],
  },
  {
    policyId: 17,
    policyTypeId: 17,
    policyHeading: 'Conditional Access Authentication Strength',
    additionalSettings: [
      {
        ...overwriteExistingPolicy,
      },
    ],
  },
  {
    policyId: 18,
    policyTypeId: 18,
    policyHeading: 'Compliance Policy Message Templates',
    additionalSettings: [
      {
        ...overwriteExistingPolicy,
      },
    ],
  },
  {
    policyId: 19,
    policyTypeId: 19,
    policyHeading: 'Anti-Phishing (Defender 365) Settings',
    additionalSettings: [
      deployPolicyDisabled,
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [deployPriorityHigh],
      },
      customQuarantinePolicies,
    ],
  },
  {
    policyId: 20,
    policyTypeId: 20,
    policyHeading: 'Anti-Malware (Defender 365) Settings',
    additionalSettings: [
      deployPolicyDisabled,
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [deployPriorityHigh],
      },
      customQuarantinePolicies,
      deployAllDomains,
    ],
  },
  {
    policyId: 21,
    policyTypeId: 21,
    policyHeading: 'Safe Attachments (Defender 365) Settings',
    additionalSettings: [
      deployPolicyDisabled,
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [deployPriorityHigh],
      },
      customQuarantinePolicies,
      deployAllDomains,
    ],
  },
  {
    policyId: 22,
    policyTypeId: 22,
    policyHeading: 'Safe Links (Defender 365) Settings',
    additionalSettings: [
      deployPolicyDisabled,
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [deployPriorityHigh],
      },
      deployAllDomains,
    ],
  },
  {
    policyId: 23,
    policyTypeId: 23,
    policyHeading: 'Spam Policy (Inbound) (Defender 365) Settings',
    additionalSettings: [
      deployPolicyDisabled,
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [deployPriorityHigh],
      },
      customQuarantinePolicies,
    ],
  },
  {
    policyId: 24,
    policyTypeId: 24,
    policyHeading: 'Spam Policy (Outbound) (Defender 365) Settings',
    additionalSettings: [
      deployPolicyDisabled,
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [deployPriorityHigh],
      },
    ],
  },
  {
    policyId: 25,
    policyTypeId: 25,
    policyHeading: 'Mail Flow Rules',
    additionalSettings: [
      deployPolicyDisabled,
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [deployPriorityHigh],
      },
      mailFlowRuleMode,
    ],
  },
  {
    policyId: 26,
    policyTypeId: 26,
    policyHeading: 'Custom Indicators',
    additionalSettings: [overwriteExistingPolicy],
  },
  {
    policyId: 27,
    policyTypeId: 27,
    policyHeading: 'Defender Custom Detection Rules',
    additionalSettings: [overwriteExistingPolicy],
  },
  {
    policyId: 28,
    policyTypeId: 28,
    policyHeading: 'Feature Update Profiles',
    additionalSettings: [
      overwriteExistingPolicy,
      {
        ...groupAssignmentActions,
        renderDropdownIfTrue: [matchGroupAssignCreate, matchGroupAssignDiscard],
      },
    ],
  },
  {
    policyId: 29,
    policyTypeId: 29,
    policyHeading: 'Quality Update Profiles',
    additionalSettings: [
      overwriteExistingPolicy,
      {
        ...groupAssignmentActions,
        renderDropdownIfTrue: [matchGroupAssignCreate, matchGroupAssignDiscard],
      },
    ],
  },
  {
    policyId: 30,
    policyTypeId: 30,
    policyHeading: 'Driver Update Profiles',
    additionalSettings: [
      overwriteExistingPolicy,
      {
        ...groupAssignmentActions,
        renderDropdownIfTrue: [matchGroupAssignCreate, matchGroupAssignDiscard],
      },
    ],
  },
  {
    policyId: 31,
    policyTypeId: 31,
    policyHeading: 'Assignment filters',
    additionalSettings: [overwriteExistingPolicy],
  },
  {
    policyId: 33,
    policyTypeId: 33,
    policyHeading: 'Quarantine Policy (Defender 365)',
    additionalSettings: [overwriteExistingPolicy],
  },
  {
    policyId: 34,
    policyTypeId: 34,
    policyHeading: 'Intune Scripts (Windows)',
    additionalSettings: [
      overwriteExistingPolicy,
      {
        ...groupAssignmentActions,
        renderDropdownIfTrue: [
          matchGroupAssignCreate,
          matchGroupAssignDiscard,
          deployAllUsers,
          deployAllDevices,
          deployAllUsersAndDevices,
        ],
      },
    ],
  },
  {
    policyId: 35,
    policyTypeId: 35,
    policyHeading: 'Intune Scripts (macOS)',
    additionalSettings: [
      overwriteExistingPolicy,
      {
        ...groupAssignmentActions,
        renderDropdownIfTrue: [
          matchGroupAssignCreate,
          matchGroupAssignDiscard,
          deployAllUsers,
          deployAllDevices,
          deployAllUsersAndDevices,
        ],
      },
    ],
  },
]

export const createNewAdditionalSettingsConfig = () =>
  JSON.parse(JSON.stringify(additionalSettingsConfig))

/**
 * Updates a single additional setting given the below params
 *
 * @param settingsConfig the entire settingsConfig object
 * @param policyId the policy ID for the policy you want to update
 * @param settingName the name of the additional setting flag
 * @param newValue new value (true or false)
 * @returns new settings config with updated value or existing object if an error occurred
 */
export const updateAdditionalSettingValue = (
  settingsConfig,
  policyId,
  settingName,
  newValue
) => {
  // find policy config with policyId
  const singlePolicyIndex = settingsConfig.findIndex(
    config => config.policyId === policyId
  )

  if (singlePolicyIndex === -1) {
    console.error(
      `Could not update additional setting value: No matching ID for ${policyId}`
    )
    return settingsConfig
  }

  // get the respective additional setting to update
  const additionalSettingIndex = settingsConfig[
    singlePolicyIndex
  ].additionalSettings.findIndex(
    additionalSetting => additionalSetting.flagName === settingName
  )

  if (additionalSettingIndex === -1) {
    console.error(
      `Could not update additional setting value: No matching flagName for ${settingName}`
    )
    return settingsConfig
  }

  const newSettingsConfig = JSON.parse(JSON.stringify(settingsConfig))
  newSettingsConfig[singlePolicyIndex].additionalSettings[
    additionalSettingIndex
  ].value = newValue

  return newSettingsConfig
}

// Sorry I know this ain't pretty but it is what it is
export const updateNestedAdditionalSetting = (
  settingsConfig,
  policyId,
  parentFlagName,
  nestedFlagName,
  newValue
) => {
  const singlePolicyIndex = settingsConfig.findIndex(
    config => config.policyId === policyId
  )

  if (singlePolicyIndex === -1) {
    console.error(
      `Could not update nested additional setting value: No matching ID for ${policyId}`
    )
    return settingsConfig
  }

  const parentSetting = settingsConfig[
    singlePolicyIndex
  ].additionalSettings.find(setting => setting.flagName === parentFlagName)

  if (!parentSetting) {
    console.error(
      `Could not update nested additional setting value: No matching parentFlagName for ${parentFlagName}`
    )
    return settingsConfig
  }

  // Handle both renderDropdownIfTrue and renderExchangeOptions
  const renderOptions =
    parentSetting.renderDropdownIfTrue ||
    parentSetting.renderExchangeOptions ||
    []

  const updatedRenderOptions = renderOptions.map(option => {
    if (option.flagName === nestedFlagName) {
      return { ...option, value: newValue }
    }
    return { ...option, value: false }
  })

  // Update the correct property based on what was originally provided
  if (parentSetting.renderDropdownIfTrue) {
    parentSetting.renderDropdownIfTrue = updatedRenderOptions
  } else if (parentSetting.renderExchangeOptions) {
    parentSetting.renderExchangeOptions = updatedRenderOptions
  }

  return [...settingsConfig]
}

export const getFlagsForPolicy = (settingsConfig, policyId) => {
  const extractedPolicy = settingsConfig.find(
    config => config.policyId === policyId
  )

  if (!extractedPolicy) {
    return undefined
  }

  // Iterate over the additional settings flags and expand them into a single object
  return extractedPolicy.additionalSettings.reduce((acc, setting) => {
    const nestedOptionsIfTrue = setting.renderDropdownIfTrue?.reduce(
      (nestedAcc, option) => ({
        ...nestedAcc,
        [option.flagName]: option.value,
      }),
      {}
    )

    const nestedOptionsExchange = setting.renderExchangeOptions?.reduce(
      (nestedAcc, option) => ({
        ...nestedAcc,
        [option.flagName]: option.value,
      }),
      {}
    )

    return {
      ...acc,
      [setting.flagName]: setting.value,
      ...nestedOptionsIfTrue,
      ...nestedOptionsExchange,
    }
  }, {})
}
