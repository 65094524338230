import { useState } from 'react'
import { bool, node } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

const ShowHideToggle = ({ expandedByDefault, children, titleHtml }) => {
  const [isExpanded, setIsExpanded] = useState(expandedByDefault)

  return (
    <div className={`flex flex-col gap-2 ${isExpanded ? 'pb-8' : 'pb-2'}`}>
      <div>
        <button
          type='button'
          aria-expanded={isExpanded}
          tabIndex={0}
          className='flex items-center w-full'
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <FontAwesomeIcon
            icon={isExpanded ? faMinusCircle : faPlusCircle}
            className='pr-3'
          />
          {titleHtml && titleHtml}
        </button>
        <hr className='border-[1px] my-3' />
      </div>
      {isExpanded && children}
    </div>
  )
}

ShowHideToggle.defaultProps = {
  expandedByDefault: false,
  titleHtml: undefined,
}

ShowHideToggle.propTypes = {
  children: node.isRequired,
  expandedByDefault: bool,
  titleHtml: node,
}

export default ShowHideToggle
