import PropTypes from 'prop-types'

const ListClientsNoClick = ({ clients }) => (
  <div>
    <div className='table-container list-clients-table'>
      <table>
        <tbody>
          <tr className='table-head'>
            <td>Client ID</td>
            <td>Client Name</td>
            <td>Report Email</td>
            <td aria-label='Check Rows' />
          </tr>
          {clients?.map(client => (
            <tr
              key={`${client.clientId}-${client.displayName}`}
              className='client-row cursor-pointer'
            >
              <td className='client-id'>{client.clientId}</td>
              <td className='client-name'>{client.displayName}</td>
              <td className='report-email'>{client.reportEmail}</td>
              <td className='check-cell' aria-label='Check Cell' />
            </tr>
          ))}

          {clients?.length === 0 && (
            <tr>
              <td colSpan='4'>No clients found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
)

ListClientsNoClick.propTypes = {
  clients: PropTypes.array.isRequired,
}

export default ListClientsNoClick
