import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Axios from 'axios'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Alert from './Alert/Alert'

const EditTenantPage = () => {
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()
  const { clientTenantId } = useParams()

  const [tenantDetails, setTenantDetails] = useState({
    tenantFriendlyName: '',
    tenantDnsName: '',
    isGoldenTenant: false,
  })
  const [backUpStatus, setBackUpStatus] = useState('')
  const [trackChangesEmails, setTrackChangesEmails] = useState({
    mailTo: '',
    mailCC: '',
    mailBCC: '',
  })
  const [baselineTenant, setBaselineTenant] = useState(false)

  const [updateSuccess, setUpdateSuccess] = useState('')
  const [updateError, setUpdateError] = useState('')

  const sanitizeMailFields = data => ({
    mailTo: data.mailTo !== null ? data.mailTo : undefined,
    mailCC: data.mailCC !== null ? data.mailCC : undefined,
    mailBCC: data.mailBCC !== null ? data.mailBCC : undefined,
  })

  useEffect(() => {
    const getTenantDetails = async () => {
      setIsLoading(true)

      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/tenant-details`,
          {
            clientTenantId,
          }
        )

        const getBackUpStatus = await Axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/get-backup-status`,
          {
            clientTenantId,
          }
        )

        const getTrackChangesEmails = await Axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/get-trackchanges-emails`,
          {
            clientTenantId,
          }
        )

        setTenantDetails(response.data)
        setBackUpStatus(getBackUpStatus.data)
        setTrackChangesEmails(sanitizeMailFields(getTrackChangesEmails.data))
        setBaselineTenant(response.data.isGoldenTenant)

        setIsLoading(false)
      } catch (error) {
        console.error('Error:', error.response.status)
        toast.error('Error Retrieving Tenant Details')
      }
    }

    getTenantDetails()
  }, [setIsLoading, clientTenantId])

  const handleTenantDetailsChange = e => {
    const { name, value } = e.target
    setTenantDetails(prevData => ({ ...prevData, [name]: value }))
  }

  const handleMailChange = e => {
    const { name, value } = e.target
    setTrackChangesEmails(prevData => ({ ...prevData, [name]: value }))
  }

  const handleBaselineTenant = e => {
    setBaselineTenant(e.target.checked)
  }

  const handleBackUpStatus = e => {
    const backUpVal = e.target.checked ? 1 : 0
    setBackUpStatus(prevData => ({ ...prevData, backupEnabled: backUpVal }))
  }

  const isValidEmail = email => !email || (email && email.includes('@'))

  const saveTenantDetails = async () => {
    if (
      !isValidEmail(trackChangesEmails.mailTo) ||
      !isValidEmail(trackChangesEmails.mailCC) ||
      !isValidEmail(trackChangesEmails.mailBCC)
    ) {
      setUpdateSuccess('')
      setUpdateError('Please enter a valid email address')
      return
    }

    if (
      (trackChangesEmails.mailTo && !trackChangesEmails.mailTo.includes('@')) ||
      (trackChangesEmails.mailCC && !trackChangesEmails.mailCC.includes('@')) ||
      (trackChangesEmails.mailBCC && !trackChangesEmails.mailBCC.includes('@'))
    ) {
      setUpdateSuccess('')
      setUpdateError('Please enter a valid email address')
      return
    }

    setIsLoading(true)
    try {
      const responses = await Promise.all([
        Axios.post(`${process.env.REACT_APP_MIDDLEWARE_URL}/set-tenant-name`, {
          newTenantFriendlyName: tenantDetails.tenantFriendlyName,
          clientTenantId: tenantDetails.clientTenantId,
        }),
        Axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/set-tenant-dnsname`,
          {
            newDnsName: tenantDetails.tenantDnsName,
            clientTenantId: tenantDetails.clientTenantId,
          }
        ),
        Axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/set-golden-tenant`,
          {
            isGoldenTenant: baselineTenant,
            clientTenantId: tenantDetails.clientTenantId,
          }
        ),
        Axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/set-backup-status`,
          {
            backupEnabled: backUpStatus.backupEnabled,
            clientTenantId: tenantDetails.clientTenantId,
          }
        ),
        Axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/set-trackchanges-emails`,
          {
            newMailTo: trackChangesEmails.mailTo,
            newMailCC: trackChangesEmails.mailCC,
            newMailBCC: trackChangesEmails.mailBCC,
            clientTenantId: tenantDetails.clientTenantId,
          }
        ),
      ])
      const allSuccess = responses.every(response => response.status === 200)

      if (allSuccess) {
        setUpdateError('')
        setUpdateSuccess('Changes saved')
      } else {
        setUpdateSuccess('')
        setUpdateError(
          'An error has occurred. Please refresh the page to view tenant details and try again'
        )
      }
    } catch (error) {
      console.error('error saving the data')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='ui-panel tenant-management edit-tenant-page'>
      <div className='heading'>
        <h2>Edit Tenant</h2>
      </div>
      <button
        type='button'
        onClick={() => navigate(-1)}
        className='btn bg-white text-gray-900 flex items-center justify-center'
      >
        {' '}
        <FontAwesomeIcon icon={icon({ name: 'angle-left' })} />
        <span>&nbsp;&nbsp;Back</span>
      </button>
      <div className='action-body no-progress-bar'>
        {isLoading ? (
          <div id='loading-spinner' role='progressbar'>
            <div className='loading' />
          </div>
        ) : (
          <form className='w-full max-w-lg mt-4'>
            {updateSuccess && (
              <Alert type='success' margin='mb-6'>
                {updateSuccess}
              </Alert>
            )}
            {updateError && (
              <Alert type='error' margin='mb-6'>
                {updateError}
              </Alert>
            )}
            <div className='flex flex-col space-y-2 my-2'>
              <label htmlFor='tenantNameField' className='font-semibold '>
                Tenant name:
              </label>
              <input
                id='tenantNameField'
                type='text'
                name='tenantFriendlyName'
                value={tenantDetails.tenantFriendlyName}
                onChange={handleTenantDetailsChange}
                className='border rounded-md p-2 focus:outline-none focus:border-blue-400 border-gray-400'
              />
            </div>
            <div className='flex flex-col space-y-2 my-2'>
              <label className='font-semibold ' htmlFor='dnsNameField'>
                DNS name:
              </label>
              <input
                type='text'
                id='dnsNameField'
                name='tenantDnsName'
                value={tenantDetails.tenantDnsName}
                onChange={handleTenantDetailsChange}
                className='border rounded-md p-2 focus:outline-none focus:border-blue-400 border-gray-400'
              />
            </div>
            <div className='flex items-center space-x-3 mt-[32px] mb-[24px]'>
              <label
                className='font-semibold '
                htmlFor='automatedBackupsCheckbox'
              >
                Enable automated backups and change tracking
              </label>
              <input
                type='checkbox'
                id='automatedBackupsCheckbox'
                name='backUpStatus'
                checked={backUpStatus.backupEnabled}
                onChange={handleBackUpStatus}
                className='focus:ring-blue-400 rounded'
              />
            </div>
            <div className='font-semibold mt-4 mb-2'>
              Track Changes Email Recipients
            </div>
            <div className='flex flex-col space-y-2 my-2'>
              <label className='font-semibold ' htmlFor='emailTo'>
                To:
              </label>
              <input
                type='text'
                name='mailTo'
                id='emailTo'
                // value={!backUpStatus.backupEnabled ? "" : trackChangesEmails.mailTo || ""}
                value={trackChangesEmails.mailTo || ''}
                onChange={handleMailChange}
                disabled={!backUpStatus.backupEnabled}
                className={
                  !backUpStatus.backupEnabled
                    ? 'bg-gray-100 border rounded-md p-2 focus:outline-none border-gray-400'
                    : 'border rounded-md p-2 focus:outline-none focus:border-blue-400 border-gray-400'
                }
              />
            </div>
            <div className='flex flex-col space-y-2 my-2'>
              <label className='font-semibold ' htmlFor='mailCc'>
                CC:
              </label>
              <input
                type='text'
                id='mailCc'
                name='mailCC'
                // value={!backUpStatus.backupEnabled ? "" : trackChangesEmails.mailCC || ""}
                value={trackChangesEmails.mailCC || ''}
                onChange={handleMailChange}
                disabled={!backUpStatus.backupEnabled}
                className={
                  !backUpStatus.backupEnabled
                    ? 'bg-gray-100 border rounded-md p-2 focus:outline-none border-gray-400'
                    : 'border rounded-md p-2 focus:outline-none focus:border-blue-400 border-gray-400'
                }
              />
            </div>
            <div className='flex flex-col space-y-2 my-2'>
              <label className='font-semibold ' htmlFor='mailBcc'>
                BCC:
              </label>
              <input
                type='text'
                id='mailBcc'
                name='mailBCC'
                // value={!backUpStatus.backupEnabled ? "" : trackChangesEmails.mailBCC || ""}
                value={trackChangesEmails.mailBCC || ''}
                onChange={handleMailChange}
                disabled={!backUpStatus.backupEnabled}
                className={
                  !backUpStatus.backupEnabled
                    ? 'bg-gray-100 border rounded-md p-2 focus:outline-none border-gray-400'
                    : 'border rounded-md p-2 focus:outline-none focus:border-blue-400 border-gray-400'
                }
              />
            </div>
            <div className='flex items-center space-x-3 mt-[24px] mb-4'>
              <label
                className='font-semibold '
                htmlFor='baselineTenantCheckbox'
              >
                Mark as Baseline Tenant:
              </label>
              <input
                type='checkbox'
                id='baselineTenantCheckbox'
                name='baselineTenant'
                checked={baselineTenant}
                onChange={handleBaselineTenant}
                className='focus:ring-blue-400 rounded'
              />
            </div>
            {!baselineTenant && tenantDetails.isGoldenTenant && (
              <Alert type='warning' margin='mb-6'>
                This action is non-reversible and will remove associated policy
                tags, and require updating any baseline groups this tenant is a
                baseline for.
              </Alert>
            )}
            <button
              type='button'
              onClick={saveTenantDetails}
              className='btn cyan-btn mt-4'
            >
              Save
            </button>
          </form>
        )}
      </div>
    </div>
  )
}

export default EditTenantPage
