import icons from './iconsConfig'

/**
 * Filters out read-only policies from the policy picker list, ensuring that only selectable policies are included.
 *
 * @param {Array} policies - The list of policy objects to filter.
 * @returns {Array} - A new array containing only policies that are selectable.
 */
export const filterOutReadOnly = policies =>
  policies.filter(item => !item.readOnly)

/**
 * Returns the icon for the specified product name.
 *
 * @param {string} name - The name of the product, e.g., 'Intune'.
 * @returns {JSX.Element} The icon component associated with the specified product.
 */
export const getIconByName = name => {
  const icon = icons.find(specificIcon => specificIcon.name === name)
  return icon ? icon.url : null
}
