import { bool, func, node, string } from 'prop-types'
import Tooltip from '../../../tooltip/Tooltip'

/**
 * Accordion component
 * @param {function} toggleExpand - function to toggle expand
 * @param {boolean} selectAll - boolean to check if all are selected
 * @param {function} handleSelectAll - function to handle select all
 * @param {string} backgroundColor - tailwind background color
 * @param {string} tooltipText - text for tooltip
 * @param {boolean} disabled - disable the checkbox
 * @param {React.ReactNode} children - children
 * @returns {JSX.Element}
 */
const Accordion = ({
  toggleExpand,
  selectAll,
  handleSelectAll,
  backgroundColor,
  tooltipText,
  disabled,
  children,
}) => (
  <div
    className={`flex items-center p-4 ${backgroundColor} cursor-pointer w-full text-left rounded-sm`}
    onClick={toggleExpand}
    onKeyDown={toggleExpand}
    role='button'
    tabIndex={0}
  >
    <Tooltip content={tooltipText}>
      <input
        type='checkbox'
        className='mr-4'
        checked={selectAll}
        onChange={handleSelectAll}
        onClick={e => e.stopPropagation()}
        disabled={disabled}
      />
    </Tooltip>

    {children}
  </div>
)

Accordion.defaultProps = {
  backgroundColor: '',
  disabled: false,
}

Accordion.propTypes = {
  toggleExpand: func.isRequired,
  selectAll: bool.isRequired,
  handleSelectAll: func.isRequired,
  backgroundColor: string,
  tooltipText: string.isRequired,
  disabled: bool,
  children: node.isRequired,
}

export default Accordion
