import { bool, func, node } from 'prop-types'

const ClickableCell = ({ onClick, disabled, children }) => (
  <button
    type='button'
    onClick={onClick}
    disabled={disabled}
    className='text-blue-500 hover:underline disabled:opacity-50 disabled:text-gray-500 disabled:hover:no-underline'
  >
    {children}
  </button>
)

ClickableCell.defaultProps = {
  disabled: false,
}

ClickableCell.propTypes = {
  onClick: func.isRequired,
  children: node.isRequired,
  disabled: bool,
}

export default ClickableCell
