import { arrayOf, string } from 'prop-types'

const SelectedItems = ({ title, selectedListItems }) => (
  <div>
    <div className='select-tenant-heading'>
      <h4 className='mr-1'>{title}</h4>
      <p className='mr-1'>{selectedListItems.join(', ')}</p>
    </div>
  </div>
)

SelectedItems.propTypes = {
  selectedListItems: arrayOf(string).isRequired,
  title: string.isRequired,
}

export default SelectedItems
