import { func, string, bool } from 'prop-types'

const FormControlButtons = ({
  onConfirm,
  onCancel,
  onReset,
  confirmButtonText,
  cancelButtonText,
  resetButtonText,
  confirmDisabled,
  resetDisabled,
}) => (
  <div className='flex gap-2'>
    <button
      type='button'
      onClick={onCancel}
      aria-label='cancel'
      className='py-2 px-3 rounded-md bg-inforcer-navy text-white hover:opacity-80'
    >
      {cancelButtonText}
    </button>

    <button
      type='button'
      onClick={onReset}
      aria-label='reset'
      className='py-2 px-3 rounded-md bg-inforcer-navy text-white hover:opacity-80  disabled:opacity-50'
      disabled={resetDisabled}
    >
      {resetButtonText}
    </button>
    <button
      type='submit'
      onClick={() => onConfirm?.()}
      aria-label='submit'
      className='py-2 btn cyan-btn text-white hover:opacity-80  disabled:opacity-50'
      disabled={confirmDisabled}
    >
      {confirmButtonText}
    </button>
  </div>
)

FormControlButtons.defaultProps = {
  confirmButtonText: 'Save',
  cancelButtonText: 'Cancel',
  resetButtonText: 'Reset',
  onConfirm: undefined,
  confirmDisabled: false,
  resetDisabled: false,
}

FormControlButtons.propTypes = {
  confirmButtonText: string,
  onConfirm: func,
  cancelButtonText: string,
  onCancel: func.isRequired,
  resetButtonText: string,
  onReset: func.isRequired,
  confirmDisabled: bool,
  resetDisabled: bool,
}

export default FormControlButtons
