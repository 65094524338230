import { NavLink, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Axios from 'axios'
import NavLinks from './NavLinks'
// import HeaderNavLinks from "./HeaderNavLinks"
import logo from '../assets/inforcer-logo.png'

const MobileSidebar = () => {
  const navigate = useNavigate()

  const handleLogout = async () => {
    try {
      await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/logout`,
        {},
        { withCredentials: true }
      )
      navigate('/')
      navigate(0)
    } catch (error) {
      toast.error('Sorry, we could not log you out. Please try again.')
    }
  }

  return (
    <nav className='mobile-sidebar w-60'>
      <img src={logo} className='inforcer-logo' alt='Inforcer Logo' />
      <NavLinks />
      <ul className='header-nav-list'>
        <li>
          <NavLink to='/my-account'>My Account</NavLink>
        </li>
        <li>
          <button
            type='button'
            onClick={handleLogout}
            className='ml-3 navy-text mobile-logout'
          >
            Logout
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default MobileSidebar
