import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import PolicyPicker from '../../components/PolicyPickerLegacy'
import BackupConfirmationPopup from '../../components/BackupConfirmationPopup'

import PolicyPickerSkeleton from '../../components/LoadingSkeletons/PolicyPickerSkeleton'
import { UNLICENSED_POLICY } from '../../utils/Policy'
import IsReadOnlyPolicy from '../../utils/isReadOnlyPolicy'
import { useNotificationsContext } from '../../contexts/NotificationsContext'
import ListTable from '../../components/ListTable/components/ListTable'
import useGetListTenants from '../../hooks/useGetListTenants'
import useTenantTags from '../../components/TenantTags/api/useTenantTags'
import uniqueTenantTags from '../../utils/uniqueTenantTags'
import tenantTypeFilter from '../../components/TableCells/TenantTypeCell/filters'
import TenantTypeCell from '../../components/TableCells/TenantTypeCell/TenantTypeCell'
import TenantTagsCell from '../../components/TableCells/TenantTagsCell/TenantTagsCell'
import Alert from '../../components/Alert/Alert'

const BackupLegacy = () => {
  const { data: legacyTenants, isLoading: isLegacyTenantsLoading } =
    useGetListTenants()

  const [loadingState, setLoadingState] = useState(true)

  const {
    data: tenantTags,
    isLoading: isTenantTagsLoading,
    isError: isTenantTagsError,
  } = useTenantTags()

  const [selectedSourceTenant, setSelectedSourceTenant] = useState([])

  const uniqueTags = uniqueTenantTags({
    tenants: legacyTenants,
    tenantTags,
  })

  const tenantsColumns = useMemo(
    () => [
      {
        header: 'Tenant Name',
        accessor: 'tenantFriendlyName',
      },
      {
        header: 'Type',
        accessor: 'type',
        size: 200,
        filterVariant: 'multi-select',
        filterSelectOptions: [
          'Baseline',
          'Shared Baseline',
          'Inforcer Managed',
          'Customer',
        ],
        filterFn: tenantTypeFilter,
        cell: ({ row }) => TenantTypeCell({ row }),
      },
      {
        header: 'Tags',
        accessor: 'tags',
        cell: ({ row }) =>
          TenantTagsCell({
            linkedTenantTagIds: row.original.tags,
            allTenantTags: tenantTags,
            numVisibleTags: 4,
          }),
        size: 700,
        minSize: 100,
        maxSize: 800,
        grow: true,
        filterVariant: 'multi-select',
        filterSelectOptions: uniqueTags.map(tag => ({
          value: tag?.id,
          label: tag?.tag,
        })),
      },
    ],
    [tenantTags, uniqueTags]
  )

  const { refetchNotifications } = useNotificationsContext()

  // Form Logic
  const formArray = [1, 2, 3, 4]
  const [formNo, setFormNo] = useState(formArray[0])

  // Policy picker
  const [checkedItems, setCheckedItems] = useState({})
  const [selectedObjects, setSelectedObjects] = useState([])
  const [data, setData] = useState([])
  const [showChildren, setShowChildren] = useState({})
  const [selectAll, setSelectAll] = useState(false)
  const [openDropdowns, setOpenDropdowns] = useState([])
  const [abortController, setAbortController] = useState(null)

  // Back Up name
  const [backUpName, setBackUpName] = useState('')

  // Parse data for posting
  const [formResponses, setFormResponses] = useState([])

  // Final response
  const [finalResponse, setFinalResponse] = useState(null)
  const [backEndErrors, setBackEndErrors] = useState(null)
  const [openPopup, setOpenPopup] = useState(false)

  useEffect(() => {
    if (formNo === 1) {
      setFormResponses([])
    }
  }, [formNo])

  const checkParentCheckbox = (parentType, updatedCheckedItems) => {
    const allChildrenChecked = data[parentType]
      .filter(childPolicy => !IsReadOnlyPolicy(childPolicy))
      .map(child => updatedCheckedItems[child.id.toString()])
    // TODO - ESLINT FIX ME
    // eslint-disable-next-line no-param-reassign
    updatedCheckedItems[parentType] = allChildrenChecked.every(Boolean)

    setCheckedItems(updatedCheckedItems)
  }

  const handleParentCheckboxChange = e => {
    const { name, checked } = e.target
    const updatedCheckedItems = { ...checkedItems }
    const updatedShowChildren = { ...showChildren }

    updatedCheckedItems[name] = checked
    updatedShowChildren[name] = checked

    if (checked) {
      data[name].forEach(child => {
        if (!IsReadOnlyPolicy(child)) {
          updatedCheckedItems[child.id] = true
          if (!selectedObjects.some(obj => obj.id === child.id)) {
            setSelectedObjects(prevSelectedObjects => [
              ...prevSelectedObjects,
              child,
            ])
          }
        }
      })
    } else {
      data[name].forEach(child => {
        updatedCheckedItems[child.id] = false
        setSelectedObjects(prevSelectedObjects =>
          prevSelectedObjects.filter(obj => obj.id !== child.id)
        )
      })
    }

    setCheckedItems(updatedCheckedItems)
    setShowChildren(updatedShowChildren)

    checkParentCheckbox(name, updatedCheckedItems)

    const containsFalse = Object.values(updatedCheckedItems).includes(false)

    if (containsFalse === true) {
      setSelectAll(false)
    }
  }

  const handleChildCheckboxChange = e => {
    const { name, checked } = e.target
    const updatedCheckedItems = { ...checkedItems }

    updatedCheckedItems[name] = checked

    const parentType = Object.keys(data).find(type =>
      data[type].some(obj => obj.id.toString() === name)
    )

    if (checked) {
      const selectedObject = data[parentType].find(
        obj => obj.id.toString() === name
      )
      setSelectedObjects(prevSelectedObjects => [
        ...prevSelectedObjects,
        selectedObject,
      ])
    } else {
      setSelectedObjects(prevSelectedObjects =>
        prevSelectedObjects.filter(obj => obj.id.toString() !== name)
      )
      // If at least one child is deselected, set the parent checkbox to false
      updatedCheckedItems[parentType] = false
    }

    // Check the parent checkbox if all children are selected
    checkParentCheckbox(parentType, updatedCheckedItems)

    const containsFalse = Object.values(updatedCheckedItems).includes(false)

    if (containsFalse === true) {
      setSelectAll(false)
    } else {
      setSelectAll(true)
    }

    setCheckedItems(updatedCheckedItems)
  }

  const handleSelectAll = () => {
    const updatedCheckedItems = {}
    const updatedSelectedObjects = []

    if (!selectAll) {
      Object.entries(data).forEach(([parentType, children]) => {
        // Any unlicensed policies shouldn't be selectable
        if (children[0].id === UNLICENSED_POLICY) return

        updatedCheckedItems[parentType] = true
        children.forEach(child => {
          if (!IsReadOnlyPolicy(child)) {
            updatedCheckedItems[child.id] = true
            if (!updatedSelectedObjects.some(obj => obj.id === child.id)) {
              updatedSelectedObjects.push(child)
            }
          }
        })
      })

      setShowChildren({}) // Hide all dropdowns when select all is checked
    } else {
      // Uncheck all checkboxes when select all is unchecked
      Object.keys(updatedCheckedItems).forEach(key => {
        updatedCheckedItems[key] = false
      })
    }

    setCheckedItems(updatedCheckedItems)
    setSelectedObjects(updatedSelectedObjects)
    setSelectAll(!selectAll)
  }

  const handleDropdownToggle = parentType => {
    if (parentType === 'selectAll') {
      return // Ignore dropdown toggle triggered by "Select All" checkbox
    }

    const updatedShowChildren = { ...showChildren }
    updatedShowChildren[parentType] = !showChildren[parentType]
    setShowChildren(updatedShowChildren)

    if (updatedShowChildren[parentType]) {
      setOpenDropdowns([...openDropdowns, parentType])
    } else {
      setOpenDropdowns(openDropdowns.filter(type => type !== parentType))
    }
  }

  // Output responses for review on final step
  const renderDisplayValue = response => {
    if (response.step === 1) {
      return (
        <div>
          <p>
            <strong>Tenant to backup </strong>
          </p>
          <ul>
            {response.response.map(tenants => (
              <li key={tenants.tenantFriendlyName} className='sm-text'>
                {tenants.tenantFriendlyName}
              </li>
            ))}
          </ul>
        </div>
      )
    }
    if (response.step === 2) {
      // let policyRes = response.response.map((policy) => policy.displayName)
      return (
        <div>
          <p>
            <strong>Policies to backup - {response.response.length} </strong>
          </p>
          <ul className='confirm-policies'>
            {response.response.map(policy => (
              <li key={policy.displayName} className='sm-text'>
                {policy.displayName || policy.name}
              </li>
            ))}
          </ul>
        </div>
      )
    }
    if (response.step === 3) {
      const buName = formResponses[2].response
      return (
        <div>
          <p>
            <strong>Back up Name </strong>
          </p>
          <p className='sm-text'>{buName}</p>
        </div>
      )
    }
    // Handle string values separately
    if (typeof response.response === 'string') {
      return <p>{response.response}</p>
    }
    return <p>{response.response.join(', ')}</p>
  }

  // Timeline navigation
  const next = () => {
    if (formNo === 1) {
      // let allTenants = selectedOption === 'All Tenants';

      if (!selectedSourceTenant) {
        return
      }

      const stepOneRes = { step: formNo, response: selectedSourceTenant }

      // Find the existing response for step 1 in the formResponses array
      const existingResponseIndex = formResponses.findIndex(
        response => response.step === formNo
      )

      if (existingResponseIndex !== -1) {
        // If an existing response exists, replace it with the latest response
        const updatedResponses = [...formResponses]
        updatedResponses[existingResponseIndex] = stepOneRes
        setFormResponses(updatedResponses)
      } else {
        // Otherwise, add the response to the array
        setFormResponses(prevFormResponses => [
          ...prevFormResponses,
          stepOneRes,
        ])
      }

      // Get clientTenantId so it can be passed
      const ctId = stepOneRes.response[0].clientTenantId
      setData([])

      const policyRequest = async () => {
        setLoadingState(true)
        const controller = new AbortController()
        setAbortController(controller)

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_MIDDLEWARE_URL}/list-policies`,
            {
              ctId,
            },
            { signal: controller.signal }
          )

          // reset policies so state can be repopulated with policies based on clientTenantID
          setData(response.data)
          setLoadingState(false)
        } catch (error) {
          toast.error('Error Retrieving Policies')
        } finally {
          setLoadingState(false)
        }
      }

      policyRequest()
      // send post request containing selected tenants to get data to populate policy picker

      setFormNo(formNo + 1)
    } else if (formNo === 2) {
      if (selectedObjects.length === 0) {
        return
      }

      const stepTwoRes = { step: formNo, response: selectedObjects }

      // Find the existing response for step 1 in the formResponses array
      const existingResponseIndex = formResponses.findIndex(
        response => response.step === formNo
      )

      if (existingResponseIndex !== -1) {
        // If an existing response exists, replace it with the latest response
        const updatedResponses = [...formResponses]
        updatedResponses[existingResponseIndex] = stepTwoRes
        setFormResponses(updatedResponses)
      } else {
        // Otherwise, add the response to the array
        setFormResponses(prevFormResponses => [
          ...prevFormResponses,
          stepTwoRes,
        ])
      }

      setFormNo(formNo + 1)
    } else if (formNo === 3) {
      const validCharactersPattern = /^[a-zA-Z0-9 _-]*$/

      if (backUpName.length < 2 || backUpName.length > 100) {
        toast.error('Back Up name must be between 2 and 100 characters')
        return
      }

      if (!validCharactersPattern.test(backUpName)) {
        toast.error('Back Up name must must not contain special characters')
        return
      }
      const stepThreeRes = { step: formNo, response: backUpName }

      // Find the existing response for step 1 in the formResponses array
      const existingResponseIndex = formResponses.findIndex(
        response => response.step === formNo
      )

      if (existingResponseIndex !== -1) {
        // If an existing response exists, replace it with the latest response
        const updatedResponses = [...formResponses]
        updatedResponses[existingResponseIndex] = stepThreeRes
        setFormResponses(updatedResponses)
      } else {
        // Otherwise, add the response to the array
        setFormResponses(prevFormResponses => [
          ...prevFormResponses,
          stepThreeRes,
        ])
      }

      setFormNo(formNo + 1)
    } else {
      toast.error('Please complete all input fields')
    }
  }

  const pre = () => {
    if (abortController) {
      abortController.abort()
    }
    if (formNo === 2) {
      // Reset form responses and policy picker if the user goes back to the first stage
      setFormResponses([])
      setOpenDropdowns([])
      setSelectedObjects([])
      setCheckedItems({})
      setSelectAll(false)
    }

    setFormNo(formNo - 1)
  }

  const finalSubmit = () => {
    setOpenPopup(true)
    const clientTenantId = +formResponses[0].response[0].clientTenantId
    const backupComment = formResponses[2].response

    const backUpBody = []

    formResponses[1].response.forEach(response => {
      const policyGuid = response.id
      const { policyTypeId, priority } = response
      const displayName = response.displayName ?? response.name

      const eachPolicy = {
        clientTenantId,
        policyGuid,
        policyTypeId,
        displayName,
        backupComment,
        priority,
      }

      backUpBody.push(eachPolicy)
    })

    const backUpRequest = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/send-backup`,
          backUpBody
        )

        const { confirmationData } = response.data
        const { errorMessages } = response.data

        if (errorMessages && errorMessages.length > 0) {
          console.error('Policies with errors:', errorMessages.join(', '))
        }

        // If there are errorMessages, add them to backEndErrors state
        if (errorMessages && errorMessages.length > 0) {
          setBackEndErrors(errorMessages)
        }

        setFinalResponse(confirmationData)
        refetchNotifications()
      } catch (error) {
        setBackEndErrors(prevErrors => [
          ...prevErrors,
          { message: 'Network or server error occurred.' },
        ])
      }
    }

    backUpRequest()
  }
  // Timeline step text
  const stepH1 = 'Backup'
  const stepH2 = 'Select policies'
  const stepH3 = 'Enter backup name'
  const stepH4 = 'Confirm changes'

  const stepP1 = 'Select tenant to backup'
  const stepP2 = 'Select policies to backup'
  const stepP3 = 'Enter backup name for reference'
  const stepP4 = 'Confirm you are happy to proceed with backup.'

  return (
    <div className='ui-panel backup-journey'>
      <div className='heading'>
        <h2>Back Up</h2>
        <p>Back Up Policies in your chosen tenant</p>
      </div>
      <div className='action-body'>
        <div className='progress-bar'>
          {formArray.map((v, i) => (
            <div key={uuidv4()}>
              <div className='flex'>
                <div className='flex'>
                  <div
                    className={`progress-number progress-number-${v} ${
                      formNo - 1 === i ||
                      formNo - 1 === i + 1 ||
                      formNo - 1 === i + 2 ||
                      formNo === formArray.length
                        ? 'cyan-bg text-white'
                        : 'bg-slate-300'
                    }`}
                  >
                    {v}
                  </div>
                  <div className='progress-text'>
                    <p className='navy-text'>
                      <strong>
                        {(v === 1 && stepH1) ||
                          (v === 2 && stepH2) ||
                          (v === 3 && stepH3) ||
                          (v === 4 && stepH4)}
                      </strong>
                    </p>
                    <p className='text-gray-400'>
                      {(v === 1 && stepP1) ||
                        (v === 2 && stepP2) ||
                        (v === 3 && stepP3) ||
                        (v === 4 && stepP4)}
                    </p>
                  </div>
                </div>
              </div>
              {i !== formArray.length - 1 && (
                <div className='progress-bar-link' />
              )}
            </div>
          ))}
        </div>
        <div className='action-body-form'>
          {formNo === 1 && (
            <>
              {isTenantTagsError ? (
                <Alert title='Error fetching tenant tags' type='alert'>
                  There was an error fetching tenant tags.
                </Alert>
              ) : (
                <ListTable
                  selectedListItems={selectedSourceTenant}
                  setSelectedListItems={setSelectedSourceTenant}
                  uniqueKey='clientTenantId'
                  columns={tenantsColumns}
                  data={legacyTenants ?? []}
                  enableMultiRowSelection={false}
                  loading={isLegacyTenantsLoading && isTenantTagsLoading}
                  enableRowSelection
                />
              )}

              <div className='mt-6 gap-3 flex'>
                <button
                  type='button'
                  onClick={next}
                  disabled={!selectedSourceTenant}
                  className='btn cyan-btn'
                >
                  Next
                </button>
              </div>
            </>
          )}

          {formNo === 2 && (
            <div className='max-w-[452px]'>
              {loadingState ? (
                <PolicyPickerSkeleton loadingTitle='Loading policies from Microsoft 365...' />
              ) : (
                <>
                  <h4 className='mb-3'>Select policies to backup</h4>
                  <PolicyPicker
                    data={data}
                    checkedItems={checkedItems}
                    showChildren={showChildren}
                    selectAll={selectAll}
                    handleParentCheckboxChange={handleParentCheckboxChange}
                    handleChildCheckboxChange={handleChildCheckboxChange}
                    handleSelectAll={handleSelectAll}
                    handleDropdownToggle={handleDropdownToggle}
                    selectedTargetTenant={selectedSourceTenant[0]}
                  />
                </>
              )}
              <div className='mt-6 gap-3 flex'>
                <button type='button' onClick={pre} className='btn navy-btn'>
                  Previous
                </button>
                <button
                  type='button'
                  onClick={next}
                  disabled={selectedObjects.length === 0}
                  className='btn cyan-btn'
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {formNo === 3 && (
            <>
              <h4>Enter Back Up Name</h4>

              <input
                className='flex w-[350px] focus:outline-blue-400 rounded-md light-grey-bg p-2 text-gray-700 mb-4 mt-4'
                type='text'
                placeholder='Search'
                maxLength={100}
                value={backUpName}
                onChange={e => setBackUpName(e.target.value)}
              />

              <div className='mt-6 gap-3 flex'>
                <button type='button' onClick={pre} className='btn navy-btn'>
                  Previous
                </button>
                <button type='button' onClick={next} className='btn cyan-btn'>
                  Next
                </button>
              </div>
            </>
          )}

          {formNo === 4 && (
            <div>
              <div className='final-confirmation'>
                <h3>Review and confirm your changes</h3>

                <div className='mt-4'>
                  <ul>
                    {formResponses.map(response => (
                      <li key={response.clientTenantId}>
                        {renderDisplayValue(response)}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className='mt-4 gap-3 flex'>
                  <button type='button' onClick={pre} className='btn navy-btn'>
                    Previous
                  </button>
                  <button
                    type='button'
                    onClick={finalSubmit}
                    className='btn cyan-btn'
                  >
                    Submit
                  </button>
                </div>
                {openPopup === true && (
                  <BackupConfirmationPopup
                    finalResponse={finalResponse}
                    formResponses={formResponses}
                    backEndErrors={backEndErrors}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BackupLegacy
