const ReportingEmails = ({ handleEmailFieldChange, emailFields }) => (
  <>
    <div className='font-semibold mb-4'>Recipient Email Addresses</div>
    <div className='flex flex-col space-y-2 my-2'>
      <label className='font-semibold '>Recipient one:</label>
      <input
        type='text'
        name='mailTo'
        value={emailFields.mailTo}
        onChange={handleEmailFieldChange}
        className='border rounded-md p-2 focus:outline-none focus:border-blue-400 border-gray-400'
      />
    </div>
    <div className='flex flex-col space-y-2 my-2'>
      <label className='font-semibold'>Recipient two:</label>
      <input
        type='text'
        name='mailCC'
        value={emailFields.mailCC}
        onChange={handleEmailFieldChange}
        className='border rounded-md p-2 focus:outline-none focus:border-blue-400 border-gray-400'
      />
    </div>
    <div className='flex flex-col space-y-2 my-2'>
      <label className='font-semibold '>Recipient three:</label>
      <input
        type='text'
        name='mailBCC'
        value={emailFields.mailBCC}
        onChange={handleEmailFieldChange}
        className='border rounded-md p-2 focus:outline-none focus:border-blue-400 border-gray-400'
      />
    </div>
  </>
)

export default ReportingEmails
