import { node } from 'prop-types'
import { Tooltip as MuiTooltip } from '@mui/material'

const Tooltip = ({ content, children }) => (
  <MuiTooltip
    title={content}
    componentsProps={{
      tooltip: {
        sx: {
          maxWidth: 150,
          backgroundColor: '#171B3A',
          color: '#fff',
        },
      },
      arrow: {
        sx: {
          color: '#171B3A',
        },
      },
    }}
    arrow
  >
    {children}
  </MuiTooltip>
)

Tooltip.propTypes = {
  content: node.isRequired,
  children: node.isRequired,
}

export default Tooltip
