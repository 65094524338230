import 'react-toastify/dist/ReactToastify.css'
import 'react-loading-skeleton/dist/skeleton.css'
import ListPolicies from './ListPolicies'
import ConfirmationPopupSkeleton from '../LoadingSkeletons/ConfirmationPopupSkeleton'
import useDeletePolicy from '../TenantAlignment/hooks/useDeletePolicy'
import Alert from '../Alert/Alert'
import { useNotificationsContext } from '../../contexts/NotificationsContext'

const DeletePolicyPopup = ({
  handleClosePopUp,
  policies,
  clientTenantId,
  onSuccess,
  resetDropdowns,
  explicitDeleteGuid = null,
  saveOverride = false,
}) => {
  const deletePolicy = useDeletePolicy(onSuccess)
  const { refetchNotifications } = useNotificationsContext()

  const handleCopy = async () => {
    // Select all list items within the list
    const listItems = document.querySelectorAll(`#copyClipboard ol li`)

    // Convert NodeList to Array and map through each item to get textContent
    const listText = Array.from(listItems)
      .map(li => li.textContent)
      .join('\n')

    // Use the Clipboard API to copy the listText to the clipboard
    navigator.clipboard.writeText(listText).catch(err => {
      console.error('Failed to copy list to clipboard: ', err)
    })
  }

  return (
    <div className='popup-bg'>
      <div className='popup-container delete-tenant-popup text-center'>
        <h3 className='mb-4'>
          {deletePolicy.isSuccess ? 'Policies Deleted' : 'Confirm Deletion'}
        </h3>
        {deletePolicy.isPending && (
          <ConfirmationPopupSkeleton loadingTitle='Deleting policies...' />
        )}
        {deletePolicy.isSuccess && (
          <>
            <p className='ml-4'>Policy Deletions Successfully queued</p>
            <div className='footer mb-footer'>
              <div id='copyClipboard' className='mb-[30px]'>
                <ListPolicies policies={policies} />
              </div>

              <button
                className='btn navy-btn ml-4 mr-2 text-white'
                onClick={() => {
                  handleClosePopUp()
                  resetDropdowns?.()
                }}
              >
                Back
              </button>

              <button
                type='button'
                className='btn slate-btn'
                onClick={handleCopy}
              >
                Copy to clipboard
              </button>
            </div>
          </>
        )}
        {deletePolicy.isError && (
          <Alert
            type='error'
            message='Error encountered deleting one or more policies'
          />
        )}
        {deletePolicy.isIdle && (
          <>
            <p className='ml-4'>
              Are you sure you'd like to delete the selected policies?
            </p>
            <br />

            <ListPolicies policies={policies} />

            <div className='footer mt-[50px] mb-2'>
              <button
                type='button'
                className='btn navy-btn mr-2 text-white'
                onClick={handleClosePopUp}
              >
                Back
              </button>
              <button
                type='button'
                className='btn bg-red-800 text-white'
                onClick={() => {
                  deletePolicy.mutate({
                    policies,
                    clientTenantId,
                    explicitDeleteGuid,
                    saveOverride,
                  })
                  refetchNotifications()
                }}
              >
                Delete
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default DeletePolicyPopup
