import { useEffect, useState } from 'react'
import PropTypes, { arrayOf, func, objectOf, string } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import TenantTagsSelect from './TenantAlignment/components/TenantTagsSelect'
import { tenantTagsShape } from '../utils/propTypes/tenantAlignmentProps'
import Tooltip from './tooltip/Tooltip'

const OnboardingSetupAllTenants = ({
  state,
  title,
  messageOverride,
  onOnboardAllCustomers,
  tenantTags,
  setSelectedTenantTags,
  selectedTenantTags,
}) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [startingCustomers] = useState(
    state.customerProgress.filter(p => p.status === 'idle')
  )
  const [completeCustomers, setCompleteCustomers] = useState([])
  const [runningCustomers, setRunningCustomers] = useState([])
  const [erroredCustomers, setErroredCustomers] = useState([])

  useEffect(() => {
    setCompleteCustomers(
      state.customerProgress.filter(p => p.status === 'complete')
    )
    setRunningCustomers(
      state.customerProgress.filter(p => p.status === 'running')
    )
    setErroredCustomers(
      state.customerProgress.filter(p => p.status === 'error')
    )
  }, [state.customerProgress])

  const buildConfirmationStep = () => (
    <>
      <p className='mb-4'>
        {messageOverride ??
          `You are about to onboard all of the selected tenants with default data.
        Your credentials may need to be refreshed during this process.`}
      </p>

      <div className='mb-8'>
        <label htmlFor='onboarding-tenant-tags py-3'>
          <b>Apply Tenant Tags to all (optional)</b>
        </label>

        <Tooltip content='Click here to create tenant tags. You will not lose your onboarding progress when you return to this page'>
          <Link to='/settings/tenant-tags'>
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              className='ml-1 text-sm'
            />
          </Link>
        </Tooltip>

        <TenantTagsSelect
          tenantTags={tenantTags}
          selectedTenantTags={selectedTenantTags}
          setSelectedTenantTags={setSelectedTenantTags}
          hideLabel
        />
      </div>

      <div>
        <button
          type='button'
          className='btn cyan-btn mr-4'
          onClick={() => {
            setIsProcessing(true)
            onOnboardAllCustomers(selectedTenantTags)
          }}
        >
          Confirm and start processing
        </button>
      </div>
    </>
  )

  const buildProcessingStep = () => (
    <>
      <p className='mb-4 font-semibold'>
        Please do not refresh your browser window.
      </p>
      <div className='w-full'>
        <div className='flex items-center justify-between gap-4 mb-2'>
          <div className='block font-semibold'>
            Currently processing {runningCustomers.length} customers
          </div>
          <div className='block font-semibold'>
            {completeCustomers.length} out of {startingCustomers.length}{' '}
            complete
          </div>
        </div>

        {runningCustomers.length > 0 ? (
          <div className='relative rounded-md border p-4 border-gray-400 bg-white text-gray-700 my-[20px] max-h-[250px] overflow-x-hidden overflow-y-scroll'>
            {runningCustomers.map(rc =>
              rc.log.map(logItem => (
                <p
                  key={logItem.message}
                  className={`${logItem.status === 'error' ? 'text-red' : 'text-gray-700'}`}
                >
                  {logItem.message}
                </p>
              ))
            )}
            <div className='absolute -top-0 -left-0 w-full'>
              <div className='h-1 overflow-hidden'>
                <div className='progress w-full h-full left-right' />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {erroredCustomers.length > 0 ? (
        <div>
          <p className='mb-4'>
            One or more critical errors were encountered and onboarding could
            not be completed.
          </p>

          {erroredCustomers.map((errorItem, errorIndex) => (
            <div
              key={errorIndex}
              className='rounded-md border p-4 border-gray-400 bg-white text-gray-700 my-[20px]'
            >
              {errorItem.log.map(logItem => (
                <p
                  key={logItem.message}
                  className={`${logItem.status === 'error' ? 'text-red' : 'text-gray-700'}`}
                >
                  {logItem.message}
                </p>
              ))}
            </div>
          ))}
        </div>
      ) : null}
    </>
  )

  return (
    <>
      <h3 className='mb-4'>{title}</h3>
      {isProcessing ? buildProcessingStep() : buildConfirmationStep()}
    </>
  )
}

OnboardingSetupAllTenants.propTypes = {
  state: objectOf(PropTypes.any).isRequired,
  title: string,
  messageOverride: string,
  onOnboardAllCustomers: func.isRequired,
  tenantTags: arrayOf(string),
  setSelectedTenantTags: func.isRequired,
  selectedTenantTags: arrayOf(tenantTagsShape).isRequired,
}

OnboardingSetupAllTenants.defaultProps = {
  title: 'Step 3: Onboard tenants',
  messageOverride: null,
  tenantTags: [],
}

export default OnboardingSetupAllTenants
