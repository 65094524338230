import { components } from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { node, object } from 'prop-types'

/**
 * Custom Control component for react-select.
 *
 * @param children
 * @param {Object} props - Props passed down from react-select.
 * @param {Object} props.icon - The icon to display in the control.
 * @returns {JSX.Element} The custom control component.
 */
const SortControl = ({ children, icon, ...props }) => (
  <components.Control {...props}>
    {icon && <FontAwesomeIcon icon={icon} className='pl-2 text-xs' />}
    {children}
  </components.Control>
)

export default SortControl

SortControl.defaultProps = {
  children: null,
  icon: null,
}

SortControl.propTypes = {
  children: node,
  // eslint-disable-next-line react/forbid-prop-types
  icon: object,
}
