import { bool, func } from 'prop-types'

const OnboardingRevokePermission = ({ onClick, loading }) => (
  <>
    <h3 className='mb-4'>Step 4: Remove CSP Permissions</h3>
    <p className='mb-4'>
      We&apos;ve successfully retrieved your customers from Partner Center, now
      we need to remove the permission you previously granted us. Clicking the
      button below will revoke Inforcer&apos;s temporary access to your
      Microsoft Partner Center.
    </p>
    <button
      type='button'
      className='btn cyan-btn'
      onClick={onClick}
      disabled={loading}
    >
      {loading ? 'Please Wait' : 'Revoke and Review'}
    </button>
  </>
)

OnboardingRevokePermission.propTypes = {
  onClick: func.isRequired,
  loading: bool.isRequired,
}

export default OnboardingRevokePermission
