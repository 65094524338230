import { PropTypes } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faCircleExclamation,
  faCircleInfo,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons'
import './Alert.css'

const Alert = ({ children, title, type, margin }) => {
  const buildIcon = () => {
    switch (type) {
      case 'success':
        return <FontAwesomeIcon icon={faCheckCircle} />
      case 'warning':
        return <FontAwesomeIcon icon={faCircleExclamation} />
      case 'error':
        return <FontAwesomeIcon icon={faCircleXmark} />
      default:
        return <FontAwesomeIcon icon={faCircleInfo} />
    }
  }

  return (
    <div className={`p-4 alert ${type} ${margin}`} role='alert'>
      <div className='flex'>
        <span className='alert-icon'>{buildIcon()}</span>
        <div className='mx-3'>
          {title && <h4>{title}</h4>}
          {children}
        </div>
      </div>
    </div>
  )
}

Alert.defaultProps = {
  title: '',
  type: 'warning',
  margin: '',
}
Alert.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf([
    'error',
    'warning',
    'success',
    'info',
    'info-cyan',
    'info-navy',
  ]),
  margin: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Alert
