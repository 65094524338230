import { useMutation, useQueryClient } from '@tanstack/react-query'
import Axios from 'axios'
import { usePendingChangesStateContext } from '../../../contexts/PendingChangesStateContext'

const usePolicyRename = (onSuccess = () => {}) => {
  const queryClient = useQueryClient()
  const { setPendingChangesState } = usePendingChangesStateContext()

  return useMutation({
    mutationKey: ['rename-policy'],
    mutationFn: async policyRenameData => {
      const { clientTenantId, targetPolicy, baselinePolicy } = policyRenameData
      const policyObject = {
        [baselinePolicy.policyNameKeyName]: baselinePolicy.policyName,
      }
      if (baselinePolicy.policyODataType) {
        policyObject['@odata.type'] = baselinePolicy.policyODataType
      }

      return await Axios.patch(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/patch-policy`,
        {
          clientTenantId,
          policyId: targetPolicy.policyId,
          policyGuid: targetPolicy.policyGuid,
          policyTypeId: baselinePolicy.policyTypeId,
          policyName: targetPolicy.policyName,
          policyObject: JSON.stringify(policyObject),
          saveOverride: true,
          overridePolicyId: targetPolicy.subjectPolicyId,
        }
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries('tenant-summary')
      setPendingChangesState('actionInitiated')
      onSuccess()
    },
  })
}

export default usePolicyRename
