import { PropTypes } from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const TenantSkeleton = ({ loadingTitle }) => (
  <>
    <div className='select-tenant-heading mb-3'>
      <h4>{loadingTitle}</h4>
    </div>
    <div className='mb-3'>
      <Skeleton count={2} width={380} />
    </div>

    <Skeleton count={5} />
  </>
)

TenantSkeleton.propTypes = {
  loadingTitle: PropTypes.string.isRequired,
}

export default TenantSkeleton
