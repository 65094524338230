import PropTypes from 'prop-types'
import { useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'

const RemoveUserPopup = ({ handleClosePopUp, submitData, responseData }) => {
  const [loading, setLoading] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')

  const handleRemoveUser = async () => {
    setLoading(true)
    await submitData()
    setLoading(false)

    setResponseMessage('User has been successfully removed')
  }

  return (
    <div className='popup-bg'>
      <div className='popup-container delete-tenant-popup text-center'>
        <h3 className='mb-4'>Confirm User Removal</h3>
        {loading ? (
          <>
            <div id='loading-spinner'>
              <div className='loading' />
            </div>
            <p>Removing user...</p>
          </>
        ) : responseMessage ? (
          <>
            <p>
              <strong>{responseData.message}</strong>
            </p>
            <div className='footer mt-[50px] mb-2'>
              <button
                className='btn navy-btn ml-4 mr-2 text-white'
                onClick={handleClosePopUp}
              >
                Back
              </button>
            </div>
          </>
        ) : (
          <>
            <p className='ml-4'>Are you sure you'd like to remove this user?</p>
            <br />

            <div className='footer mt-[50px] mb-2'>
              <button
                className='btn navy-btn ml-4 mr-2 text-white'
                onClick={handleClosePopUp}
              >
                Back
              </button>
              <button
                className='btn bg-red-800 text-white'
                onClick={handleRemoveUser}
              >
                Remove User
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

RemoveUserPopup.propTypes = {
  responseData: PropTypes.string.isRequired,
  submitData: PropTypes.func.isRequired,
  handleClosePopUp: PropTypes.func.isRequired,
}

export default RemoveUserPopup
