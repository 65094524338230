import { func, string, shape } from 'prop-types'
import Tag from '../Tag/Tag'
import { hexToRgb } from '../../utils/colours'

const CustomTagsDisplayValues = props => {
  const { data, removeProps } = props

  const handleDelete = () => {
    removeProps.onClick()
  }

  return (
    <div className='py-1'>
      <Tag
        text={data.label}
        description={data.description}
        colourRGB={hexToRgb(data.colour)}
        onDelete={handleDelete}
      />
    </div>
  )
}

export default CustomTagsDisplayValues

CustomTagsDisplayValues.propTypes = {
  data: shape({
    label: string.isRequired,
    colour: string.isRequired,
  }).isRequired,
  removeProps: shape({
    onClick: func.isRequired,
    onMouseDown: func.isRequired,
    onTouchEnd: func.isRequired,
    onTouchStart: func.isRequired,
  }).isRequired,
}
