import { useEffect, useState } from 'react'
import Axios from 'axios'
import { Link } from 'react-router-dom'
import logo from '../assets/inforcer-logo.png'

const ResetForgottenPass = () => {
  const [newPass, setNewPass] = useState('')
  const [confirmNewPass, setConfirmNewPass] = useState('')
  const [passSuccess, setPassSuccess] = useState('')
  const [passError, setPassError] = useState([])
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isResetValid, setIsResetValid] = useState(false)
  const [resetToken, setResetToken] = useState(null)
  const [savedUserId, setSavedUserId] = useState(null)

  const resetTokenKey = 'resetToken'
  const userIdKey = 'userId'

  useEffect(() => {
    const resetTokenValue = localStorage.getItem(resetTokenKey)
    const savedUserIdValue = localStorage.getItem(userIdKey)

    if (!resetTokenValue || !savedUserIdValue) {
      setIsResetValid(false)
    } else {
      setResetToken(resetTokenValue)
      setSavedUserId(savedUserIdValue)
      setIsResetValid(true)
    }
  }, [])

  // Password conditions
  const validPass = () => {
    const issues = []

    if (!resetToken || !savedUserId) {
      issues.push(
        "Invalid reset token. Please follow the 'Forgot Password Link' to reset your password"
      )
      setIsResetValid(false)
    }

    if (newPass !== confirmNewPass) {
      issues.push('New password fields do not match')
    }
    if (newPass.length < 8 || newPass.length > 25) {
      issues.push('New password should be between 8-25 characters')
    }
    if (!/[A-Z]/.test(newPass)) {
      issues.push('New password should have at least 1 uppercase character')
    }
    if (!/\d/.test(newPass)) {
      issues.push('New password should have at least 1 number')
    }
    if (!/[^A-Za-z0-9]/.test(newPass)) {
      issues.push('New password should have at least 1 special character')
    }
    if (/[\s]/.test(newPass)) {
      issues.push('New password should not contain spaces')
    }

    if (issues.length) {
      setPassError(issues)
      setPassSuccess('')
      return false
    }

    setPassError([])
    return true
  }

  // Build Request
  const handlePassChange = async () => {
    setIsLoading(true)

    try {
      const requestBody = {
        userId: savedUserId,
        newPassword: newPass,
        resetToken,
      }

      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/resetForgotUserPass`,
        requestBody
      )

      if (response.status === 200) {
        setPassSuccess('Password Set Successfully')
        setPassError([])
        setIsSubmitted(true)
        localStorage.removeItem(userIdKey)
        setSavedUserId(null)
        localStorage.removeItem(resetTokenKey)
        setResetToken(null)
      }
    } catch (error) {
      setPassError(['Could not change password. Please try again.'])
      setPassSuccess('')
      setIsSubmitted(true)
    } finally {
      setIsLoading(false)
    }
  }

  // Run Request
  const handleSubmit = e => {
    e.preventDefault()

    if (validPass()) {
      handlePassChange()
    }
  }

  return (
    <div className='login-page'>
      <div className='login-box text-center'>
        <form onSubmit={handleSubmit}>
          <img src={logo} className='inforcer-logo' alt='Inforcer Logo' />

          {!isResetValid && (
            <>
              <div
                className='p-4 mb-[24px] text-sm text-red-800 rounded-lg bg-red-50 text-left'
                role='alert'
              >
                <span>
                  Please follow the forgot password link to reset your password
                </span>
              </div>
              <Link to='/forgot-password' className='text-sm underline'>
                Forgot password?
              </Link>
              <br />
              <Link to='/login' className='text-sm underline'>
                Login
              </Link>
            </>
          )}

          {passSuccess && (
            <>
              <div
                className='p-4 mb-[24px] text-sm text-green-800 rounded-lg bg-green-50'
                role='alert'
              >
                <span className='font-medium'>{passSuccess}</span>
              </div>
              <Link to='/login' className='text-sm underline'>
                Login
              </Link>
            </>
          )}

          {passError.length > 0 && (
            <>
              <div
                className='p-4 mb-[24px] text-sm text-red-800 rounded-lg bg-red-50 text-left'
                role='alert'
              >
                <ul>
                  {passError.map((issue, index) => (
                    <li key={index} className='text-sm list-disc ml-4'>
                      {issue}
                    </li>
                  ))}
                </ul>
              </div>
              <Link to='/forgot-password' className='text-sm underline'>
                Forgot password?
              </Link>
            </>
          )}

          {isLoading && (
            <div id='loading-spinner'>
              <div className='loading' />
            </div>
          )}

          {!isSubmitted && !isLoading && isResetValid && (
            <div>
              <input
                type='password'
                placeholder='New Password'
                className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
                onChange={e => {
                  setNewPass(e.target.value)
                }}
                disabled={!isResetValid}
              />

              <input
                type='password'
                placeholder='Confirm New Password'
                className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
                onChange={e => {
                  setConfirmNewPass(e.target.value)
                }}
                disabled={!isResetValid}
              />
              <button
                className='btn cyan-btn'
                type='submit'
                disabled={!isResetValid}
              >
                Submit
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export default ResetForgottenPass
