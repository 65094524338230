import { oneOf } from 'prop-types'
import {
  faCheckCircle,
  faCircleXmark,
  faLightbulb,
  faWarning,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  alignedStatus,
  driftedDeviationStatus,
  suggestedPolicyStatus,
  unacceptedDeviationStatus,
} from './utils'

const PolicyAlignmentIcon = ({ policyAlignmentStatus }) => {
  const iconMap = {
    [alignedStatus]: {
      icon: faCheckCircle,
      color: 'cyan',
      alt: 'Aligned Policy',
    },
    [unacceptedDeviationStatus]: {
      icon: faCircleXmark,
      color: 'red',
      alt: 'Unaccepted Deviation',
    },
    [suggestedPolicyStatus]: {
      icon: faLightbulb,
      color: 'yellow',
      alt: 'Suggested Policy',
    },
    [driftedDeviationStatus]: {
      icon: faWarning,
      color: 'yellow',
      alt: 'Drifted Deviation',
    },
  }

  return (
    <div className={`alignment-light ${iconMap[policyAlignmentStatus].color}`}>
      <FontAwesomeIcon
        icon={iconMap[policyAlignmentStatus].icon}
        title={iconMap[policyAlignmentStatus].alt}
      />
    </div>
  )
}

PolicyAlignmentIcon.propTypes = {
  policyAlignmentStatus: oneOf([
    unacceptedDeviationStatus,
    driftedDeviationStatus,
    suggestedPolicyStatus,
    alignedStatus,
  ]).isRequired,
}

export default PolicyAlignmentIcon
