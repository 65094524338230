import { arrayOf, shape, string } from 'prop-types'
import Alert from '../Alert/Alert'

const ErrorHandlerAlert = ({ error, title }) => (
  <Alert type='error' title={title || 'Unexpected error fetching data'}>
    <div className='flex flex-col gap-1'>
      {error?.response?.data?.errors ? (
        error.response.data.errors.map(errorMessage => (
          <div key={errorMessage}>{errorMessage}</div>
        ))
      ) : (
        <p>No further information was provided by the API</p>
      )}
    </div>
  </Alert>
)

ErrorHandlerAlert.propTypes = {
  error: shape({
    response: shape({
      data: shape({
        errors: arrayOf(string),
      }).isRequired,
    }).isRequired,
  }).isRequired,
  title: string.isRequired,
}

export default ErrorHandlerAlert
