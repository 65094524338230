import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { toast } from 'react-toastify'

const useCreateOrUpdateBaselineGroup = ({ onSuccess, isUpdate = false }) => {
  const queryClient = useQueryClient()

  return useMutation({
    queryKey: ['createOrUpdateBaselineGroup'],
    mutationFn: async ({
      name,
      alignedThreshold,
      semiAlignedThreshold,
      baselineClientTenantId,
      clientTenantIds,
      id = undefined,
    }) => {
      const groupResponse = await axios.put(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/tenantGroup`,
        {
          id,
          name,
          alignedThreshold: parseFloat(alignedThreshold),
          semiAlignedThreshold: parseFloat(semiAlignedThreshold),
          baselineClientTenantId,
        }
      )

      const clientTenantGroupId = groupResponse.data.id

      return axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/tenantGroupMembers`,
        {
          clientTenantGroupId,
          clientTenantIds,
        }
      )
    },
    onSuccess: () => {
      toast.success(`Tag ${isUpdate ? 'updated' : 'created'} successfully!`)
      queryClient.invalidateQueries(['tenantTags'])
      onSuccess()
    },
  })
}

export default useCreateOrUpdateBaselineGroup
