import { useContext, useState } from 'react'
import Axios from 'axios'
import Select from 'react-select'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { useFeatureFlag } from 'configcat-react'
import GlobalContext from '../contexts/GlobalContext'

import logo from '../assets/inforcer-logo.png'
import { clearLocalOnboardingState } from '../hooks/UseOnboarding'
import SSOButton from '../components/sso/SSOButton'
import SSOLogin from '../components/sso/SSOLogin'
import HorizontalDivider from '../components/HorizontalDivider'
import RegionOption from '../components/RegionOption'

const Login = () => {
  const {
    setLoadApp,
    loadingState,
    setLoadingState,
    username,
    setUsername,
    setForcePasswordChange,
  } = useContext(GlobalContext)
  const { boot } = useIntercom()

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const errCode = searchParams.get('errCode')
  const ssoError = (errCode || '').startsWith('SSO')
  const [usingSSO, setUsingSSO] = useState(ssoError)
  const [password, setPassword] = useState('')
  const [authCode, setAuthCode] = useState('')
  const [loginStatus, setLoginStatus] = useState('')
  // form validation
  const [emailError, setEmailError] = useState('')
  const [authCodeError, setAuthCodeError] = useState('')
  const [loginError, setLoginError] = useState('')

  const { value: showOldPortalRegion } = useFeatureFlag(
    'showOldPortalRegion',
    true
  )
  const { value: showRegionSelectorValue } = useFeatureFlag(
    'showRegionSelector',
    false
  )
  // ENV Region URLs
  const UKURL = process.env.REACT_APP_UK_URL
  const ANZURL = process.env.REACT_APP_ANZ_URL
  const EUURL = process.env.REACT_APP_EU_URL
  const USURL = process.env.REACT_APP_US_URL
  const DEPRECATEDPORTALURL = process.env.REACT_APP_DEPRECATED_PORTAL_URL
  const LOCALHOSTURL = process.env.REACT_APP_LOCALHOST_URL
  const DEVURL = process.env.REACT_APP_DEV_URL
  const UATURL = process.env.REACT_APP_UAT_URL

  const regionOptions = [
    ...(showOldPortalRegion
      ? [
          {
            value: DEPRECATEDPORTALURL,
            label: <RegionOption region='Old Portal' />,
          },
        ]
      : []),
    { value: UKURL, label: <RegionOption region='UK' /> },
    { value: ANZURL, label: <RegionOption region='ANZ' /> },
    { value: EUURL, label: <RegionOption region='EU' /> },
    { value: USURL, label: <RegionOption region='US' /> },
    ...(process.env.NODE_ENV === 'development'
      ? [
          { value: LOCALHOSTURL, label: 'Localhost' },
          { value: DEVURL, label: 'Development' },
          { value: UATURL, label: 'UAT' },
        ]
      : []),
  ]

  const [selectedRegion, setSelectedRegion] = useState(
    regionOptions.find(option => window.location.href.startsWith(option.value))
  )

  const handleRegionChange = selectedOption => {
    window.location.href = selectedOption.value
    setSelectedRegion(selectedOption.value)
  }

  // Email validation function
  const validateEmail = email => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(email)
  }

  const handleLogin = async () => {
    // Validate email before login
    if (!validateEmail(username)) {
      setEmailError('Please enter a valid email address.')
      return
    }

    try {
      setLoadingState(true)

      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/login`,
        {
          username,
          password,
          authCode,
        }
      )

      if (response.data.authenticated === true) {
        localStorage.setItem(
          'Inforcer_FE_Version',
          response.data.ReleaseCounter
        )
        localStorage.setItem(
          'isSalesAdmin',
          response.data.isSalesAdmin ? 'true' : 'false'
        )
        localStorage.setItem(
          'isClientAdmin',
          response.data.clientAdmin ? 'true' : 'false'
        )
        localStorage.setItem('isSSOUser', 'false')

        if (response.data.forcePasswordChange) {
          setForcePasswordChange(true)
          localStorage.setItem('forcePasswordChange', 'true')
          localStorage.setItem('username', username)
          navigate('/update-password')
        } else {
          setLoginStatus([
            response.data.displayName,
            ' ',
            response.data.message,
          ])
          setLoadApp(true)
          navigate('/')

          localStorage.setItem('username', username)

          const region = process.env.REACT_APP_ENVIRONMENT

          // Boot Intercom with user details
          boot({
            appId: process.env.REACT_APP_INTERCOM_APP_ID,
            apiBase: 'https://api-iam.intercom.io',
            name: response.data.displayName,
            email: localStorage.getItem('username'),
            createdAt: response.data.createDate,
            userHash: response.data.emailHash,
            company: {
              companyId: `${region}_${response.data.clientid}`,
              name: response.data.customerName,
            },
          })
          clearLocalOnboardingState()
        }
      } else {
        setLoginStatus(response.data.message)
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400 || error.response.status === 401) {
          setLoginError(
            'Login unsuccessful. Please check your credentials and try again'
          )
        } else if (
          error.response.status === 502 ||
          error.response.status === 503 ||
          error.response.status === 500
        ) {
          setLoginError('Internal Server Error: Please try again later.')
        } else {
          setLoginError('Internal Server Error: Please try again later.')
        }
      } else if (error.message === 'Network Error') {
        setLoginError(
          'Network error. Please check your internet connection and try again.'
        )
      } else {
        setLoginError('Unknown error occurred. Please try again later.')
      }
    } finally {
      setLoadingState(false)
    }
  }

  // Authentication code validation function
  const validateAuthCode = code => {
    const authCodePattern = /^\d{6}$/ // Regular expression to match 6 digits only
    return authCodePattern.test(code)
  }

  const isValidAuthCode = code => {
    const isValid = validateAuthCode(code)

    if (isValid) {
      setAuthCodeError('')
    } else {
      setAuthCodeError('Please enter a valid 6 digit auth code')
    }
  }

  const handleSubmit = e => {
    e.preventDefault() // Prevent the default form submission behavior
    // Perform any additional validation or processing before calling handleLogin
    if (!usingSSO) {
      handleLogin()
    }
  }

  const ssoLogin = e => {
    e.preventDefault()
    setUsingSSO(true)
  }

  const normalLogin = e => {
    e.preventDefault()
    setUsingSSO(false)
  }

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#60a5fa' : '#cbd5e1',
      boxShadow: state.isFocused ? '0 0 0 1px #60a5fa' : provided.boxShadow,
      '&:hover': {
        borderColor: state.isFocused ? '#60a5fa' : '#cbd5e1',
      },
    }),
  }

  return (
    <div className='relative'>
      <div className='login-page'>
        <div className='login-box text-center '>
          <form onSubmit={handleSubmit} hidden={usingSSO}>
            <h3>{loginStatus}</h3>
            <img src={logo} className='inforcer-logo' alt='Inforcer Logo' />
            {showRegionSelectorValue && !loadingState && (
              <>
                <div className='w-[100%] flex align-center justify-center navy-bg text-white text-center p-2 absolute top-0 left-0'>
                  <p>
                    There is now a dedicated instance of Inforcer for your
                    region. Please select the region your organisation operates
                    in, and log in as usual.
                  </p>
                </div>
                <div className='regional-selection flex justify-between mb-4 text-left'>
                  <Select
                    options={regionOptions}
                    onChange={handleRegionChange}
                    value={selectedRegion}
                    placeholder='Select your region'
                    className='w-[100%] text-gray-700'
                    styles={selectStyles}
                  />
                </div>
                <HorizontalDivider className='my-5' />
              </>
            )}

            {loadingState ? (
              <div id='loading-spinner'>
                <div className='loading' />
              </div>
            ) : (
              <div className='login-box-inner'>
                {emailError && (
                  <p className='text-red-500 mb-[24px]'>{emailError}</p>
                )}
                {authCodeError && (
                  <p className='text-red-500 mb-[24px]'>{authCodeError}</p>
                )}
                {loginError && (
                  <p className='text-red-500 mb-[24px]'>{loginError}</p>
                )}
                <input
                  type='text'
                  aria-label='Inforcer Email'
                  placeholder='Email'
                  autoComplete='email'
                  className={`flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 mb-[20px] focus:outline-blue-400 ${emailError ? 'border-red-500' : ''}`}
                  onChange={e => {
                    setUsername(e.target.value)
                    setEmailError('') // Reset the email error when the input changes
                  }}
                />

                <input
                  type='password'
                  className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
                  placeholder='Password'
                  autoComplete='current-password'
                  onChange={e => {
                    setPassword(e.target.value)
                  }}
                />

                <input
                  autoComplete='off'
                  spellCheck='false'
                  type='text'
                  maxLength='6'
                  className={`flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400 ${authCodeError ? 'border-red-500' : ''}`}
                  placeholder='Authenticator code'
                  onChange={e => {
                    const code = e.target.value
                    setAuthCode(code)
                  }}
                  onBlur={() => isValidAuthCode(authCode)}
                />

                <button
                  disabled={showRegionSelectorValue ? !selectedRegion : false}
                  className='btn cyan-btn'
                  type='submit'
                >
                  Login
                </button>
                <HorizontalDivider className='mb-6 mt-2' />
                <SSOButton
                  text='Sign in with Microsoft'
                  onClick={ssoLogin}
                  lightMode
                  disabled={showRegionSelectorValue ? !selectedRegion : false}
                />
                <Link to='/forgot-password' className='text-sm underline'>
                  Forgot password?
                </Link>
              </div>
            )}
          </form>
          {usingSSO && <SSOLogin show={usingSSO} normalLogin={normalLogin} />}
        </div>
      </div>
    </div>
  )
}

export default Login
