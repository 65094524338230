import { useInfiniteQuery } from '@tanstack/react-query'
import axios from 'axios'

/**
 * Fetches shared baseline groups from the middleware
 * @param {number} itemsPerPage - The number of items to fetch per page
 * @param {boolean} isAdmin - If the endpoint should be admin or client
 */

const useGetBaselineGroups = (itemsPerPage, isAdmin) =>
  useInfiniteQuery({
    queryKey: ['sharedBaselineGroups', itemsPerPage],
    queryFn: async ({ pageParam = 0 }) => {
      let response

      if (isAdmin) {
        response = await axios.get(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/internal/sharedBaselineGroups`,
          {
            params: {
              take: itemsPerPage,
              skip: pageParam * itemsPerPage,
            },
          }
        )
      } else {
        response = await axios.get(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/sharedBaselineGroups`,
          {
            params: {
              take: itemsPerPage,
              skip: pageParam * itemsPerPage,
            },
          }
        )
      }

      return response.data
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      // If lastPage.data is empty, there are no more items to fetch
      if (lastPage.data.length < itemsPerPage || lastPage.data.length === 0) {
        return undefined
      }

      // Otherwise, return the next step as the number of pages already fetched
      return pages.length
    },
  })

export default useGetBaselineGroups
