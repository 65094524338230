import { useMutation, useQueryClient } from '@tanstack/react-query'
import Axios from 'axios'

const useDeployPolicies = onSuccess => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deployPayload =>
      Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/send-deployment`,
        deployPayload
      ),
    onSuccess: () => {
      queryClient.invalidateQueries('tenant-summary')
      onSuccess()
    },
  })
}

export default useDeployPolicies
