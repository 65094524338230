import { bool, string } from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import Alert from '../Alert/Alert'

const ProcessClientVariablesAlert = ({
  pending,
  isSuccess,
  okMessage,
  warningMessage,
}) => {
  if (pending) {
    return <Skeleton count={1} height={60} />
  }

  return (
    <Alert type={isSuccess ? 'info' : 'warning'}>
      {isSuccess ? okMessage : warningMessage}
    </Alert>
  )
}

ProcessClientVariablesAlert.propTypes = {
  pending: bool.isRequired,
  isSuccess: bool.isRequired,
  okMessage: string.isRequired,
  warningMessage: string.isRequired,
}

export default ProcessClientVariablesAlert
