import { node, number, shape, string, arrayOf } from 'prop-types'
import { createContext, useContext } from 'react'

const TenantContext = createContext()

export const TenantProvider = ({ children, tenant }) => (
  <TenantContext.Provider value={tenant}>{children}</TenantContext.Provider>
)

export const useTenant = () => useContext(TenantContext)

TenantProvider.propTypes = {
  children: node.isRequired,
  tenant: arrayOf(
    shape({
      clientTenantId: number.isRequired,
      tenantFriendlyName: string.isRequired,
    })
  ).isRequired,
}
