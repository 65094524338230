import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { func } from 'prop-types'
import { usePolicyViewer } from '../../hooks/UsePolicyViewer'
import { isDriftPolicy } from './utils'
import DeviationDetails from './components/DeviationDetails'
import {
  policyShape,
  summaryTenantShape,
} from '../../utils/propTypes/tenantAlignmentProps'

const PolicyDescription = ({
  policy,
  onViewPolicyComparison,
  baselineTenant,
  selectedTenant,
}) => {
  const {
    baselineTenantId,
    policyGuid,
    policyTypeId,
    policyTypeName,
    policyName,
    stringifiedDiff,
    matchingSubjectPoliciesCount,
    matchingBaselinePoliciesCount,
    isMissingFromBaseline,
    isMissingFromSubject,
    subjectTenantId,
    deviationAcceptedReason,
    deviationAcceptedByUsername,
    deviationAcceptedTimestamp,
    comparatorPolicyGuids,
    policyDataDiff,
    isDeviationAccepted,
  } = policy

  const showViewSubjectBtn =
    isMissingFromBaseline ||
    (!isMissingFromSubject &&
      matchingSubjectPoliciesCount === 1 &&
      comparatorPolicyGuids.length === 1)
  const showViewBtnArea = showViewSubjectBtn || !isMissingFromBaseline
  const multipleSubjectPoliciesDetected = matchingSubjectPoliciesCount > 1
  const multipleBaselinePoliciesDetected = matchingBaselinePoliciesCount > 1

  const isAcceptedOrDriftedDeviation =
    isDriftPolicy(policyDataDiff) || isDeviationAccepted

  const viewPolicy = usePolicyViewer()

  return (
    <div className='text-md'>
      {isAcceptedOrDriftedDeviation && (
        <DeviationDetails
          dataDiff={policyDataDiff[0]?.driftedDiff ?? []}
          timestamp={deviationAcceptedTimestamp}
          reason={deviationAcceptedReason}
          username={deviationAcceptedByUsername}
        />
      )}

      {/* eslint-disable-next-line no-nested-ternary */}
      {multipleSubjectPoliciesDetected ? (
        <div className='mb-2'>
          <span className='text-red'>Duplicate Policy Detected:</span> Multiple
          identically named policies have been identified in this tenant. Please
          check your customer tenant.
        </div>
      ) : stringifiedDiff ? (
        <div className='mb-2'>
          <b>Data deviation from baseline:</b>
          <p style={{ whiteSpace: 'pre-wrap', lineBreak: 'anywhere' }}>
            {stringifiedDiff}
          </p>
        </div>
      ) : null}

      {showViewBtnArea && (
        <div className='text-right'>
          {!isMissingFromBaseline &&
          !multipleBaselinePoliciesDetected &&
          !(
            showViewSubjectBtn &&
            !isMissingFromBaseline &&
            !multipleBaselinePoliciesDetected
          ) ? (
            <button
              type='button'
              className='btn text-xs navy-btn'
              onClick={event => {
                event.stopPropagation()
                viewPolicy(
                  baselineTenantId,
                  baselineTenant.tenantFriendlyName,
                  policyGuid,
                  policyTypeId,
                  policyTypeName,
                  policyName
                )
              }}
            >
              View Baseline Policy
              <FontAwesomeIcon className='pl-2' icon={faMagnifyingGlass} />
            </button>
          ) : null}

          {showViewSubjectBtn &&
          !(
            showViewSubjectBtn &&
            !isMissingFromBaseline &&
            !multipleBaselinePoliciesDetected
          ) ? (
            <button
              type='button'
              className={`btn text-xs navy-btn${showViewSubjectBtn ? ' ml-1' : ''}`}
              onClick={event => {
                event.stopPropagation()
                viewPolicy(
                  subjectTenantId,
                  selectedTenant.tenantFriendlyName,
                  isMissingFromBaseline ? policyGuid : comparatorPolicyGuids[0],
                  policyTypeId,
                  policyTypeName,
                  policyName
                )
              }}
            >
              View Customer Policy
              <FontAwesomeIcon className='pl-2' icon={faMagnifyingGlass} />
            </button>
          ) : null}

          {showViewBtnArea &&
            showViewSubjectBtn &&
            !isMissingFromBaseline &&
            !multipleBaselinePoliciesDetected && (
              <button
                type='button'
                className='btn navy-btn text-xs ml-1'
                onClick={event => {
                  event.stopPropagation()
                  onViewPolicyComparison()
                }}
              >
                View Policy Comparison
                <FontAwesomeIcon className='pl-2' icon={faMagnifyingGlass} />
              </button>
            )}
        </div>
      )}
    </div>
  )
}

PolicyDescription.propTypes = {
  policy: policyShape.isRequired,
  onViewPolicyComparison: func.isRequired,
  baselineTenant: summaryTenantShape.isRequired,
  selectedTenant: summaryTenantShape.isRequired,
}

export default PolicyDescription
