import { useState } from 'react'
import './PolicyLibrary.css'

import useGetBaselineGroups from '../../components/SharedBaselines/api/useGetBaselineGroups'

import CustomerBaselineCard from '../../components/SharedBaselines/components/CustomerBaselineCard'
import PolicyLibraryTag from '../../components/SharedBaselines/components/PolicyLibraryTag'
import SearchBar from '../../components/SharedBaselines/components/BaselinesPageSearchBar'
import SharedBaselinesGroupSkeleton from '../../components/SharedBaselines/components/SharedBaselinesGroupSkeleton'
import Alert from '../../components/Alert/Alert'

import Tooltip from '../../components/tooltip/Tooltip'

const PolicyLibrary = () => {
  const itemsPerPage = 100

  const {
    data: baselineGroups,
    isLoading: isLoadingBaselineGroups,
    isError: isErrorBaselineGroups,
    error: errorBaselineGroups,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetBaselineGroups(itemsPerPage, false)

  const [searchQuery, setSearchQuery] = useState('')

  const handleSearchChange = e => {
    setSearchQuery(e.target.value)
  }

  const handleClearSearch = () => {
    setSearchQuery('')
  }

  const filteredData = baselineGroups?.pages.flatMap(baselineGroup =>
    baselineGroup.data.filter(
      baseline =>
        baseline.subscribed &&
        baseline.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  )

  if (isLoadingBaselineGroups) {
    return (
      <div className='shared-baselines-page md:container mx-auto'>
        <div className='flex items-center shadow-sm bg-white w-full rounded-xl mb-6 py-8 px-10'>
          <SharedBaselinesGroupSkeleton />
        </div>
      </div>
    )
  }

  if (isErrorBaselineGroups) {
    return (
      <div className='shared-baselines-page md:container mx-auto'>
        <div className='flex items-center shadow-sm bg-white w-full rounded-xl mb-6 py-8 px-10'>
          <Alert
            type='error'
            title={errorBaselineGroups.response.data.message}
            margin='mb-4'
          >
            <ul>
              {errorBaselineGroups.response.data.errors?.map(error => (
                <li>{error}</li>
              ))}
            </ul>
          </Alert>
        </div>
      </div>
    )
  }

  return (
    <div className='shared-baselines-page md:container mx-auto'>
      <div className='flex items-center max-[1280px]:flex-wrap shadow-sm bg-white w-full rounded-xl py-8 px-10 mb-6'>
        <div className='shared-baselines-heading w-full rounded-xl overflow-hidden'>
          <h2>Policy Library</h2>
          <p>Baseline groups your organisation is a member or subscriber of</p>
        </div>
        <div className='searchbar w-full rounded-xl overflow-hidden p-2'>
          <SearchBar
            searchQuery={searchQuery}
            handleSearchChange={handleSearchChange}
            handleClearSearch={handleClearSearch}
          />
        </div>
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
        {filteredData.length > 0 ? (
          filteredData.map(baselineGroup => (
            <CustomerBaselineCard
              key={baselineGroup.id}
              baselineData={baselineGroup}
              tags={[
                baselineGroup.subscribed && (
                  <Tooltip content='You are subscribed to this baseline and can view all of its policies'>
                    <PolicyLibraryTag type='subscriber' key='subscriber-tag' />
                  </Tooltip>
                ),
                baselineGroup.inforcerManaged && (
                  <Tooltip content='This shared baseline is managed by Inforcer'>
                    <PolicyLibraryTag
                      type='inforcerManaged'
                      key='inforcerManaged-tag'
                    />
                  </Tooltip>
                ),
              ]}
            />
          ))
        ) : (
          <div className='col-span-3 bg-white p-4 text-center'>
            <h3 className='text-2xl opacity-80'>
              No shared baseline groups found
            </h3>
          </div>
        )}
      </div>
      <div className='flex justify-center'>
        {hasNextPage && !isLoadingBaselineGroups && (
          <button
            className='mt-6 navy-btn btn'
            type='button'
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage && 'Loading more...'}
            {hasNextPage && 'Load More'}
          </button>
        )}
        {filteredData.length > 0 && !hasNextPage && (
          <h4 className='pt-4 text-lg opacity-80'>All Groups Loaded</h4>
        )}
      </div>
    </div>
  )
}

export default PolicyLibrary
