const ListPolicies = ({ policies }) => (
  <ol className='text-left ps-5 mt-2 space-y-1 list-decimal list-inside'>
    {policies.map(policy => (
      <li key={policy.id}>
        {policy.displayName || policy.policyName || policy.name}
      </li>
    ))}
  </ol>
)

export default ListPolicies
