import { useMutation } from '@tanstack/react-query'
import Axios from 'axios'

const useDeletePolicy = (onSuccess, onError) =>
  useMutation({
    mutationKey: ['delete-policy'],
    mutationFn: async ({
      policies,
      clientTenantId,
      explicitDeleteGuid,
      saveOverride,
    }) => {
      const requestBodies = policies.map(policy => ({
        clientTenantId,
        policyTypeId: policy.policyTypeId,
        policyGuid: explicitDeleteGuid || policy.id,
        policyName: policy.displayName || policy.policyName || policy.name,
        saveOverride,
        overridePolicyId: policy.subjectPolicyId,
      }))
      return await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/delete-policy`,
        requestBodies
      )
    },
    onSuccess: () => {
      onSuccess?.()
    },
    onError,
  })

export default useDeletePolicy
