import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const ListClients = ({ clients, selectedClient, setSelectedClient }) => {
  const clientClick = ({ clientId, displayName }) => {
    if (selectedClient?.clientId === clientId) {
      setSelectedClient(null)
    } else {
      const updatedClient = {
        displayName,
        clientId,
      }
      setSelectedClient(updatedClient)
    }
  }

  return (
    <div>
      <div className='table-container list-clients-table'>
        <table>
          <tbody>
            <tr className='table-head'>
              <td>Client ID</td>
              <td>Client Name</td>
              <td>Report Email</td>
              <td aria-label='Is Selected' />
            </tr>
            {clients?.map(client => (
              <tr
                key={`${client.clientId}-${client.displayName}`}
                onClick={() => clientClick(client)}
                className={`client-row cursor-pointer ${
                  selectedClient?.clientId === client.clientId ? 'selected' : ''
                }`}
              >
                <td className='client-id'>{client.clientId}</td>
                <td className='client-name'>{client.displayName}</td>
                <td className='report-email'>{client.reportEmail}</td>
                <td className='check-cell'>
                  {selectedClient?.clientId === client.clientId ? (
                    <FontAwesomeIcon icon={faCheckCircle} className='ml-2' />
                  ) : null}
                </td>
              </tr>
            ))}

            {clients?.length === 0 && (
              <tr>
                <td colSpan='4'>No clients found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ListClients
