import { createContext, useReducer, useContext, useMemo } from 'react'
import { node } from 'prop-types'

const ExpansionContext = createContext()

const expansionInitialState = {
  expandedItems: [], // Tracks the expanded state of items by unique keys
}

const expansionReducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_EXPAND': {
      const { parentGroup, name } = action.payload
      const key = name ? `${parentGroup}-${name}` : parentGroup // Create a unique key

      return {
        ...state,
        expandedItems: {
          ...state.expandedItems,
          [key]: !state.expandedItems[key], // Toggle the key's expansion state
        },
      }
    }

    case 'EXPAND_ALL_DYNAMICALLY': {
      const { groupedPolicies } = action.payload

      const itemsToExpand = groupedPolicies.reduce((acc, group) => {
        acc.push(group.name)

        group.subGroups.forEach(subGroup => {
          acc.push(`${group.name}-${subGroup.name}`)

          subGroup.categories.forEach(category => {
            acc.push(`${group.name}-${subGroup.name}-${category.name}`)

            category.items.forEach(item => {
              acc.push(
                `${group.name}-${subGroup.name}-${category.name}-${item.displayName}`
              )
            })
          })
        })

        return acc
      }, [])

      const expandedItems = itemsToExpand.reduce((acc, item) => {
        acc[item] = true
        return acc
      }, {})

      return {
        ...state,
        expandedItems: {
          ...state.expandedItems,
          ...expandedItems,
        },
      }
    }

    case 'TOGGLE_ALL_EXPAND': {
      const { items, parentGroup } = action.payload

      // Determine if we should expand all or collapse all within the group
      const shouldExpandAll = items.some(item => {
        const key = parentGroup ? `${parentGroup}-${item}` : item // Handle top-level or nested items
        return !state.expandedItems[key]
      })

      // Construct the new expanded state for all items
      const expandedItems = items.reduce((acc, item) => {
        const key = parentGroup ? `${parentGroup}-${item}` : item // Handle top-level or nested items
        acc[key] = shouldExpandAll
        return acc
      }, {})

      // Ensure the parent group itself is expanded if applicable
      if (shouldExpandAll && parentGroup) {
        expandedItems[parentGroup] = true
      }

      return {
        ...state,
        expandedItems: {
          ...state.expandedItems,
          ...expandedItems,
        },
      }
    }

    case 'RESET':
      return expansionInitialState

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const ExpansionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(expansionReducer, expansionInitialState)

  const contextValue = useMemo(() => {
    const toggleExpand = ({ parentGroup, name }) => {
      dispatch({
        type: 'TOGGLE_EXPAND',
        payload: { parentGroup, name },
      })
    }

    const toggleExpandAll = ({ parentGroup, items }) => {
      dispatch({
        type: 'TOGGLE_ALL_EXPAND',
        payload: { parentGroup, items },
      })
    }

    const expandAllDynamically = groupedPolicies => {
      dispatch({
        type: 'EXPAND_ALL_DYNAMICALLY',
        payload: { groupedPolicies },
      })
    }

    const isExpanded = (parentGroup, name) => {
      const key = name ? `${parentGroup}-${name}` : parentGroup
      return !!state.expandedItems[key]
    }

    const isAllExpanded = (parentGroup, items) => {
      if (parentGroup) {
        // When parentGroup is provided, check if all child items are expanded
        return items.every(
          item => state.expandedItems[`${parentGroup}-${item}`]
        )
      }
      // When no parentGroup is provided, check if all top-level items are expanded
      return items.every(item => state.expandedItems[item])
    }

    const resetExpansionState = () => {
      dispatch({ type: 'RESET' })
    }

    return {
      state,
      toggleExpand,
      toggleExpandAll,
      expandAllDynamically,
      isExpanded,
      isAllExpanded,
      resetExpansionState,
    }
  }, [state])

  return (
    <ExpansionContext.Provider value={contextValue}>
      {children}
    </ExpansionContext.Provider>
  )
}

export const useExpansion = () => {
  const context = useContext(ExpansionContext)
  if (!context) {
    throw new Error('useExpansion must be used within a ExpansionProvider')
  }
  return context
}

ExpansionProvider.propTypes = {
  children: node.isRequired,
}
