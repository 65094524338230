import { arrayOf, node, shape, string } from 'prop-types'
import React from 'react'

const CustomerBaselineCard = ({ baselineData, tags, children }) => (
  <div className='shared-baseline-card w-full rounded-xl shadow-sm bg-white p-5'>
    <h4>{baselineData?.name}</h4>
    <p>{baselineData?.description}</p>
    {children}
    <div className='subscription-tags'>
      {tags?.map(tag => (
        <React.Fragment key={tag.id}>{tag}</React.Fragment>
      ))}
    </div>
  </div>
)

export default CustomerBaselineCard

CustomerBaselineCard.defaultProps = {
  baselineData: {
    name: '',
    description: '',
  },
  tags: [],
  children: null,
}

CustomerBaselineCard.propTypes = {
  baselineData: shape({
    name: string,
    description: string,
  }),
  tags: arrayOf(node),
  children: node,
}
