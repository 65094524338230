/**
 * Determines if a policy is read-only
 * @param {Object} policy - The policy object to check
 * @returns {boolean} - True if the policy is read-only, otherwise false
 */
const IsReadOnlyPolicy = policy => {
  const { readOnly } = policy

  if (readOnly !== undefined && readOnly !== null) {
    return readOnly
  }

  return false
}

export default IsReadOnlyPolicy
