import HiddenValueCell from '../../../TableCells/HiddenValueCell/HiddenValueCell'

const matchingVariablesTableConfig = () => [
  {
    header: 'Name',
    accessor: 'variableName',
  },
  {
    header: 'Value',
    accessor: 'variableValue',
    cell: ({ row }) => (
      <HiddenValueCell
        value={row.original.variableValue}
        hidden={row.original.hidden}
      />
    ),
  },
]

export default matchingVariablesTableConfig
