import Axios from 'axios'
import { useCallback, useState } from 'react'

const useOnboardingCodeValidator = () => {
  const [loading, setLoading] = useState()
  const [error, setError] = useState()
  const [result, setResult] = useState()

  const validateCode = useCallback(
    async (onboardingCode, onValidated = null) => {
      if (!onboardingCode) return

      setLoading(true)
      setError(undefined)

      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/validateOnboardingCode`,
          {
            onboardingCode,
          }
        )

        if (response.status < 200 || response.status >= 300) {
          setError('Failed to validate onboarding code')
          return
        }

        setResult(response.data)
        onValidated?.(response.data)
      } catch (validationError) {
        setError('Failed to validate onboarding code')
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const resetValidation = useCallback(() => {
    setResult(undefined)
    setError(undefined)
    setLoading(false)
  })

  return {
    validateCode,
    loading,
    error,
    isValid: result?.isValid === true,
    allowedOnboardings: result?.allowedOnboardings ?? 0,
    message: result?.message,
    resetValidation,
  }
}

export default useOnboardingCodeValidator
