import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

const useDeleteTenantTag = (onSuccess, onError) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['remove-tenant-tag'],
    mutationFn: async id => {
      await axios.delete(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/tenantTags/${id}`
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries('tenantTags')
      onSuccess()
    },
    onError,
  })
}

export default useDeleteTenantTag
