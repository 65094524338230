import { getFlagsForPolicy } from '../AdditionalOptions/AdditionalOptionsConfig'

const createDeployPolicyPayload = (
  policiesToDeploy,
  additionalSettings,
  sourceTenant,
  destinationTenants,
  selectedVariables
) => {
  const payload = []

  policiesToDeploy.forEach(policy => {
    const {
      policyGuid,
      policyTypeId,
      policyName,
      priority,
      subjectPolicyId,
      matchingVariables,
    } = policy

    const additionalSettingsForPolicy = getFlagsForPolicy(
      additionalSettings,
      policyTypeId
    )

    if (
      additionalSettingsForPolicy.deployAllUsers === true ||
      additionalSettingsForPolicy.deployAllDevices === true ||
      additionalSettingsForPolicy.deployAllUsersAndDevices === true
    ) {
      additionalSettingsForPolicy.deployAssignments = false
    }

    destinationTenants.forEach(destinationTenant => {
      let variableIds = []
      // filters down the selected variables to only those that have a match in the selected policy
      if (selectedVariables?.length > 0 && matchingVariables?.length > 0) {
        variableIds = selectedVariables
          .filter(selectedVariable =>
            matchingVariables.some(
              matchingVariable =>
                matchingVariable.variableId === selectedVariable.id
            )
          )
          .map(v => v.id)
      }

      const currentPolicy = {
        clientTenantIdDeployFrom: sourceTenant.clientTenantId,
        clientTenantIdDeployTo: +destinationTenant.clientTenantId,
        policyGuid,
        policyTypeId,
        priority,
        displayName: policyName,
        saveOverride: true,
        overridePolicyId: subjectPolicyId,
        ...additionalSettingsForPolicy,
        variables: variableIds,
      }

      payload.push(currentPolicy)
    })
  })

  return payload
}

export default createDeployPolicyPayload
