import axios from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ssoUserQueryKey } from './useGetSSOUsersQuery'

const useAddUser = (onSuccess, onError) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['add-user-sso'],
    mutationFn: async ({ email, displayName }) =>
      await axios.post(`${process.env.REACT_APP_MIDDLEWARE_URL}/sso/users`, {
        username: email,
        displayName,
      }),
    onSuccess: data => {
      queryClient.invalidateQueries(ssoUserQueryKey)
      onSuccess(data)
    },
    onError,
  })
}

export default useAddUser
