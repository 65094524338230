import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

const useSearchUserData = (onSuccess, onError) =>
  useMutation({
    mutationKey: ['userData'],
    mutationFn: async clientId => {
      const response = await axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/getUserList`,
        {
          clientId,
        }
      )
      return response.data
    },
    onSuccess: data => {
      // Call the onSuccess callback if provided
      if (onSuccess) {
        onSuccess(data)
      }
    },
    onError: error => {
      // Call the onError callback if provided
      if (onError) {
        onError(error)
      }
    },
  })

export default useSearchUserData
