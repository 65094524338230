import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import RemoveUserPopup from '../components/RemoveUserPopup'

const RemoveUser = () => {
  const [clients, setClients] = useState([])
  const [users, setUsers] = useState([])

  const [selectedClientId, setSelectedClientId] = useState('')
  const [selectedUserName, setSelectedUserName] = useState('')

  const [responseData, setResponseData] = useState('')
  const [openPopup, setOpenPopup] = useState(false)

  const navigate = useNavigate()

  const clientSearch = async () => {
    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/get-clients`
      )
      const { data } = response
      setClients(data)
    } catch (error) {
      console.error('Error:', error.response.status)
      toast.error('Error Retrieving Clients')
    }
  }

  useEffect(() => {
    clientSearch()
  }, [])

  const userSearch = async clientId => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/getUserList`,
        { clientId }
      )
      let { data } = response
      if (!Array.isArray(data)) {
        data = [data]
      }
      setUsers(data)
    } catch (error) {
      console.error('Error:', error.response.status)
    }
  }

  const handleClientChange = e => {
    setSelectedUserName('')
    const clientId = e.target.value
    setSelectedClientId(clientId)
    if (clientId) {
      userSearch(clientId)
    } else {
      setUsers([])
    }
  }

  const handleUserChange = e => {
    setSelectedUserName(e.target.value)
  }

  const submitData = async () => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/removeUser`,
        {
          clientId: selectedClientId,
          username: selectedUserName,
        }
      )

      setResponseData(response.data)
    } catch (error) {
      toast.error('Error Removing User')
      setResponseData('')
    }
  }

  const handleOpenPopUp = () => {
    setOpenPopup(true)
  }

  const handleClosePopUp = () => {
    setOpenPopup(false)
  }

  return (
    <div className='ui-panel admin-2-col'>
      <div className='heading'>
        <h2>Remove User</h2>
      </div>
      <button
        type='button'
        onClick={() => navigate(-1)}
        className='btn bg-white text-gray-900 flex items-center justify-center mb-2'
      >
        {' '}
        <FontAwesomeIcon icon={icon({ name: 'angle-left' })} />
        <span>&nbsp;&nbsp;Back</span>
      </button>
      <div className='action-body no-progress-bar flex'>
        <form className='w-[50%] max-w-lg'>
          <div className='flex flex-col space-y-2 my-4'>
            <label className='font-semibold' htmlFor='selectclient'>
              Select Client:
            </label>
            <select
              value={selectedClientId}
              onChange={handleClientChange}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
            >
              <option value=''>Select a client</option>
              {clients.map(client => (
                <option key={uuidv4()} value={client.clientId}>
                  {client.displayName}
                </option>
              ))}
            </select>
          </div>

          <div className='flex flex-col space-y-2 my-4'>
            <label className='font-semibold' htmlFor='selectuser'>
              Select User:
            </label>
            <select
              value={selectedUserName}
              onChange={handleUserChange}
              className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[20px] focus:outline-blue-400'
            >
              <option value=''>Select a user</option>
              {users.map(user => (
                <option key={uuidv4()} value={user.username}>
                  {user.displayName}
                </option>
              ))}
            </select>
          </div>

          <button
            type='button'
            disabled={!selectedUserName}
            onClick={handleOpenPopUp}
            className='btn cyan-btn mt-4'
          >
            Remove User
          </button>
        </form>
        <div className='w-[50%] p-4 ml-4' />
      </div>
      {openPopup === true && (
        <RemoveUserPopup
          handleOpenPopUp={handleOpenPopUp}
          handleClosePopUp={handleClosePopUp}
          submitData={submitData}
          responseData={responseData}
          selectedUserName={selectedUserName}
        />
      )}
    </div>
  )
}

export default RemoveUser
