import { useState } from 'react'
import Axios from 'axios'
import { Link } from 'react-router-dom'
import logo from '../assets/inforcer-logo.png'

const ForgotPassword = () => {
  const [emailAddress, setEmailAddress] = useState('')
  const [emailError, setEmailError] = useState('')
  const [submitMessage, setSubmitMessage] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Email validation function
  const validateEmail = emailAddress => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(emailAddress)
  }

  const submitRequest = async e => {
    e.preventDefault()
    setIsLoading(true)

    // ensure confirmation message is cleared
    setSubmitMessage('')

    // Validate email before req send
    if (!validateEmail(emailAddress)) {
      setEmailError('Please enter a valid email address.')
      setIsLoading(false)
      return
    }
    const requestBody = {
      username: emailAddress,
    }
    try {
      await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/initiatePassResetFE`,
        requestBody
      )
      setSubmitMessage(
        "Thanks! If we recognised your email, we'll have sent you a link to reset your password."
      )
    } catch (error) {
      setSubmitMessage(
        "Thanks! If we recognised your email, we'll have sent you a link to reset your password."
      )
    } finally {
      setIsLoading(false)
      setIsSubmitted(true)
    }
  }

  return (
    <div className='login-page forgot-password-page'>
      <div className='login-box text-center'>
        <form onSubmit={submitRequest}>
          <img src={logo} className='inforcer-logo' alt='Inforcer Logo' />
          {submitMessage ? (
            <p className='mb-[28px] font-bold'>{submitMessage}</p>
          ) : (
            <p className='mb-[28px]'>
              Enter your account email address below to reset your password
            </p>
          )}
          {emailError && (
            <div
              className='p-4 mb-[24px] text-sm text-red-800 rounded-lg bg-red-50 text-left'
              role='alert'
            >
              <span className='text-sm'>{emailError}</span>
            </div>
          )}

          {isLoading && (
            <div id='loading-spinner'>
              <div className='loading' />
            </div>
          )}

          {!isSubmitted && !isLoading && (
            <div>
              <input
                type='text'
                placeholder='Email'
                className={`flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 mb-[20px] focus:outline-blue-400 ${emailError ? 'border-red-500' : ''}`}
                onChange={e => {
                  setEmailAddress(e.target.value)
                  setEmailError('')
                }}
              />
              <button className='btn cyan-btn' type='submit'>
                Submit
              </button>
            </div>
          )}
        </form>
        <Link to='/login' className='text-sm underline'>
          Back to login page
        </Link>
      </div>
    </div>
  )
}

export default ForgotPassword
