import Select from 'react-select'
import { arrayOf, bool, func, number, shape, string } from 'prop-types'

/**
 * Renders conditional settings for the additional options component in deploy
 *
 * @param optionsToRender Array of Options as found in policy.js -> renderDropdownIfTrue
 * @param policyId top level policyId
 * @param parentFlag the flagName of the parent setting
 * @param onNestedFlagChange function to update the flag value onChange
 */
const ConditionalSettings = ({
  optionsToRender,
  policyId,
  parentFlag,
  onNestedFlagChange,
}) => {
  // This really isn't an ideal way of doing things but it'll do for now :)
  const handleChange = flagNameToUpdate => {
    onNestedFlagChange(policyId, parentFlag, flagNameToUpdate.flagName, true)
  }

  const dropdownOptions = optionsToRender.map(option => ({
    label: option.text,
    value: option.value,
    flagName: option.flagName,
  }))

  return (
    <form
      className='pl-4 py-2 border-l-2'
      aria-label={`form-${policyId}-${parentFlag}`}
    >
      <label htmlFor={`select-${policyId}-${parentFlag}`}>
        Assignment Options
      </label>
      <Select
        name={`select-${policyId}-${parentFlag}`}
        inputId={`select-${policyId}-${parentFlag}`}
        value={dropdownOptions.find(option => option.value === true)}
        options={dropdownOptions}
        onChange={handleChange}
      />
    </form>
  )
}

ConditionalSettings.propTypes = {
  optionsToRender: arrayOf(
    shape({
      text: string,
      flagName: string,
      value: bool,
    })
  ).isRequired,
  policyId: number.isRequired,
  parentFlag: string.isRequired,
  onNestedFlagChange: func.isRequired,
}

export default ConditionalSettings
