function hexToRgb(hexCode) {
  const hex = hexCode.replace('#', '')

  const fullHex =
    hex.length === 3
      ? hex
          .split('')
          .map(hexChar => hexChar + hexChar)
          .join('')
      : hex

  const r = parseInt(fullHex.substring(0, 2), 16)
  const g = parseInt(fullHex.substring(2, 4), 16)
  const b = parseInt(fullHex.substring(4, 6), 16)

  return { r, g, b }
}

function getLuminance(r, g, b) {
  const calculate = value => {
    const normalized = value / 255
    return normalized <= 0.03928
      ? normalized / 12.92
      : ((normalized + 0.055) / 1.055) ** 2.4
  }

  return 0.2126 * calculate(r) + 0.7152 * calculate(g) + 0.0722 * calculate(b)
}

function isColourDarkRGB(rgb) {
  const { r, g, b } = rgb
  const luminance = getLuminance(r, g, b)

  return luminance < 0.5
}

const lightenRGBColour = (rgb, percent) => {
  const { r, g, b } = rgb

  const lightenedRed = Math.min(
    255,
    Math.floor(r + ((255 - r) * percent) / 100)
  )
  const lightenedGreen = Math.min(
    255,
    Math.floor(g + ((255 - g) * percent) / 100)
  )
  const lightenedBlue = Math.min(
    255,
    Math.floor(b + ((255 - b) * percent) / 100)
  )

  return { r: lightenedRed, g: lightenedGreen, b: lightenedBlue }
}

const darkenRGBColour = (rgb, percent) => {
  const { r, g, b } = rgb

  const darkenedRed = Math.max(0, Math.floor(r * (1 - percent / 100)))
  const darkenedGreen = Math.max(0, Math.floor(g * (1 - percent / 100)))
  const darkenedBlue = Math.max(0, Math.floor(b * (1 - percent / 100)))

  return { r: darkenedRed, g: darkenedGreen, b: darkenedBlue }
}

export {
  isColourDarkRGB,
  hexToRgb,
  lightenRGBColour,
  darkenRGBColour,
  getLuminance,
}
